import Textarea from 'components/Fields/Textarea'
import Input from 'components/Fields/Input'
import Select from 'components/Fields/Select'
import Checkbox from 'components/Fields/Checkbox'
import CheckboxGroup from 'components/Fields/CheckboxGroup'
import RadioGroup from 'components/Fields/RadioGroup'
import Address from 'components/Fields/Address'
import Phone from 'components/Fields/Phone'
import Switch from 'components/Fields/Switch'
import SwitchGroup from 'components/Fields/SwitchGroup'
import Duration from 'components/Fields/Duration'
import FileUpload from 'components/Fields/FileUpload'
import VideoUpload from 'components/Fields/VideoUpload'
import ImageUpload from 'components/Fields/ImageUpload'
import ImageUploadBunny from 'components/Fields/ImageUploadBunny'
import Datepicker from 'components/Fields/Datepicker'
import DatepickerRange from 'components/Fields/DatepickerRange'
import RangeSlider from 'components/Fields/RangeSlider'
import Divider from 'components/Fields/Divider'
import Badges from 'components/Fields/Badges'
import InputList from 'components/Fields/InputList'
import EditableInput from 'components/Fields/EditableInput'

const FieldController = (field) => {
  switch (field.type) {
    case 'textarea':
      return <Textarea {...field} />
    case 'checkbox':
      return <Checkbox {...field} />
    case 'checkboxgroup':
      return <CheckboxGroup {...field} />
    case 'radiogroup':
      return <RadioGroup {...field} />
    case 'select':
      return <Select {...field} />
    case 'address':
      return <Address {...field} />
    case 'phone':
      return <Phone {...field} />
    case 'switch':
      return <Switch {...field} />
    case 'switchgroup':
      return <SwitchGroup {...field} />
    case 'duration':
      return <Duration {...field} />
    case 'file':
      return <FileUpload {...field} />
    case 'datepicker':
      return <Datepicker {...field} />
    case 'datepickerrange':
      return <DatepickerRange {...field} />
    case 'range':
      return <RangeSlider {...field} />
    case 'divider':
      return <Divider {...field} />
    case 'badges':
      return <Badges {...field} />
    case 'video':
      return <VideoUpload {...field} />
    case 'image':
      return <ImageUpload {...field} />
    case 'bunny-image':
      return <ImageUploadBunny {...field} />
    case 'inputlist':
      return <InputList {...field} />
    case 'editable':
      return <EditableInput {...field} />
    default:
      return <Input {...field} />
  }
}

export default FieldController