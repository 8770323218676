import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getValueLabel, getCustomValueLabel } from 'utils/preferences'

const PreferencesFieldsetValues = ({ fieldset, options, preferences }) => {
  const { name, title, description, fields, isCustom } = fieldset
  return (
    <MDBox key={`${name}`}>
      <MDTypography
        display="block"
        variant="button"
        fontWeight="regular"
        color="text"
        sx={{ lineHeight: 1 }}>
        {title}
      </MDTypography>
      {description ? (
        <MDTypography display="block" variant="caption" mt={0.5} mb={1}>
          {description}
        </MDTypography>
      ) : null}
      {fields.map((field, index) => {
        return (
          <MDBox key={`${field.name}-${index}`} mb={2}>
            {fields.length > 1 ? (
              <MDTypography display="block" variant="caption" color="text">
                {field.label || field.title}
              </MDTypography>
            ) : null}
            <MDTypography variant="body2" color="black">
              {isCustom
                ? getCustomValueLabel({ field, userPreferences: preferences })
                : getValueLabel({ field, options, userPreferences: preferences })}
            </MDTypography>
          </MDBox>
        )
      })}
    </MDBox>
  )
}

export default PreferencesFieldsetValues
