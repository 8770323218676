import { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import imagePlaceholder from 'assets/illustrations/image-placeholder.svg'

const Image = ({
  id,
  alt,
  formats,
  errorImage,
  format = 'mobile',
  onClick,
  sx = {},
  wrapperStyles = {},
  ...imageProps
}) => {
  // This should be a temporary check
  // normally there will not be a situation where an image exists but has no formats
  const isFormats = !!formats && !!Object.keys(formats).length
  const getBestFormat = () => {
    return isFormats ? formats[format] : imagePlaceholder
  }

  const [image, setImage] = useState(getBestFormat())

  const onError = (e) => {
    setImage(errorImage || imagePlaceholder)
  }

  const handleOnClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick({
        id,
        alt,
        formats,
        ...imageProps
      })
    }
  }

  useEffect(() => {
    setImage(getBestFormat())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formats])

  return (
    <MDBox {...wrapperStyles}> 
      { image ? (
        <MDBox
          component="img"
          src={image}
          alt={alt || 'Image'}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          sx={{ objectFit: 'cover', ...sx }}
          onError={onError}
          onClick={handleOnClick}
          {...imageProps}
        />
      ) : null }
    </MDBox>
  )
}

export default Image
