const ICONS = [
  "airConditioning",
  "baby",
  "barbecue",
  "basket",
  "beach",
  "bed",
  "billiard",
  "calendar",
  "campfire",
  "cardAccount",
  "chair",
  "chat",
  "chevronDown",
  "chevronRight",
  "close",
  "coffee",
  "coffeeTakeaway",
  "conferenceRoom",
  "coworking",
  "desktopCheck",
  "dialPad",
  "elevator",
  "facebook",
  "fireplace",
  "flipchart",
  "forwardClock",
  "garden",
  "glutenFree",
  "heating",
  "instagram",
  "kitchen",
  "lake",
  "laundry",
  "link",
  "linkedin",
  "lock",
  "lockRotation",
  "logo",
  "meetingRoom",
  "microphone",
  "newWindow",
  "outside",
  "palette",
  "panorama",
  "paper",
  "parking",
  "passwordLock",
  "patio",
  "people",
  "petsAllowed",
  "phoneRinging",
  "piano",
  "pin",
  "pool",
  "printer",
  "projector",
  "ruler",
  "sauna",
  "scroll",
  "shower",
  "ski",
  "smokingArea",
  "speaker",
  "star",
  "starBadge",
  "stroller",
  "tableLamp",
  "terrace",
  "tv",
  "twitter",
  "users",
  "vegan",
  "vegetarian",
  "wheelchair",
  "wifi",
  "workspace",
  "single",
  "double",
  "french",
  "suite",
  "apartment"
].map((value) => ({ label: value, value }))

export default ICONS