import town from './schemas/town'
import accommodation from './schemas/accommodation'
import catering from './schemas/catering'
import experience from './schemas/experience'
import workshop from './schemas/workshop'
import coworking from './schemas/coworking'
import plenaryHall from './schemas/plenary-hall'
import allInVenue from './schemas/all-in-venue'
import venueAccommodation from './schemas/venue-accommodation'

const schemas = {
  town,
  accommodation,
  catering,
  experience,
  workshop,
  coworking,
  plenaryHall,
  allInVenue,
  venueAccommodation
}

export default schemas
