import { useState, useEffect } from 'react'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAlert from 'components/MDAlert'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import ImageComponent from 'components/Fields/ImageUploadBunny/ImageComponent'
import { BunnyGallerySkeleton } from './Skeletons'
import FileUpload from 'components/Fields/FileUpload'

import noVideos from 'assets/illustrations/no_videos.svg'
import internalError from 'assets/illustrations/error.svg'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon
} from '@mui/material'

const CustomTabPanel = ({ children, tab, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={tab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {tab === index && <MDBox sx={{ pb: 2 }}>{children}</MDBox>}
    </div>
  )
}

const ImageGalleryDialog = ({
  open,
  images,
  selected,
  isLoading,
  isError,
  uploadProps,
  error,
  onImageAdded,
  onImageSelect,
  onClose
}) => {
  const [tab, setTab] = useState(0)
  const [imageEvent, setImageEvent] = useState(null)
  const [selectedImage, setSelectedImage] = useState(selected)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      setImageEvent(null)
      setSelectedImage(null)
      setTab(0)
      onClose()
    }
  }

  const handleOnSave = () => {
    if (imageEvent) {
      const { name, file, event } = imageEvent
      onImageAdded(name, file, event)
    } else if (selectedImage) {
      onImageSelect(selectedImage)
    }
    handleOnClose()
  }

  const handleSelectImage = (image) => {
    if (selectedImage && selectedImage.Guid === image.Guid) {
      setSelectedImage(null)
    } else {
      setSelectedImage(image)
    }
  }

  const handleOnImageChange = (name, files, event) => {
    setImageEvent({ name, file: files[0], event })
  }

  useEffect(() => {
    if (selected && selected !== selectedImage) {
      setSelectedImage(selected)
    }
    // We do not put selectedVideo in the deps because it would not be possible change the selectedVideo otherwise
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="icon label tabs example">
            <Tab label="Choose from gallery" />
            <Tab label="Upload a new image" />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers className="dialogContent">
          <MDBox sx={{ maxHeight: 'calc(100vh - 220px)'}}>
            <CustomTabPanel tab={tab} index={0}>
              {isLoading ? (
                <BunnyGallerySkeleton />
              ) : isError ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%">
                  <MDBox
                    component="img"
                    src={internalError}
                    width="300px"
                    height="250px"
                    ml={4}
                    mb={3}
                    alt="internal error"
                  />
                  <MDTypography
                    variant="body2"
                    textAlign="center"
                    maxWidth="450px"
                    mb={4}>
                    Sorry it seems that there was an error loading the image
                    gallery. Please try again later or contact your
                    administrator!
                  </MDTypography>
                </MDBox>
              ) : images && !!images.length ? (
                <Grid
                  container
                  display="grid"
                  gridTemplateColumns="repeat(3, 1fr)"
                  gridTemplateRows="repeat(auto-fill, minmax(200px, 1fr))"
                  alignItems="start"
                  spacing={2}
                  sx={{ height: '100%' }}>
                  {images.map((image) => {
                    return (
                      <Grid item key={`image-${image.Guid}`} height="100%">
                        <ImageComponent
                          key={image.Guid}
                          selectable
                          selected={image.Guid === selectedImage?.Guid}
                          image={image}
                          onSelect={handleSelectImage}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%">
                  <MDBox
                    component="img"
                    src={noVideos}
                    width="300px"
                    height="250px"
                    ml={4}
                    mb={3}
                    alt="internal error"
                  />
                  <MDTypography
                    variant="body2"
                    textAlign="center"
                    maxWidth="450px"
                    mb={4}>
                    We cannot find any image for the moment. Upload your first
                    image!
                  </MDTypography>
                </MDBox>
              )}
            </CustomTabPanel>
            <CustomTabPanel tab={tab} index={1}>
              <FileUpload
                {...uploadProps}
                onFileChange={handleOnImageChange}
              />
            </CustomTabPanel>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton onClick={handleOnSave} variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ImageGalleryDialog
