import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: null,
  modal: null
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    notify: (state, action) => {
      state.notification = {
        timeout: 5000,
        icon: 'notifications',
        ...action.payload,
      }
    },
    closeNotification: (state, action) => {
      state.notification = null
    }
  }
});

// Selector functions
export const getNotification = (state) => state.notification;

// Action creators are generated for each case reducer function
export const { notify, closeNotification } = slice.actions;

export default slice.reducer;
