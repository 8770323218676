import { smartwayApi, processPromisesBatch } from 'services/api'

const bulkDeleteParticipants = async ({ offsiteId, selection, dispatch }) => {
  let result = null
  if (!selection || !selection.length) return
  try {
    const ids = selection.map((participant) => participant.id)
    const promises = ids.map((id) => {
      return dispatch(smartwayApi.endpoints.deleteEntity.initiate({ entity: 'offsite', id: offsiteId, action: `participants/${id}/` }))
    })
    const results = await processPromisesBatch(promises, 12)
    const isSuccess = results.every((r) => !!r.data && r.data.success)
    const allErrors = results.every((r) => !r.data || !r.data.success)
    const someErrors = results.some((r) => !r.data || !r.data.success) && !allErrors
    result = {
      success: isSuccess,
      ...(allErrors ? { error: 'None of the participants were deleted' } : {}),
      ...(someErrors ? { error: 'Some of the participants were deleted' } : {})
    }
  } catch (error) {
    console.warn(error)
    result = {
      error: 'Something went wrong. Please try again later.'
    }
  }
  return result
}

const bulkChangeParticipantsStatus = async ({ offsiteId, selection, dispatch, confirmation }) => {
  let result = null
  if (!selection || !selection.length) return
  try {
    const ids = selection.map((participant) => participant.id)
    const promises = ids.map((id) => {
      return dispatch(smartwayApi.endpoints.updateEntity.initiate({ entity: 'offsite', id: offsiteId, action: `participants/${id}/`, confirmation }))
    })
    const results = await processPromisesBatch(promises, 12)
    const isSuccess = results.every((r) => !!r.data && r.data.success)
    const allErrors = results.every((r) => !r.data || !r.data.success)
    const someErrors = results.some((r) => !r.data || !r.data.success) && !allErrors
    result = {
      success: isSuccess,
      ...(allErrors ? { error: 'None of the participants were updated' } : {}),
      ...(someErrors ? { error: 'Some of the participants were updated' } : {})
    }
  } catch (error) {
    console.warn(error)
    result = {
      error: 'Something went wrong. Please try again later.'
    }
  }
  return result
}

export { bulkDeleteParticipants, bulkChangeParticipantsStatus }