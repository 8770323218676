import { useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Form from 'components/Form'

import getSchema, { formatPreferencesToServer } from '../../schemas/offsite-confirmation'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import MobileStepper from '@mui/material/MobileStepper'
import Tooltip from '@mui/material/Tooltip'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { stripTags } from 'utils/functions'

const PreferencesStep = ({ offsite, participation, profile, onSave, onClose }) => {
  const updateProfile = async (values) => {
    try {
      const response = await dispatch(
        smartwayApi.endpoints.updateEntity.initiate({ entity: 'profile', id: 'me', ...values })
      )
      if (response && response.data) {
        return response.data
      } else {
        return null
      }
    } catch (error) {
      console.warn(error)
      return null
    }
  }
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const [initialPreferences, setInitialPreferences] = useState({})
  const { mobile, user_preferences } = profile
  const [errorFields, setErrorFields] = useState([])
  const getPreferencesSteps = (values) => {
    return [
      {
        title: !!mobile ? 'Confirm your phone number' : 'Phone number',
        description:
          'It is necessary for the host to contact you and notify checkin/checkout informations. This information will not be used in any case by Smartway for marketing or commercial purposes.',
        fields: [
          {
            label: 'Phone number',
            name: 'mobile',
            type: 'phone',
            required: true,
            variant: 'outlined',
            validationType: 'string',
            validation: [
              {
                type: 'required',
                params: 'Phone number is required'
              }
            ],
            value: mobile ? `${mobile}` : null,
            placeholder: '+39 333 333 3333',
            helperText: 'Please include the international prefix'
          }
        ],
        submit: {
          fields: ['mobile'],
          handler: updateProfile
        }
      },
      ...(offsite?.preference_form && offsite?.preference_form.fieldsets
        ? offsite?.preference_form.fieldsets.filter((f) => f.active).filter(f => {
          const preferSingle = f.name === 'roommates' && values?.room_sharing === 'SINGLE'
          return !preferSingle
        })
        : []),
      ...(offsite?.preference_form && offsite?.preference_form.custom_fields
        ? offsite?.preference_form.custom_fields.map((c) => ({
            ...c,
            fields: [{ ...c, custom: true }]
          }))
        : [])
    ]
  }
  const [preferencesSteps, setPreferencesSteps] = useState(getPreferencesSteps())

  const getInitialValues = (values) => {
    const {
      room_sharing,
      special_needs,
      transfer,
      transfer_return,
      transfer_from,
      transfer_to,
      arrival_date,
      departure_date,
      arrival_reservation_number,
      departure_reservation_number,
      customs,
      roommates,
      roommates_string
    } = values

    const customsValues = {}
    if (customs && customs.length) {
      customs.forEach((customField) => {
        return (customsValues[customField.field_key] = customField.value)
      })
    }

    return {
      room_sharing,
      special_needs,
      transfer,
      transfer_return,
      transfer_from,
      transfer_to,
      arrival_date: arrival_date
        ? new Date(arrival_date)
        : offsite.start_at
        ? new Date(offsite.start_at)
        : null,
      departure_date: departure_date
        ? new Date(departure_date)
        : offsite.end_at
        ? new Date(offsite.end_at)
        : null,
      arrival_reservation_number,
      departure_reservation_number,
      roommates,
      roommates_string: roommates_string ? roommates_string.join('|') : '',
      ...customsValues,
      mobile,
      dietary_options: user_preferences?.dietary_options || [],
      dietary_options_other: user_preferences?.dietary_options_other || '',
      allergies: user_preferences?.allergies || [],
      allergies_other: user_preferences?.allergies_other || ''
    }
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const watchedFields = preferencesSteps?.reduce((acc, fieldset) => {
    const { fields } = fieldset
    const fieldsNames = fields.map((f) => (f.name ? f.name : f.field_key))
    return [...acc, ...fieldsNames]
  }, [])
  const [preferences, setPreferences] = useState(initialValues)
  const [activeStep, setActiveStep] = useState(0)
  const [schema, setSchema] = useState(
    getSchema({ step: activeStep, preferencesSteps, offsiteId: offsite?.id, values: preferences })
  )
  const isLastStep = preferencesSteps.length > 1 ? activeStep === preferencesSteps.length - 1 : true

  const handleNext = async () => {
    if (preferencesSteps[activeStep].submit && preferencesSteps[activeStep].submit.handler) {
      const payload = preferencesSteps[activeStep].submit.fields.reduce(
        (acc, field) => ({ ...acc, [field]: preferences[field] }),
        {}
      )
      const response = await preferencesSteps[activeStep].submit.handler(payload)
      if (response) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else {
      if (preferencesSteps[activeStep].required) {
        let errorFields = []
        schema.fieldsets[0].fields
          .filter((f) => f.required)
          .forEach((field) => {
            const value =
              field.type === 'textarea'
                ? stripTags(preferences[field.name])
                : preferences[field.name]
            if (!value) {
              errorFields.push(field.label || field.name)
            }
          })
        if (!errorFields.length) {
          setErrorFields([])
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else {
          setErrorFields(errorFields)
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
  }

  const handleBack = () => {
    setErrorFields([])
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    setPreferences({ ...preferences, [name]: value })
    if (['transfer', 'room_sharing'].includes(name) && value !== preferences?.room_sharing) {
      setPreferencesSteps(getPreferencesSteps({ ...preferences, [name]: value }))
    }
  }

  const handleSavePreferences = async () => {
    if (preferencesSteps[activeStep].submit && preferencesSteps[activeStep].submit.handler) {
      await preferencesSteps[activeStep].submit.handler(preferences)
    }
    const formattedPreferences = formatPreferencesToServer(preferences, initialPreferences)
    onSave(formattedPreferences)
  }

  useEffect(() => {
    if (!initialValues || !Object.keys(initialValues).length) {
      if (participation && participation.offsite_preference) {
        const getParticipationsPreferences = async () => {
          try {
            const response = await dispatch(
              smartwayApi.endpoints.fetchEntity.initiate({
                entity: 'offsite',
                id: offsite.id,
                action: `participants/${participation.id}/preferences/`
              })
            )
            if (response && response.data) {
              const _initialValues = getInitialValues(response.data)
              setInitialValues(_initialValues)
              setPreferences(_initialValues)
              setInitialPreferences(response.data)
              setPreferencesSteps(getPreferencesSteps(_initialValues))
            }
          } catch (error) {
            console.warn(error)
          }
        }
        getParticipationsPreferences()
      } else {
        const _initialValues = getInitialValues({})
        setInitialValues(_initialValues)
        setPreferences({})
      }
    }
  }, [offsite, participation, initialValues])

  useEffect(() => {
    const _preferencesSteps = getPreferencesSteps(preferences)
    setSchema(getSchema({ step: activeStep, preferencesSteps: _preferencesSteps, offsiteId: offsite?.id, values: preferences }))
  }, [activeStep, preferencesSteps])

  return (
    <>
        <Tooltip
          title="If you close this dialog, you will lose your non-saved preferences"
          placement="bottom-end">
          <IconButton
            aria-label="close"
            onClick={handleOnClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <Icon>close</Icon>
          </IconButton>
        </Tooltip>

      <MDBox display="flex" flexDirection="column" alignItems="center">
        <DialogTitle variant="h5">Select your preferences!</DialogTitle>

        <MDBox mt={3} textAlign="center" maxWidth="500px">
          <MDTypography variant="h3">{preferencesSteps[activeStep].title}</MDTypography>
          <MDTypography
            variant="body2"
            mt={1}
            dangerouslySetInnerHTML={{
              __html: preferencesSteps[activeStep].description
            }}></MDTypography>
          <MDBox minWidth="250px">
            <Form
              {...schema}
              watchedFields={watchedFields}
              values={initialValues}
              onFieldChange={handleFieldChange}
            />
          </MDBox>
        </MDBox>
        {preferencesSteps[activeStep].required && !!errorFields.length ? (
          <MDBox width="100%" display="flex" justifyContent="center">
            <MDTypography variant="button" color="error" mt={1}>
              The following fields are required: {errorFields.join(', ')}
            </MDTypography>
          </MDBox>
        ) : null}
        <MDBox width="100%" mt={4}>
          {preferencesSteps.length > 1 ? (
            <MobileStepper
              variant="dots"
              steps={preferencesSteps.length}
              position="static"
              activeStep={activeStep}
              sx={{ background: 'transparent', flexGrow: 1 }}
              nextButton={
                isLastStep ? (
                  <MDButton variant="gradient" color="primary" onClick={handleSavePreferences}>
                    Save
                  </MDButton>
                ) : (
                  <MDButton
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === preferencesSteps.length - 1}>
                    Next
                  </MDButton>
                )
              }
              backButton={
                <MDButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                  Back
                </MDButton>
              }
            />
          ) : (
            <MDBox display="flex" justifyContent="flex-end" width="100%">
              <MDButton variant="gradient" color="primary" onClick={handleSavePreferences}>
                Save
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </>
  )
}

export default PreferencesStep
