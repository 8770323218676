import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import { getMainImage } from 'utils/image'


const getLinkFromEntity = (type, id) => {
  const types = {
    PACKAGE: 'premade-packages',
    ALL_IN_VENUE: 'locations/all-in-venues',
  }

  return `/${types[type]}/${id}`
}

const EntityCard = ({ id, type, images, featured_image, title, name, excerpt, description, onEdit, onDelete, ...props }) => {

  const handleRemove = () => {
    onDelete({ id, title, description, ...props })
  }

  const link = getLinkFromEntity(type, id)
  const mainImage = featured_image || getMainImage(images)
  const image = mainImage && mainImage.formats ? mainImage.formats.thumbnail : null

  return (
    <Card sx={{ height: '100%' }}>
      {image ? (
        <CardMedia
          sx={{ height: 80 }}
          image={image}
          title={title || name}
        />
      ) : null}
      <CardContent sx={{ flexGrow: 1 }}>
        <MDBox pt={2}>
          <MDTypography variant="h6" component="div">
            {title || name}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="secondary"
            dangerouslySetInnerHTML={{
              __html: excerpt
            }}></MDTypography>
            <Link to={link}>
              <MDButton variant="outlined" color="secondary" size="small">
                <Icon sx={{ ml: 0.5 }}>open_in_new</Icon>
              </MDButton>
            </Link>
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default EntityCard
