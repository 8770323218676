
import { useState, useRef, useEffect, useCallback } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'

import { castStringToValue, checkActivityWarnings } from 'utils/events'
import { getBestFormat, getMainImage } from 'utils/image'
import { excerptHtml } from 'utils/functions'

import {
  getHoursDiffBetweenDates,
  getMinutesDiffBetweenDates,
} from 'utils/dates'

const EXCERPT_CHARACTERS = 80

// TODO: numParticipants should be remove in the future because each slot should have a list of participants
const AgendaSlot = ({ slot, numParticipants, loading, onOverlapClick, onRemove }) => {
  const [warnings, setWarnings] = useState([])
  const containerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(loading)

  const {
    event: { id, title, start, end, extendedProps }
  } = slot
  const { activity, description, custom_image_formats, overlapEvents } = extendedProps

  const duration = getHoursDiffBetweenDates(start, end)
  const slotMinutesDuration = getMinutesDiffBetweenDates(start, end)

  /**
   * Retrieves the texts for the agenda slot including the title and subtitle.
   *
   * @return {Object} An object containing the title and subtitle texts.
   */
  const getTexts = () => {
    const charactersNumber = EXCERPT_CHARACTERS * duration
    const realTitle = castStringToValue(title)
    const activityName = activity && activity.name ? activity.name : ''
    const _title = realTitle ? realTitle : activityName
    const subtitle =  description ? excerptHtml(description, charactersNumber) : activity && activity.excerpt ? excerptHtml(activity.excerpt, charactersNumber) : ''

    return {
      title: _title,
      subtitle
    }
  }

  const handleOverlapClick = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    onOverlapClick(id)
  }, [id, onOverlapClick])


  const handleRemove = (e) => {
    e.stopPropagation()
    setIsLoading(true)
    onRemove(id)
  }

  const checkWarnings = useCallback(() => {
    const warnings = checkActivityWarnings({activity, slotMinutesDuration, numParticipants, overlapEvents, onClick: !!overlapEvents ? handleOverlapClick : null})
    if (warnings.find(w => w.id === 'overlap')) {
      const fullCalendarSlot = containerRef.current?.closest('.fc-timegrid-event-harness')
      if (fullCalendarSlot) {
        fullCalendarSlot.classList.add('agenda-slot--overlap')
      }
    }
    setWarnings(warnings)
  }, [activity, slotMinutesDuration, numParticipants, overlapEvents, handleOverlapClick])

  useEffect(() => {
    const isCustomImage = custom_image_formats && Object.keys(custom_image_formats).length
    const activityImage = activity ? activity.featured_image ? activity.featured_image : getMainImage(activity.images) : null
    if ((activityImage || isCustomImage) && containerRef.current) {
      const mainImage = isCustomImage ? {formats: custom_image_formats} : activityImage
      const targetElement = containerRef.current
      if (targetElement && mainImage && Object.keys(mainImage.formats).length) {
        targetElement.classList.add('has-image')
        targetElement.style.background = `center / cover no-repeat url(${getBestFormat(mainImage.formats, 'mobile')})`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkWarnings()
  }, [checkWarnings])

  return isLoading ? (
    <MDBox position="relative" height="100%">
      <MDBox position="absolute" width="100%" height="100%" sx={{ 
        top: 0,
        left: 0,
        zIndex: 2,
        background: 'rgba(255, 255, 255, 0.72)'
      }}>
        <Skeleton animation="wave" variant="rounded" height="100%" />
      </MDBox>
    </MDBox>
  ) : (
    <MDBox ref={containerRef} height="100%" sx={{ marginLeft: '3px' }}>
      <MDBox
        color="white"
        className="agenda-slot"
        sx={{
          cursor: 'pointer',
          color: '#344767',
          height: '100%',
          padding: '2px 6px',
          overflow: 'hidden',
          background: 'rgba(255, 255, 255, 0.72)',
          '&:hover .agenda-slot__icon': { opacity: '1 !important' }
        }}>
        <div
          className="agenda-slot__icon"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: warnings.length && slotMinutesDuration === 30 ? `${(warnings.length * 26) + 6}px` : '3px',
            top: warnings.length && slotMinutesDuration === 30 ? 'calc(100% - 20px)' :'3px',
            border: '1px solid #344767',
            borderRadius: '50%',
            width: '22px',
            height: '22px',
            opacity: 0,
            transition: 'opacity 0.3s'
          }}
          onClick={handleRemove}>
          <Icon>delete</Icon>
        </div>
        <div style={{ maxWidth: '85%', lineHeight: 1.2, marginBottom: '4px' }}>
          <p style={{
            width: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            maxHeight: '32px',
            textOverflow: 'ellipsis'
          }}>{getTexts().title}</p>
        </div>
        {duration > 0.5 ? (
          <div style={{ lineHeight: 1.1 }}>
            <MDTypography
              variant="caption"
              sx={{
                fontWeight: 400,
                color: '#344767',
                lineHeight: '15px',
                width: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: (duration * 2).toString(),
                overflow: 'hidden',
                height: (18 + duration * 18).toString() + 'px',
                textOverflow: 'ellipsis'
              }}
              dangerouslySetInnerHTML={{ __html: getTexts().subtitle }}></MDTypography>
          </div>
        ) : null}
        { warnings && !!warnings.length ? (
          <MDBox sx={{
            position: 'absolute',
            bottom: '-2px',
            right: '0px',
            zIndex: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }} >
            { warnings.map((warning, index) => {
              return (
                <MDBox key={index} mr={0.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={warning.onClick}>
                  <Tooltip title={warning.text}>
                    <MDBox display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "warning.main", borderRadius: '50%', width: '22px', height: '22px' }} >
                      <Icon sx={{ fontSize: 10, color: 'white !important' }}>{warning.icon}</Icon>
                    </MDBox>
                  </Tooltip>
                </MDBox>
              )
            })}
          </MDBox>
        ) : null }
      </MDBox>
    </MDBox>
  )
}

export default AgendaSlot
