import EditableImage from 'components/EditableImage'
import VideoUpload from 'components/Fields/VideoUpload/VideoUpload'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  zIndex: '10',
  right: '5px',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
const EditableMedia = ({ media, onEdit, onDelete }) => {
  const handleVideoChange = (event) => {
    const { value } = event.target
    if (!value) {
      onDelete(media)
    }
  }
  return media.type === 'IMAGE' ? (
    <EditableImage iconStyles={iconStyles} image={media} onEdit={onEdit} onDelete={onDelete}/>
  ) : (
    <VideoUpload value={media.video_id} onEdit={onEdit} onChange={handleVideoChange} />
  )
}

export default EditableMedia
