import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Image from 'components/Image/Image'
import PreferencesStep from './components/OffsiteConfirmation/PreferencesStep'

const ConfirmationStep = ({ offsite, onSave }) => {
  const handleConfirmation = (confirmation) => {
    onSave(confirmation)
  }
  const offsiteBtoaUrl = btoa(`{"offer":${offsite.id}}`)
  const offsiteUrl = `https://listings.smartway.work/offsites/${offsiteBtoaUrl}`
  return (
    <MDBox display="flex" flexDirection="column" alignItems="center">
      <DialogTitle variant="h5" textAlign="center">
        You've been invited to this offsite!
      </DialogTitle>

      <MDBox width="85%">
        <Image
          {...offsite?.fullVenue?.featured_image}
          alt={offsite.title}
          sx={{ width: '100%', maxHeight: { xs: 120, md: 200 } }}
        />
      </MDBox>

      <MDBox mt={{ xs: 2, md: 3 }} textAlign="center">
        <MDTypography variant="h3" fontWeight="light">
          {offsite.title}
        </MDTypography>
        <MDTypography variant="body2" mt={1}>
          {offsite.fullVenue?.name}
        </MDTypography>
        <MDTypography variant="body2">{offsite.dates}</MDTypography>

        <MDBox mt={{ xs: 2, md: 3 }}>
          <a href={offsiteUrl} target="_blank" rel="noreferrer">
            <MDButton color="primary">Discover more</MDButton>
          </a>
        </MDBox>

        <MDTypography variant="h3" fontWeight="light" mt={{ xs: 2, md: 3 }}>
          Will you come?
        </MDTypography>
        <MDTypography variant="body2">You can change it later.</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" mt={4}>
        <MDBox
          onClick={() => handleConfirmation({ confirmation: false })}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mr={4}
          sx={{ cursor: 'pointer' }}>
          <Icon color="primary" fontSize="large">
            thumb_down_alt
          </Icon>
          <MDTypography variant="body2">I decline</MDTypography>
        </MDBox>
        <MDBox
          onClick={() => handleConfirmation({ confirmation: true })}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ cursor: 'pointer' }}>
          <Icon color="success" fontSize="large">
            thumb_up_alt
          </Icon>
          <MDTypography variant="body2">I confirm</MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

const OffsiteConfirmationDialog = ({
  offsite,
  participation,
  profile,
  open,
  initialStep,
  setOpen,
  onSave,
  onClose
}) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(initialStep)
  const handleOnSave = async ({ confirmation }) => {
    try {
      const response = await dispatch(smartwayApi.endpoints.updateEntity.initiate({
        entity: 'offsite',
        id: offsite.id,
        action: `participants/${participation.id}/`,
        confirmation
      }))
    } catch (error) {
      console.warn(error)
    } finally {
      if (confirmation) {
        setStep(1)
      } else {
        handleOnClose({ refresh: true })
      }
    }
  }

  const resetState = () => {
    setStep(0)
  }

  
  const handleOnClose = (args) => {
    if (onClose && typeof onClose === 'function') {
      onClose({ refresh: args?.refresh || false })
      setTimeout(() => {
        resetState()
      }, 200)
    }
  }

  const handleSave = (preferences) => {
    const { id, dates, fullVenue, last_invited_at, room, preference_form, ...previousValues } =
      offsite
    const fullPreferences = { ...previousValues, ...preferences }
    onSave(fullPreferences)
    handleOnClose()
  }

  useEffect(() => {
    setStep(initialStep)
  }, [open, initialStep])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby={offsite.title}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}>
      <MDBox sx={{ p: 1 }}>
        <DialogContent>
          {step === 0 ? (
            <ConfirmationStep
              offsite={offsite}
              onSave={handleOnSave}
            />
          ) : null}
          {step === 1 ? (
            <PreferencesStep
              profile={profile}
              offsite={offsite}
              participation={participation}
              onSave={handleSave}
              onClose={handleOnClose}
            />
          ) : null}
        </DialogContent>
      </MDBox>
    </Dialog>
  )
}

export default OffsiteConfirmationDialog
