const getPublicLinkByLocation = ({ id, location_type }) => {
  const base = 'https://listings.smartway.work/'
  const publicPath = {
    accommodation: 'accommodations',
    experience: 'experiences',
    catering: 'food-drink',
    coworking: 'coworkings',
    plenary_hall: 'event-spaces',
    workshop: 'team-building'
  }
  if (!publicPath[location_type]) {
    console.warn(`Public path not found for ${location_type}`)
    return ''
  }
  return `${base}${publicPath[location_type]}/${id}`
}

export { getPublicLinkByLocation }