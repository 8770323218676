import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'

export class PagesLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    const page = url.searchParams.get('page')
    try {
      const results = await 
        this._loader(
          smartwayApi.endpoints.fetchEntities,
          request,
          { entity: 'page', page_size: 20, ...Object.fromEntries(url.searchParams)}
        )
      return {
        pages: results.results,
        pagination: {
          page,
          lastPage: results.page_number
        },
        searchParams: url.searchParams
      }
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id || params.id === 'new'
    if (isNew) {
      return { page: {}}
    }
    const [page] = await Promise.all([
      this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        { entity: 'page', id: params.id},
      )
    ])
    return {
      page,
    }

  }
}