import { Link } from 'react-router-dom'
const LinkWrapper = ({ target, href, children, className, ...rest }) => {
  let isLink = false
  let internal = true

  if (href) {
    if (['_blank', '_self', 'self'].includes(target)) {
      isLink = true
      if (href.startsWith('http')) {
        internal = false
      }
    }
  }

  return (
    <span style={{ color: 'inherit', textDecoration: 'inherit' }}>
      {isLink ? (
        internal ? (
          <Link to={href} passHref target={target} {...rest}>
            {children}
          </Link>
        ) : (
          <a href={href} target={target} {...rest}>
            {children}
          </a>
        )
      ) : (
        <span>{children}</span>
      )}
    </span>
  )
}

export default LinkWrapper
