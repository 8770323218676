import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

export const CardsSkeleton = () => {
  return (
    <Grid container rowSpacing={6} columnSpacing={3} pb={8}>
      {Array.from(new Array(8)).map((_, index) => (
        <Grid key={`entity-card-skeleton-${index}`} item xs={12} sm={6} md={4}>
          <Skeleton animation="wave" variant="rounded" height={205} />
        </Grid>
      ))}
    </Grid>
  )
}
