import { getPublicLinkByLocation } from 'utils/links'

import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const ParticipantCaterings = ({ caterings }) => {
  const COLUMNS = [
    {
      name: 'when',
      cols: 2
    },
    {
      name: 'where',
      cols: 3
    },
    {
      name: 'description',
      cols: 4
    }
  ]

  const getCellValue = (activity, column) => {
    const { time, title, description, location } = activity
    switch (column) {
      case 'when':
        return (
          <MDTypography variant="button" fontWeight="normal" color="text" textTransform="uppercase">
            {time}
          </MDTypography>
        )
      case 'where':
        return (
          <MDTypography variant="button" fontWeight="regular" color="text">
            {location ? (
              <a
                style={{ borderBottom: '1px solid #7b809a', color: 'inherit' }}
                href={getPublicLinkByLocation(location)}
                target="_blank"
                rel="noreferrer">
                {title}
              </a>
            ) : (
              title
            )}
          </MDTypography>
        )
      case 'description':
        return (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            dangerouslySetInnerHTML={{ __html: description }}></MDTypography>
        )
      default:
        return ''
    }
  }
  return (
    <MDBox mb={4}>
      <MDTypography variant="h4" fontWeight="medium" mx={2} mb={2}>
        Food & Drinks
      </MDTypography>
      <MDBox mx={2}>
        <Grid container spacing={2} mb={2} sx={{ borderBottom: '1px solid #E2E8F0' }}>
          {COLUMNS.map((col, index) => {
            return (
              <Grid item xs={col.cols} key={`day-header-${index}`}>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="medium"
                  textTransform="uppercase">
                  {col.name}
                </MDTypography>
              </Grid>
            )
          })}
        </Grid>
        {caterings.map((catering, index) => {
          return (
            <Grid container spacing={2} key={`catering-${index}`} mt={0}>
              {COLUMNS.map((col, colIndex) => {
                return (
                  <Grid item xs={col.cols} key={`catering-${index}-${colIndex}`}>
                    {getCellValue(catering, col.name)}
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </MDBox>
    </MDBox>
  )
}

export default ParticipantCaterings
