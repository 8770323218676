const NOTIFICATION_TYPES = {
  'OFFSITE_INVITATION': {
    color: 'success',
    icon: 'upcoming',
    link: '/dashboard/profile'
  },
  'OFFSITE_INVITATION_REMINDER': {
    color: 'warning',
    icon: 'event_available',
    link: '/dashboard/profile'
  },
  'OFFSITE_ACCOMMODATION_READY': {
    color: 'success',
    icon: 'king_bed',
    link: '/dashboard/profile'
  },
  'OFFSITE_BUSINESS_ACTIVITY_REMINDER': {
    color: 'warning',
    icon: 'event',
    link: '/dashboard/profile'
  },
  'OFFSITE_EXPERIENTIAL_ACTIVITY_REMINDER': {
    color: 'warning',
    icon: 'event',
    link: '/dashboard/profile'
  },
  'OFFSITE_CHECKIN_REMINDER': {
    color: 'warning',
    icon: 'room_service',
    link: '/dashboard/profile'
  },
  'OFFSITE_CONTRACT_SIGNED': {
    color: 'success',
    icon: 'rocket',
    link: '/dashboard/profile'
  },
  'OFFSITE_COMPLETE_REMINDER_1': {
    color: 'warning',
    icon: 'room_service',
    link: '/dashboard/profile'
  },
  'OFFSITE_COMPLETE_REMINDER_2': {
    color: 'warning',
    icon: 'room_service',
    link: '/dashboard/profile'
  },
  'OFFSITE_SERVICE': {
    color: 'success',
    icon: 'upcoming',
    link: '/dashboard/profile'
  },
  'CHAT': {
    color: 'success',
    icon: 'chat',
    // link: '/dashboard/profile'
  }
}

const getNotificationPropsByType = (type) => {
  return NOTIFICATION_TYPES[type]
}


export { NOTIFICATION_TYPES, getNotificationPropsByType }