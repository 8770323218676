import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { grey } from '@mui/material/colors';

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const RadioButtonsGroup = ({
  name,
  value,
  label,
  options,
  errors,
  wrapperStyles = {},
  onChange
}) => {
  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <MDBox sx={{ position: 'relative', ...wrapperStyles }}>
      <FormControl>
      {label ? <MDTypography variant="button" color="secondary" display="block" mb={2} fontWeight="regular">{label}</MDTypography> : null}
        <RadioGroup
          aria-labelledby={`radio-buttons-${name}`}
          name={name}
          value={value}
          onChange={handleChange}>
          {options && options.length
            ? options.map(({ value, name, label, ...control }, index) => {
                return (
                  <FormControlLabel
                    value={value}
                    color="secondary"
                    {...control}
                    sx={{
                      color: '#495057',
                      display: 'inline-flex',
                      '& > span': { fontWeight: '400 !important' }
                    }}
                    control={
                      <Radio 
                        color="secondary" 
                        sx={{
                          '&:after': {
                            backgroundImage: `linear-gradient(195deg, ${grey[700]} 0%, ${grey[700]} 100%)`,
                          },
                          color: grey[700],
                          '&.Mui-checked': {
                            color: grey[700],
                            '.MuiSvgIcon-root': {
                              borderColor: `${grey[700]}`,
                            }
                          }
                        }}
                      />}
                    label={label}
                    key={`radio-option-${index}`}
                  />
                )
              })
            : null}
        </RadioGroup>
      </FormControl>
      {errors ? (
        <MDTypography color="error" variant="caption">
          {errors}
        </MDTypography>
      ) : null}
    </MDBox>
  )
}

export default RadioButtonsGroup
