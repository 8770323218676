export const PAGE_TEMPLATES = {
  homepage: [
    {
      type: 'HERO',
      title: 'Hero',
      repeater: {
        title: 'Clients logos',
        media: {
          title: 'Logo',
          showPosition: false,
          showType: false,
          defaultType: 'IMAGE'
        }
      },
      media: {
        title: 'Hero image',
        showPosition: false
      }
    },
    {
      type: 'HIGHLIGHTED',
      title: 'Highlights',
      entity: {
        title: 'Select highlighted packages or venues'
      }
    },
    {
      type: 'ABOUT',
      title: 'About',
      media: {
        title: 'Background media',
        showPosition: false
      },
      repeater: {
        title: 'About items',
      }
    },
    {
      type: 'SLIDER_SECTION',
      title: 'First slider section',
      index: 0,
      entity: {
        title: 'Choose a package/venue'
      }
    },
    {
      type: 'SLIDER_SECTION',
      title: 'Second slider section',
      index: 1,
      entity: {
        title: 'Choose a package/venue'
      }
    },
    {
      type: 'SLIDER_SECTION',
      title: 'Third slider section',
      index: 2,
      entity: {
        title: 'Choose a package/venue'
      }
    },
    {
      type: 'FULL_SCREEN_BANNER',
      title: 'Fullscreen banner',
      media: {
        title: 'Banner image',
        showPosition: true,
      }
    },
    {
      type: 'TEXT_MEDIA',
      title: 'How does it work?',
      media: {
        title: 'Media',
        showPosition: true
      }
    },
    {
      type: 'TESTIMONIALS',
      title: 'Testimonials',
      repeater: {
        title: 'Testimonials',
        media: {
          title: 'Avatar',
          showPosition: false,
          showType: false,
          defaultType: 'IMAGE'
        }
      }
    },
    {
      type: 'QUOTATION_BANNER',
      title: 'Quotation banner',
      media: {
        title: 'Banner image',
        showPosition: true
      }
    }
  ],
  packages: [
    {
      type: 'SLIDER_SECTION',
      title: 'Slider section (Featured packages)',
      entity: {
        title: 'Choose a package'
      }
    },
    {
      type: 'TESTIMONIALS',
      title: 'Testimonials',
      repeater: {
        title: 'Testimonials',
        media: {
          title: 'Avatar',
          showPosition: false,
          showType: false,
          defaultType: 'IMAGE'
        }
      }
    },
    {
      type: 'QUOTATION_BANNER',
      title: 'Quotation banner',
      media: {
        title: 'Banner image',
        showPosition: false
      }
    }
  ]
}

export const PAGE_TEMPLATES_NAMES = Object.keys(PAGE_TEMPLATES) 


export const cleanSectionValues = ({section, isRepeaterItem, parentSection}) => {
  let _section
  const toExclude = ['media']
  for (const [key, value] of Object.entries(section)) {
    if (value !== null && !toExclude.includes(key)) {
      _section = { ..._section, [key]: value }
    }
  }

  let _items = _section.items || []
  if (_items && _items.length) {
    _items = _items.map((item) => item.id)
  } 

  const repeaterProps = isRepeaterItem ? {
    type: 'INNER_SECTION',
    parent_section: parentSection.id,
    parent_type: parentSection.type
  } : {}

  return {
    ..._section,
    ...repeaterProps,
    items: _items
  }
}

export const formatSectionValuesToServer = ({ sectionValues, isRepeaterItem = false, parentSection = {}}) => {
  const {
    type,
    href,
    label,
    target,
    anchor,
    cta_icon,
    ...section
  } = sectionValues
  if (!type) {
    return {
      error: 'Missing section type'
    }
  }
  return cleanSectionValues({
    section: {
      ...section,
      type,
      cta: {
        href,
        label,
        target,
        anchor,
        icon: cta_icon
      },
    },
    isRepeaterItem,
    parentSection
  })
}
export const formatSectionValuesFromServer = (values) => {
  const { type, cta, ...section } = values
  const ctaValues = {
    href: cta?.href || '',
    label: cta?.label || '',
    target: cta?.target || '',
    anchor: cta?.anchor || '',
    cta_icon: cta?.icon || ''
  }


  return {
    type,
    ...ctaValues,
    ...section,
  }
}
