import { useState, useEffect } from 'react'
import {
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
  IconButton,
  MobileStepper
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox/index'
import MDTypography from 'components/MDTypography/index'

import Portal from 'components/Portal'
import Form from 'components/Form/Form'
import InvitationEmailRecap from 'components/InvitationEmailRecap'
import getSchema from './schemas/invitation'
import { removeDuplicates } from 'utils/functions'

const SendInvitationDialog = ({ open, initialStep, initialValues, offsite, profile, participants, onSave, onClose }) => {
  const someoneAlreadyInvited = removeDuplicates(participants, 'email').some(
    (p) => !!p.last_invited_at
  )
  const [activeStep, setActiveStep] = useState(initialStep || 0)
  const [isLoading, setIsLoading] = useState(false)
  const [_initialValues, setInitialValues] = useState(initialValues || {})
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [mailValues, setMailValues] = useState({
    manager: `by ${profile.first_name} ${profile.last_name}`,
  })
  const [schema, setSchema] = useState(getSchema(removeDuplicates(participants, 'email')))

  const resetState = () => {
    setActiveStep(0)
    setIsLoading(false)
    setSubmitDisabled(false)
    setInitialValues({})
  }

  const handleOnClose = (e) => {
    e.stopPropagation()
    resetState()
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnSave = async () => {
    if (onSave && typeof onSave === 'function') {
      try {
        await onSave({ peopleValues: _initialValues, mailValues })
        resetState()
      } catch (error) {
        console.warn(error)
        setSubmitDisabled(false)
      }

    }
  }

  const handleNext = (values) => {
    setInitialValues(values)
    setActiveStep(1)
  }
  const handleBack = () => {
    setActiveStep(0)
  }

  const handleOnChange = ({ manager, custom_text }) => {
    setMailValues({ manager, custom_text })
  }

  const handleFieldChange = (event, _values) => {
    const { name, checked } = event.target
    const values = { ..._values, [name]: checked }
    if (Object.values(values).some((v) => !!v)) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }

  useEffect(() => {
    setSchema(getSchema(removeDuplicates(participants, 'email')))
  }, [participants, setSchema])

  useEffect(() => {
    if (JSON.stringify(_initialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(initialValues)
    }
    if (initialStep) {
      setActiveStep(initialStep)
    }
  }, [initialStep, initialValues, open])

  return (
    <Portal>
      <Dialog
        key={open}
        open={open}
        maxWidth="md"
        fullWidth
        sx={{ marginLeft: { xl: '250px' } }}
        onClose={handleOnClose}>
        <Container>
          <DialogTitle></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleOnClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <Icon>close</Icon>
          </IconButton>
          <DialogContent className="dialogContent" sx={{ pt: 0 }}>
            {activeStep === 0 ? (
              <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <MDBox maxWidth="400px">
                  <MDTypography variant="h4" mb={2}>Send invites</MDTypography>
                  <MDTypography variant="body2" mb={2}>
                    {!someoneAlreadyInvited ? (
                      <p>
                        You are about to send an email to everyone in the participant list and invite
                        them to your offsite!
                      </p>
                    ) : (
                      <>
                        <p>You can send a mail to invite people from the dashboard.</p>
                        <p>Choose your recipients: </p>
                      </>
                    )}
                  </MDTypography>

                  <Grid container>
                    <Form
                      {...schema}
                      values={_initialValues}
                      onSubmit={handleNext}
                      watchedFields={['not_invited', 'not_registered', 'not_confirmed', 'everyone']}
                      onFieldChange={handleFieldChange}
                    />
                  </Grid>
                  <MDTypography variant="body2" mt={2}>
                    In the next step you can review the details of the communication you are going to
                    send.
                  </MDTypography>
                </MDBox>
              </MDBox>
            ) : null}
            { activeStep === 1 ? (
              <MDBox>
                <MDTypography variant="h4" mb={2}>Email preview</MDTypography>
                <MDTypography variant="body2" fontSize="20px" mb={3}>This is a preview of the invitation email you are going to send. You can modify or remove the name of the sender and add a custom message.</MDTypography>
                <InvitationEmailRecap offsite={offsite} profile={profile} onChange={handleOnChange}/>
              </MDBox>
            ) : null }
          </DialogContent>
          { initialStep !== undefined ? (
            <MDBox width="100%" display="flex" justifyContent="flex-end" my={4}>
              <LoadingButton
                loading={isLoading}
                onClick={handleOnSave}
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white !important',
                  '&:hover': {
                    backgroundColor: 'error.light'
                  }
                }}>
                Send invitations
              </LoadingButton>
            </MDBox>
          ) : (
            <MDBox width="100%" mt={4}>
              <MobileStepper
                steps={2}
                position="static"
                activeStep={activeStep}
                sx={{ background: 'transparent', flexGrow: 1 }}
                nextButton={
                  activeStep === 1 ? (
                    <LoadingButton
                      disabled={submitDisabled}
                      loading={isLoading}
                      onClick={handleOnSave}
                      variant="contained"
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'white !important',
                        '&:hover': {
                          backgroundColor: 'error.light'
                        }
                      }}>
                      Send invitations
                    </LoadingButton>
                  ) : (
                    <MDButton
                      type="submit"
                      form="invitation-form"
                      size="small"
                      color="secondary"
                      disabled={submitDisabled}>
                      Next
                    </MDButton>
                  )
                }
                backButton={
                  <MDButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                    Back
                  </MDButton>
                }
              />
            </MDBox>
          )}
        </Container>
      </Dialog>
    </Portal>
  )
}

export default SendInvitationDialog
