import { defer } from 'react-router-dom'

import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'

export class UsersLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    try {
      const results = this._loader(
          smartwayApi.endpoints.fetchEntities,
          request,
          { entity: 'user', transformResponse: false, ...Object.fromEntries(url.searchParams), page_size: url.searchParams.get('page_size') || 10 }
        )
      return defer({
        results,
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const [user] = await Promise.all([
      this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        { entity: 'user', id: params.id },
      )
    ])
    return {
      profile: {...user, ...user.profile, id: user.id, profile_id: user.profile.id},
    }

  }
}