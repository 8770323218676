import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import {
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import EntityPickerCard from './EntityPickerCard'
import { getApiEntityByType } from './EntityPicker'
import { CardsSkeleton } from './Skeletons'
import noResults from 'assets/illustrations/relax.svg'

const EntityDialog = ({ open, title, onSave, onClose, type, selected }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [results, setResults] = useState(null)
  const [selectedItems, setSelectedItems] = useState(selected)
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }
  const handleOnSave = () => {
    if (onSave && typeof onSave === 'function') {
      onSave(selectedItems)
    }
  }

  const handleEntitySelect = (entity) => {
    if (selectedItems.map((r) => r.id).includes(entity.id)) {
      setSelectedItems(selectedItems.filter((r) => r.id !== entity.id))
    } else {
      setSelectedItems([...selectedItems, entity])
    }
  }

  useEffect(() => {
    if (type) {
      const fetchAll = async () => {
        try {
          const _entity = getApiEntityByType(type)
          const response = await dispatch(
            smartwayApi.endpoints.fetchAllEntities.initiate({
              entity: _entity
            })
          )
          if (response && response.data) {
            setResults(response.data)
            setSelectedItems(response.data.filter((r) => selected.map((r) => r.id).includes(r.id)))
          }
        } catch (error) {
          console.warn(error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchAll()
    }
  }, [dispatch, selected, type])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Grid
            container
            spacing={2}
            sx={{
              '.entity-picker-card:not(.selected):not(:hover)': {
                backgroundColor: 'rgba(200, 200, 200, 0.2)',
                boxShadow: 'none'
              }
            }}>
            {isLoading ? (
              <CardsSkeleton />
            ) : results && results.length ? (
              <>
                {results.map((entity) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={entity.id}>
                      <EntityPickerCard
                        {...entity}
                        onSelect={handleEntitySelect}
                        selected={selectedItems.map((e) => e.id).includes(entity.id)}
                      />
                    </Grid>
                  )
                })}
              </>
            ) : (
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
                minHeight="500px">
                <MDBox
                  component="img"
                  src={noResults}
                  alt="no results"
                  width="100%"
                  maxHeight={200}
                  mb={3}
                />
                <MDTypography variant="h5" fontWeight="light" textAlign="center">
                  There are no results for {type}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Close</MDButton>
          <MDButton variant="contained" color="primary" onClick={handleOnSave}>
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default EntityDialog
