import { Route, createRoutesFromElements, createBrowserRouter } from 'react-router-dom'

import Root from 'layouts/Root'
import GlobalError from 'layouts/GlobalError'
// Material Dashboard 2 PRO React layouts
import Profile from 'layouts/pages/account/profile'
import SignIn from 'layouts/authentication/sign-in'
import Registration from 'layouts/authentication/sign-up'
import ConfirmRegistration from 'layouts/authentication/sign-up/ConfirmRegistration'
import ResetPassword from 'layouts/authentication/reset-password'
import Logout from 'layouts/authentication/logout'
import BaseRedirect from 'layouts/authentication/base-redirect'

import ProtectedRoute from 'layouts/authentication/components/ProtectedRoute'

import { LocationsLoader } from './locations.loader'
import { CompaniesLoader } from './companies.loader'
import { AuthLoader } from './auth.loader'
import { UsersLoader } from './users.loader'
import { OffsitesLoader } from './offsites.loader'
import { OffersLoader } from './offers.loader'
import { PackagesLoader } from './packages.loader'
import { AgendasLoader } from './agendas.loader'
import { PagesLoader } from './pages.loader'

import { editOrCreateAction, listAction } from './default.actions'
import {
  loginAction,
  updateProfileAction,
  resetPasswordAction,
  registerAction,
  confirmRegistrationAction
} from './auth.actions'
import { editOrCreateCompanyAction } from './companies.actions'
import { definitionAction, offsiteDefaultAction } from './offsites.actions'
import { updateUserAction, usersListAction } from './users.actions'
import { editOrCreatePageAction } from './pages.actions'

import LocationDetailPage from 'layouts/pages/locations/detailPage/LocationDetailPage'
import LocationListPage from 'layouts/pages/locations/listPage/LocationListPage'

import CompanyListPage from 'layouts/pages/companies/list/CompanyListPage'
import CompanyDetailPage from 'layouts/pages/companies/detail/CompanyDetailPage'

import PagesListPage from 'layouts/pages/pages/list/PagesListPage'
import PagesDetailPage from 'layouts/pages/pages/detail/PagesDetailPage'

import UsersListPage from 'layouts/pages/users/list/UsersListPage'

import PackagesListPage from 'layouts/pages/packages/list/PackagesListPage'
import PackageDetailPage from 'layouts/pages/packages/detail/PackageDetailPage'

import AgendasListPage from 'layouts/pages/agendas/list/AgendasListPage'
import AgendaDetailPage from 'layouts/pages/agendas/detail/AgendaDetailPage'

import OffsiteDetailPage from 'layouts/pages/offsites/detail'
import DefinitionStep from 'layouts/pages/offsites/detail/steps/DefinitionStep'
import OffsiteListPage from 'layouts/pages/offsites/list'
import ParticipantsStepWrapper from 'layouts/pages/offsites/detail/steps/ParticipantsStepWrapper'
import AgendaStepWrapper from 'layouts/pages/offsites/detail/steps/AgendaStepWrapper'
import ReviewStep from 'layouts/pages/offsites/detail/steps/ReviewStep'
import CommunicationStep from 'layouts/pages/offsites/detail/steps/CommunicationStep'

import OffersListPage from 'layouts/pages/offers/list'
import OfferDetailPage from 'layouts/pages/offers/detail'

import NotificationsPage from 'layouts/pages/notifications'
import OfferStep from 'layouts/pages/offsites/detail/steps/OfferStep'
import AccommodationStepFetchWrapper from 'layouts/pages/offsites/detail/steps/AccommodationStepFetchWrapper'

const DynamicBreadcrumb = ({ match, ...rest }) => {
  return <>{match.id}</>
}

export const getRouter = (store) => {
  const companyLoader = new CompaniesLoader(store)
  const authLoader = new AuthLoader(store)
  const usersLoader = new UsersLoader(store)
  const offsitesLoader = new OffsitesLoader(store)
  const offersLoader = new OffersLoader(store)
  const packagesLoader = new PackagesLoader(store)
  const agendasLoader = new AgendasLoader(store)
  const pagesLoader = new PagesLoader(store)

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<GlobalError />}>
        <Route index element={<BaseRedirect to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Root />
            </ProtectedRoute>
          }>
          <Route index element={<BaseRedirect to="profile" />} />
          <Route path="locations">
            <Route index element={<BaseRedirect to="towns" />} />
            {[
              { name: 'town', label: 'town' },
              { name: 'accommodation', label: 'accommodation' },
              { name: 'catering', label: 'catering' },
              { name: 'experience', label: 'experience' },
              { name: 'workshop', label: 'workshop' },
              { name: 'coworking', label: 'coworking' },
              { name: 'plenaryHall', label: 'event-space' },
              { name: 'allInVenue', label: 'all-in-venue' },
              { name: 'venueAccommodation', label: 'all-in-venues-accommodation' }
            ].map(({ name, label }) => {
              const loader = new LocationsLoader(store, name)
              const args = [store.dispatch, name, `/dashboard/locations/${label}s`]
              return (
                <Route path={`${label}s`} key={name}>
                  <Route
                    index
                    element={<LocationListPage entityName={name} routeSegment={label} />}
                    loader={loader.listLoader}
                    action={listAction(...args)}
                  />
                  <Route
                    path="new"
                    id={`location-${name}-new`}
                    loader={loader.detailLoader}
                    element={<LocationDetailPage entityName={name} routeSegment={label} />}
                    action={editOrCreateAction(...args)}
                  />
                  <Route
                    path=":id"
                    id={`location-${name}-editing`}
                    element={
                      <LocationDetailPage entityName={name} editingMode routeSegment={label} />
                    }
                    loader={loader.detailLoader}
                    action={editOrCreateAction(...args)}
                  />
                </Route>
              )
            })}
          </Route>

          <Route path="premade-packages">
            <Route
              index
              element={<PackagesListPage />}
              loader={packagesLoader.listLoader}
              action={listAction(store.dispatch, 'package', '/dashboard/premade-packages')}
            />
            <Route
              path="new"
              element={<PackageDetailPage editingMode={false} />}
              loader={packagesLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'package', '/dashboard/premade-packages')}
            />
            <Route
              path=":id"
              element={<PackageDetailPage editingMode />}
              loader={packagesLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'package', '/dashboard/premade-packages')}
            />
          </Route>

          <Route path="agendas">
            <Route
              index
              element={<AgendasListPage />}
              loader={agendasLoader.listLoader}
              action={listAction(store.dispatch, 'agenda', '/dashboard/agendas')}
            />
            <Route
              path="new"
              element={<AgendaDetailPage editingMode={false} />}
              loader={agendasLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'agenda', '/dashboard/agendas')}
            />
            <Route
              path=":id"
              element={<AgendaDetailPage editingMode />}
              loader={agendasLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'agenda', '/dashboard/agendas')}
            />
          </Route>

          <Route path="offsites">
            <Route
              index
              element={<OffsiteListPage />}
              loader={offsitesLoader.listLoader}
              action={listAction(store.dispatch, 'offsite', '/dashboard/offsites')}
            />
            <Route
              path="new"
              element={<OffsiteDetailPage editingMode={false} />}
              loader={offsitesLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'offsite', '/dashboard/offsites')}
            />
            <Route
              path=":id"
              id="offsite-detail"
              element={<OffsiteDetailPage editingMode />}
              loader={offsitesLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'offsite', '/dashboard/offsites')}
              handle={{
                breadcrumb: ({ offsite }) => {
                  return offsite ? offsite?.title : 'New offsite'
                }
              }}
              breadcrumb={DynamicBreadcrumb}>
              <Route
                key="definition-step"
                path="definition"
                element={<DefinitionStep />}
                loader={offsitesLoader.detailDefinitionLoader}
                action={definitionAction(store.dispatch, 'offsite', '/dashboard/offsites')}
              />
              <Route
                key="participants-step"
                path="participants"
                id="offsite-participants"
                element={<ParticipantsStepWrapper />}
                loader={offsitesLoader.detailParticipantsLoader}
                action={offsiteDefaultAction(store.dispatch, 'offsite')}
              />
              <Route
                key="agenda-step"
                path="agenda"
                element={<AgendaStepWrapper />}
                loader={offsitesLoader.detailAgendaLoader}
                action={offsiteDefaultAction(store.dispatch, 'offsite')}
              />
              <Route
                key="accommodation-step"
                path="accommodations"
                element={<AccommodationStepFetchWrapper />}
                // loader={offsitesLoader.detailAccommodationsLoader}
                // action={offsiteDefaultAction(
                //   store.dispatch,
                //   'offsite',
                //   '/dashboard/offsites'
                // )}
              />
              <Route
                key="economics-step"
                path="economics"
                element={<OfferStep />}
                action={offsiteDefaultAction(store.dispatch, 'offsite', '/dashboard/offsites')}
              />
              <Route
                key="communication-step"
                path="communication"
                element={<CommunicationStep/>}
                loader={offsitesLoader.detailCommunicationLoader}
                action={definitionAction(
                  store.dispatch,
                  'offsite',
                  null,
                  'communication'
                )}
              />
              <Route
                key="review-step"
                path="review"
                element={<ReviewStep />}
                loader={offsitesLoader.detailReviewLoader}
              />
            </Route>
          </Route>

          <Route path="offers-offsites">
            <Route
              index
              element={<OffersListPage />}
              loader={offersLoader.listLoader}
              action={listAction(store.dispatch, 'offsite', '/dashboard/offers-offsites')}
            />
            <Route
              path="new"
              element={<OfferDetailPage editingMode={false} />}
              loader={offersLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'offsite', '/dashboard/offers-offsites')}
            />
            <Route
              path=":id"
              id="offer-detail"
              element={<OfferDetailPage editingMode />}
              loader={offersLoader.detailLoader}
              action={editOrCreateAction(store.dispatch, 'offsite', '/dashboard/offers-offsites')}
              handle={{
                breadcrumb: ({ offsite }) => {
                  return offsite ? offsite?.title : 'New offsite offer'
                }
              }}
              breadcrumb={DynamicBreadcrumb}>
              <Route
                key="definition-step"
                path="definition"
                element={<DefinitionStep parent="offer" />}
                loader={offersLoader.detailDefinitionLoader}
                action={definitionAction(
                  store.dispatch,
                  'offsite',
                  '/dashboard/offers-offsites',
                  'agenda'
                )}
              />
              <Route
                key="agenda-step"
                path="agenda"
                element={<AgendaStepWrapper parent="offer" />}
                loader={offsitesLoader.detailAgendaLoader}
                action={offsiteDefaultAction(
                  store.dispatch,
                  'offsite',
                  '/dashboard/offers-offsites'
                )}
              />
              <Route
                key="economics-step"
                path="economics"
                element={<OfferStep parent="offer" />}
                action={definitionAction(store.dispatch, 'offsite')}
              />
              <Route
                key="communication-step"
                path="communication"
                element={<CommunicationStep parent="offer"/>}
                loader={offsitesLoader.detailCommunicationLoader}
                action={definitionAction(
                  store.dispatch,
                  'offsite',
                )}
              />
              <Route
                key="review-step"
                path="review"
                element={<ReviewStep parent="offer" />}
                loader={offsitesLoader.detailReviewLoader}
              />
            </Route>
          </Route>

          <Route path="users">
            <Route
              index
              element={<UsersListPage />}
              loader={usersLoader.listLoader}
              action={usersListAction(store.dispach)}
            />
            <Route
              path=":id"
              element={<Profile context="user" />}
              loader={usersLoader.detailLoader}
              action={updateUserAction(store.dispatch)}
              handle={{
                breadcrumb: ({ profile }) => {
                  const { first_name, last_name } = profile
                  return `${first_name} ${last_name}`
                }
              }}
              breadcrumb={DynamicBreadcrumb}
            />
          </Route>

          <Route
            path="profile"
            element={<Profile />}
            loader={authLoader.fullProfileLoader}
            action={updateProfileAction(store.dispatch)}
          />

          <Route path="companies">
            <Route
              index
              element={<CompanyListPage />}
              loader={companyLoader.listLoader}
              action={listAction(store.dispatch, 'company', '/dashboard/companies')}
            />
            <Route
              path=":id"
              element={<CompanyDetailPage editingMode />}
              loader={companyLoader.detailLoader}
              action={editOrCreateCompanyAction(store.dispatch, 'company', '/dashboard/companies')}
            />
            <Route
              path="new"
              element={<CompanyDetailPage />}
              loader={companyLoader.detailLoader}
              action={editOrCreateCompanyAction(store.dispatch, 'company', '/dashboard/companies', store)}
            />
          </Route>

          <Route path="pages">
            <Route
              index
              element={<PagesListPage />}
              loader={pagesLoader.listLoader}
              action={listAction(store.dispatch, 'pages', '/dashboard/pages')}
            />
            <Route
              path=":id"
              element={<PagesDetailPage editingMode />}
              loader={pagesLoader.detailLoader}
              action={editOrCreatePageAction(store.dispatch, 'pages', '/dashboard/pages')}
            />
            <Route
              path="new"
              element={<PagesDetailPage />}
              loader={pagesLoader.detailLoader}
              action={editOrCreatePageAction(store.dispatch, 'pages', '/dashboard/pages')}
            />
          </Route>

          <Route path="notifications">
            <Route index element={<NotificationsPage />} />
          </Route>
        </Route>
        <Route path="/authentication">
          <Route index element={<BaseRedirect to="/authentication/sign-in" />} />
          <Route path="sign-in" element={<SignIn />} action={loginAction(store.dispatch)} />
          <Route
            path="reset-password"
            element={<ResetPassword />}
            action={resetPasswordAction(store.dispatch)}
          />
          <Route
            path="sign-up"
            element={<Registration />}
            action={registerAction(store.dispatch)}
          />
          <Route
            path="confirm-registration"
            element={<ConfirmRegistration />}
            action={confirmRegistrationAction(store.dispatch)}
          />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Route>
    )
    // {
    //   basename: '/panel'
    // }
  )
}
