import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
  IconButton
} from '@mui/material'

const PeopleCardsDialog = ({
  open,
  title,
  children,
  onClose,
}) => {
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent">
          {children}
        </DialogContent>
      </Container>
    </Dialog>
  )
}

export default PeopleCardsDialog
