import { useState, useEffect, useRef } from 'react'

import MDBox from 'components/MDBox'

import Input from '../Input'
import MapDialog from './MapDialog'

import { getValueFromAddressComponents } from 'utils/maps'

const Address = ({ value = '', latitudeProp, longitudeProp, addressComponents = [], setFieldTouched, setFieldValue, onChange, ...field }) => {
  const _latitudeProp = latitudeProp || 'latitude'
  const _longitudeProp = longitudeProp || 'longitude'
  const latitude = field[_latitudeProp] || 0
  const longitude = field[_longitudeProp] || 0
  const [address, setAddress] = useState(value)
  const [lat, setLat] = useState(latitude ? Number(latitude) : 0)
  const [lng, setLong] = useState(longitude ? Number(longitude) : 0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogOpened, setDialogOpened] = useState(false)
  const inputRef = useRef(null)

  const handleOnFocus = (e) => {
    setDialogOpen(true)
    setTimeout(() => {
      setDialogOpened(true)
    }, 200)
  }
  
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setTimeout(() => {
      inputRef.current.querySelector('input').blur()
    })
    e.stopPropagation()
    e.preventDefault()
  }

  const handleOnSave = (values) => {
    const { address: newAddress, lat: newLat, lng: newLng, place_id, address_components } = values
    const isChanged = (newAddress !== address) || (lat !== newLat) || (lng !== newLng)
    if (isChanged) {
      onChange({ target: { name: _latitudeProp, value: newLat }})
      onChange({ target: { name: _longitudeProp, value: newLng }})
      onChange({ target: { name: field.name, value: newAddress, needTouched: true }})
      onChange({ target: { name: '__address', value: { [field.name]: newAddress, [_latitudeProp]: newLat, [_longitudeProp]: newLng, place_id } }})
      addressComponents.forEach(({ name, component }) => {
        const value = getValueFromAddressComponents({ address_components, value: component })
        setFieldValue(name, value)
      })
    }
  } 

  useEffect(() => {
    setAddress(value)
    setLat(field[_latitudeProp])
    setLong(field[_longitudeProp])
  }, [value, field, _latitudeProp, _longitudeProp])

  return (
    <MDBox>
      <MDBox>
        <Input
          {...field}
          errors={dialogOpened ? field.errors : null}
          ref={inputRef}
          value={address}
          readOnly
          inputProps={{ readOnly: true }}
          onClick={handleOnFocus}
        />
      </MDBox>
      <MDBox>
        <MapDialog
          countries={field.countries}
          open={dialogOpen}
          setAddress={setAddress}
          lat={lat}
          lng={lng}
          address={address}
          setLat={setLat}
          setLong={setLong}
          onClose={handleDialogClose}
          onSave={handleOnSave}
        ></MapDialog>
      </MDBox>
    </MDBox>
  )
}

export default Address
