import ICONS from 'utils/icons'
import { PAGE_TEMPLATES_NAMES } from 'utils/pages'
import { capitalizeFirstLetter } from 'utils/functions'

const highlightedStyles = {
  xs: 6,
  padding: '12px',
  backgroundColor: 'grey.200',
  borderRadius: '8px'
}

const TEMPLATES = PAGE_TEMPLATES_NAMES.map((template) => ({ label: capitalizeFirstLetter(template), value: template }))

const commonFields = {
  title: {
    type: 'text',
    name: 'title',
    label: 'Title',
    validationType: 'string',
    validations: null,
    grid: { xs: 12, md: 8, lg: 6 }
  },
  subtitle: {
    type: 'text',
    name: 'subtitle',
    label: 'Subtitle',
    validationType: 'string',
    validations: null,
    grid: { xs: 12, md: 8, lg: 6 }
  },
  section_id: {
    type: 'text',
    label: 'Section id (used for anchor links)',
    name: 'section_id',
    validationType: 'string',
    validations: null,
    grid: { xs: 12, md: 8, lg: 6 }
  },
  icon: {
    type: 'select',
    label: 'Icon name',
    name: 'icon',
    options: ICONS,
    validationType: 'string',
    validations: null,
    grid: { xs: 6 }
  },
  description: {
    type: 'textarea',
    label: 'Description',
    name: 'description',
    validationType: 'string',
    validations: null,
    grid: { xs: 12, md: 8, xl: 8 }
  }
}

// hero|highlighted|sliderSections|fullscreenBanner|textMedia|quotationBanner|about|testimonials,
const fieldsBySectionType = {
  HERO: {
    fields: ['title', 'subtitle', { name: 'section_id', grid: { mr: 12 } }, 'description'],
    repeater: {
      fields: ['title'],
    }
  },
  HIGHLIGHTED: {
    fields: ['title', 'subtitle', 'section_id'],
    showMedia: false
  },
  ABOUT: {
    fields: ['title', 'subtitle', 'section_id'],
    repeater: {
      fields: ['title', 'subtitle', 'icon'],
      showCta: false
    }
  },
  SLIDER_SECTION: {
    fields: ['title', 'subtitle', 'section_id'],
    showMedia: false,
  },
  FULL_SCREEN_BANNER: {
    fields: ['title', 'subtitle', 'section_id'],
  },
  TEXT_MEDIA: {
    fields: ['title', 'subtitle', 'description', 'section_id'],
    showCta: false
  },
  TESTIMONIALS: {
    fields: ['title', 'subtitle', 'section_id'],
    showCta: false,
    repeater: {
      fields: ['title', 'subtitle', 'description'],
      showCta: false
    }
  },
  QUOTATION_BANNER: {
    fields: ['title', 'subtitle', 'section_id'],
  }
}

const getFieldsBySectionType = ({ type, isRepeaterItem }) => {
  const section = isRepeaterItem ? fieldsBySectionType[type].repeater : fieldsBySectionType[type]
  return {
    showCta: true,
    showMedia: true,
    showEntity: true,
    ...section,
    fields:
      section && section.fields
        ? section.fields.map((f) => {
            if (typeof f === 'string') {
              return { ...commonFields[f] }
            } else {
              const existingGrid = commonFields[f.name].grid
              return {
                ...commonFields[f.name],
                ...f,
                grid: {
                  ...existingGrid,
                  ...f.grid
                }
              }
            }
          })
        : []
  }
}

const getSectionSchema = ({ title, type, isRepeaterItem = false, ...section }) => {
  const { fields, showCta } = getFieldsBySectionType({
    type,
    isRepeaterItem
  })

  return {
    title,
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        fields
      },
      ...(showCta
        ? [
            {
              id: 'cta',
              title: 'CTA (call to action)',
              sx: highlightedStyles,
              fields: [
                {
                  type: 'text',
                  name: 'label',
                  label: 'Label',
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 6, mr: 10 }
                },
                {
                  type: 'text',
                  name: 'href',
                  label: 'URL of the link',
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 6 }
                },
                {
                  type: 'text',
                  name: 'anchor',
                  label: 'Anchor',
                  validationType: 'string',
                  validations: null,
                  helperText: 'Goes to section in the same page. It must be a section id',
                  grid: { xs: 6 }
                },
                {
                  type: 'select',
                  name: 'target',
                  label: 'Target',
                  options: [
                    {
                      label: 'Open in same tab',
                      value: '_self'
                    },
                    {
                      label: 'Open in another tab',
                      value: '_blank'
                    }
                  ],
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 6 }
                },
                {
                  type: 'select',
                  label: 'Icon name',
                  name: 'cta_icon',
                  options: ICONS,
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 6 }
                }
              ]
            }
          ]
        : [])
    ],
    submitButton: {
      label: 'Save',
      icon: 'save',
      sx: { width: 'fit-content' }
    }
  }
}

const getPageSchema = ({ editingMode, dispatch, page }) => {
  return {
    id: 'page-form',
    name: 'page-form',
    title: editingMode ? '' : 'Create your page',
    fieldsets: [
      {
        id: 'page',
        fields: [
          {
            type: 'text',
            name: 'slug',
            label: 'Page slug',
            validationType: 'string',
            required: editingMode,
            validations: editingMode ? [
              {
                type: 'required',
                params: ['This field is required']
              }
            ] : null
          },
          {
            type: 'select',
            name: 'template',
            options: TEMPLATES,
            label: 'Template',
            disabled: editingMode,
            helperText: editingMode ? 'Once the page is created you cannot change the template' : '',
            placeholder: 'Choose a template',
            grid: { xs: 12, sx: { '> div': { mb: 0 } } }
          }
        ]
      }
    ],
    ctasPosition: 'bottom',
    submitButton: {
      label: editingMode ? 'Save page' : 'Create page',
      icon: 'save',
      sx: { width: 'fit-content' }
    }
  }
}

const getMediaSchema = (section, mediaFields) => {
  const { title, showPosition = true, defaultType = 'IMAGE', showType = true } = mediaFields
  const media = section && section?.media
  const mediaType = media && media.type ? media.type : defaultType
  const showImage = mediaType === 'IMAGE'
  const showVideo = mediaType === 'VIDEO'

  const schema = {
    id: `media-form-${section?.id || 'new'}`,
    name: `media-form-${section?.id || 'new'}`,
    title: title || 'Choose a media',
    fieldsets: [
      {
        id: 'media',
        fields: [
          ...(showType ? [{
            type: 'select',
            name: 'type',
            label: 'Media type',
            options: [
              {
                label: 'Video',
                value: 'VIDEO'
              },
              {
                label: 'Image',
                value: 'IMAGE'
              }
            ],
            validationType: 'string',
            validations: null
          }] : []),
          ...(showPosition ? [{
            type: 'select',
            name: 'media_position',
            label: 'Media position',
            options: [
              {
                label: 'Left',
                value: 'LEFT'
              },
              {
                label: 'Right',
                value: 'RIGHT'
              }
            ],
            validationType: 'string',
            validations: null
          }] : []),
          ...(showVideo
            ? [
                {
                  type: 'video',
                  label: 'Video',
                  name: 'video_id',
                  placeholder: 'Upload a video',
                  validationType: 'string',
                  collectionName: 'discovery',
                  innerField: {
                    validationType: 'mixed',
                    validations: [
                      {
                        type: 'fileSize',
                        params: ['File too large', '104860000']
                      },
                      {
                        type: 'fileFormat',
                        params: ['Unsupported Format', ['video/mp4']]
                      },
                      {
                        type: 'nullable'
                      }
                    ],
                    maxSize: 104860000,
                    accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
                  }
                }
              ]
            : []),
          ...(showImage
            ? [
                {
                  type: 'image',
                  name: 'image',
                  label: 'Image',
                  validationType: 'string',
                  validations: [
                    {
                      type: 'fileSize',
                      params: ['File too large', '5242880']
                    },
                    {
                      type: 'fileFormat',
                      params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/png']]
                    },
                    {
                      type: 'nullable'
                    }
                  ],
                  maxSize: 5242880,
                  accept: '.jpg, .jpeg, .png'
                }
              ]
            : [])
        ]
      }
    ]
  }
  return schema
}

export { getPageSchema, getMediaSchema }

export default getSectionSchema
