import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { formatSectionValuesToServer } from 'utils/pages'

import { useState } from 'react'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'

import ItemCard from './ItemCard'
import RepeaterItemDialog from './RepeaterItemDialog'

export const AddCard = ({ title, icon, onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        minHeight="180px"
        minWidth="120px"
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">{title}</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            {icon}
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const Repeater = ({
  type,
  parentSection,
  repeaterFields = {},
  page,
  items,
  onSave,
  onDelete,
  onSaveEntity,
  onDeleteEntity,
  onMediaSave,
  onMediaDelete
}) => {
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [_parentSection, setParentSection] = useState(parentSection)

  const handleAddItem = async () => {
    if (!_parentSection || !_parentSection.id) {
      const formatted = formatSectionValuesToServer({
        sectionValues: {type, ..._parentSection}
      })
      const newSection = await dispatch(smartwayApi.endpoints.createPageEntity.initiate({
        entity: 'sections',
        subentity: 'page',
        pageId: page.id,
        type,
        ...formatted
      }))
      if (newSection && newSection.data) {
        setParentSection(newSection.data)
      } else {
        throw new Error('Failed to create section')
      }
    }
    setDialogOpen(true)
    setSelectedItem({
      type
    })
  }

  const handleOnSave = (item) => {
    setDialogOpen(false)
    onSave({ sectionValues: { ...selectedItem, ...item } })
  }

  const handleEditItem = (item) => {
    setDialogOpen(true)
    setSelectedItem(item)
  }

  const handleDeleteItem = (item) => {
    onDelete(item)
  }

  useEffect(() => {
    if (parentSection && Object.keys(parentSection).length !== Object.keys(_parentSection).length) {
      setParentSection(parentSection)
    }
  }, [parentSection])

  return (
    <>
      <MDBox mb={2}>
        {repeaterFields.title ? (
          <MDTypography variant="h4">{repeaterFields.title}</MDTypography>
        ) : null}
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AddCard title="Add new item" icon="add" onAdd={handleAddItem} />
        </Grid>
        {items && items.length
          ? items.map((item, index) => (
              <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
                <ItemCard {...item} onEdit={handleEditItem} onDelete={handleDeleteItem} />
              </Grid>
            ))
          : null}
      </Grid>
      {selectedItem && _parentSection?.id ? (
        <RepeaterItemDialog
          type={type}
          page={page}
          title={repeaterFields.title}
          repeaterFields={repeaterFields}
          open={dialogOpen}
          onSaveEntity={onSaveEntity}
          onMediaSave={onMediaSave}
          onMediaDelete={onMediaDelete}
          onDeleteEntity={onDeleteEntity}
          onSave={handleOnSave}
          onClose={() => setDialogOpen(false)}
          item={selectedItem}
          parentSection={_parentSection}
        />
      ) : null}
    </>
  )
}

export default Repeater
