// @mui icons
import Icon from '@mui/material/Icon'
import { getBestFormat } from 'utils/image'
import MDAvatar from 'components/MDAvatar'

const getNavigation = ({ first_name, last_name, avatar_formats, is_staff, offsites, company }) => {
  const companyRoutes = is_staff
    ? {
        type: 'collapse',
        name: 'Companies',
        key: 'company-management',
        permission: 'view_company',
        icon: <Icon fontSize="medium">business</Icon>,
        divider: true,
        collapse: [
          {
            name: 'Companies',
            key: 'companies',
            permission: 'view_company',
            route: '/dashboard/companies'
          },
          {
            name: 'Create company',
            key: 'create-company',
            permission: 'add_company',
            route: '/dashboard/companies/new'
          }
        ]
      }
    : company && company.id ? {
        type: 'route',
        name: 'Your company',
        key: 'your-company',
        route: `/dashboard/companies/${company?.id}`,
        permission: 'view_company',
        icon: <Icon fontSize="medium">business</Icon>
      } : {
        name: 'Create your company',
        key: 'create-company',
        permission: 'add_company',
        route: '/dashboard/companies/new'
      }
  
  const isOffsiteManager = offsites && !!offsites.length && offsites.some(offsite => offsite.your_role !== 'PARTICIPANT')
  
  return [
    {
      type: 'route',
      name: `${first_name || last_name ? `${first_name || ''} ${last_name || ''}` : 'My Profile'}`,
      key: 'profile',
      route: '/dashboard/profile',
      permission: 'view_userprofile',
      icon: (
        <MDAvatar
          sx={{ mr: 1, ml: -0.5 }}
          src={getBestFormat(avatar_formats, 'mobile')}
          alt={`${first_name} ${last_name}`}
          size="sm"
        />
      ),
      divider: true
    },
    {
      type: 'collapse',
      name: 'Venues',
      key: 'area-management',
      icon: <Icon fontSize="medium">edit_location_alt</Icon>,
      collapse: [
        {
          name: 'Towns',
          key: 'towns-list',
          permission: 'view_town',
          route: '/dashboard/locations/towns'
        },
        {
          name: 'Accommodations',
          key: 'accommodations-list',
          permission: 'view_accommodation',
          route: '/dashboard/locations/accommodations'
        },
        {
          name: 'Experiences',
          key: 'experiences-list',
          permission: 'view_experience',
          route: '/dashboard/locations/experiences'
        },
        {
          name: 'Workshops',
          key: 'workshops-list',
          permission: 'view_experience',
          route: '/dashboard/locations/workshops'
        },
        {
          name: 'CoWorkings',
          key: 'coworkings-list',
          permission: 'view_coworking',
          route: '/dashboard/locations/coworkings'
        },
        {
          name: 'Events spaces',
          key: 'events-spaces-list',
          permission: 'view_plenaryhall',
          route: '/dashboard/locations/event-spaces'
        },
        {
          name: 'Caterings',
          key: 'caterings-list',
          permission: 'view_catering',
          route: '/dashboard/locations/caterings'
        }
      ]
    },
    {
      type: 'collapse',
      name: 'All-in Venues',
      key: 'prebuilt-offsites-management',
      icon: <Icon fontSize="medium">redeem</Icon>,
      collapse: [
        {
          name: 'Venues',
          key: 'venues-list',
          permission: 'view_allinvenue',
          route: '/dashboard/locations/all-in-venues'
        },
        {
          name: 'Accommodations',
          key: 'venues-accommodations-list',
          permission: 'view_allinvenueaccommodation',
          route: '/dashboard/locations/all-in-venues-accommodations'
        }
      ]
    },
    {
      type: 'collapse',
      name: 'Premade packages',
      key: 'prebuilt-packages-management',
      icon: <Icon fontSize="medium">all_inclusive</Icon>,
      divider: true,
      collapse: [
        {
          name: 'All packages',
          key: 'packages-list',
          permission: 'view_allinvenue',
          route: '/dashboard/premade-packages'
        },
        {
          name: 'Create package',
          key: 'packages-detail',
          permission: 'add_allinvenue',
          route: '/dashboard/premade-packages/new'
        }
      ]
    },
    {
      type: 'collapse',
      name: 'Agenda templates',
      key: 'agenda-management',
      icon: <Icon fontSize="medium">edit_calendar</Icon>,
      divider: true,
      collapse: [
        {
          name: 'All agendas',
          key: 'packages-list',
          permission: 'view_agenda',
          route: '/dashboard/agendas'
        },
        {
          name: 'Create agenda template',
          key: 'packages-detail',
          permission: 'add_agenda',
          route: '/dashboard/agendas/new'
        }
      ]
    },
    {
      type: 'collapse',
      name: 'Offsites',
      key: 'offsite-management',
      ...(isOffsiteManager ? {} : { permission: 'view_offsite' }),
      icon: <Icon fontSize="medium">dashboard</Icon>,
      collapse: [
        {
          name: 'Your offsites',
          key: 'offsites',
          ...(isOffsiteManager ? {} : { permission: 'view_offsite' }),
          route: '/dashboard/offsites'
        },
        {
          name: 'Create Offsite',
          permission: 'add_offsite',
          icon: <Icon fontSize="medium">draw</Icon>,
          key: 'create-offsite',
          route: '/dashboard/offsites/new'
        }
      ]
    },
    {
      name: 'Offsites offers',
      key: 'offers',
      type: 'route',
      permission: 'is_staff',
      icon: 'whatshot',
      route: '/dashboard/offers-offsites'
    },
    { type: 'divider' },
    companyRoutes,
    ...(is_staff
      ? [
          {
            type: 'collapse',
            name: 'Discovery Pages',
            key: 'pages-management',
            permission: 'view_offsite',
            icon: <Icon fontSize="medium">menu_book</Icon>,
            divider: true,
            collapse: [
              {
                name: 'Pages',
                key: 'pages',
                permission: 'view_offsite',
                route: '/dashboard/pages'
              },
              {
                name: 'Create page',
                key: 'create-page',
                permission: 'add_offsite',
                route: '/dashboard/pages/new'
              }
            ]
          }
        ]
      : []),
    {
      name: is_staff ? 'Users' : 'Your colleagues',
      key: 'users',
      type: 'route',
      permission: 'view_user',
      icon: 'group',
      route: '/dashboard/users'
    }
  ]
}

export { getNavigation }
