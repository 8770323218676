import { useState, useEffect, useRef } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import FormHelperText from '@mui/material/FormHelperText'

import { styled } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloseIcon from '@mui/icons-material/Close'
import Spinner from 'components/Spinner/Spinner'
import { humanFileSize } from 'utils/functions'

const iconStyles = {
  position: 'absolute',
  zIndex: '10',
  right: '5px',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'secondary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const StyledFileUpload = styled('label')(({ theme, isVideo, error }) => {
  return {
    position: 'relative',
    display: 'flex',
    height: 'auto',
    padding: '10px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `solid 1px ${error ? '#F44335' : '#d2d6da'}`,
    borderRadius: '6px',
    color: '#495057',
    backgroundColor: 'transparent',
    appearance: 'none',
    flexGrow: 1,
    outline: 'none',
    minWidth: '152px',
    width: '100%',
    maxWidth: '100vw',
    maxHeight: '48px',
    marginBottom: '44px',
    fontSize: '14px',
    cursor: 'pointer',
    '&.disabled': {
      backgroundColor: 'grey',
      cursor: 'not-allowed',
      pointerEvents: 'none'
    },
    ...(isVideo && {
      flexDirection: 'column',
      justifyContent: 'center',
      height: '250px',
      maxHeight: 'auto',
      marginBottom: '0px',
      border: `1px dashed ${error ? '#F44335' : '#d2d6da'}`,
      p: {
        fontSize: '16px',
        marginBottom: '8px'
      }
    })
  }
})

const FileUpload = ({
  name,
  value,
  label,
  accept,
  acceptReadable,
  required,
  multiple = false,
  maxSize,
  isVideo,
  disabled,
  errors,
  placeholder = 'No selected file',
  showPreview,
  onFileChange
}) => {
  const [filename, setFilename] = useState(null)
  const [files, setFiles] = useState([])
  const [filesPreviews, setFilesPreviews] = useState({})
  const [previewsReady, setPreviewsReady] = useState(false)
  const inputRef = useRef(null)

  const checkSize = (file) => {
    return file.size <= maxSize
  }

  const getFilesPreviews = (files) => {
    let previews = {}
    files.forEach((file) => {
      const reader = new FileReader()
        reader.onload = () => {
          previews[file.name] = reader.result
        }
        reader.readAsDataURL(file)
    })
    setFilesPreviews(previews)
    setTimeout(() => {
      setPreviewsReady(true)
    }, 200) 
  }

  const handleChange = async (e) => {
    setPreviewsReady(false)
    const _files = Array.from(e.currentTarget.files)
    setFilename(_files.length === 1 ? _files[0].name : `${_files.length} files selected`)
    if (typeof onFileChange === 'function') {
      onFileChange(name, _files, e)
    }
    setFiles(_files)
  }

  const handleClick = (e) => {
    e.preventDefault()
    inputRef.current.click()
  }
  const handleReset = (e) => {
    e.preventDefault()
    inputRef.current.value = ''
    setFilename(null)
    setFiles([])
    if (typeof onFileChange === 'function') {
      onFileChange(name, 'RESET', { target: { name, value: null } })
    }
  }

  const handleFocus = (e) => {}

  const handleRemoveFile = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name)
    const newFilesLength = newFiles.length
    const newFilename = newFilesLength > 1 ? `${newFilesLength} files selected` : !!newFilesLength ? newFiles[0].name : 'No selected file'
    
    setFiles(newFiles)
    setFilename(newFilename)

    const newPreviews = { ...filesPreviews }
    delete newPreviews[file.name]
    setFilesPreviews(newPreviews)
    if (typeof onFileChange === 'function') {
      onFileChange(name, newFiles, { target: { name  } })
    }
  }

  useEffect(() => {
    if (showPreview || files.length > 1) {
      getFilesPreviews(files)
    }
  }, [files])

  useEffect(() => {
    if (showPreview && value && typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length) {
      setPreviewsReady(false)
      setFiles([])
      setFilename('1 file selected')
      setFilesPreviews(value)
    }
  }, [value])

  return (
    <MDBox>
      { label ? <MDTypography variant="button" fontWeight="regular" color="text" ml={0.5} mb={1} >{label}</MDTypography> : null }
      <StyledFileUpload isVideo={isVideo} htmlFor={name} error={!!errors}>
        <p
          style={{
            color: !!filename ? '#495057' : '#7b809a',
            fontSize: isVideo ? '22px' : '16px',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',       
          }}>
          {!!filename ? filename : `${placeholder}${required ? ' *' : ''}`}
        </p>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={inputRef}
          id={name}
          name={name}
          multiple={multiple}
          accept={accept}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {filename ? (
          <CloseIcon onClick={handleReset} />
        ) : (
          <CloudUploadIcon
            fontSize={isVideo ? 'large' : 'medium'}
            onClick={handleClick}
          />
        )}
        {accept && isVideo ? (
          <MDTypography
            sx={{
              textAlign: 'center',
              maxWidth: '300px',
              color: !!filename ? '#495057' : '#7b809a',
              fontSize: '22px'
            }}>
            Accepted formats: {accept}
          </MDTypography>
        ) : null}
        {(maxSize || accept) && !isVideo && !errors ? (
          <MDBox position="absolute" top={50} left={0}>
            <FormHelperText
              sx={{
                fontSize: '12px'
              }}>
              {maxSize ? `Max size: ${humanFileSize(maxSize)}` : null} {maxSize && accept ? ' | ' : null} {accept ? `Accepted formats: ${acceptReadable || accept}` : null}
            </FormHelperText>
          </MDBox>
        ) : null}
        {errors ? (
          <MDBox position="absolute" top={isVideo ? '100%' : 50} left={0}>
            <MDTypography display="block" color="error" variant="caption" sx={{ lineHeight: '14px !important' }}>
              {errors}
            </MDTypography>
          </MDBox>
        ) : null}
      </StyledFileUpload>
      { (showPreview || (multiple && files && files.length > 1)) ? previewsReady ? (
        <MDBox>
          { files.map((file) => {
            const isTooBig = maxSize ? !checkSize(file) : false
            return (
              <MDBox key={file.name} mt={1} display="flex" justifyContent="space-between" pr={1} sx={{ backgroundColor: isTooBig ? 'rgba(254, 189, 189, 0.5)' : 'rgba(0, 0, 0, 0.05)'}}>
                <MDBox display="flex" alignItems="center">
                  <MDBox component="img" src={filesPreviews[file.name]} alt={file.name} width="50px" mr={2}/>
                  <MDTypography variant="button" sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{file.name}</MDTypography>
                  <MDTypography variant="caption" ml={2}>{humanFileSize(file.size)}</MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <CloseIcon onClick={() => handleRemoveFile(file)} />
                </MDBox>
              </MDBox>
            )
          })}
        </MDBox> 
      ) : (
        <MDBox>
          <Spinner />
        </MDBox>
      ) : null }
      { value && typeof value === 'object' && !!Object.keys(value).length && !Array.isArray(value) && showPreview ? (
        <MDBox position="relative">
          <MDBox component="img" src={value.mobile} alt={name} sx={{
            objectFit: 'cover',
            maxHeight: '60px'
          }}/>
          <MDBox
              sx={{...iconStyles, top: '5px', }}
              onClick={handleReset}>
            <Icon>delete</Icon>
          </MDBox>
        </MDBox>

      ) : null }

    </MDBox>
  )
}

export default FileUpload
