// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'


const Sidenav = ({ items }) => {

  const renderSidenavItems = items.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`

    const handleScrollSection = () => {
      const element = document.getElementById(href)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    return (
      <MDBox
        key={itemKey}
        component="li"
        pt={key === 0 ? 0 : 1}
        sx={{ cursor: 'pointer' }}
        onClick={handleScrollSection}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions
          }) => ({
            display: 'flex',
            alignItems: 'center',
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create('background-color', {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter
            }),

            '&:hover': {
              backgroundColor: light.main
            }
          })}>
          <MDBox mr={1.5} lineHeight={1} color="dark">
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    )
  })

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: 'sticky',
        top: '1%'
      }}>
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: 'none' }}>
        {renderSidenavItems}
      </MDBox>
    </Card>
  )
}

export default Sidenav
