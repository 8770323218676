import { useState, useEffect, useRef } from 'react'

import ReactQuill from 'react-quill'

// react-quill styles
import 'react-quill/dist/quill.snow.css'
import 'quill-paste-smart'

// Custom styles for the MDEditor
import MDEditorRoot from 'components/MDEditor/MDEditorRoot'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import LoadingButton from '@mui/lab/LoadingButton'

import Icon from '@mui/material/Icon'
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context'

const Textarea = ({ ...props }) => {
  const {
    name,
    hideToolbar,
    required,
    disabled,
    value,
    label,
    helperText,
    hidden,
    errors,
    maxLength,
    insideCta,
    size,
    onChange,
    onBlur,
    setFieldValue
  } = props
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const [loading, setLoading] = useState(false)
  const editorRef = useRef(null)
  const [textLength, setTextLength] = useState(0)

  const removeStylesFromValue = (string) => {
    return string.replaceAll(/style="([^"]*)"/g, '')
  }

  const handleChange = (val) => {
    onChange({ target: { name, value: removeStylesFromValue(val) } })
  }
  const handleBlur = () => {
    if (onBlur && typeof onBlur === 'function') {
      onBlur({ target: { name, value: removeStylesFromValue(value) } })
    }
  }

  const handleInsideCtaClick = async () => {
    setLoading(true)
    if (insideCta.onClick && typeof insideCta.onClick === 'function') {
      const response = await insideCta.onClick(props)
      if (response && response[name]) {
        setFieldValue(name, response[name])
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleKeyDown = (e) => {
    if (maxLength) {
      // this timeout is to take in consideration the clipboard async operations before checking the length
      setTimeout(() => {
        const editorLength = editorRef && editorRef.current ? editorRef.current.value.length : 0
        setTextLength(editorLength)
        if (editorLength >= maxLength && e.key !== 'Backspace') {
          e.preventDefault()
          e.stopPropagation()
        }
      }, 100)
    }
  }

  useEffect(() => {
    if (value) {
      setTextLength(value.length)
    }
  }, [])

  const defaultStyles = {
    opacity: hidden ? 0 : 1,
    visibility: hidden ? 'hidden' : 'visible',
    height: hidden ? 0 : 'auto',
    textAlign: 'left',
    transition: 'opacity 0.2s',
    '.ql-editor > *': {
      color: '#495057',
      fontFamily: 'Barlow Semi Condensed'
    },
    '.ql-editor p': {
      color: '#495057 !important',
      fontSize: '14px !important',
      fontFamily: 'Barlow Semi Condensed'
    },
    ...errors ? {
      '.ql-toolbar, .ql-container': {
        border: '1px solid #f44336',
      },
      '.ql-container': {
        borderTop: '0px',
      }
    } : {},
    ...(size === 'small'
      ? {
          '.ql-toolbar': {
            '.ql-formats': {
              marginRight: '8px'
            },
            '.ql-picker-label': {
              paddingLeft: '0px'
            },
            '.ql-picker.ql-header': {
              width: '70px'
            },
            button: {
              height: '20px',
              padding: '3px 2px',
              width: '24px'
            }
          }
        }
      : {})
  }

  return (
    <MDBox
      mb={hidden ? 0 : 4}
      position="relative"
      sx={
        hideToolbar
          ? {
              ...defaultStyles,
              '.ql-toolbar': {
                display: 'none'
              },
              '.ql-container': {
                borderTop: '1px solid rgb(204,204,204) !important',
                borderRadius: '0.375rem'
              },
              '.ql-editor': {
                minHeight: '64px'
              },
              ...(insideCta
                ? {
                    '.ql-editor': {
                      minHeight: '80px'
                    }
                  }
                : {})
            }
          : {
              ...defaultStyles,
              ...(insideCta
                ? {
                    '.ql-editor': {
                      paddingBottom: '48px'
                    }
                  }
                : {})
            }
      }>
      {label ? (
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            {label} {required ? '*' : null}
          </MDTypography>
        </MDBox>
      ) : null}
      <MDEditorRoot ownerState={{ darkMode }}>
        <ReactQuill
          {...props}
          ref={editorRef}
          readOnly={disabled}
          id={name}
          theme="snow"
          value={value || ''}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </MDEditorRoot>
      {helperText || errors || maxLength ? (
        <MDBox position="absolute" width="100%" sx={{ lineHeight: 1 }}>
          
          {(helperText || maxLength)? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
               { errors ? (
                <MDTypography variant="caption" color="error">{errors}</MDTypography>
              ) : (
                <MDBox>
                  <MDTypography variant="caption" color={textLength > maxLength ? 'error' : 'text'}>{helperText ? helperText : ' '}</MDTypography>
                </MDBox>
              ) }
              {maxLength ? (
                <MDTypography variant="caption" color={textLength > maxLength ? 'error' : 'text'}>
                  {textLength || 0} / {maxLength}
                </MDTypography>
              ) : null}
            </MDBox>
          ) : null}
        </MDBox>
      ) : null}
      {insideCta ? (
        <MDBox position="absolute" sx={{ bottom: '8px', right: '8px' }}>
          <LoadingButton
            {...insideCta}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main'
            }}
            loading={loading}
            onClick={handleInsideCtaClick}>
            {insideCta.icon ? <Icon sx={{ mr: 1 }}>{insideCta.icon}</Icon> : null}
            {insideCta.label}
          </LoadingButton>
        </MDBox>
      ) : null}
    </MDBox>
  )
}

export default Textarea
