import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

const StepHeader = ({ title, subtitle }) => {
  return title || subtitle ? (
    <MDBox mt={2} mb={4}>
      <MDTypography variant="h3" fontWeight="medium">{title}</MDTypography>
      <MDTypography variant="subtitle2">{subtitle}</MDTypography>
    </MDBox>
  ) : null 
}

export default StepHeader