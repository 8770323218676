import { useState, useEffect } from 'react'

import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'
import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon
} from '@mui/material'

import getSchema from './schemas/edit-image'

import Form from 'components/Form'
import { getBestFormat } from 'utils/image'

const EditImageDialog = ({ open, image, title, multiple, error, hideOtherFields = false, onClose, onSave }) => {
  const [schema, setSchema] = useState(getSchema({ editingImage: !!image, hideOtherFields, multiple }))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [previewURL, setPreviewURL] = useState(
    !!image && !!image.formats
      ? getBestFormat(image.formats, 'desktop')
      : ''
  )
  const onError = (e) => {
    setPreviewURL('https://via.placeholder.com/640x360.jpg?text=Set%20Image')
  }
  const handleFieldChange = (event, _values, formik) => {
    setIsError(false)
    const { files, name } = event.target
    if (name === 'image') {
      if (files) {
        if (files.length === 1) {
          const reader = new FileReader()
          reader.onload = () => {
            setPreviewURL(reader.result)
          }
          reader.readAsDataURL(files[0])
        } else {
          setPreviewURL(null)
        }
        if (formik.errors?.image) {
          setIsError(true)
        }
      } else {
        setPreviewURL(null)
      }
    }
  }

  const handleOnSubmit = async (values) => {
    setIsSubmitting(true)
    if (!!image && !values.image) {
      delete values.image
    }
    try {
      await onSave(values)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsSubmitting(false)
      setPreviewURL(null)
    }
  }

  const handleOnClose = () => {
    setPreviewURL(null)
    setIsSubmitting(false)
    setSchema(getSchema({ editingImage: null, multiple }))
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (image && image.formats && image.formats.desktop !== previewURL) {
      setPreviewURL(image.formats.desktop)
      setSchema(getSchema({ editingImage: !!image, multiple }))
    }
  }, [image, previewURL])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="dialogContent">
          {previewURL ? (
            <MDBox px={2} display="flex" justifyContent="center">
              <MDBox
                component="img"
                src={previewURL}
                alt={title}
                borderRadius="lg"
                shadow="md"
                position="relative"
                zIndex={1}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '25vh',
                  objectFit: 'contain'
                }}
                onError={onError}
              />
            </MDBox>
          ) : null}
          <Form
            id="edit-image-form"
            {...schema}
            values={image || {}}
            onSubmit={handleOnSubmit}
            watchedFields={['image']}
            onFieldChange={handleFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            sx={{
              color: 'white !important',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' }
            }}
            form="edit-image-form"
            type="submit"
            variant="contained"
            disabled={isSubmitting || isError}
            loading={isSubmitting}>
            Save
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default EditImageDialog
