import { useSelector } from 'react-redux'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import Avatar from 'components/Avatar'

import Form from 'components/Form'
import getSchema from './schema'

const Header = ({ profile, onAvatarChange, onChange }) => {

  const {
    first_name,
    last_name,
    groups: profileGroups,
    avatar_formats,
    gender,
    ...restProfile
  } = profile

  const isMyProfile = useSelector((state) => state.auth.profile.profile_id === profile.profile_id)
  const canModify = useSelector((state) => {
    const permissions = state.auth.profile.permissions
    const isStaff = state.auth.profile.is_staff
    return isStaff || permissions.includes('change_group')
  })
  const allGroups = useSelector((state) => state.auth.groups)
  
  let schema = {}
  if (canModify && allGroups) {
    schema = getSchema({ groups: allGroups })
  }
  const handleSaveImage = (imageUrl) => {
    onAvatarChange({ avatar: imageUrl })
  }

  const handleFieldChanges = (event) => {
    const { name, value } = event.target
    const values = { [name]: value }
    onChange(values)
  }

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} md={1.3}>
            <Avatar editable={isMyProfile} image={avatar_formats} onSave={handleSaveImage} />
          </Grid>
          <Grid item xs={9} md={10.7}>
            <MDBox height="100%" width="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {first_name} {last_name}
              </MDTypography>
              <MDBox display="flex" alignItems="center" width="100%">
                {canModify && allGroups ? (
                  <Form
                    {...schema}
                    sx={{ 'form > div': { flexWrap: 'nowrap !important'} }}
                    watchedFields={['avatar', 'groups', 'company']}
                    onFieldChange={handleFieldChanges}
                    values={{
                      ...restProfile,
                      avatar: profile.avatar_formats ? profile.avatar_formats.thumbnail : null,
                      groups: profileGroups ? profileGroups.map(g => g.id) : [],
                      company: profile?.company ? profile.company.id : null,
                    }}
                  />
                ) : (
                  <MDTypography color="text" variant="button">
                    {profileGroups && !!profileGroups.length ? (
                      <>
                        <span>{profileGroups.map(g => g.name).join(', ')}</span>
                        { profile?.company ? (
                          <span style={{ marginLeft: '4px' }}>
                            @ {profile.company.name}
                          </span>
                        ) : null }
                      </>
                    ) : null}
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default Header
