import MaterialSwitch from '@mui/material/Switch'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const Switch = ({ name, value, hidden, label, inverted, containerStyles = {}, onChange }) => {
  const handleOnChange = () => {
    onChange({ target: { name, value: !value } })
  }
  return (
    <MDBox
      display="flex"
      ml={-1}
      mb={4}
      alignItems="center"
      {...(inverted ? { flexDirection: 'row-reverse', justifyContent: 'space-between' } : {})}
      sx={{
        ...containerStyles,
        opacity: hidden ? 0 : 1, visibility: hidden ? 'hidden' : 'visible', transition: 'opacity 0.2s'
      }}>
      <MaterialSwitch name={name} checked={value} onChange={handleOnChange} />
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="text"
        onClick={handleOnChange}
        sx={{ cursor: 'pointer', userSelect: 'none' }}>
        {label}
      </MDTypography>
    </MDBox>
  )
}

export default Switch
