const filters = {
  name: 'coworking-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the coworking you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Town / Venue',
      subtitle: 'Choose the town or the venue of the working space',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Category',
      subtitle: 'Select the category of the working space you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: { 
            endpoints: [
              {
                entity: 'locationCategories', id: 'coworking'
              }
            ]
          },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Capacity',
      subtitle: 'How many participants will need desks? How many will partecipate in plenary space',
      fields: [
        {
          type: 'number',
          name: 'capacity',
          label: 'Min. capacity',
          grid: { xs: 3 },
        },
        {
          type: 'number',
          label: 'Min. plenary capacity',
          name: 'plenary_capacity',
          grid: { xs: 3 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Download speed',
      subtitle: 'Filter for the minimum required speeds for download and upload',
      fields: [
        {
          type: 'number',
          label: 'Min. download speed',
          name: 'download_speed',
          suffix: 'Mbps',
          grid: { xs: 3 },
        },
        {
          type: 'number',
          label: 'Min. upload speed',
          name: 'upload_speed',
          suffix: 'Mbps',
          grid: { xs: 3 },
        },
      ]
    },
    {
      title: 'Features',
      subtitle: 'Select the features you are looking for',
      fields: [
        {
          type: 'checkboxgroup',
          name: 'features',
          label: '',
          optionsGetter: {
            endpoints: [
             {
               name: 'feature',
               entity: 'locationFeatures',
               id: 'coworking',
               formatGroup: (options) => {
                return options.reduce((acc, curr) => {
                  const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                  if (alreadyInAcc) {
                    alreadyInAcc.controls.push({
                      name: curr.id,
                      label: curr.name
                    })
                  } else {
                    acc.push({
                      label: curr.subtype,
                      controls: [{ name: curr.id, label: curr.name }]
                    })
                  }
                  return acc
                }, [])
               }
             }
            ]
          }
        }
      ]
    },
    {
      title: 'Video',
      subtitle: 'Select whether the coworking has a video',
      fields: [
        {
          type: 'switch',
          name: 'video',
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the coworking is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters