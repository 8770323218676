import MDButton from "components/MDButton"
import MDBox from "components/MDBox"
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'

const AddPeopleButton = ({ tooltip, disabled, onClick }) => {

  const handleOnClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }

  return tooltip ? (
    <Tooltip title={tooltip}>
      <MDBox sx={{ cursor: 'pointer' }}>
        <MDButton disabled={disabled} size="medium" color="primary" onClick={onClick}>
          <Icon size="medium" sx={{ mr: 1 }}>
            group
          </Icon>
          Add participants
        </MDButton>
      </MDBox>
    </Tooltip>
  ) : (
    <MDButton disabled={disabled} size="medium" color="primary" onClick={handleOnClick}>
      <Icon size="medium" sx={{ mr: 1 }}>
        group
      </Icon>
      Add participants
    </MDButton>
  )
}

export default AddPeopleButton