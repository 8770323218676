import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import { getMainImage } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const getLinkFromEntity = (type, id) => {
  const types = {
    PACKAGE: 'premade-packages',
    ALL_IN_VENUE: 'locations/all-in-venues',
  }

  return `/${types[type]}/${id}`
}

const EntityPickerCard = ({ id, type, featured_image, images,selected, title, excerpt, description, onSelect, ...props }) => {
  const handleSelect = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect({ id, title, description, excerpt, ...props })
    }
  }

  const link = getLinkFromEntity(type, id)
  const mainImage = featured_image || getMainImage(images)
  const image = mainImage && mainImage.formats ? mainImage.formats.thumbnail : null

  return (
    <Card 
      position="relative"
      className={
        selected ? 'entity-picker-card selected' : 'entity-picker-card'
      }
      sx={{ 
        height: '100%',
        '&:hover, &.selected': {
          '.entity-picker-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <MDBox className="entity-picker-card__icon" sx={iconStyles} onClick={handleSelect}>
        <Icon fontSize="small">check</Icon>
      </MDBox>
      {image ? (
        <CardMedia
          sx={{ height: 140 }}
          image={image}
          title={title}
        />
      ) : null}
      <CardContent sx={{ flexGrow: 1 }}>
        <MDBox pt={2}>
          <MDTypography variant="h6" component="div">
            {title}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="secondary"
            dangerouslySetInnerHTML={{
              __html: excerpt
            }}></MDTypography>
            <Link to={link}>
              <MDButton variant="outlined" color="secondary" size="small">
                <Icon sx={{ ml: 0.5 }}>open_in_new</Icon>
              </MDButton>
            </Link>
        </MDBox>
      </CardContent>
    </Card>
  )
}

export default EntityPickerCard
