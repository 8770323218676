import { useState, useEffect } from 'react'

import { smartwayApi } from 'services/api'
import { useDispatch } from 'react-redux'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'
import DataTable from 'components/Tables/DataTable'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const hiddenFullListStyles = {
  table: {
    height: '200px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      background: 'linear-gradient(rgba(69,83,99,0),#fff)'
    }
  }
}

const PeopleCompanyDialog = ({
  open,
  company,
  title,
  error,
  onClose,
  onSave
}) => {
  const dispatch = useDispatch()
  const [seeFullList, setSeeFullList] = useState(false)
  const [usersList, setUsersList] = useState(null)
  const [usersListTable, setUsersListTable] = useState(null)

  const resetState = () => {
    setSeeFullList(false)
  }

  const handleOnSubmit = () => {
    onSave(usersList)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleFullList = () => {
    setSeeFullList(!seeFullList)
  }

  useEffect(() => {
    const getUsersList = async () => {
      const usersResponse = await dispatch(
        smartwayApi.endpoints.fetchEntities.initiate({
          entity: 'user',
          page_size: 100,
          company
        })
      )
      if (usersResponse.status === 'fulfilled') {
        const { results } = usersResponse.data
        const table = {
          columns: [
            { Header: 'firstname', accessor: 'firstname' },
            { Header: 'lastname', accessor: 'lastname' },
            { Header: 'role', accessor: 'role' },
          ],
          rows: results && !!results.length ? results.map(
            ({ first_name, last_name, profile }) => {
              const { groups } = profile

              return {
                firstname: first_name,
                lastname: last_name,
                role: groups && !!groups.length ? groups.map(g => g.name).join(', ') : 'Not defined'
              }
            }
          ) : []
        }
        setUsersList(results)
        setUsersListTable(table)
      }
    }
    if (!usersList && company) {
      getUsersList()
    }
  }, [company, dispatch, usersList])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent">
          {usersList ? (
            <MDBox
              py={2}
              sx={{
                position: 'relative',
                ...(!seeFullList && hiddenFullListStyles),
              }}>
              <DataTable
                title="People of your company"
                table={usersListTable}
              />
              <MDButton
                color="secondary"
                sx={{
                  position: 'absolute',
                  top: 'calc(100% - 40px)',
                  left: 'calc(100% - 75px)',
                  minWidth: '170px',
                  zIndex: 2,
                  transform: 'translate(-50%, -50%)'
                }}
                onClick={handleFullList}>
                {seeFullList ? <VisibilityOff sx={{ mr: 1 }}/> : <Visibility sx={{ mr: 1 }} />}
                {seeFullList ? 'Hide' : 'Show'} full list
              </MDButton>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose}>Cancel</MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={handleOnSubmit}>
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default PeopleCompanyDialog
