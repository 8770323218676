import { useState } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import Grid from '@mui/material/Grid'

import Repeater, { AddCard } from './Repeater'
import MediaDialog from './MediaDialog'
import EntityPicker from './EntityPicker'
import EditableMedia from 'components/EditableMedia'

import { formatSectionValuesToServer } from 'utils/pages'

const SectionExtraFields = ({
  page,
  section,
  parentSection,
  type,
  sectionFields = {},
  onSaveEntity,
  onDeleteEntity,
  onMediaSave,
  onMediaDelete,
  inRepeaterSection = false,
  onSaveRepeaterSection,
  onDeleteRepeaterSection
}) => {
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false)
  const { entity, media, repeater } = sectionFields

  const handleMediaSave = (createdSection = null) => {
    setMediaDialogOpen(false)
    if (onMediaSave && typeof onMediaSave === 'function') {
      onMediaSave(createdSection)
    }
  }

  const handleMediaEdit = (media) => {
    setMediaDialogOpen(true)
  }

  const handleSaveRepeaterSection = ({ sectionValues }) => {
    const values = formatSectionValuesToServer({
      sectionValues,
      isRepeaterItem: true,
      parentSection: section
    })
    onSaveRepeaterSection({ sectionValues: values })
  }
  const handleDeleteRepeaterSection = (section) => {
    onDeleteRepeaterSection(section)
  }

  const handleDeleteMedia = (media) => {
    onMediaDelete(media)
  }

  const handleCloseMediaDialog = () => {
    setMediaDialogOpen(false)
  }

  return (
    <>
      {entity ? (
        <MDBox mt={2}>
          <EntityPicker
            entity={section?.api_entity}
            section={{ type, ...section }}
            onSave={onSaveEntity}
            onDelete={onDeleteEntity}
          />
        </MDBox>
      ) : null}

      {!!media ? (
        <>
          <Grid
            container
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="grey.200"
            p={2}
            borderRadius="8px">
            <Grid xs={12}>
              <MDTypography>Choose an image or a video</MDTypography>
            </Grid>
            <Grid item mt={2} xs={12} sm={6}>
              {section?.media ? (
                <EditableMedia media={section?.media} onEdit={handleMediaEdit} onDelete={handleDeleteMedia}/>
              ) : (
                <AddCard title={media.title} icon="image" onAdd={() => setMediaDialogOpen(true)} />
              )}
            </Grid>
          </Grid>
          <MediaDialog
            key={`media-dialog-${section?.id || 'new'}`}
            type={inRepeaterSection ? 'INNER_SECTION' : type}
            mediaFields={media}
            page={page}
            section={section}
            parentSection={parentSection}
            open={mediaDialogOpen}
            onSave={handleMediaSave}
            onClose={handleCloseMediaDialog}
          />
        </>
      ) : null}

      {repeater ? (
        <MDBox mt={8}>
          <Repeater
            page={page}
            type={type}
            parentSection={section}
            repeaterFields={repeater}
            items={section?.items}
            onDelete={handleDeleteRepeaterSection}
            onSave={handleSaveRepeaterSection}
            onMediaSave={handleMediaSave}
            onMediaDelete={onMediaDelete}
            onDeleteEntity={onDeleteEntity}
            onSaveEntity={onSaveEntity}
          />
        </MDBox>
      ) : null}
    </>
  )
}

export default SectionExtraFields
