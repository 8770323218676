import { useEffect } from 'react'
import { Link, useActionData } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import AgendaBuilder from 'components/AgendaBuilder'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import MDAlert from 'components/MDAlert'

import Icon from '@mui/material/Icon'
import StepHeader from '../components/StepHeader'
import { getDaysDiffBetweenDates } from 'utils/dates'
import { STATUSES_NAMES } from 'utils/offsiteStatus'

import schedule from 'assets/illustrations/schedule.svg'

const AgendaStep = ({ title, subtitle, parent = 'offsite', offsite, profile, participants }) => {
  const dispatch = useDispatch()
  const actionData = useActionData()

  let durationDays = 3

  if (offsite) {
    if (offsite.indicative_number_days) {
      durationDays = offsite.indicative_number_days
    } else if (offsite.start_at && offsite.end_at) {
      durationDays = Math.ceil(getDaysDiffBetweenDates(new Date(offsite.start_at), new Date(offsite.end_at)) + 1)
    }
  }

  const agendaClosedToModification = profile && !profile.is_staff ? (offsite && offsite.status.includes(STATUSES_NAMES.CONFIRMED_AGENDA)) : false

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Agenda saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch])

  return (
    <MDBox>
      <StepHeader title={title} subtitle={subtitle} />
      { profile.id && agendaClosedToModification ? (
        <MDAlert color="warning" sx={{ marginBottom: '32px'}}>
          <Icon fontSize="medium" sx={{ mr: 2 }}>
            warning
          </Icon>
          <MDTypography variant="body2" fontWeight="regular" color="white" sx={{ fontSize: { xs: '14px', md: '20px'}}}>
            Agenda is closed to modification because there is less that 2 weeks before the offsite!
            <br />
            If you need to make a change contact your Smartway referent.
          </MDTypography>
        </MDAlert>
      ) : null }
      {offsite && offsite.start_at && offsite.end_at ? (
        <MDBox>
          <AgendaBuilder
            showCustomActivities
            profile={profile}
            context="offsite"
            participants={participants}
            editable={!agendaClosedToModification}
            venueType={offsite.all_in_venue ? 'allInVenue' : 'town'}
            venue={offsite.all_in_venue || offsite.town}
            agendaId={offsite.agendas && offsite.agendas[0] ? offsite.agendas[0] : null}
            initialDate={offsite.start_at}
            durationDays={durationDays}
          />
          <MDBox display="flex" justifyContent="space-between" marginBlock={4}>
            <Link
              to={`/dashboard/${parent === 'offer' ? 'offers-offsites' : 'offsites'}/${
                offsite.id
              }/${parent === 'offer' ? 'definition' : 'participants'}`}>
              <MDButton variant="outlined" color="dark">
                Go back
              </MDButton>
            </Link>
            <MDBox>
              <Link
                to={`/dashboard/${parent === 'offer' ? 'offers-offsites' : 'offsites'}/${
                  offsite.id
                }/${parent === 'offer' ? 'communication' : 'accommodations'}`}>
                <MDButton variant="gradient" color="primary" sx={{ ml: 1 }}>
                  Next step
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <MDBox
            component="img"
            src={schedule}
            alt="no results"
            width="100%"
            maxHeight={200}
            mb={3}
          />
          <MDTypography variant="body2">
            Choose the dates of your offsite before to schedule the agenda!
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  )
}

export default AgendaStep
