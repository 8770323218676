import { useState } from 'react'
import { utils, writeFile } from 'xlsx'

import MDBox from 'components/MDBox'
import DataTable from 'components/Tables/DataTable'
import ButtonGroup from '@mui/material/ButtonGroup'
import Icon from '@mui/material/Icon'

import MDButton from 'components/MDButton'
import SettingsButton from 'components/SettingsButton'
import { TABLE_SETTINGS_OPTIONS } from '../../steps/AccommodationStep'
import RoomBeds, { getRoomCategory } from 'components/RoomBeds'
import LoadingButton from '@mui/lab/LoadingButton'
import MDTypography from 'components/MDTypography/index'

const RoomingListTable = ({ offsiteVenue, participants, rooms, roomsAccommodations, onSettingsClick }) => {
  const [view, setView] = useState('rooms')
  const [isExporting, setIsExporting] = useState(false)

  const participantsTable = {
    columns: [
      { Header: 'name', accessor: 'name' },
      { Header: 'accommodation', accessor: 'accommodation' },
      { Header: 'room', accessor: 'room' },
      { Header: 'roommates', accessor: 'roommates' }
    ],
    rows:
      participants && !!participants.length
        ? participants.map((participant) => {
            const { first_name, last_name } = participant
            let accommodation = null
            let room = null
            let roommates = null
            if (participant.room) {
              const participantRoom = rooms.find(r => r.id === participant.room)
              const fullAccommodation = roomsAccommodations.find(
                (a) => a.id === participantRoom.detail.accommodation
              )
              accommodation = fullAccommodation ? fullAccommodation.name : ''
              room = participantRoom.detail.title
              roommates = participantRoom
                .participants
                  .filter((p) => p.id !== participant.id)
                  .map((p) => `${p.first_name} ${p.last_name}`)
                  .join(', ')
            }
            return {
              name: `${first_name} ${last_name}`,
              accommodation,
              room,
              roommates
            }
          })
        : []
  }

  const roomsTable = {
    columns: [
      { Header: 'name', accessor: 'name' },
      { Header: 'people', accessor: 'people' },
      { Header: 'Full capacity', accessor: 'capacity' }
    ],
    rows:
      roomsAccommodations && !!roomsAccommodations.length
        ? roomsAccommodations.map((accommodation) => {
            const { name, rooms: _rooms } = accommodation
            return {
              name,
              people: participants.reduce((acc, curr) => {
                const participantRoom = rooms.find(r => r.id === curr.room)
                if (participantRoom && participantRoom.detail.accommodation === accommodation.id) {
                  return acc + 1
                }
                return acc
              }, 0),
              capacity: _rooms.reduce((acc, curr) => {
                const fullRoom = rooms.find(r => r.room === curr)
                if (!fullRoom) return acc
                return acc + fullRoom?.detail.capacity
              }, 0),
              __innerColumns: [
                { Header: 'Room name', accessor: 'title' },
                { Header: 'Type of room', accessor: 'category' },
                { Header: 'Level', accessor: 'level' },
                { Header: 'People', accessor: 'people' }
              ],
              subRows: _rooms
                ?.filter((r) => {
                  return rooms.map(({ detail }) => detail.id).includes(r)
                })
                .map((r) => {
                  const fullRoom = rooms.find(room => room.detail.id === r)
                  const { title, level, category } = fullRoom?.detail
                  return {
                    title,
                    category: <RoomBeds category={category} />,
                    level: level,
                    people: (
                      <MDBox as="ul">
                        {participants
                          .filter((p) => p.room && p.room === fullRoom.id)
                          .map((p) => (
                            <MDBox as="li" key={p.id}>
                              <MDTypography variant="button" fontWeight="regular">
                                {p.first_name} {p.last_name}
                              </MDTypography>
                            </MDBox>
                          ))}
                      </MDBox>
                    )
                  }
                })
            }
          })
        : []
  }

  const getParticipantFullName = (participant) => {
    if (!participant) return ''
    const { first_name, last_name, email, user } = participant
    return `${first_name} ${last_name} ${participant.hasOwnProperty('user') ? ` (${email}${user && user.mobile ? ` - ${user.mobile}` : ''})` : ''}`
  }

  const formatRoomingListToExcel = () => {
    const merges = []
    let mergedCells = 0
    const firstSheet =
      roomsAccommodations && !!roomsAccommodations.length
        ? roomsAccommodations
            .map((accommodation, index) => {
              const { name, breakfast_included, host, rooms: _rooms } = accommodation
              const actualRooms = rooms.filter((r) =>
                _rooms.includes(r.detail.id)
              )
              if (actualRooms.length > 0) {
                if (actualRooms.length > 1) {
                  merges.push({
                    s: { r: index + 1 + mergedCells, c: 0 },
                    e: { r: actualRooms.length + index + mergedCells, c: 0 }
                  })
                  mergedCells += actualRooms.length - 1
                }
                return actualRooms.map((r, i) => {
                  const { title, bathrooms, category, level } = r.detail
                  const hasParticipants = r.participants && !!r.participants.length

                  return {
                    Name: i > 0 ? '' : name,
                    Rooms: title,
                    Host: getParticipantFullName(host),
                    'Room typology': getRoomCategory({ category }),
                    'Room range': level,
                    Bathrooms: bathrooms,
                    'Breakfast included': breakfast_included ? 'Yes' : 'No',
                    'Guest 1': hasParticipants ? getParticipantFullName(r.participants[0]) : '',
                    'Guest 2': hasParticipants ? getParticipantFullName(r.participants[1]) : '',
                    'Guest 3': hasParticipants ? getParticipantFullName(r.participants[2]) : '',
                    'Guest 4': hasParticipants ? getParticipantFullName(r.participants[3]) : ''
                  }
                })
              }
              return []
            })
            .flat()
        : []
    const secondSheet =
    participants && !!participants.length
    ? participants.map((participant) => {
        const { first_name, last_name } = participant
        let accommodation = null
        let room = null
        let roommates = null
        if (participant.room) {
          const participantRoom = rooms.find(r => r.id === participant.room)
          const fullAccommodation = roomsAccommodations.find(
            (a) => a.id === participantRoom.detail.accommodation
          )
          accommodation = fullAccommodation ? fullAccommodation.name : ''
          room = participantRoom.detail.title
          roommates = participantRoom.participants
            .filter((p) => p.id !== participant.id)
            .map((p) => `${p.first_name} ${p.last_name}`)
        }
        return {
          Name: `${first_name} ${last_name}`,
          Accommodation: accommodation,
          Room: room,
          'Roommate 1': roommates && roommates[0] ? roommates[0] : '',
          'Roommate 2': roommates && roommates[1] ? roommates[1] : '',
          'Roommate 3': roommates && roommates[2] ? roommates[2] : '',
          'Roommate 4': roommates && roommates[3] ? roommates[3] : '',
        }
      })
    : []
    return {
      sheets: [
        {
          title: 'By accommodation',
          data: firstSheet,
          merges
        },
        { title: 'By participant', data: secondSheet }
      ]
    }
  }

  const handleExportRoomingList = async () => {
    setIsExporting(true)
    const a = document.createElement('a')
    a.style = 'display: none'

    const { sheets } = formatRoomingListToExcel()

    document.body.appendChild(a)

    const wb = utils.book_new()

    sheets.forEach((_sheet) => {
      const { title, data, merges } = _sheet
      const sheet = utils.json_to_sheet(data)
      if (merges && merges.length) {
        sheet['!merges'] = merges
      }
      const MIN_COL_WIDTH = 150
      console.log(data)
      if (data && data[0]) {
        sheet['!cols'] = Object.keys(data[0]).map((k) => ({ wpx: MIN_COL_WIDTH }))
        utils.book_append_sheet(wb, sheet, title)
      }
    })

    writeFile(wb, `Rooming-list_Offsite-${offsiteVenue.name}.xlsx`)
    document.body.removeChild(a)
    setIsExporting(false)
  }

  return (
    <MDBox mx={3}>
      <MDBox display="flex" justifyContent="flex-end">
        <ButtonGroup sx={{ mx: 2 }}>
          <MDButton
            variant="outlined"
            color={view === 'rooms' ? 'primary' : 'secondary'}
            onClick={() => setView('rooms')}>
            <Icon>bed</Icon>
          </MDButton>
          <MDButton
            variant="outlined"
            color={view === 'people' ? 'primary' : 'secondary'}
            onClick={() => setView('people')}>
            <Icon>people</Icon>
          </MDButton>
        </ButtonGroup>
        <SettingsButton options={TABLE_SETTINGS_OPTIONS} onClick={onSettingsClick}/>
      </MDBox>

      <MDBox>
        <MDBox display="flex" justifyContent="flex-end" mt={2}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ color: 'secondary.main' }}
            onClick={handleExportRoomingList}
            disabled={isExporting}
            loading={isExporting}>
            Export as XLS
          </LoadingButton>
        </MDBox>
        {view === 'people' ? <DataTable table={participantsTable} /> : null}
        {view === 'rooms' ? <DataTable table={roomsTable} /> : null}
      </MDBox>
    </MDBox>
  )
}

export default RoomingListTable
