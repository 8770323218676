import { Fragment, useMemo, useEffect, useState, useRef, cloneElement } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded
} from 'react-table'


// @mui material components
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Autocomplete from '@mui/material/Autocomplete'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'

import Pagination from 'components/Pagination'

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from 'components/Tables/DataTable/DataTableHeadCell'
import DataTableBodyCell from 'components/Tables/DataTable/DataTableBodyCell'
import InnerRowTable from './InnerRowTable'

const DataTable = ({
  title,
  subtitle,
  entriesPerPage,
  hideEntriesPerPage = false,
  canSearch,
  addAction,
  filterAction,
  showTotalEntries,
  table,
  tableOptions = {
    initialState: {}
  },
  isSorted,
  noEndBorder,
  noResultsComponent,
  pagination,
  actionsOnRowHover,
  onRowClick,
  onSearch,
  onPageSizeChange,
  onSort
}) => {
  const sortRef = useRef(null)
  const [entriesStart, setEntriesStart] = useState(1)
  const [entriesEnd, setEntriesEnd] = useState(pagination?.pageSize || 10)
  const [hoveredRow, setHoveredRow] = useState(null)
  // const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ['5', '10', '15', '20', '25']
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])

  const tableInstance = useTable(
    {
      columns,
      data,
      ...tableOptions,
      ...(pagination
        ? {
            initialState: {
              ...tableOptions.initialState,
              pageIndex: 0,
              pageSize: pagination?.pageSize,
              ...(sortRef && sortRef.current
                ? {
                    sortBy: sortRef.current
                  }
                : {})
            }
          }
        : {
            initialState: {
              ...tableOptions.initialState,
              pageSize: 200,
              ...(sortRef && sortRef.current
                ? {
                    sortBy: sortRef.current
                  }
                : {})
            }
          })
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    // canPreviousPage,
    // canNextPage,
    // gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    setGlobalFilter,
    state: {
      // pageIndex,
      pageSize,
      globalFilter
    }
  } = tableInstance

  // Set the default value for the entries per page when component mounts
  // useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    onPageSizeChange(value)
  }

  // Render the paginations
  // const renderPagination = pageOptions.map((option) => (
  //   <MDPagination
  //     item
  //     key={option}
  //     onClick={() => gotoPage(Number(option))}
  //     active={pageIndex === option}
  //   >
  //     {option + 1}
  //   </MDPagination>
  // ));

  // Handler for the input to set the pagination index
  // const handleInputPagination = ({ target: { value } }) =>
  //   value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // // Customized page options starting from 1
  // const customizedPageOptions = pageOptions.map((option) => option + 1);

  // // Setting value for the pagination input
  // const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter)

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    if (onSearch && typeof onSearch === 'function') {
      onSearch(value || undefined)
    } else {
      setGlobalFilter(value || undefined)
    }
  }, 100)

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce'
    } else if (isSorted) {
      sortedValue = 'none'
    } else {
      sortedValue = false
    }

    return sortedValue
  }

  const getFixedColumnStyles = (column) => {
    return column.fixed ? {
      position: 'sticky',
      background: 'white',
      zIndex: 1,
      opacity: 0.95,
      // width: column.getSize(),
      boxShadow: column.fixed === 'left'
      ? '-5px 0px 5px -5px #DEDEDE inset'
      : column.fixed === 'right'
        ? '5px 0 5px -5px #DEDEDE inset'
        : undefined,
      ...(column.fixed === 'left' ? { left: 0 } : {}),
      ...(column.fixed === 'right' ? { right: 0 } : {}),
    } : {}
  }

  // Setting the entries starting point
  // const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  // let entriesEnd;

  // if (pageIndex === 0) {
  //   entriesEnd = pageSize;
  // } else if (pageIndex === pageOptions.length - 1) {
  //   entriesEnd = rows.length;
  // } else {
  //   entriesEnd = pageSize * (pageIndex + 1);
  // }

  useEffect(() => {
    if (pagination && pagination.page) {
      const realPage = pagination.page - 1
      const resultsLowerThanPageSize = pagination.count < pagination.pageSize
      const newEntriesEnd = resultsLowerThanPageSize
        ? pagination.count
        : realPage * pagination.pageSize + pagination.pageSize
      setEntriesStart(realPage * pagination.pageSize + 1)
      setEntriesEnd(newEntriesEnd)
      if (pagination.pageSize && pagination.pageSize !== pageSize) {
        setPageSize(pagination.pageSize)
      }
    } else {
      setEntriesStart(1)
      setEntriesEnd(rows.length)
    }
  }, [pageSize, pagination, rows.length, setPageSize])

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {title || subtitle ? (
        <MDBox position="sticky" left="0" px={{ xs: 2, md: 3 }} pt={{ xs: 2, md: 3 }} pb={{ xs: 2, md: 4 }}>
          {title ? <MDTypography variant="h4">{title}</MDTypography> : null}
          {subtitle ? <MDTypography variant="body2">{subtitle}</MDTypography> : null}
        </MDBox>
      ) : null}
      {entriesPerPage || canSearch || addAction || filterAction ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          position="sticky"
          left="0"
          p={{ xs: 2, md: 3 }}>
          {filterAction && !canSearch ? <MDBox>{filterAction}</MDBox> : null}
          {!!entriesPerPage && !!pagination && !hideEntriesPerPage ? (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pagination.pageSize?.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10))
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          ) : null}
          {canSearch ? (
            <MDBox
              display="flex"
              alignItems="center"
              width={{ xs: '100%', md: '16rem' }}
              mb={{ xs: 2, md: 0 }}>
              {filterAction ? <MDBox minWidth="4rem">{filterAction}</MDBox> : null}
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search)
                  onSearchChange(currentTarget.value)
                }}
              />
            </MDBox>
          ) : null}
          {addAction ? <MDBox>{addAction}</MDBox> : null}
        </MDBox>
      ) : null}

      {page && page.length ? (
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup, headerIndex) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}-${headerIndex}`}>
                {headerGroup.headers.map((column, colIndex) => {
                  const handleColumnClick = (e) => {
                    sortRef.current =
                      sortRef.current && Array.isArray(sortRef.current)
                        ? sortRef.current.reduce((acc, curr) => {
                            const isInAcc = acc?.map((i) => i.id).includes(column.id)
                            if (isInAcc) {
                              const index = acc?.map((i) => i.id).indexOf(column.id)
                              acc[index].desc = !column.isSortedDesc
                            } else {
                              acc.push({
                                id: column.id,
                                desc: !column.isSortedDesc
                              })
                            }
                            return acc
                          }, [])
                        : [{ id: column.id, desc: !column.isSortedDesc }]

                    column.toggleSortBy(!column.isSortedDesc)
                    if (onSort && typeof onSort === 'function') {
                      onSort(column)
                    }
                  }
                  let sx = {}
                  if (actionsOnRowHover) {
                    sx.maxWidth = column.width
                  }
                  if (column.fixed) {
                    sx = { ...sx, ...getFixedColumnStyles(column) }
                  }
                  return (
                    <DataTableHeadCell
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={!actionsOnRowHover && column.width ? column.width : 'auto'}
                      sx={sx}
                      align={column.align ? column.align : 'left'}
                      sorted={setSortedValue(column)}
                      onClick={handleColumnClick}
                      key={`column--${headerIndex}-${colIndex}`}>
                      {column.render('Header')}
                    </DataTableHeadCell>
                  )
                })}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              const hoverProps = actionsOnRowHover
                ? {
                    sx: {
                      position: 'relative'
                    },
                    onMouseEnter: () => setHoveredRow(row),
                    onMouseLeave: () => setHoveredRow(null)
                  }
                : {}
              const rowProps = onRowClick && typeof onRowClick === 'function'
                ? {
                    onClick: (e) => {
                      e.stopPropagation()
                      onRowClick(row.original.__data)
                    },
                    ...hoverProps,
                    sx: { position: 'relative', cursor: 'pointer' }
                  }
                : hoverProps
              return (
                <Fragment key={`${key}-${row.id}`}>
                  {row.depth !== 0 ? null : (
                    <TableRow
                      {...row.getRowProps()}
                      {...rowProps}
                      onClick={onRowClick ? () => onRowClick(row.original.__data) : () => {}}
                      style={
                        row.original.__styles
                          ? { ...(row.original.__styles(row.original.__data) || {}) }
                          : {}
                      }>
                      {row.cells.map((cell, cellIndex) => {
                        const sx = getFixedColumnStyles(cell.column)
                        return (
                          <DataTableBodyCell
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : 'left'}
                            {...cell.getCellProps()}
                            sx={sx}
                            key={`cell-${key}-${cellIndex}`}>
                            {row.canExpand && cellIndex === 0 ? (
                              <MDBox display="inline" mr={2} {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? (
                                  <Icon>chevron_down</Icon>
                                ) : (
                                  <Icon>chevron_right</Icon>
                                )}
                              </MDBox>
                            ) : null}
                            {cell.render('Cell')}
                          </DataTableBodyCell>
                        )
                      })}
                      {actionsOnRowHover && hoveredRow ? (
                        <MDBox
                          component="td"
                          sx={
                            hoveredRow.id === row.id
                              ? {
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  height: '100%',
                                  width: '100%',
                                  zIndex: 1,
                                  background:
                                    'linear-gradient(90deg, rgba(106,106,106,0.04) 58%, rgba(255,255,255,0.7) 90%)'
                                }
                              : {
                                  display: 'none'
                                }
                          }>
                          <MDBox sx={{ mr: 2, transform: 'translate(1px, 0)' }}>
                            {cloneElement(actionsOnRowHover, { data: row.original.__data })}
                          </MDBox>
                        </MDBox>
                      ) : null}
                    </TableRow>
                  )}
                  {row.canExpand && row.isExpanded ? (
                    <MDBox ml={4}>
                      <InnerRowTable
                        table={{
                          columns: row.original.__innerColumns,
                          rows: row.originalSubRows
                        }}
                      />
                    </MDBox>
                  ) : null}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      ) : noResultsComponent ? (
        <MDBox
          width="100%"
          p={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px">
          {noResultsComponent}
        </MDBox>
      ) : (
        <MDBox px={3}>
          <MDTypography color="secondary">No records found</MDTypography>
        </MDBox>
      )}

      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        position="sticky"
        left="0"
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}>
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of{' '}
              {pagination ? pagination.count : rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {!!pagination ? <Pagination {...pagination} /> : null}
        {/* {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )} */}
      </MDBox>
    </TableContainer>
  )
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: null,
  isSorted: true,
  noEndBorder: false
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number)
    }),
    PropTypes.bool
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light'
    ])
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool
}

export default DataTable
