import { useState, useEffect } from 'react'

import MDButton from 'components/MDButton'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

import getSchema from './schemas/room'

import Form from 'components/Form'

const RoomDialog = ({ open, room = {}, bulk, entity, entityName, title, onClose, onSave }) => {
  const [schema, setSchema] = useState(getSchema({room, bulk, entityName}))
  const handleFieldChange = (event) => {
    const { value, name } = event.target
    if (name === 'position_switch') {
      setSchema(getSchema({
        room: { ...room, [name]: value }, 
        bulk, 
        entityName,
      }))
    }
  }

  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      onSave({ ...room, ...values })
    }
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    setSchema(getSchema({room, bulk, entityName}))
  }, [room, bulk, entityName])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      scroll="paper"
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
        <DialogTitle>
          <Container>{title}</Container>
        </DialogTitle>
        <DialogContent dividers className="dialogContent">
          <Container>
            <Form
              {...schema}
              values={room}
              watchedFields={['position_switch']}
              onFieldChange={handleFieldChange}
              onSubmit={handleOnSave}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MDButton onClick={onClose}>Cancel</MDButton>
            <MDButton
              form="room-form"
              type="submit"
              variant="contained"
              color="primary">
              Save
            </MDButton>
          </Container>
        </DialogActions>
    </Dialog>
  )
}

export default RoomDialog
