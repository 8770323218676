const form = {
  id: 'signin',
  title: '',
  fieldsets: [
    {
      title: '',
      fields: [
        {
          type: 'email',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          autoComplete: "email",
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: [
                'This field is required'
              ]
            }, 
            {
              type: 'email',
              params: [
                'You should provide a valid email address'
              ]
            }
          ]
        },
        {
          type: 'password',
          name: 'password',
          label: 'Password',
          placeholder: 'Password',
          validationType: 'string',
          autoComplete: "current-password",
          grid: { xs: 12, sx: { '> div': { mb: 2 } } },
          validations: [
            {
              type: 'required',
              params: [
                'This field is required'
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default form