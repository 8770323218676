import MDBox from "components/MDBox"
import Checkbox from "components/Fields/Checkbox/Checkbox"

const RoomActionsCell = ({ room, selected, onSelect }) => {

  const handleSelect = () => {
    onSelect(room)
  }

  return (
    <MDBox>
      <Checkbox
        name={room.id}
        value={selected}
        onClick={handleSelect}
      />
    </MDBox>
  )
}

export default RoomActionsCell