import { useState, useRef } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { DatepickerDay, getDatepickerStyles } from '../Datepicker/Datepicker'

const DatepickerRange = ({
  name,
  start,
  end,
  value,
  disabled,
  initialValue = [null, null],
  hidden = false,
  onChange,
  errors,
  minDate,
  warning,
  inputProps = {},
  ...props
}) => {
  const [intermediateValues, setIntermediateValues] = useState(value || initialValue)
  const startDatepicker = useRef(null)
  const endDatepicker = useRef(null)

  const handleMinClear = () => {
    setIntermediateValues([null, intermediateValues[1]])
    onChange({
      target: {
        name: start.name,
        value: null
      }
    })
  }
  const handleMaxClear = () => {
    setIntermediateValues([intermediateValues[0], null])
    onChange({
      target: {
        name: end.name,
        value: null
      }
    })
  }

  const handleStartChange = (date) => {
    const timeDate = new Date(date).getTime()
    let intermediate = intermediateValues
    if (intermediateValues[1]) {
      const timeEnd = new Date(intermediateValues[1]).getTime()
      if (timeDate > timeEnd) {
        intermediate = [date, null]
        setTimeout(() => {
          endDatepicker.current.querySelector('button').click()
        })
      } else {
        intermediate = [date, intermediateValues[1]]
      }
    } else {
      endDatepicker.current.querySelector('button').click()
      intermediate = [date, intermediateValues[1] || null]
    }
    onChange({
      target: {
        name: start.name,
        value: intermediate[0]
      }
    })
    setIntermediateValues(intermediate)
  }

  const handleEndChange = (date) => {
    const timeDate = new Date(date).getTime()
    let endIntermediate = intermediateValues
    if (intermediateValues[0]) {
      const timeStart = new Date(intermediateValues[0]).getTime()
      if (timeDate < timeStart) {
        startDatepicker.current.querySelector('button').click()
        endIntermediate = [date, null]
      } else {
        endIntermediate = [intermediateValues[0], date]
      }
    } else {
      startDatepicker.current.querySelector('button').click()
      endIntermediate = [null, date]
    }
    onChange({
      target: {
        name: end.name,
        value: endIntermediate[1]
      }
    })
    setIntermediateValues(endIntermediate)
  }

  return (
    <MDBox
      position="relative"
      mb={hidden ? 0 : 4}
      sx={{
        opacity: hidden ? 0 : 1,
        visibility: hidden ? 'hidden' : 'visible',
        transition: 'opacity 0.2s',
        height: hidden ? 0 : 'auto',

      }}>
      <MDBox display="flex" justifyContent="space-between" gap={2}>
        <DatePicker
          name={start.name}
          value={intermediateValues[0] ? new Date(intermediateValues[0]) : null}
          ref={startDatepicker}
          disabled={disabled}
          {...start}
          sx={{
            ...(props.sx ? props.sx : {}),
            ...getDatepickerStyles(inputProps)
          }}
          slots={{
            day: DatepickerDay
          }}
          slotProps={{
            field: { clearable: true, onClear: handleMinClear }
          }}
          input={{ fullWidth: true, ...inputProps }}
          onChange={handleStartChange}
        />
        <DatePicker
          name={end.name}
          value={intermediateValues[1]}
          minDate={intermediateValues[0]}
          ref={endDatepicker}
          disabled={disabled}
          {...end}
          sx={{
            ...(props.sx ? props.sx : {}),
            ...getDatepickerStyles(inputProps)
          }}
          slots={{
            day: DatepickerDay
          }}
          slotProps={{
            field: { clearable: true, onClear: handleMaxClear }
          }}
          input={{ fullWidth: true, ...inputProps }}
          onChange={handleEndChange}
        />
      </MDBox>
      {errors ? (
        <MDBox position="absolute">
          <MDTypography color="error" variant="caption">
            {errors}
          </MDTypography>
        </MDBox>
      ) : null}
      {warning ? (
        <MDBox position="relative" display="flex" alignItems="center" mt={1}>
          <Icon fontSize="small" color="warning" sx={{ mr: 1 }}>
            warning
          </Icon>
          <MDTypography color="warning" variant="caption" display="inline-block" lineHeight="18px">
            {warning}
          </MDTypography>
        </MDBox>
      ) : null}
    </MDBox>
  )
}

export default DatepickerRange
