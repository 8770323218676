import { useState } from 'react'
import { format } from 'date-fns'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Select from 'components/Fields/Select/Select'

const ActivityParticipantsDialog = ({ open, slot, participants = [], onSave, onClose }) => {
  const allSlots = [slot, ...(slot?.overlapEvents || [])]
  let _participantsBySlots = {}
  if (allSlots.length > 1) {
    _participantsBySlots = allSlots.reduce((acc, slot) => {
      acc[slot.id] = slot.participants
      return acc
    }, {})
  }
  const flatNewParticipants = Object.keys(_participantsBySlots).reduce((acc, key) => {
    return [...acc, ..._participantsBySlots[key]]
  }, [])
  const [participantsBySlot, setParticipantsBySlot] = useState(_participantsBySlots)
  const _availableParticipants =
    participants && !!participants.length
      ? participants.filter((p) => !flatNewParticipants.includes(p.id))
      : []
  const [availableParticipants, setAvailableParticipants] = useState(_availableParticipants)

  const handleOnSave = () => {
    if (onSave && typeof onSave === 'function') {
      onSave(participantsBySlot)
    }
  }
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleChange = (event) => {
    const {
      target: { name, value }
    } = event
    const newParticipants = {
      ...participantsBySlot,
      [name]: value[value.length - 1] === 'all' ? availableParticipants.map((p) => p.id) : value
    }
    setParticipantsBySlot(newParticipants)
    const flatNewParticipants = Object.keys(newParticipants).reduce((acc, key) => {
      return [...acc, ...newParticipants[key]]
    }, [])
    setAvailableParticipants(participants.filter((p) => !flatNewParticipants.includes(p.id)))
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>Select the participants for each activity.</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <MDBox display="flex" flexWrap="wrap" mt={2} gap="3rem 0">
            {allSlots.map((slot) => {
              const participantsInOtherSlots = Object.keys(participantsBySlot).reduce((acc, key) => {
                if (Number(key) !== slot.id) {
                  return [...acc, ...participantsBySlot[key]]
                }
                return acc
              }, [])
              return (
                <MDBox
                  key={slot.id}
                  width={`${100 / allSlots.length}%`}
                  sx={{
                    minWidth: '33%',
                    pr: 3,
                    '&:not(:nth-child(3n + 1))': {
                      pl: 3,
                      borderLeft: '1px solid #e0e0e0'
                    }
                  }}>
                  <MDTypography variant="h6">
                    <span>{slot.title}</span>
                    <span style={{ display: 'block' }}>
                      {slot.start_hour ? slot.start_hour : format(new Date(slot.start_at), 'HH:mm')}{' '}
                      - {slot.end_hour ? slot.end_hour : format(new Date(slot.end_at), 'HH:mm')}
                    </span>
                  </MDTypography>
                  <MDBox mt={2}>
                    <Select
                      name={slot.id}
                      value={participantsBySlot[slot.id] || []}
                      options={[
                        {
                          label: 'All remaining participants',
                          value: 'all',
                          // disabled: !availableParticipants.length
                        },
                        ...(participants && participants.length
                          ? participants.filter((p) => !participantsInOtherSlots.includes(p.id)).map(({ id, first_name, last_name }) => ({
                              label: `${first_name} ${last_name}`,
                              value: id,
                              // disabled: !availableParticipants.map((p) => p.id).includes(id)
                            }))
                          : [])
                      ]}
                      multiple
                      limitTags={2}
                      onChange={handleChange}></Select>
                  </MDBox>
                  <MDBox>
                    {participantsBySlot[slot.id] ? (
                      <MDBox component="ul">
                        {participantsBySlot[slot.id].map((p) => {
                          const participant =
                            participants && !!participants.length
                              ? participants.find((pp) => pp.id === p)
                              : {}
                          return (
                            <MDBox component="li" key={participant?.id} sx={{ ml: 3 }}>
                              <MDTypography variant="body">
                                {participant?.first_name} {participant?.last_name}
                              </MDTypography>
                            </MDBox>
                          )
                        })}
                      </MDBox>
                    ) : null}
                  </MDBox>
                </MDBox>
              )
            })}
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" color="secondary" onClick={handleOnClose}>
            Close
          </MDButton>
          <MDButton variant="gradient" color="primary" onClick={handleOnSave}>
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ActivityParticipantsDialog
