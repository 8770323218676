import { useReducer, useEffect } from 'react'
import { useLoaderData, useSubmit, useActionData } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import AgendaTypeStep from './components/AgendaTypeStep'
import AgendaVenueStep from './components/AgendaVenueStep'
import AgendaDaysStep from './components/AgendaDaysStep'
import AgendaBuilderStep from './components/AgendaBuilderStep'

const AgendaDetailPage = ({ editingMode }) => {
  const submit = useSubmit()
  const dispatch = useDispatch()
  const actionData = useActionData()
  const { entity, images, fullVenue } = useLoaderData()

  const reducer = (state, action) => {
    if (action.type === 'update') {
      return {
        ...state,
        ...action.payload
      }
    }
    if (action.type === 'reset') {
      return initialState
    }
  }

  const initialState = {
    step: editingMode ? 3 : 0,
    type: entity?.all_in_venue ? 'allInVenue' : 'town',
    venue: entity ? entity.all_in_venue ? entity.all_in_venue : entity.town : null,
    days: entity && entity.nb_days ? entity.nb_days : 2,
    fullVenue
  }

  const [state, reducerDispatch] = useReducer(reducer, initialState)

  const handleCreateAgenda = () => {
    const { type, venue, days } = state
    const payload = {
      title: `Agenda for ${venue.name}`,
      price_per_person: 200,
      nb_days: days,
      all_in_venue: type === 'allInVenue' ? venue.id : null,
      town: type === 'town' ? venue.id : null
    }
    submit(payload, { method: 'post', encType: 'application/json' })
  }

  const handleOnSubmit = (values) => {
    const { type, venue, fullVenue } = state
    const payload = {
      ...values,
      id: entity.id,
      all_in_venue: type === 'allInVenue' ? (venue || fullVenue.id) : null,
      town: type === 'town' ? (venue || fullVenue.id) : null
    }
    submit(payload, { method: 'patch', encType: 'application/json' })
  }

  const handleTypeChange = (type) => {
    reducerDispatch({ type: 'update', payload: { step: 1, type } })
  }
  const handleVenueChange = (venue) => {
    reducerDispatch({ type: 'update', payload: { step: 2, venue } })
  }

  const handleDaysChange = (days) => {
    reducerDispatch({ type: 'update', payload: { step: 3, days } }) 
    handleCreateAgenda()
  }

  const handleDaysBack = () => {
    reducerDispatch({ type: 'update', payload: { step: 1 } })
  }

  const handleVenueBack = () => {
    reducerDispatch({ type: 'update', payload: { step: 0 } })
  }

  const handleOnCancel = () => {
    if (!editingMode) {
      reducerDispatch({ type: 'update', payload: { step: 1 } })
    }
  }

  const steps = [
    {
      key: 'venue-type',
      component: <AgendaTypeStep type={state.type} onChange={handleTypeChange} />
    },
    {
      key: 'venue',
      component: (
        <AgendaVenueStep
          type={state.type}
          onBack={handleVenueBack}
          onVenueChange={handleVenueChange}
        />
      )
    },
    {
      key: 'days',
      component: (
        <AgendaDaysStep
          onBack={handleDaysBack}
          onChange={handleDaysChange}
        />
      )
    },
    {
      key: 'builder',
      component: (
        <AgendaBuilderStep
          entity={entity}
          context="agenda"
          images={images}
          venueType={state.type}
          venue={state.venue}
          days={state.days}
          fullVenue={state.fullVenue}
          onCancel={handleOnCancel}
          onSubmit={handleOnSubmit}
        />
      )
    }
  ]

  useEffect(() => {
    if (actionData?.success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Agenda saved successfully',
          type: 'success'
        })
      )
    }
    if (actionData?.error) {
      dispatch(notify({ title: 'There was an error', message: actionData.error, type: 'error' }))
    }
  }, [actionData, dispatch])

  useEffect(() => {
    if (entity && entity.days !== state.days) {
      reducerDispatch({ type: 'reset' })
    } 
  }, [entity, state.days])

  return steps[state.step].component
}

export default AgendaDetailPage
