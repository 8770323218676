import Form from 'components/Form'
import getSectionSchema from './schema'

import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'
import { formatSectionValuesToServer, formatSectionValuesFromServer } from 'utils/pages'

const SectionForm = ({ title, type, section, onChange, onSave }) => {
  const values = section ? formatSectionValuesFromServer(section) : {}
  const schema = getSectionSchema({ title, type, ...values })
  const fields = schema.fieldsets.reduce((acc, curr) => {
    return [...acc, ...curr.fields]
  }, [])

  const handleSubmit = (values) => {
    if (onSave && typeof onSave === 'function') {
      const formatted = formatSectionValuesToServer({ sectionValues: { type, ...section, ...values }, isRepeaterItem: false })
      onSave({ sectionValues: formatted })
    }
  }

  return fields && fields.length ? (
    <MDBox>
      <Grid container>
        <Form
          {...schema}
          watchedFields={fields.map((f) => f.name)}
          onFieldChange={onChange}
          values={values}
          onSubmit={handleSubmit}
        />
      </Grid>
    </MDBox>
  ) : null
}

export default SectionForm
