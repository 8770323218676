import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import { Link, useAsyncError } from 'react-router-dom'

import internalError from 'assets/illustrations/error.svg'


const GenericError = ({ title, message, link, minHeight, minWidth }) => {

  const error = useAsyncError()
  console.warn(error)

  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight || "100%"}
      minWidth={minWidth || "100%"}>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={-6}>
        <MDBox
          component="img"
          src={internalError}
          width="300px"
          height="250px"
          ml={4}
          mb={3}
          alt="internal error"
        />
        <MDTypography variant="h1" textAlign="center" mb={1}>
          Oh no!
        </MDTypography>
        <MDTypography
          variant="body2"
          textAlign="center"
          maxWidth="450px"
          mb={4}>
          {title ? title : `It seems that there is an error that depends of us. If you want you can contact the IT team with this message`}: {error && error.message ? error.message : message}
        </MDTypography>
        <Link to={link || `/dashboard`}>
          <MDButton color="primary">Take me home</MDButton>
        </Link>
      </MDBox>
    </MDBox>
  )
}

export default GenericError