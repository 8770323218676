const acceptedDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'live.com',
  'libero.it',
  'outlook.com',
  'hotmail.it',
  'verizon.net',
  'yahoo.it',
  'facebook.com',
  'yahoo.co.jp',
  'me.com',
  'live.it',
  'live.fr',
  'mac.com'
]

export default acceptedDomains
