import { useState } from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
} from '@mui/material'

import MDButton from 'components/MDButton'

import LoadingButton from '@mui/lab/LoadingButton'
import CompanyForm from 'layouts/pages/companies/detail/components/CompanyForm/CompanyForm'


const CompanyDialog = ({ open, title, companyName, link = {}, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnSave = async (values) => {
    if (onSave && typeof onSave === 'function') {
      setIsLoading(true)
      await onSave(values)
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' }}}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0}}>
          <CompanyForm inDialog company={{ name: companyName }} onSubmit={handleOnSave}/>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            type="submit"
            form="company-form"
            disabled={isLoading}
            loading={isLoading}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white !important',
              '&:hover': {
                backgroundColor: 'error.light'
              }
            }}
          >
            Create company
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default CompanyDialog
