import { useState, useEffect } from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'

import Form from 'components/Form/Form'

import getSchema from './schemas/additional-information'

const AdditionalInformationDialog = ({ open, offsiteId, title, additionalInformation, participants, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [schema, setSchema] = useState(getSchema({ offsiteId }))

  const resetState = () => {
    setIsLoading(false)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleOnSave = async (values) => {
    let _values = values
    if (values && values.participants && values.participants.includes('all')) {
      _values.participants = []
    }
    setIsLoading(true)
    if (onSave && typeof onSave === 'function') {
      await onSave(_values)
      setIsLoading(false)
    }
  }

  const handleFieldChange = (event, values, formik) => {
    const { name, value } = event.target
    if (name === 'participants') {
      let _value = value
      if (value.includes('all')) {
        if (value[value.length - 1] === 'all') {
          formik.setFieldValue(name, ['all'])
          _value = []
        } else {
          _value = _value.filter((v) => v !== 'all')
          formik.setFieldValue(name, _value)
        }
      }
      setSchema(getSchema({ offsiteId, values: {...values, [name]: _value}, allParticipants: participants }))
    }
  }

  const initialValues = {
    ...additionalInformation,
    participants: additionalInformation.participants && additionalInformation.participants.length ? additionalInformation.participants : ['all'],
  }

  useEffect(() => {
    if (additionalInformation) {
      setSchema(getSchema({ offsiteId, values: initialValues, allParticipants: participants }))
    }
  }, [additionalInformation])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Form
            {...schema}
            onFieldChange={handleFieldChange}
            values={initialValues}
            onSubmit={handleOnSave}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            sx={{
              color: 'white !important',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' }
            }}
            form="additional-information-form"
            type="submit"
            variant="contained"
            disabled={isLoading}
            loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default AdditionalInformationDialog
