import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { ACTIVITY_TYPES } from 'utils/events'

import coworking from 'assets/illustrations/coworking.svg'
import catering from 'assets/illustrations/catering.svg'
import experience from 'assets/illustrations/experience.svg'
import freeTime from 'assets/illustrations/free_time.svg'
import transfer from 'assets/illustrations/transfer.svg'
import logistic from 'assets/illustrations/logistic.svg'
import plenaryHall from 'assets/illustrations/plenary-hall.svg'
import custom from 'assets/illustrations/custom-activity.svg'

const illustrations = {
  coworking: coworking,
  experience: experience,
  workshop: experience,
  catering: catering,
  FREE_TIME: freeTime,
  TRANSFER: transfer,
  LOGISTIC: logistic,
  'plenary_hall': plenaryHall,
  CUSTOM: custom
}

const getTabs = (profile, showCustomActivities) => {
  const showCustom = profile && profile.company && showCustomActivities
  return ACTIVITY_TYPES.map((type) => {
    return {
        ...type,
        illustration: illustrations[type.name]
      }
    }).filter(t => showCustom ? true : t.name !== 'CUSTOM')
  }


// type -> ACTIVITY_TYPE
const TypeCard = ({ illustration, onCardClick, ...type }) => {
  const handleClick = () => {
    onCardClick(type)
  }
  return (
    <Card
      position="relative"
      sx={{
        minHeight: '200px',
        height: '100%'
      }}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}>
        <MDBox
          component="img"
          src={illustration}
          alt={type.label}
          height={120}
          maxWidth={150}
          minHeight={120}
          maxHeight={120}
          mb={2}></MDBox>
        <MDTypography variant="body">{type.label}</MDTypography>
      </MDBox>
    </Card>
  )
}

const ActivityTypePicker = ({ profile, showCustomActivities, onTypeChange }) => {
  return (
    <MDBox>
      <MDTypography variant="h5">Choose the type of your activity</MDTypography>
      <MDTypography variant="body2">Don't worry, you can change it later</MDTypography>
      <Grid container spacing={2} mt={2}>
        {getTabs(profile, showCustomActivities).map((tab, index) => (
          <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
            <TypeCard {...tab} onCardClick={onTypeChange} />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  )
}

export default ActivityTypePicker
