import { useState } from 'react'
import { useSubmit } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AgendaBuilder from 'components/AgendaBuilder/AgendaBuilder'
import { smartwayApi } from 'services/api'
import { generateAgendaDescription } from 'services/ai/generation-functions'

import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import Form from 'components/Form'
import ImageCarousel from 'components/ImageCarousel'
import getSchema from './schema'

const AgendaBuilderStep = ({ entity, images, days, venueType, venue, fullVenue, onSubmit }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const dispatch = useDispatch()
  const submit = useSubmit()
  const handleGenerateClick = ({ value }) => {
    return new Promise(async (resolve) => {
      const description = await generateAgendaDescription({
        dispatch,
        smartwayApi,
        nb_days: days,
        params: { id: entity.id }
      })
      resolve({ description })
    })
  }
  const schema = getSchema({ editingMode: !!entity, handleGenerateClick })

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const handleImagesChange = () => {
    submit({ onlyImages: true }, { method: 'post', encType: 'application/json' })
  }

  return (
    <>
      <MDBox>
        <AgendaBuilder
          {...entity}
          venueType={venueType}
          venue={venue}
          agendaId={entity?.id}
          initialDate={new Date('1970-01-01')}
          durationDays={days}
          actionsSlot={<MDBox mb={2}>
            <MDButton
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ justifyContent: 'space-between', px: 2 }}
              onClick={() => setDrawerOpen(true)}>
                Edit agenda
              <Icon sx={{ ml: 1 }}>edit</Icon>
            </MDButton>
          </MDBox>}
        />
      </MDBox>
      <Drawer
        PaperProps={{ sx: { width: '70vw' } }}
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}>
        <IconButton
          aria-label="close"
          onClick={() => setDrawerOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <Grid container mt={4} spacing={3} px={2}>
          {!!entity ? (
            <Grid item xs={12} md={4}>
              <ImageCarousel
                id={entity.id}
                entity="agenda"
                images={images}
                onChange={handleImagesChange}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={8}>
            <MDBox p={2} pb={0}>
              <MDTypography
                variant="h5"
                fontWeight="medium"
                display="inline-flex"
                alignItems="center">
                <Icon sx={{ mr: 1 }}>location_on</Icon>
                {venue?.name || fullVenue?.name}
              </MDTypography>
              <MDBox>
                <Form
                  values={entity}
                  {...schema}
                  needChangeToSubmit={false}
                  onSubmit={handleSubmit}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}

export default AgendaBuilderStep
