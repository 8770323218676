import { Link, useRouteError } from 'react-router-dom'

import Icon from '@mui/material/Icon'

import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton/index'
import notFoundIllustration from 'assets/illustrations/lost.svg'
import internalError from 'assets/illustrations/error.svg'
import serverError from 'assets/illustrations/serverdown.svg'

const InternalError = ({ message }) => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={-6}>
        <MDBox
          component="img"
          src={internalError}
          width="300px"
          height="250px"
          ml={4}
          mb={3}
          alt="internal error"
        />
        <MDTypography variant="h1" textAlign="center" mb={1}>
          Oh no!
        </MDTypography>
        <MDTypography
          variant="body2"
          textAlign="center"
          maxWidth="450px"
          mb={4}>
          It seems that there is an error that depends of us. If you want you
          can contact the IT team with this message: {message}
        </MDTypography>
        <MDBox display="flex" gap={2}>
          <MDButton variant="outlined" color="secondary" onClick={() => window.location.reload()}>Try to Refresh <Icon sx={{ ml: 1 }}>refresh</Icon></MDButton>
          <Link to={`/dashboard`}>
            <MDButton color="primary">Take me home</MDButton>
          </Link>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

const ServerError = ({ status, statusText }) => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={-6}>
        <MDBox
          component="img"
          src={serverError}
          width="300px"
          height="250px"
          ml={4}
          mb={3}
          alt="internal error"
        />
        <MDTypography variant="h1" textAlign="center" mb={1}>
          Oh no!
        </MDTypography>
        <MDTypography
          variant="body2"
          textAlign="center"
          maxWidth="450px"
          mb={4}>
          It seems that there is an error from the server. You can retry later or you
          can contact the IT team with this message: {statusText}
        </MDTypography>
        <Link to={`/dashboard`}>
          <MDButton color="primary">Take me home</MDButton>
        </Link>
      </MDBox>
    </MDBox>
  )
}

const NotFound = () => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
      sx={{
        background: `url(${notFoundIllustration}) no-repeat center`,
        backgroundSize: 'cover'
      }}>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <MDTypography variant="h1" mb={1}>
          It seems you're lost
        </MDTypography>
        <MDTypography
          variant="body2"
          textAlign="center"
          maxWidth="450px"
          mb={4}>
          This page does not exist
        </MDTypography>
        <Link to={`/dashboard`}>
          <MDButton color="primary">Take me home</MDButton>
        </Link>
      </MDBox>
    </MDBox>
  )
}

const GlobalError = () => {
  let { status, statusText, message } = useRouteError()
  const notFound = status === 404
  const internalError = !status

  if (notFound) {
    return <NotFound />
  }
  if (internalError) {
    return <InternalError message={message} />
  }
  return <ServerError status={status} statusText={statusText} />
}

export default GlobalError
