import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

export const OffsiteCardSkeleton = () => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'row' }}>
      <Skeleton variant="rounded" sx={{ width: '33%', minWidth: '230px' }} height={230} />
      <MDBox m={2} width="100%">
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <Skeleton variant="rounded" sx={{ width: '200px' }} height={30} />
            <Skeleton variant="rounded" sx={{ width: '240px', mt: 1 }} height={20} />
          </MDBox>
          <MDBox>
            <Skeleton variant="rounded" sx={{ width: '40px' }} height={40} />
          </MDBox>
        </MDBox>

        <MDBox display="flex" alignItems="center" mt={4} gap={1}>
          <Skeleton variant="rounded" sx={{ width: '20px' }} height={21} />
          <Skeleton variant="rounded" sx={{ width: '80px' }} height={21} />
        </MDBox>
        <MDBox display="flex" alignItems="center" mt={1} gap={1}>
          <Skeleton variant="rounded" sx={{ width: '20px' }} height={21} />
          <Skeleton variant="rounded" sx={{ width: '60px' }} height={21} />
        </MDBox>
        <MDBox display="flex" alignItems="center" mt={2} gap={1}>
          <Skeleton variant="rounded" sx={{ width: '158px' }} height={40} />
          <Skeleton variant="rounded" sx={{ width: '158px' }} height={40} />
        </MDBox>
      </MDBox>
    </Card>
  )
}

export const OffsiteCardsSkeleton = ({ offsites }) => {
  return (
    <Grid container spacing={3}>
      {offsites && offsites.length ? offsites.map((_, index) => (
        <Grid item xs={12} key={`skeleton-${index}`}>
          <OffsiteCardSkeleton key={`skeleton-${index}`} />
        </Grid>
      )) : null}
    </Grid>
  )
}
