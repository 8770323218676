import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const ske = {
  animation: 'wave',
  variant: 'rounded'
}
export const AccommodationStepSkeleton = ({ town }) => {
  return (
    <MDBox>
      <MDBox mt={2} mx={3}>
        <MDTypography variant="h3" fontWeight="medium">
          Accommodations
        </MDTypography>
        <MDTypography variant="subtitle2">
          Smartway selected for you the best available accommodations in{' '}
          {town}. Fill them with guests!
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mt={4} mx={3}>
        <Skeleton {...ske} width={230} height={48}/>
        <Skeleton {...ske} width={200} height={48}/>
      </MDBox>
      <MDBox mx={3}>
        <Grid
          container
          alignItems="start"
          spacing={4}
          sx={{ height: '100%', mt: 2 }}>
          {Array.from(new Array(6)).map((_, index) => (
            <Grid key={`cards-skeleton-${index}`} item xs={12} md={5} lg={4}>
              <Skeleton {...ske} height={370} />
            </Grid>
          ))}
          <Grid container justifyContent="center" marginTop={6}>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

