/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import BasicInfoForm from './BasicInfoForm'

const BasicInfo = ({ profile, onSubmit }) => {
  return (
    <Card id="basic-info" sx={{ overflow: "visible", px: 2, py: 1 }}>
      <MDBox px={1}>
        <BasicInfoForm profile={profile} onSubmit={onSubmit} />
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
