const defaultInputsProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true
  }
}
const getSchema = ({ values }) => {
  const { indicative_number_days } = values
  return {
    name: 'offsite-transformation-form',
    id: 'offsite-transformation-form',
    watchedFields: ['start_at', 'end_at'],
    fieldsets: !!indicative_number_days
      ? [
          {
            id: 'general',
            title: 'Please add the dates of the event',
            fields: [
              {
                type: 'datepickerrange',
                name: 'start_at_end_at',
                start: {
                  name: 'start_at',
                  label: 'Check-in',
                  placeholder: 'Select a check-in date',
                  minDate: new Date()
                },
                end: {
                  name: 'end_at',
                  label: 'Check-out',
                  placeholder: 'Select a check-out date'
                },
                validationType: 'string',
                grid: { xs: 12 },
                minDate: 'start_at',
                validations: [
                  {
                    type: 'nullable'
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ],
                inputProps: {
                  label: 'Check-out',
                  placeholder: 'Select date',
                  ...defaultInputsProps
                },
                ...defaultInputsProps
              }
            ]
          }
        ]
      : []
  }
}

export default getSchema
