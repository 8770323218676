export const getValueFromAddressComponents = ({ address_components, value }) => {
  if (value === 'country') {
    return address_components
      .filter(({ types }) => types.includes('country'))
      .map(({ long_name }) => long_name)[0]
  }
  if (value === 'region') {
    return address_components
      .filter(({ types }) => types.includes('administrative_area_level_1'))
      .map(({ long_name }) => long_name)[0]
  }
  return null
}

export const ITALY_REGIONS = [
  {
    label: 'Abruzzo',
    value: 'Abruzzo'
  },
  {
    label: 'Basilicata',
    value: 'Basilicata'
  },
  {
    label: 'Calabria',
    value: 'Calabria'
  },
  {
    label: 'Campania',
    value: 'Campania'
  },
  {
    label: 'Emilia-Romagna',
    value: 'Emilia-Romagna'
  },
  {
    label: 'Friuli-Venezia Giulia',
    value: 'Friuli-Venezia Giulia'
  },
  {
    label: 'Lazio',
    value: 'Lazio'
  },
  {
    label: 'Liguria',
    value: 'Liguria'
  },
  {
    label: 'Lombardy',
    value: 'Lombardy'
  },
  {
    label: 'Marche',
    value: 'Marche'
  },
  {
    label: 'Molise',
    value: 'Molise'
  }, 
  {
    label: 'Piedmont',
    value: 'Piedmont'
  },
  {
    label: 'Apulia',
    value: 'Apulia'
  },
  {
    label: 'Sardinia',
    value: 'Sardinia'
  },
  {
    label: 'Sicily',
    value: 'Sicily'
  },
  {
    label: 'Tuscany',
    value: 'Tuscany'
  },
  {
    label: 'Trentino-South Tyrol',
    value: 'Trentino-South Tyrol'
  },
  {
    label: 'Umbria',
    value: 'Umbria'
  },
  {
    label: 'Aosta Valley',
    value: 'Aosta Valley'
  },
  {
    label: 'Veneto',
    value: 'Veneto'
  }
]

export const COUNTRIES = [
  {
    value: 'Italy',
    label: 'Italy',
  }
]