const getSchema = (participants) => {
  const someoneAlreadyInvited = participants.some(p => !!p.last_invited_at)

  return {
    id: 'invitation-form',
    fieldsets: [
      {
        fields: someoneAlreadyInvited ? [
          {
            type: 'checkbox',
            name: 'selected',
            label: 'Selected people',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 12, pt: '0 !important' }
          },
          {
            type: 'checkbox',
            name: 'not_invited',
            label: 'New people added',
            validationType: 'boolean',
            validations: null,
            grid: { pt: '0 !important' }
          },
          {
            type: 'checkbox',
            name: 'not_registered',
            label: 'People who need to create an account',
            validationType: 'boolean',
            validations: null,
            grid: { pt: '0 !important' }
          },
          {
            type: 'checkbox',
            name: 'not_confirmed',
            label: 'People who need to confirm their participation',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 12, pt: '4px !important' }

          }
        ] : [
          {
            type: 'checkbox',
            name: 'selected',
            label: 'Only selected people',
            validationType: 'boolean',
            validations: null,
            grid: { pt: '0 !important' }
          },
          {
            type: 'checkbox',
            name: 'everyone',
            label: 'Every participant in the list',
            validationType: 'boolean',
            validations: null,
            grid: { pt: '0 !important' }
          }
        ]
      }
    ]
  }
} 

export default getSchema