import { LOCATION_ACTIVITY_TYPES, NOTIFICATION_ACTIVITY_TYPES } from 'utils/events'

const getHoursFieldOptions = () => {
  const hours = []
  const steps = [0, 15, 30, 45]
  for (let i = 0; i <= 23; i++) {
    const hour = i < 10 ? `0${i}` : i
    for (let j = 0; j < steps.length; j++) {
      if (i === 0 && j === 0) {
        hours.push({ label: '00:00', value: '00:01' })
      } else {
        const step = steps[j] < 10 ? `0${steps[j]}` : steps[j]
        hours.push({ label: `${hour}:${step}`, value: `${hour}:${step}` })
      }
    }
  }
  hours.push({ label: '00:00', value: '23:59' })
  return hours
}

const getActivitySchema = ({ id, title, participants }, activityType, allParticipants) => {
  let formTitle = ''
  if (!LOCATION_ACTIVITY_TYPES.includes(activityType.name)) {
    const activityTypeLabel = activityType ? ` (${activityType.label})` : ''
    formTitle = !id ? `New activity ${activityTypeLabel}` : `${title} ${activityTypeLabel}`
  }

  const showNotificationFields = NOTIFICATION_ACTIVITY_TYPES.includes(activityType?.name)
  const showParticipantsFields =
    allParticipants && Array.isArray(allParticipants) && !!allParticipants.length

  return {
    name: 'activity-form',
    title: formTitle,
    watchedFields: ['participants'],
    fieldsets: [
      {
        fields: [
          {
            type: 'select',
            name: 'start_hour',
            label: 'Start time',
            options: getHoursFieldOptions(),
            grid: { xs: 3 }
          },
          {
            type: 'select',
            name: 'end_hour',
            label: 'End time',
            options: getHoursFieldOptions(),
            grid: { xs: 3, mr: 6 }
          },
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 8, mr: 6 }
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            grid: { xs: 8 },
            validationType: 'string',
            validations: [
              {
                type: 'max',
                params: [1000, 'Description is too long. Keep it under 1000 characters']
              }
            ],
            maxLength: 1000,
          },
          ...(activityType?.name === 'TRANSFER'
            ? [
                {
                  type: 'text',
                  label: 'Meeting point name',
                  name: 'meeting_point_name',
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 8 }
                },
                {
                  type: 'address',
                  name: 'meeting_point_address',
                  label: 'Meeting point',
                  latitudeProp: 'meeting_point_latitude',
                  longitudeProp: 'meeting_point_longitude',
                  validationType: 'string',
                  grid: { xs: 8 }
                }
              ]
            : []),
          ...(!LOCATION_ACTIVITY_TYPES.includes(activityType?.name)
            ? [
                {
                  type: 'image',
                  multiple: false,
                  name: 'custom_image',
                  returnedKey: 'custom_image_formats',
                  label: 'Image',
                  grid: { xs: 8 },
                  validationType: 'string',
                  validations: [
                    {
                      type: 'fileSize',
                      params: ['File too large', '5242880']
                    },
                    {
                      type: 'fileFormat',
                      params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/png']]
                    },
                    {
                      type: 'nullable'
                    }
                  ],
                  maxSize: 5242880,
                  accept: '.jpg, .jpeg, .png'
                }
              ]
            : [])
        ]
      },
      ...(showParticipantsFields
        ? [
            {
              title: 'Participants',
              subtitle: 'Who will participate in this activity?',
              fields: [
                {
                  type: 'select',
                  name: 'participants',
                  label: 'Participants',
                  multiple: true,
                  options: [
                    {
                      label: 'Everybody',
                      value: 'all'
                    },
                    ...allParticipants?.map((participant) => ({
                      label: `${participant.first_name} ${participant.last_name}`,
                      value: participant.id,
                      // disabled: participants?.includes(participant.id)
                    }))
                  ],
                  grid: { xs: 8 }
                }
              ]
            }
          ]
        : []),
      ...(showNotificationFields
        ? [
            {
              title: 'Notification',
              subtitle:
                'Configure the notification that will be sent to the participants. They will be sent 15 minutes before each activity',
              fields: [
                {
                  type: 'text',
                  name: 'custom_notification_title',
                  label: 'Notification custom title',
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 8 }
                },
                {
                  type: 'text',
                  name: 'custom_notification_body',
                  label: 'Notification custom message',
                  validationType: 'string',
                  validations: null,
                  grid: { xs: 8 }
                }
              ]
            }
          ]
        : [])
    ]
  }
}

const getTeamSchema = (participants) => {
  return {
    id: 'team-form',
    name: 'team-form',
    fieldsets: [
      {
        fields: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Meeting point name',
            name: 'meeting_point_name',
            validationType: 'string',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'address',
            name: 'meeting_point',
            label: 'Meeting point',
            latitudeProp: 'meeting_point_lat',
            longitudeProp: 'meeting_point_lng',
            validationType: 'string',
            grid: { xs: 6 }
          },
          {
            type: 'select',
            name: 'participants',
            label: 'Team members',
            labelProp: 'name',
            valueProp: 'id',
            multiple: true,
            options: participants && participants.length ? participants : []
          }
        ]
      }
    ]
  }
}

export { getTeamSchema }
export default getActivitySchema
