const schema = {
  name: 'participant-status-form',
  id: 'participant-status-form',
  fieldsets: [
    {
      id: 'confirmation',
      fields: [
        {
          type: 'radiogroup',
          name: 'confirmation',
          label: 'Change the status of the selected participants to',
          options: [
            {
              label: 'Confirmed',
              value: 'confirmed'
            },
            {
              label: 'Declined',
              value: 'declined'
            }
          ]
        }
      ]
    }
  ]
}

export default schema