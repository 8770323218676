import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography/index'
import IconButton from '@mui/material/IconButton'
import Image from 'components/Image/Image'

import { getMainImage } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const errorImageStyles = {
  maxWidth: '150px'
}

const ActivityPickerCard = ({
  entity = 'coworking',
  location,
  selected,
  illustrations,
  onCardClick
}) => {
  
  const mainImage = location.featured_image
  const image = mainImage || illustrations[entity]

  const handleCardClick = () => {
    onCardClick(location)
  }

  const needZero = (value) => (Number(value) < 10 ? `0${value}` : value)

  const {
    capacity,
    categories,
    dietary_options,
    duration,
    max_participants,
    min_participants,
    download_speed,
    upload_speed,
    features
  } = location
  let infos = []

  const locationInfos = {
    experience: () => {
      if (duration) {
        const minutes = location.duration % 60
        const hours = Math.floor(location.duration / 60)
        infos.push({
          icon: 'av_timer',
          label: `${needZero(hours)}h${needZero(minutes)}`
        })
      }
      if (min_participants || max_participants) {
        infos.push({
          icon: 'groups',
          label: `${min_participants || 0} ${
            max_participants ? `- ${max_participants}` : ''
          }`
        })
      }
      if (categories && !!categories.length) {
        infos.push({
          icon: 'local_activity',
          label: categories.map((cat) => cat.name).join(', ')
        })
      }
      return infos
    },
    workshop: () => {
      if (duration) {
        const minutes = location.duration % 60
        const hours = Math.floor(location.duration / 60)
        infos.push({
          icon: 'av_timer',
          label: `${needZero(hours)}h${needZero(minutes)}`
        })
      }
      if (min_participants || max_participants) {
        infos.push({
          icon: 'groups',
          label: `${min_participants || 0} ${
            max_participants ? `- ${max_participants}` : ''
          }`
        })
      }
      if (categories && !!categories.length) {
        infos.push({
          icon: 'local_activity',
          label: categories.map((cat) => cat.name).join(', ')
        })
      }
      return infos
    },
    coworking: () => {
      if (categories && !!categories.length) {
        infos.push({
          icon: 'loyalty',
          label: categories.map((cat) => cat.name).join(', ')
        })
      }
      if (capacity) {
        infos.push({
          icon: 'group',
          label: capacity
        })
      }
      if (features && !!features.length) {
        infos.push({
          icon: 'checklist',
          label: features.map((feature) => feature.name).join(', ')
        })
      }
      if (download_speed || upload_speed) {
        infos.push({
          icon: 'network_check',
          label: `${download_speed ? `Down ${download_speed}Mbps` : ''} ${
            upload_speed ? `- Up ${upload_speed}Mbps` : ''
          }`
        })
      }
      return infos
    },
    plenary_hall: () => {
      if (capacity) {
        infos.push({
          icon: 'group',
          label: capacity
        })
      }
      return infos
    },
    catering: () => {
      if (categories && !!categories.length) {
        infos.push({
          icon: 'local_dining',
          label: categories.map((category) => category.name).join(', ')
        })
      }
      if (dietary_options && !!dietary_options.length) {
        infos.push({
          icon: 'no_food',
          label: dietary_options.map((category) => category.name).join(', ')
        })
      }
      return infos
    }
  }

  const Info = ({ label, icon }) => {
    return (
      <MDTypography
        variant="button"
        display="flex"
        alignItems="center"
        color="text">
        <Icon sx={{ mr: 1 }}>{icon}</Icon>
        {label}
      </MDTypography>
    )
  }

  return (
    <Card
      position="relative"
      className={
        selected ? 'activity-picker-card selected' : 'activity-picker-card'
      }
      sx={{
        minHeight: '200px',
        height: '100%',
        '&:hover, &.selected': {
          '.activity-picker-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <MDBox className="activity-picker-card__icon" sx={iconStyles}>
        <Icon fontSize="small">check</Icon>
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        p={2}
        sx={{ cursor: 'pointer', height: '100%' }}
        onClick={handleCardClick}>
        <Image
          {...image}
          alt={location.name}
          height={120}
          width="100%"
          minHeight={120}
          maxHeight={120}
          mb={2}
          sx={
            mainImage
              ? { objectFit: 'cover !important' }
              : errorImageStyles
          }
          errorImage={illustrations[entity]}
        />
        <MDTypography variant="body" sx={{ minHeight: '52px', WebkitLineClamp: 2, overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis' }}>{location.name}</MDTypography>
        <MDTypography variant="caption" sx={{ minHeight: '44px', WebkitLineClamp: 3, overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: location.excerpt }} />
        <MDBox mt={1}>
          {locationInfos[entity]().map((info, index) => {
            return <Info key={index} {...info} />
          })}
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          height="100%"
          flexGrow={1}>
          <Link
            to={`/dashboard/locations/${entity}s/${location.id}`}
            target="_blank">
            <IconButton sx={{ p: 0, maxHeight: '24px' }}>
              <Icon>open_in_new</Icon>
            </IconButton>
          </Link>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ActivityPickerCard
