import { Suspense, useState, useEffect } from 'react'

import { Await, Link, useLoaderData, useActionData, useSubmit, useSearchParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import Pagination from 'components/Pagination'
import { CardsSkeleton, FiltersButtonSkeleton } from './Skeletons'
import AgendaCard from './AgendaCard'
import AgendasTableView from './AgendasTableView'

import {
  convertQueryStringToFilters,
  getActiveFiltersNumber
} from 'utils/filters'
import FiltersDialog from 'components/Dialogs/FiltersDialog'
import { FilterButton } from 'components/Filters/Filters'

import noResults from 'assets/illustrations/noresults.svg'

const AgendasListPage = () => {
  const { results, initialFilters } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const actionData = useActionData()
  const [view, setView] = useState('grid')
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [cloneConfirmationOpen, setCloneConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [markedForClone, setMarkedForClone] = useState(null)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  const openFiltersDialog = () => setFiltersDialogOpen(true)
  const closeFiltersDialog = () => setFiltersDialogOpen(false)

  const handleConfirmDeleteAgenda = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'delete', encType: 'application/json' }
    )
  }

  const handleCloneAgenda = (agenda) => {
    setMarkedForClone(agenda)
    setCloneConfirmationOpen(true)
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleDeleteAgenda = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }

  const handleApplyFilters = (activeFilters) => {
    closeFiltersDialog()
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  const handleConfirmCloneAgenda = () => {
    const { id, title } = markedForClone
    if (id) {
      submit(
        { intent: 'cloneAgenda', entity: 'agenda', id, title  },
        { method: 'post', encType: 'application/json' }
      )
    }
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Agenda deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (searchParams.get('copied')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'copied') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Agenda template copied successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  useEffect(() => {
    if (!!actionData) {
      if (actionData.success) {
        dispatch(notify({ type: "success", title: 'Well done!', message: "Reset password email sent!", icon: 'done' }))
      }
      if (actionData.error) {
        dispatch(notify({ type: "error", title: 'Oops!', message: actionData.error, icon: 'error' }))
      }
    }
  }, [actionData, dispatch])

  useEffect(() => {
    if (!!initialFilters) {
      const applied = convertQueryStringToFilters(searchParams, initialFilters)
      const activeFiltersNumber = getActiveFiltersNumber(applied, initialFilters)
      setAppliedFilters(applied)
      setActiveFiltersNumber(activeFiltersNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, initialFilters])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete this agenda?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleConfirmDeleteAgenda}
        onCancel={handleCancelConfirmation}
      />
      <ConfirmationDialog
        title={`Are you sure you want to copy this offer?`}
        message="You will copy this offer as is and will have to modify every detail after copying."
        open={cloneConfirmationOpen}
        setOpen={setCloneConfirmationOpen}
        onConfirm={handleConfirmCloneAgenda}
        onCancel={handleCancelConfirmation}
      />
      <Suspense>
        <Await resolve={initialFilters}>
          {(initialFilters) => (
            <FiltersDialog
              open={filtersDialogOpen}
              initialFilters={initialFilters}
              appliedFilters={appliedFilters}
              searchParams={searchParams}
              onApply={handleApplyFilters}
              onClose={closeFiltersDialog}
            />
          )}
        </Await>
      </Suspense>
      <MDBox sx={{ margin: 'auto' }}>
        <Grid mt={6} container justifyContent="space-between">
        <Suspense fallback={<FiltersButtonSkeleton />}>
            <Await resolve={initialFilters}>
              {() => initialFilters ? (
                <FilterButton activeFiltersNumber={activeFiltersNumber}>
                  <MDButton
                    color="white"
                    onClick={openFiltersDialog}
                    sx={{
                      border: !!activeFiltersNumber
                        ? '2px solid grey'
                        : '2px solid transparent'
                    }}>
                    Filters &nbsp;<Icon>tunesharp</Icon>
                  </MDButton>
                </FilterButton>
              ) : <div></div> }
            </Await>
          </Suspense>

          <MDBox>
            <Link to={`/dashboard/agendas/new`}>
              <MDButton color="primary">
                <Icon>add</Icon>&nbsp; Create an agenda
              </MDButton>
            </Link>
            <ButtonGroup sx={{ ml: 2 }}>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'grid' ? 'primary' : 'secondary'}
                onClick={() => setView('grid')}>
                <Icon>grid_view</Icon>
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'table' ? 'primary' : 'secondary'}
                onClick={() => setView('table')}>
                <Icon>view_list</Icon>
              </MDButton>
            </ButtonGroup>
          </MDBox>
        </Grid>
        <Suspense fallback={<CardsSkeleton />}>
          <Await resolve={results}>
            {({ results, page_number, count }) => {
              const pagination = {
                page: searchParams.get('page') || 1,
                lastPage: page_number || Math.ceil(count / 24) || 1,
                count,
                pageSize: parseInt(searchParams.get('page_size')) || 24
              }
              const agendas = results && results.length ? results : []

              return view === 'grid' ? (
                <>
                  <Grid
                    container
                    display="grid"
                    gridTemplateColumns={agendas.length ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'}
                    alignItems="start"
                    spacing={2}
                    sx={{ height: '100%', mt: 2 }}>
                    {agendas.length ? (
                      agendas.map((result) => {
                        return (
                          <Grid key={result.id} item m={0} height="100%">
                            <AgendaCard
                              {...result}
                              onCloneAgenda={handleCloneAgenda}
                              onDeleteAgenda={handleDeleteAgenda}
                            />
                          </Grid>
                        )
                      })
                    ) : (
                      <Grid item xs={12}>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="50vh">
                          <MDBox
                            component="img"
                            src={noResults}
                            alt="no results"
                            width="100%"
                            maxHeight={200}
                            mb={3}
                          />
                          <MDTypography variant="h5">No results found!</MDTypography>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                  {pagination && pagination.lastPage > 1 && !!results.length ? (
                    <Grid container justifyContent="center" marginTop={6}>
                      <Pagination
                        page={pagination.page || 1}
                        lastPage={pagination.lastPage}
                        searchParams={searchParams}
                      />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <MDBox mt={2}>
                  <AgendasTableView
                    agendas={agendas}
                    onDelete={handleDeleteAgenda}
                    pagination={pagination}
                  />
                </MDBox>
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
    </>
  )
}

export default AgendasListPage
