import MDBox from 'components/MDBox'
import Icon from '@mui/material/Icon'

import { getImageUrl } from 'hooks/useBunnyStorage'

const ImageComponent = ({
  image,
  editable,
  selectable,
  selected,
  onEdit,
  onSelect,
  onDelete
}) => {

  const iconStyles = {
    transitionProperty: 'opacity, transform',
    transitionDuration: '0.3s',
    opacity: 0,
    transform: 'scale(0.8)',
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: '10',
    color: 'white !important',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    backgroundColor: 'primary.main',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const handleOnSelect = () => {
    onSelect(image)
  }

  return (
    <MDBox
      position="relative"
      className={selected ? 'selected' : ''}
      sx={{
        height: '100%',
        minHeight: '180px',
        '&:hover, &.selected': {
          '.image-component__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      {editable ? (
        <MDBox display="flex">
          <MDBox
            className="image-component__icon"
            sx={{...iconStyles, right: '42px'}}
            onClick={onEdit}>
            <Icon fontSize="small">edit</Icon>
          </MDBox>
          <MDBox
            className="image-component__icon"
            sx={iconStyles}
            onClick={onDelete}>
            <Icon fontSize="small">delete</Icon>
          </MDBox>
        </MDBox>
      ) : null}
      {selectable ? (
        <MDBox
          className="image-component__icon"
          sx={iconStyles}
          onClick={handleOnSelect}>
          <Icon fontSize="small">check</Icon>
        </MDBox>
      ) : null}
      <img
        src={image ? typeof image === 'object' ? getImageUrl(image) : image : null}
        loading="lazy"
        style={{
          objectFit: 'cover',
          borderRadius: '16px',
          border: 0,
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          opacity: editable || selected ? 1 : 0.6
        }}
        />
    </MDBox>
  )
}

export default ImageComponent
