import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

export const BunnyGallerySkeleton = () => {
  return (
    <Grid
      container
      alignItems="start"
      spacing={6}
      sx={{ height: '100%', mt: 2 }}>
      {Array.from(new Array(2)).map((_, index) => (
        <Grid key={`cards-skeleton-${index}`} item xs={5} md={3} lg={2}>
          <Skeleton animation="wave" variant="rounded" height={370} />
        </Grid>
      ))}
    </Grid>
  )
}

export const AccommodationsSkeleton = () => {
  return (
    <MDBox display="flex" flexDirection="column" gap={2}>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={`cards-skeleton-${index}`} animation="wave" variant="rounded" height={130} />
      ))}
    </MDBox>
  )
}

export const CardsSkeleton = () => {
  return (
    <Grid container rowSpacing={3} columnSpacing={3} pb={8}>
      {Array.from(new Array(6)).map((_, index) => (
        <Grid key={`card-skeleton-${index}`} item xs={12} sm={6} md={3}>
          <Skeleton animation="wave" variant="rounded" height={205} />
        </Grid>
      ))}
    </Grid>
  )
}

export const ItinerarySkeleton = () => {
  return (
    <MDBox mx={2}>
      <Skeleton animation="wave" variant="rounded" height={33} width={162} style={{ marginBottom: 12 }}/>
      <MDBox display="flex" justifyContent="space-between" gap={4}>
        <Skeleton animation="wave" variant="rounded" height={300} width={405} />
        <MDBox mb={2}>
          <Skeleton animation="wave" variant="rounded" height={26} width={194} style={{ marginBottom: 12 }} />
          <MDBox display="flex" alignItems="center" gap={1} mb={1}>
            <Skeleton animation="wave" variant="rounded" width={24} height={24} />
            <Skeleton animation="wave" variant="rounded" width={360} height={24} />
          </MDBox>
          <MDBox display="flex" alignItems="center" gap={1} mb={1}>
            <Skeleton animation="wave" variant="rounded" width={24} height={24} />
            <Skeleton animation="wave" variant="rounded" width={260} height={24} />
          </MDBox>
          <MDBox display="flex" alignItems="center" gap={1} mb={1}>
            <Skeleton animation="wave" variant="rounded" width={24} height={24} />
            <Skeleton animation="wave" variant="rounded" width={50} height={24} />
          </MDBox>
          <MDBox display="flex" alignItems="center" gap={1}>
            <Skeleton animation="wave" variant="rounded" width={24} height={24} />
            <Skeleton animation="wave" variant="rounded" width={100} height={24} />
          </MDBox>

          <MDBox mt={4} mb={2}>
            <Skeleton animation="wave" variant="rounded" height={26} width={80} style={{ marginBottom: 8 }} />
            <Skeleton animation="wave" variant="rounded" width={360} height={24} />
          </MDBox>
          <MDBox mb={2}>
            <Skeleton animation="wave" variant="rounded" height={26} width={80} style={{ marginBottom: 8 }} />
            <Skeleton animation="wave" variant="rounded" width={140} height={24} />
          </MDBox>
        </MDBox>
        
      </MDBox>
    </MDBox>
  )
}
