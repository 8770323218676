import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'

const AddCustomActivityCard = ({ onClick }) => {
  return (
    <MDBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 200,
        height: 220,
        border: '1px dashed grey',
        borderRadius: 4,
        cursor: 'pointer'
      }}
      onClick={onClick}>
      <MDBox
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <MDTypography variant="body2">Add a custom activity</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            group_outlined
          </Icon>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default AddCustomActivityCard