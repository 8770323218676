import { getTimezoneOffset } from "date-fns-tz"

export const needZero = (value) => (Number(value) < 10 ? `0${value}` : value)

/**
 * Returns the number of minutes between two timestamps.
 *
 * @param {number} dateInitial - The initial timestamp
 * @param {number} dateFinal - The final timestamp
 * @return {number} The difference in minutes between the timestamps
 */
export const getMinutesDiffBetweenDates = (dateInitial, dateFinal) => {
  const diffInMinutes = (dateFinal - dateInitial) / (1000 * 60)
  return diffInMinutes
}

/**
 * Returns the number of hours between two timestamps
 * @param {number} dateInitial 
 * @param {number} dateFinal 
 * @returns number
 */
export const getHoursDiffBetweenDates = (dateInitial, dateFinal) => {
  return (dateFinal - dateInitial) / (1000 * 3600)
}
export const getDaysDiffBetweenDates = (dateInitial, dateFinal) => {
  return (dateFinal - dateInitial) / (1000 * 3600 * 24)
}

/**
 * Converts minutes to a readable time format.
 *
 * @param {number} minutes - The number of minutes to convert.
 * @param {string} [separator=':'] - The separator to use between hours and minutes. Default is ':'.
 * @return {string} The readable time format, e.g. "01:30" for 90 minutes.
 */
export const fromMinutesToReadableTime = (minutes, separator = ':') => {
  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60
  return `${needZero(hours)}${separator}${needZero(minutesLeft)}`
}

/**
 * Returns a new Date object representing the input date in the local timezone.
 *
 * @param {Date} date - The input date to be converted.
 * @return {Date} The input date converted to the local timezone.
 */
export const getLocalizedValue = (date) => {
  const tzOffsetMilliseconds = getTimezoneOffset('Europe/Rome', new Date())
  const localOffsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000
  const localized = new Date(date.getTime() + tzOffsetMilliseconds + localOffsetMilliseconds)
  return localized
}

export const getUTCValue = (date) => {
  const tzOffsetMilliseconds = getTimezoneOffset('Europe/Rome', new Date())
  const localOffsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000
  const utc = new Date(date.getTime() - tzOffsetMilliseconds - localOffsetMilliseconds)
  return utc
}