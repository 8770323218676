import { useState, useEffect } from 'react'
import { useLoaderData, useSubmit, useActionData, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import PageForm from './components/PageForm'

import { cleanSectionValues } from 'utils/pages'
// MUI Components
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PageSection from './components/PageSection'

import { PAGE_TEMPLATES, PAGE_TEMPLATES_NAMES } from 'utils/pages'

const PagesDetailPage = ({ editingMode }) => {
  const [openAccordion, setOpenAccordion] = useState(0)
  const { page } = useLoaderData()
  const submit = useSubmit()
  const actionData = useActionData()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()


  const handleSectionSave = ({ sectionValues }) => {
    const isExisting = !!sectionValues.id
    submit(
      { subentity: 'page', entity: 'sections', pageId: page.id, ...sectionValues },
      { method: isExisting ? 'patch' : 'post', encType: 'application/json' }
    )
  }

  const handleSectionDelete = (section) => {
    submit(
      { subentity: 'page', entity: 'sections', pageId: page.id, id: section.id },
      { method: 'delete', encType: 'application/json' }
    )
  }

  const handleMediaSave = () => {
    submit({ onlyImages: true }, { method: 'post', encType: 'application/json' })
  }

  const handleMediaDelete = (section, media) => {
    if (section.id && media.id) {
      submit({ subentity: 'page', entity: 'sections', pageId: page.id, id: section.id, action: `media/${media.id}` }, { method: 'delete', encType: 'application/json' })
    }
  }

  const handlePageSave = (pageValues) => {
    const method = editingMode ? 'patch' : 'post'
    submit({ entity: 'page', ...pageValues }, { method, encType: 'application/json' })
  }

  const handleSaveEntity = (section, entity, isRepeaterItem = false) => {
    const isExistingSection = section && !!section.id
    const _section = cleanSectionValues({ section, isRepeaterItem })
    submit(
      {
        subentity: 'page',
        entity: 'sections',
        pageId: page.id,
        ..._section,
        api_entity: entity
      },
      { method: isExistingSection ? 'patch' : 'post', encType: 'application/json' }
    )
  }

  const handleDeleteEntity = (section, entity, isRepeaterItem) => {
    const _entity = {
      ...section.api_entity,
      selected: section.api_entity.selected.filter((id) => id !== entity.id)
    }
    handleSaveEntity(section, _entity, isRepeaterItem)
  }

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if ((searchParams && searchParams.get('new')) || actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Page saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch])

  useEffect(() => {
    if (searchParams.get('new')) {
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])


  return (
    <Grid
      container
      spacing={2}
      display="flex"
      width="100%"
      justifyContent={editingMode ? 'flex-start' : 'center'}>
      <Grid item xs={12} xl={4}>
        <PageForm page={page} onSave={handlePageSave} />
      </Grid>
      {editingMode ? (
        <Grid item xs={12} xl={8}>
          {PAGE_TEMPLATES_NAMES.includes(page.template) ? PAGE_TEMPLATES[page.template].map((sectionTemplate, i) => {
            const { title, type, index } = sectionTemplate
            let section = index ? page.sections.filter((s) => s.type === type)[index] : page.sections.find((s) => s.type === type)
            if (section && section.items && section.items.length) {
              section = {
                ...section,
                items: [...section.items].map((sectionId) => {
                  return page.sections.find((s) => s.id === sectionId)
                })
              }
            }
            return (
              <Accordion
                key={`accordion-${i}`}
                expanded={openAccordion === i}
                onChange={() => setOpenAccordion(openAccordion === i ? null : i)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MDBox>
                    <MDTypography variant="h6">{title}</MDTypography>
                  </MDBox>
                </AccordionSummary>
                <AccordionDetails>
                  <PageSection
                    page={page}
                    type={type}
                    sectionValues={section}
                    sectionTemplate={sectionTemplate}
                    onSectionDelete={handleSectionDelete}
                    onSaveEntity={handleSaveEntity}
                    onDeleteEntity={handleDeleteEntity}
                    onMediaSave={handleMediaSave}
                    onMediaDelete={handleMediaDelete}
                    onDeleteRepeaterSection={handleSectionDelete}
                    onSaveRepeaterSection={handleSectionSave}
                  />
                </AccordionDetails>
              </Accordion>
            )
          }) : null }
        </Grid>
      ) : null}
    </Grid>
  )
}

export default PagesDetailPage
