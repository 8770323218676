import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'

const PlaceCard = ({
  name,
  photo,
  business_status, // OPERATIONAL, CLOSED_TEMPORARILY, and CLOSED_PERMANENTLY
  current_opening_hours,
  opening_hours,
  rating,
  website,
  international_phone_number,
  user_ratings_total
}) => {
  const open_now = current_opening_hours?.open_now
  const weekday_text = current_opening_hours?.weekday_text
  const today = new Date().getDay()
  const todayIndex = today === 0 ? 6 : today - 1

  const infos = [
    ...(website ? [{ prop: website, icon: 'language', type: 'link' }] : []),
    ...(international_phone_number ? [{ prop: international_phone_number, icon: 'phone', type: 'phone' }] : []),
    ...(rating ? [{ prop: rating, icon: 'star' }] : []),
  ]

  const statuses = {
    OPERATIONAL: 'Operational',
    CLOSED_TEMPORARILY: 'Closed temporarily',
    CLOSED_PERMANENTLY: 'Closed permanently'
  }

  const renderInfos = () => {
    return infos.map((info, index) => {
      const { prop, icon, type } = info

      return (
        <MDBox key={index} display="flex" alignItems="center" pb={1}>
          <Icon size="small">{icon}</Icon>
          <MDTypography variant="button" ml={1}>
            {type === 'phone' ? (
              <a href={`tel:${prop}`} rel="noreferrer" target="_blank">
                {prop}
              </a>
            ) : type === 'link' ? (
              <a href={prop} rel="noreferrer" target="_blank">
                {prop}
              </a>
            ) : (
              prop
            )}
          </MDTypography>
        </MDBox>
      )
    })
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'row' }}>
      <MDBox maxWidth="40%">
        <CardMedia
          component="img"
          sx={{
            height: '100% !important',
            m: 0,
            borderRadius: 0,
            width: '100% !important',
            objectFit: 'cover'
          }}
          image={photo}
          alt={name}
        />
      </MDBox>
      <CardContent sx={{ pb: '8px !important' }}>
        <MDBox p={2}>
          <MDTypography
            gutterBottom
            variant="body2"
            fontWeight="bold"
            component="div">
            {name ? `${name} - ` : ''} {statuses[business_status]}
          </MDTypography>
          <MDBox>{renderInfos()}</MDBox>
          <MDTypography variant="body2" mt={1}>
            {open_now ? (
              <>
                <span style={{ fontWeight: 'bold' }}>Open now</span>
                <span> - Closes at</span>
              </>
            ) : (
              'Closed'
            )}
          </MDTypography>
          <MDBox mt={2}>
            {weekday_text
              ? weekday_text.map((day, index) => {
                  return (
                    <MDTypography
                      key={index}
                      display="block"
                      fontWeight={todayIndex === index ? 'bold' : 400}
                      pb={1}
                      variant="button">
                      {day}
                    </MDTypography>
                  )
                })
              : null}
          </MDBox>
        </MDBox>
      </CardContent>
    </Card>
  )
}

const PlaceInfos = ({ placeDetails }) => {
  return (
    <MDBox>
      <MDBox mt={2}>
        <PlaceCard {...placeDetails?.result} />
      </MDBox>
    </MDBox>
  )
}

export default PlaceInfos
