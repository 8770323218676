import { COUNTRIES, ITALY_REGIONS } from 'utils/maps'

const filters = {
  name: 'all-in-venues-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the venue you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Location',
      subtitle: 'Filter by region or country',
      fields: [
        {
          type: 'select',
          name: 'region',
          grid: { xs: 6 },
          options: ITALY_REGIONS
        },
        {
          type: 'select',
          name: 'country',
          grid: { xs: 6 },
          options: COUNTRIES
        },
      ]
    },
    {
      title: 'Categories',
      subtitle: 'Select the categories of the accommodations you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                entity: 'locationCategories',
                id: 'all_in_venues'
              }
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    // {
    //   title: 'Price',
    //   subtitle: 'Select the price range you\'re looking for',
    //   fields: [
    //     {
    //       type: 'range',
    //       name: 'price',
    //       min: 0,
    //       max: 300,
    //       prefix: '€',
    //       initialValue: [50, 150],
    //       withInputs: true,
    //       grid: { xs: 12, display: 'flex', justifyContent: 'center' },
    //       boxStyles: {
    //         width: '100%',
    //         maxWidth: '600px'
    //       }
    //     },
    //     {
    //       type: 'divider',
    //       hr: true,
    //     }
    //   ]
    // },
    // {
    //   title: 'Published or not',
    //   subtitle: 'Select whether the accommodation is published or not',
    //   fields: [
    //     {
    //       type: 'switch',
    //       name: 'published'
    //     }
    //   ]
    // }
  ]
}

export default filters