import { useState } from 'react'

import MDBox from 'components/MDBox'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

import Form from "components/Form"

import { getCustomQuestionSchema } from "components/Dialogs/schemas/participants-settings"

const CustomQuestion = ({ index, question, onChange, onDelete }) => {
  const getRootName = () => `custom_question_${index}`
  const getInitialValues = () => {
    const rootName = getRootName()
    return {
      [`${rootName}_title`]: question?.title || `Custom question ${index + 1}`,
      [`${rootName}_description`]: question?.description || `Custom question description ${index + 1}`,
      [`${rootName}_field_type`]: question?.type.toUpperCase() || 'TEXT',
      [`${rootName}_required`]: question?.required || false,
      [`${rootName}_label`]: question?.label || "User answer",
      [`${rootName}_options`]: question?.options && question?.options.length ? question.options.map(({ value }) => value).join('|') : null,
    }
  }
  const getSubsequentValues = (values) => {
    const rootName = getRootName()
    return {
      ...values,
      ...( values[`${rootName}_field_type`] === 'SELECT' ? {
        [`${rootName}_options`]: values[`${rootName}_options`]
      } : {} )
    }
  }
  const [initialValues, setInitialValues] = useState(getInitialValues(question))
  const [schema, setSchema] = useState(getCustomQuestionSchema({ index, values: getInitialValues(question) }))

  const handleFieldChange = (event, formValues, formik) => {
    const { value, name, checked } = event.target
    const rootName = getRootName()
    const isFieldType = name.includes('field_type')
    const isRequired = name.includes('required')
    let fieldTypeValues = {}
    if (isFieldType) {
      fieldTypeValues = {
        [rootName]: ''
      }
      formik.setFieldValue(rootName, '')
    }
    const newValues = {
      ...formValues,
      ...fieldTypeValues,
      [name]: isRequired ? checked : value,
    }
    setSchema(
      getCustomQuestionSchema({
        index,
        values: newValues
      })
    )
    setInitialValues(getSubsequentValues(newValues))
    onChange({ [rootName]: newValues })

  }

  const handleOnDelete = () => {
    const rootName = question ? question.field_key : getRootName()
    onDelete(rootName)
  }

  return (
    <MDBox position="relative">
        <IconButton
          aria-label="close"
          onClick={handleOnDelete}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.error.main
          }}>
          <Icon>delete</Icon>
        </IconButton>
      <Form
        {...schema}
        onFieldChange={handleFieldChange}
        values={initialValues}
      />
    </MDBox>
  )
}

export default CustomQuestion