import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

const ske = { animation: 'wave', variant: 'rounded' }

export const FiltersButtonSkeleton = () => {
  return <Skeleton animation="wave" variant="rounded" width={120} height={40} />
}

export const PaginationSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton
          key={`pagination-skeleton-${index}`}
          variant="circular"
          width={33}
          height={33}
          sx={{ mr: 1 }}
        />
      ))}
    </div>
  )
}

export const CardsSkeleton = () => {
  return (
    <Grid
      container
      alignItems="start"
      spacing={6}
      sx={{ height: '100%', mt: 2 }}>
      {Array.from(new Array(6)).map((_, index) => (
        <Grid key={`cards-skeleton-${index}`} item xs={12} md={5} lg={4}>
          <Skeleton animation="wave" variant="rounded" height={370} />
        </Grid>
      ))}
      <Grid container justifyContent="center" marginTop={6}>
        <PaginationSkeleton />
      </Grid>
    </Grid>
  )
}

export const ImagesSkeleton = () => {
  return (
    <Grid item xs={12} lg={4}>
      <Skeleton animation="wave" variant="rounded" height={215} width="100%" />
      <MDBox display="flex" justifyContent="center" mt={3}>
        <MDBox width="40%">
          <Skeleton animation="wave" variant="rounded" height={80} width="100%" />
        </MDBox>
        <MDBox width="40%" ml={2}>
          <Skeleton animation="wave" variant="rounded" height={80} width="100%" />
        </MDBox>
      </MDBox>
    </Grid>
  )
}

export const LocationFormSkeleton = () => {
  return (
    <Card>
      <MDBox p={4}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Skeleton {...ske} width={180} height={40} />
          <MDBox display="flex">
            <Skeleton {...ske} width={100} height={40} sx={{ mr: 2 }} />
            <Skeleton {...ske} width={100} height={40} />
          </MDBox>
        </MDBox>

        <MDBox mt={8} mb={1}>
          <Skeleton {...ske} width="100%" height={40} />
          <Skeleton {...ske} width={400} height={20} sx={{ mt: 1 }}/>
        </MDBox>
        <MDBox mt={3} mb={3}>
          <Skeleton {...ske} width={120} height={20} sx={{ mb: 1 }}/>
          <Skeleton {...ske} width="100%" height={110} />
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mb={3}>
          <Skeleton {...ske} width="48%" height={40} sx={{ mb: 1 }}/>
          <Skeleton {...ske} width="48%" height={40} sx={{ mb: 1 }}/>
        </MDBox>
        <MDBox>
          <Skeleton {...ske} width="100%" height={40} />
          <Skeleton {...ske} width={250} height={20} sx={{ mt: 1 }}/>
        </MDBox>
        <MDBox mt={3} mb={3}>
          <Skeleton {...ske} width={120} height={20} sx={{ mb: 1 }}/>
          <Skeleton {...ske} width="100%" height={200} />
        </MDBox>
        <MDBox>
          <Skeleton {...ske} width="100%" height={40} />
        </MDBox>
      </MDBox>
    </Card>
  )
}

export const LocationDetailSkeleton = () => {
  return (
    <MDBox my={3}>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={120}
              height={40}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3} justifyContent="center">
        <ImagesSkeleton />
        <Grid item xs={12} lg={8}>
          <LocationFormSkeleton />
        </Grid>
      </Grid>
    </MDBox>
  )
}

export const RoomsSkeleton = () => {
  return (
    <Card>
      <MDBox p={4}>
        <Skeleton {...ske} width={200} height={40} sx={{ mr: 2 }} />
      </MDBox>
    </Card>
  )
}
