// Redux
import store from 'store'

// Firebase
// DO NOT REMOVE
// eslint-disable-next-line no-unused-vars
import { auth } from 'services/firebase'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enGB from 'date-fns/locale/en-GB';

// react-router components
import { RouterProvider } from 'react-router-dom'
import { getRouter } from 'router'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

// Custom components
import NotificationAlert from 'components/NotificationAlert'

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme'
import themeDark from 'assets/theme-dark'
import { useMaterialUIController } from 'context'

export default function App() {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <RouterProvider router={getRouter(store)} />
      </LocalizationProvider>
      <div id="notifications">
        <NotificationAlert />
      </div>
    </ThemeProvider>
  )
}
