import { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'

const ConfirmationDialog = ({ title, message, open, children, setOpen, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleOnConfirm = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsLoading(true)
    try {
      await onConfirm()
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
      setOpen(false)
    }
  }

  const handleOnCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel()
    }
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby={title}
      sx={{ marginLeft: { xl: '250px' } }}
      aria-describedby={message}>
      <MDBox sx={{ p: 1 }}>
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          {message ? (
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          ) : null}
          {children}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnCancel}>Go back</MDButton>
          <LoadingButton
            disabled={isLoading}
            loading={isLoading}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white !important',
              '&:hover': {
                backgroundColor: 'error.light'
              }
            }}
            onClick={handleOnConfirm}>
            I confirm
          </LoadingButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  )
}

export default ConfirmationDialog
