import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'
import Table from 'components/Tables/Table'
export const ParticipantsSkeleton = () => {
  const randomWidths = [80, 100, 120, 140, 160, 180, 200]

  const table = {
    columns: [
      {
        header: 'name',
        size: 150,
        Cell: () => {
          return (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={randomWidths[Math.floor(Math.random() * 7)]}
              height={30}
            />
          )
        }
      },
      {
        header: 'contact',
        size: 200,
        Cell: () => {
          return (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={randomWidths[Math.floor(Math.random() * 7)]}
              height={30}
            />
          )
        }
      },
      {
        header: 'needs',
        size: 180,
        Cell: () => {
          return (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={randomWidths[Math.floor(Math.random() * 7)]}
              height={30}
            />
          )
        }
      },
      {
        header: 'transfer',
        size: 260,
        Cell: () => {
          return (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={randomWidths[Math.floor(Math.random() * 7)]}
              height={30}
            />
          )
        }
      },
      {
        header: 'status',
        size: 150,
        Cell: () => {
          return (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={randomWidths[Math.floor(Math.random() * 7)]}
              height={30}
            />
          )
        }
      }
    ],
    rows: new Array(12).fill({})
  }
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={6}
        px={{ xs: 2, md: 3 }}
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 6 }}>
        <MDBox>
          <Skeleton animation="wave" variant="rounded" height={33} width={225}></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={24} width={500} style={{ marginTop: 12 }}></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={24} width={200} style={{ marginTop: 8 }}></Skeleton>
        </MDBox>
        <MDBox display="flex" alignItems="center" gap={1}>
          {Array.from(new Array(3)).map((card, index) => (
            <Skeleton key={`cards-skeleton-${index}`} animation="wave" variant="rounded" height={86} width={88} />
          ))}
        </MDBox>
      </MDBox>
      <MDBox mt={4}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" gap={6} sx={{ marginBottom: '-32px'}}>
          <MDBox display="flex" justifyContent="space-between" gap={1}>
            <Skeleton animation="wave" variant="circular" height={42} width={42}></Skeleton>
            <Skeleton animation="wave" variant="rounded" height={42} width={217}></Skeleton>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" gap={1}>
            <Skeleton animation="wave" variant="rounded" height={42} width={164}></Skeleton>
            <Skeleton animation="wave" variant="circular" height={42} width={42}></Skeleton>
          </MDBox>
        </MDBox>
        <Table
          table={table}
          filtersOptions={{ search: false }}
          options={{
            initialState: {
              showGlobalFilter: false,
              columnPinning: {
                left: ['mrt-row-select', 'name'],
                right: ['status', 'mrt-row-actions']
              }
            }
          }}></Table>
      </MDBox>
      <MDBox display="flex" justifyContent={'flex-end'} gap={1} mt={2} mb={1}>
        <Skeleton animation="wave" variant="rounded" height={46} width={46} />
        <Skeleton animation="wave" variant="rounded" height={46} width={46} />
      </MDBox>
    </>
  )
}
