import MDBox from 'components/MDBox'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import { Link } from 'react-router-dom'

const CompaniesActionsCell = ({ data, onEdit, onDelete }) => {
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Link to={`/dashboard/companies/${data.id}`}>
        <IconButton
          size="small"
          sx={{ border: '1px solid #0000008a', borderRadius: '50%', width: '30px', height: '30px' }}
        >
          <Icon>edit</Icon>
        </IconButton>
      </Link>
      <IconButton
        size="small"
        sx={{ border: '1px solid #0000008a', borderRadius: '50%', width: '30px', height: '30px', mt: '2px' }}
        onClick={() => onDelete(data)}>
        <Icon>delete</Icon>
      </IconButton>
    </MDBox>
  )
}

export default CompaniesActionsCell
