import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'

import initialFilters from 'layouts/pages/companies/list/filters'

export class CompaniesLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    const page = url.searchParams.get('page')
    try {
      const results = await 
        this._loader(
          smartwayApi.endpoints.fetchEntities,
          request,
          { entity: 'company', page_size: 20, ...Object.fromEntries(url.searchParams)}
        )
      return {
        results: results.results,
        pagination: {
          page,
          lastPage: results.page_number
        },
        searchParams: url.searchParams,
        initialFilters
      }
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    if (isNew) {
      return {}
    }
    const url = new URL(request.url)
    const [entityResponse, usersResponse] = await Promise.allSettled([
      this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        { entity: 'company', id: params.id},
      ),
      this._loader(
        smartwayApi.endpoints.fetchEntities,
        request,
        { entity: 'user', company: params.id, ...Object.fromEntries(url.searchParams), page_size: url.searchParams.get('page_size') || 10 }
      )
    ])
    const company = entityResponse.status === 'fulfilled' ? entityResponse.value : {}
    const users = usersResponse.status === 'fulfilled' ? usersResponse.value : {}
    return {
      company,
      users: users ? users.results : []
    }

  }
}