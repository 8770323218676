import { useState } from 'react'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import accommodationIllustration from 'assets/illustrations/accommodation-secondary.svg' 
import { getBestFormat, getMainImage } from 'utils/image'

const AccommodationsSidebarCard = ({ selected, onSelect, ...accommodation }) => {
  const { name, featured_image, images, normal_price, capacity, position } = accommodation
  const mainImage = featured_image || getMainImage(images)
  const image = mainImage ? getBestFormat(mainImage.formats, 'thumbnail') : null
  const [cardImage, setCardImage] = useState(image || accommodationIllustration)
  const _position = position > 0 ? `Ouside (${position / 1000}km)` : 'Center'

  const handleOnSelect = () => {
    onSelect(accommodation)
  }

  return (
    <Card sx={!selected ? { boxShadow: 'none', cursor: 'pointer' } : { boxShadow: '0px 0px 12px 2px rgba(0,0,0,0.2)',  cursor: 'pointer'}} onClick={handleOnSelect}>
      <MDBox p={1} display="flex">
        <MDBox
          component="img"
          src={cardImage}
          borderRadius="md"
          width="25%"
          minWidth="25%"
          onError={() => {
            setCardImage(accommodationIllustration)
          }}
          sx={{
            objectFit: 'cover',
            display: 'inline-block',
            position: 'relative',
            minHeight: '100%',
            '::before': {
              content: "''",
              border: 'none',
              width: '100%',
              height: 'calc(100% + 10px)',
              backgroundColor: 'rgba(230, 230, 230, 1)',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)'
            }
          }}
        />
        <MDBox ml={2} sx={{ overflow: 'hidden' }}>
          <MDTypography variant="h6" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: 'normal' }}>{name}</MDTypography>
          { capacity ? (
            <MDTypography variant="button" display="block" mt={1}>Capacity: {capacity}</MDTypography>
          ) : null }
          { normal_price ? (
            <MDTypography variant="button" display="block">Price: {normal_price}</MDTypography>
          ) : null}
          <MDTypography variant="button" display="block">Position: {_position}</MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default AccommodationsSidebarCard