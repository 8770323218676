import { format } from 'date-fns'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'

const ParticipantStatusCell = ({ participant, onInvite }) => {
  const { user, confirmation, replied_at, last_invited_at } = participant

  const handleInviteParticipant = () => {
    onInvite(participant)
  }

  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      minWidth="140px"
      width="100%">
      {!!replied_at ? (
        <MDTypography
          variant="button"
          fontWeight="medium"
          sx={{ color: confirmation ? '#0B8F18' : 'error.main' }}>
          {confirmation ? 'Confirmed' : 'Declined'}
        </MDTypography>
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ zIndex: 1 }}>
          <MDTypography mb={1} variant="button">
            {!!user ? (
              user.active ? (
                'Registered'
              ) : (
                <Tooltip title="This participant has created an account but has not verified his email">
                  <MDBox display="flex" alignItems="center" justifyContent="center">
                    <Icon sx={{ mr: 1 }}>warning</Icon>Registered
                  </MDBox>
                </Tooltip>
              )
            ) : (
              'Not registered'
            )}
          </MDTypography>
          {last_invited_at ? (
            <MDTypography mb={1} variant="button">
              Invited: {format(new Date(last_invited_at), 'dd/MM/yyyy')}
            </MDTypography>
          ) : null}
          <MDButton
            sx={{ zIndex: 1 }}
            variant="gradient"
            color="primary"
            size="small"
            onClick={handleInviteParticipant}>
            {last_invited_at ? 'Resend' : 'Invite'}
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default ParticipantStatusCell
