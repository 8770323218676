import { useMemo } from 'react'
import { useTable } from 'react-table'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'

import DataTableHeadCell from './DataTableHeadCell'
import DataTableBodyCell from './DataTableBodyCell'
import MDBox from 'components/MDBox'

const InnerRowTable = ({ table }) => {
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })
  return (
    <Table {...getTableProps()}>
      <MDBox component="thead">
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, colIndex) => (
              <DataTableHeadCell
                {...column.getHeaderProps()}
                sorted={null}
                width={column.width}
                align={column.align ? column.align : 'left'}
                key={`sub-column-${colIndex}`}>
                {column.render('Header')}
              </DataTableHeadCell>
            ))}
          </TableRow>
        ))}
      </MDBox>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, key) => {
          prepareRow(row)
          return (
            <>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <DataTableBodyCell
                    align={cell.column.align ? cell.column.align : 'left'}
                    {...cell.getCellProps()}
                    sorted={null}
                    key={`sub-cell-${key}-${cellIndex}`}>
                    {cell.render('Cell')} 
                  </DataTableBodyCell>
                ))}
              </TableRow>
            </>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default InnerRowTable
