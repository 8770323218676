import { useState, useEffect } from 'react'
import Input from '../Input'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const Duration = ({ name, value = 0, label, required, onChange }) => {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [duration, setDuration] = useState(value || 0)

  useEffect(() => {
    if (value !== 0) {
      const minutes = value % 60
      const hours = Math.floor(value / 60)
      setMinutes(minutes)
      setHours(hours)
      setDuration(value)
    } else {
      setHours(0)
      setMinutes(0)
      setDuration(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const hoursAndMinutesToMinutes = hours * 60 + minutes
    if (hoursAndMinutesToMinutes !== duration) {
      setDuration(hoursAndMinutesToMinutes)
      setTimeout(() => {
        onChange({ target: { name, value: hoursAndMinutesToMinutes } })
      })
    }
  }, [duration, hours, minutes, name, onChange])

  const handleChange = (e) => {
    onChange(e)
  }

  const handleHoursChange = (e) => setHours(Number(e.target.value))
  const handleMinutesChange = (e) => setMinutes(Number(e.target.value))

  return (
    <MDBox>
      <MDTypography component="label" variant="button" fontWeight="regular" color="text">
        {label} {required ? '*' : null}
      </MDTypography>
      <MDBox display="flex" width="100%">
        <MDBox mr={1} width="50%">
          <Input
            name="hours"
            value={hours || undefined}
            type="number"
            inputProps={{ max: 23 }}
            suffix="hrs"
            errors={hours && hours > 23 ? 'Hours must be less than 24' : null}
            onChange={handleHoursChange}
          />
        </MDBox>
        <MDBox width="50%">
          <Input
            name="minutes"
            value={minutes || undefined}
            type="number"
            inputProps={{ max: 59 }}
            suffix="mn"
            errors={minutes && minutes > 59 ? 'Minutes must be less than 60' : null}
            onChange={handleMinutesChange}
          />
        </MDBox>
      </MDBox>
      <input type="hidden" name="duration" value={duration} onChange={handleChange} />
    </MDBox>
  )
}

export default Duration
