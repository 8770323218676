const bedsBedroomsOptions = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '5',
    value: 5
  },
  {
    label: '6',
    value: 6
  },
  {
    label: '7',
    value: 7
  },
  {
    label: '8+',
    value: 0
  }
]

const filters = {
  name: 'accommodations-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the accommodation you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Town',
      subtitle: 'Choose the town of the accommodation',
      fields: [
        {
          type: 'select',
          name: 'town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 12 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' }
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Category',
      subtitle: 'Select the category of the accommodations you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                entity: 'locationCategories',
                id: 'accommodation'
              }
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Price',
      subtitle: 'Select the price range you\'re looking for',
      fields: [
        {
          type: 'range',
          name: 'price',
          min: 0,
          max: 300,
          prefix: '€',
          initialValue: [50, 150],
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    }, 
    {
      title: 'Bedrooms, Beds & Bathrooms',
      subtitle: 'Refine your search with a specific number of rooms, beds and bathrooms available in the accommodations',
      fields: [
        {
          type: 'badges',
          name: 'room_qty',
          label: 'Min. bedrooms',
          options: bedsBedroomsOptions,
          initialValue: 'any'
        },
        {
          type: 'badges',
          name: 'capacity',
          label: 'Min. capacity',
          options: bedsBedroomsOptions,
          initialValue: 'any'
        },
        {
          type: 'badges',
          name: 'bathroom_qty',
          label: 'Min. bathrooms',
          options: bedsBedroomsOptions,
          initialValue: 'any'
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Surface',
      subtitle: 'Select the minimum surface you are looking for',
      fields: [
        {
          type: 'badges',
          name: 'min_area',
          initialValue: 'any',
          options: [
            {
              label: 'Any',
              value: 'any'
            },
            {
              label: '50mq',
              value: 50
            },
            {
              label: '70mq',
              value: 70
            },
            {
              label: '100mq',
              value: 100
            },
            {
              label: '150mq',
              value: 150
            }
          ]
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Features',
      subtitle: 'Select the features you are looking for',
      fields: [
        {
          type: 'checkboxgroup',
          name: 'features',
          label: '',
          optionsGetter: {
            endpoints: [
             {
               name: 'feature',
               entity: 'locationFeatures',
               id: 'accommodation',
               formatGroup: (options) => {
                return options.reduce((acc, curr) => {
                  const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                  if (alreadyInAcc) {
                    alreadyInAcc.controls.push({
                      name: curr.id,
                      label: curr.name
                    })
                  } else {
                    acc.push({
                      label: curr.subtype,
                      controls: [{ name: curr.id, label: curr.name }]
                    })
                  }
                  return acc
                }, [])
               }
             }
            ]
          }
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the accommodation is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters