import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'

import {
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'

import Form from 'components/Form'

import { getMediaSchema } from './schema'

const MediaDialog = ({ type, mediaFields, open, onSave, onClose, page, section, parentSection }) => {
  const dispatch = useDispatch()
  const [tempImage, setTempImage] = useState(null)
  const [mediaValues, setMediaValues] = useState(section?.media || {})
  const [mediaSchema, setMediaSchema] = useState(getMediaSchema(section, mediaFields))
  const [isSubmitting, setIsSubmitting] = useState(false)


  const resetState = () => {
    setTempImage(null)
    setMediaValues({})
  }
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      resetState()
      onClose()
    }
  }

  const handleOnSave = async (values) => {
    setIsSubmitting(true)
    try {
      let _section = section
      let createdSection = null
    // IF THERE IS NO SECTION WE CREATE ONE
    if (!_section || !_section.id) {
      const newSection = await dispatch(
        smartwayApi.endpoints.createPageEntity.initiate({
          entity: 'sections',
          subentity: 'page',
          pageId: page.id,
          type,
          ...(type === 'INNER_SECTION' && parentSection ? {
            parent_type: parentSection.type,
            parent_section: parentSection.id,
          } : {})
        })
      )
      if (newSection && newSection.data) {
        _section = newSection.data
        createdSection = newSection.data
      } else {
        throw new Error('Failed to create section')
      }
    }
      const mediaId = _section?.media && _section?.media.id
      const entity = mediaId ? 'updatePageEntity' : 'createPageEntity'
      const formData = new FormData()
      for (const [key, value] of Object.entries(values)) {
        if (key === 'image' && !(value instanceof File)) continue
        formData.append(key, value)
      }
      if ((values.image || tempImage) && !formData.get('type')) {
        formData.append('type', 'IMAGE')
      } 
      if (tempImage) {
        formData.append('image', tempImage)
      }
      if (values.video_id) {
        formData.append('type', 'VIDEO')
      }

      const action = mediaId ? `media/${mediaId}` : `${_section.id}/media`
      const response = await dispatch(
        smartwayApi.endpoints[entity].initiate({
          entity: 'sections',
          action,
          ...(mediaId ? { id: mediaId } : {}),
          pageId: page.id,
          formData,
          bodyType: 'formData'
        })
      )
      if (response && response.data) {
        if (onSave && typeof onSave === 'function') {
          onSave(createdSection)      
        }
      } else {
        throw new Error('Failed to upload media')
      }
    } catch (error) {
      console.warn(error)
      dispatch(notify({
        title: 'Oops!',
        message: 'There was an error uploading the media',
        type: 'error',
        icon: 'error'
      }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    if (name === 'type') {
      const newSchema = getMediaSchema({ ...section, media: { ...section?.media, type: value } }, mediaFields)
      setMediaSchema(newSchema)
      setMediaValues({ ...mediaValues, type: value })
    }
    if (name === 'image' && value) {
      const file = value[0]
      if (file) {
        setTempImage(file)
      }
    }
  }

  useEffect(() => {
    if (open && section && section.media) {
      setMediaValues(section.media)
    }
  }, [section, open])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>Choose a media</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Grid container sx={{ width: '100%' }}>
            <Form
              {...mediaSchema}
              sx={{ width: '100%' }}
              watchedFields={['type', 'image']}
              onFieldChange={handleFieldChange}
              onSubmit={handleOnSave}
              values={mediaValues}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            sx={{
              color: 'white !important',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' }
            }}
            form={`media-form-${section?.id || 'new'}`}
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}>
            Save
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default MediaDialog
