import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Subtitle } from 'components/Cards/LocationCard/LocationCard'

import { getMainImage, getBestFormat } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const PackageCard = ({
  id,
  title,
  published_at,
  images,
  excerpt,
  town,
  all_in_venue,
  agendas,
  onDeletePackage,
  onClonePackage
}) => {
  const fullVenue = town || all_in_venue
  const fullVenueImage = fullVenue.featured_image
    ? fullVenue.featured_image
    : fullVenue.images
    ? getMainImage(fullVenue.images)
    : null
  const mainImage = images && images.length ? getMainImage(images) : fullVenueImage

  const handleDeletePackage = () => {
    onDeletePackage(id)
  }

  const handleClonePackage = () => {
    onClonePackage({ id, title })
  }

  return (
    <Card
      className="package-card"
      sx={{
        position: 'relative',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        height: '100%',
        '&:hover': {
          '.package-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <CardMedia
        component="img"
        src={!!mainImage ? getBestFormat(mainImage.formats, 'mobile') : ''}
        alt={title}
        sx={{
          margin: 0,
          minHeight: {
            xs: '120px',
            md: '100%'
          },
          maxHeight: {
            xs: '160px',
            md: '100%'
          },
          width: {
            xs: '100%',
            md: '40% !important'
          },
          minWidth: {
            xs: '100%',
            md: '40% !important'
          },
          objectFit: 'cover',
          borderRadius: {
            xs: '12px 12px 0 0',
            md: '12px 0 0 12px'
          },
          display: 'inline-block',
          position: 'relative'
        }}
      />
      <MDBox
        mb={1}
        position="absolute"
        left="8px"
        top="8px"
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', px: 1, py: 0.5, borderRadius: '16px' }}>
        <Subtitle publishedAt={published_at} />
      </MDBox>
      <MDBox py={1} width="95%" maxWidth="95%" position="relative">
        
        <MDBox
          className="package-card__icon"
          sx={{
            ...iconStyles,
            right: '84px',
            backgroundColor: 'transparent',
            color: 'primary.main',
            border: '1px solid',
            borderColor: 'primary.main'
          }}>
          <Icon fontSize="small" onClick={handleClonePackage}>
            copy
          </Icon>
        </MDBox>
        <MDBox
          className="package-card__icon"
          sx={{
            ...iconStyles,
            right: '48px'
          }}>
          <Link
            to={`/dashboard/premade-packages/${id}`}>
            <Icon fontSize="small" sx={{ color: 'white !important' }}>
              edit
            </Icon>
          </Link>
        </MDBox>
        <MDBox className="package-card__icon" sx={iconStyles}>
          <Icon fontSize="small" onClick={handleDeletePackage}>
            delete
          </Icon>
        </MDBox>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', pr: 2 }}>
          <MDTypography variant="h4" fontWeight="medium" maxWidth="80%">
            {title}
          </MDTypography>
          <MDTypography
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: excerpt }}
            mb={2}
            maxWidth="80%"
          />
          <MDTypography variant="button" display="flex" alignItems="center" my={1}>
            <Icon sx={{ mr: 1 }}>location_on</Icon> {fullVenue?.name}
          </MDTypography>
          <MDBox
            pt={2}
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            flexGrow={1}
            sx={{ marginBottom: '-16px' }}>
            <Link to={`/dashboard/premade-packages/${id}`}>
              <MDButton variant="gradient" color="secondary" sx={{ mr: 1 }}>
                <Icon sx={{ mr: 1 }}>search</Icon> Package details
              </MDButton>
            </Link>
          </MDBox>
        </CardContent>
      </MDBox>
    </Card>
  )
}

export default PackageCard
