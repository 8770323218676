import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { smartwayApi } from 'services/api'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import { getMainImage, getBestFormat } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const AgendaCard = ({ id, title, excerpt, town, all_in_venue, is_template, onDeleteAgenda, onCloneAgenda }) => {
  const dispatch = useDispatch()
  const [fullVenue, setFullVenue] = useState(null)
  const [fullVenueImage, setFullVenueImage] = useState(null)

  const handleDeleteAgenda = () => {
    onDeleteAgenda(id)
  }

  const handleCloneAgenda = () => {
    onCloneAgenda({id, title, excerpt, town, all_in_venue, is_template})
  }

  useEffect(() => {
    const getVenue = async () => {
      try {
        const venueResponse = await dispatch(
          smartwayApi.endpoints.fetchEntity.initiate({
            entity: all_in_venue ? 'allInVenue' : 'town',
            id: all_in_venue ? all_in_venue : town
          })
        )
        if (venueResponse.status === 'fulfilled' && venueResponse?.data) {
          setFullVenue(venueResponse.data)
          setFullVenueImage(venueResponse.data.featured_image || getMainImage(venueResponse.data.images))
        }
      } catch (error) {
        console.warn(error)
      }
    }

    if (town || all_in_venue) {
      getVenue()
    }
  }, [dispatch, town, all_in_venue])

  return (
    <Card
      className="agenda-card"
      sx={{
        flexDirection: 'row',
        height: '100%',
        '&:hover': {
          '.agenda-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <CardMedia
        component="img"
        src={!!fullVenueImage ? getBestFormat(fullVenueImage.formats, 'mobile') : ''}
        alt={title}
        sx={{
          margin: 0,
          minHeight: '100%',
          width: '33% !important',
          minWidth: '33% !important',
          objectFit: 'cover',
          borderRadius: '12px 0 0 12px',
          display: 'inline-block',
          position: 'relative',
          '::before': {
            content: "''",
            border: 'none',
            width: '100%',
            height: 'calc(100% + 10px)',
            backgroundColor: 'rgba(230, 230, 230, 1)',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)'
          }
        }}
      />
      <MDBox py={1} width="95%" maxWidth="95%" position="relative">
        <MDBox
          className="agenda-card__icon"
          sx={{
            ...iconStyles,
            right: '48px',
            backgroundColor: 'transparent',
            color: 'primary.main',
            border: '1px solid',
            borderColor: 'primary.main'
          }}>
          <Icon fontSize="small" onClick={handleCloneAgenda}>
            copy
          </Icon>
        </MDBox>
        <MDBox className="agenda-card__icon" sx={iconStyles}>
          <Icon fontSize="small" onClick={handleDeleteAgenda}>
            delete
          </Icon>
        </MDBox>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', pr: 2 }}>
          { is_template ? (
            <MDTypography variant="caption" fontWeight="medium">
              Template
            </MDTypography>
          ) : null }
          <MDTypography variant="h4" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="subtitle2" dangerouslySetInnerHTML={{ __html: excerpt }} mb={3} />
          <MDTypography variant="button" display="flex" alignItems="center" mt={1}>
            <Icon sx={{ mr: 1 }}>location_on</Icon> {fullVenue?.name}
          </MDTypography>
          <MDBox
            pt={2}
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            flexGrow={1}
            sx={{ marginBottom: '-16px' }}>
            <Link to={`/dashboard/agendas/${id}`}>
              <MDButton variant="gradient" color="secondary" sx={{ mr: 1 }}>
                <Icon sx={{ mr: 1 }}>search</Icon> Agenda details
              </MDButton>
            </Link>
          </MDBox>
        </CardContent>
      </MDBox>
    </Card>
  )
}

export default AgendaCard
