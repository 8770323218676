// react-router-dom components
import { Link, useMatches } from "react-router-dom";
import { capitalizeFirstLetter } from 'utils/functions';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const Breadcrumbs = ({ icon, light }) => {
  let matches = useMatches()
  let crumbs = matches && matches.length ? matches.map(({ data, handle, pathname }, index) => {
    if (handle && handle.breadcrumb) {
      return {
        label: handle.breadcrumb(data),
        pathname
      }
    }
    return {
      label: capitalizeFirstLetter(pathname.split('/')[index]),
      pathname
    }
  }) : []
  const breadcrumbs = crumbs && crumbs.length ? crumbs.slice(1) : []; 
  const title = breadcrumbs[breadcrumbs.length - 1].label
  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "li": {
            lineHeight: '20px'
          },
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        {breadcrumbs.map(({ label, pathname }, index) => {
          const last = index === breadcrumbs.length - 1
          const otherProps = last ? { fontWeight: 'bold', textTransform: 'capitalize', noWrap: true, variant: 'h6' } : {}
          return (
            <Link to={pathname} key={`${pathname}-${index}`}>
              <MDTypography
                component="span"
                variant="body2"
                color={light ? "white" : "dark"}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
                {...otherProps}
              >
                {pathname === '/dashboard' ? (<Icon>{icon}</Icon>) : label} 
              </MDTypography>
            </Link>
          )
        })}
      </MuiBreadcrumbs>
      <MDTypography
        component="span"
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
