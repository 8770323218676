import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const PeopleCard = ({ title, subtitle, type, icon, onClick }) => {
  const handleCardClick = () => {
    onClick(type)
  }

  return (
    <Card
      sx={{
        cursor: 'pointer',
        transition: 'transform .2s',
        '&:hover': { transform: 'scale(1.001)' }
      }}
      onClick={handleCardClick}>
      <MDBox p={3} display="flex" alignItems="center">
        <MDBox>
          <Icon>{icon}</Icon>
        </MDBox>
        <MDBox ml={3} flexGrow="1">
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="button" color="text">
            {subtitle}
          </MDTypography>
        </MDBox>
        <MDBox width="auto">
          <MDButton color="primary">Select</MDButton>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default PeopleCard
