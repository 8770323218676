import Form from 'components/Form'
import getFiltersSchema from './filters'
const RoomsFilters = ({ onChange }) => {
  const schema = getFiltersSchema()

  const handleFieldChange = (event) => {
    onChange(event)
  }

  return (
    <Form
      {...schema}
      watchedFields={['search', 'level', 'category', 'empty']}
      onFieldChange={handleFieldChange}
    />
  )
}

export default RoomsFilters
