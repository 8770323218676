import { useEffect, useState } from 'react'
const BUNNY_BASE_URL = 'https://storage.bunnycdn.com'
const CDN_BASE_URL = 'https://smartway.b-cdn.net'
const STORAGE_NAME = 'smartway-notifications'
const BUNNY_ACCESS_KEY = 'b520283b-4a1f-4d5f-943a1d17419d-5f49-456a' // only for STORAGE

export const constants = {
  BUNNY_BASE_URL
}

const getImageUrl = (image) => {
  const { Path, ObjectName } = image
  if (!Path) return ''
  return `${CDN_BASE_URL}${Path.replace(`/${STORAGE_NAME}`, '')}${ObjectName}`
}

const useBunnyStorage = ({ offsiteId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)
  const [existingImages, setExistingImages] = useState(null)

  const getBunnyStorageByOffsiteId = async ({ offsiteId }) => {
    const options = {
      method: 'GET',
      headers: {
        AccessKey: BUNNY_ACCESS_KEY,
        accept: 'application/json',
        'content-type': 'application/*+json'
      }
    }

    try {
      const res = await fetch(
        `${BUNNY_BASE_URL}/${STORAGE_NAME}/${offsiteId}/`,
        options
      )
      const results = await res.json()
      if (results) {
        setExistingImages(results)
      }
    } catch (error) {
      setIsError('Not able to retrieve images from the server. Please try again later')
    } finally {
      setIsLoading(false)
    }
  }

  const uploadImage = async ({ file }) => {
    try {
      const uploadOptions = {
        method: 'PUT',
        headers: {
          AccessKey: BUNNY_ACCESS_KEY,
          accept: 'application/json',
          'Content-Type': 'application/octet-stream'
        },
        body: file
      }

      const dateTime = new Date().getTime()
      const filename = `${dateTime}-${file.name}`
      const uploadRes = await fetch(
        `${BUNNY_BASE_URL}/${STORAGE_NAME}/${offsiteId}/${filename}`,
        uploadOptions
      )
      const uploadResponse = await uploadRes.json()
      if (uploadResponse) {
        getBunnyStorageByOffsiteId({ offsiteId })
        return {
          status: 'success',
          filename: `${CDN_BASE_URL}/${offsiteId}/${filename}`,
        }
      }
    } catch (error) {
      console.warn(error)
      return {
        status: 'error',
        error
      }
    }
  }

 

  useEffect(() => {
    getBunnyStorageByOffsiteId({ offsiteId })
  }, [offsiteId])

  return {
    images: existingImages,
    getImageUrl,
    getImages: getBunnyStorageByOffsiteId,
    uploadImage,
    isLoading,
    isError
  }

}

export { getImageUrl }
export default useBunnyStorage