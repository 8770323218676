import { defer } from 'react-router-dom'

import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'

import initialFilters from 'layouts/pages/agendas/list/filters'

export class AgendasLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    try {
      const results = this._loader(smartwayApi.endpoints.fetchEntities, request, {
        entity: 'agenda',
        page: 1,
        ...Object.fromEntries(url.searchParams),
        /* If it's not a template it means:
        * - it's a company agenda (used for Custom activities)
        * - it's an agenda inserted in an offsite
        */
        is_template: true,
        page_size: url.searchParams.get('page_size') || 24
      })
      return defer({
        results,
        initialFilters
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    if (isNew) return {}
    const [agenda, images] = await Promise.all([
      this._loader(smartwayApi.endpoints.fetchEntity, request, {
        entity: 'agenda',
        id: params.id
      }),
      this._loader(
        smartwayApi.endpoints.fetchImages,
        request,
        {
          entity: 'agenda',
          id: params.id,
          page_size: 30
        },
        { forceRefetch: true }
      )
    ])
    let fullVenue = {}
    if (agenda.all_in_venue || agenda.town) {
      fullVenue = await this._loader(smartwayApi.endpoints.fetchEntity, request, {
        entity: agenda.all_in_venue ? 'allInVenue' : 'town',
        id: agenda.all_in_venue ? agenda.all_in_venue : agenda.town
      })
    }
    return {
      entity: agenda,
      images: images && images.results && images.results.length ? images.results : null,
      fullVenue
    }
  }
}
