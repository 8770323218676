import Grid from '@mui/material/Grid'
import MaterialDivider from '@mui/material/Divider'

const Divider = ({ hr = false }) => {
  return hr ? (
    <MaterialDivider variant="fullWidth" sx={(theme) => ({ border: `1px solid ${theme.palette.grey['400']}` })} />
  ) : (
    <Grid item xs={12}></Grid>
  )
}

export default Divider