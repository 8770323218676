import { useState, useEffect } from 'react'

import MDBox from 'components/MDBox'
import { resolveFullOffsites } from 'utils/offsites'
import { OffsiteCardsSkeleton } from './Skeletons'
import OffsiteCard from './OffsiteCard'

const OffsiteCardsWrapper = ({
  offsites,
  dispatch,
  needOffsiteData,
  firstNeedConfirmationOffsite,
  onParticipationChange
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fullOffsites, setFullOffsites] = useState(null)

  useEffect(() => {
    const checkOffsitesRefresh = (offsites, fullOffsites) => {
      return fullOffsites.some((offsite) => {
        const propOffsite = offsites.find((_offsite) => _offsite.id === offsite.id)
        return (
          offsite.participation.confirmation !== propOffsite.participation.confirmation ||
          offsite.participation.offsite_preference !== propOffsite.participation.offsite_preference
        )
      })
    }
    const resolveOffsites = async () => {
      try {
        const results = await resolveFullOffsites({ offsites, dispatch, needOffsiteData })
        setFullOffsites(results)
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (!fullOffsites) {
      resolveOffsites()
    } else {
      const needRefresh = checkOffsitesRefresh(fullOffsites, offsites)
      if (needRefresh) {
        setFullOffsites(
          fullOffsites.map((offsite) => {
            const propOffsite = offsites.find((_offsite) => _offsite.id === offsite.id)
            return {
              ...offsite,
              participation: propOffsite.participation
            }
          })
        )
      }
      setIsLoading(false)
    }
    return () => {}
  }, [offsites])

  if (isLoading) {
    return (
      <MDBox mt={1} mb={3}>
        <OffsiteCardsSkeleton offsites={offsites} />
      </MDBox>
    )
  }
  return fullOffsites?.map((offsite) => {
    return (
      <MDBox key={offsite.id} mt={1} mb={3}>
        <OffsiteCard
          {...offsite}
          dialogOpen={
            offsite.needConfirmation &&
            firstNeedConfirmationOffsite &&
            firstNeedConfirmationOffsite.id === offsite.id
          }
          href={offsite.url}
          onParticipationChange={onParticipationChange}
        />
      </MDBox>
    )
  })
}

export default OffsiteCardsWrapper
