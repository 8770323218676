import { resolveField, resolveCustomField } from './offsite-confirmation'

export const TRANSFER_OPTIONS = [
  {
    label: 'Company transport',
    value: 'COMPANY'
  },
  {
    label: 'Personal transport',
    value: 'PERSONAL'
  },
  {
    label: 'Train',
    value: 'TRAIN'
  }
]

// There are not the same as the room categories endpoint
export const ROOMS_OPTIONS = [
  {
    label: 'I prefer a single room',
    value: 'SINGLE'
  },
  {
    label: "I'm ok to share a room",
    value: 'DOUBLE'
  }
]

// const getSchema = ({ preferences }) => {
//   let _preferences = preferences
//   if (!preferences) {
//     _preferences = {}
//   }

//   const {
//     custom_preference_active,
//     custom_preference_field_type,
//     custom_preference_options,
//     custom_preference_title,

//     room_preference_active,
//     room_preference_title,

//     transfer_active,
//     transfer_field_type,
//     transfer_title,
//     transfer_options
//   } = _preferences
//   return {
//     id: 'preferences-form',
//     name: 'preferences-form',
//     fieldsets: [
//       {
//         id: 'preferences',
//         fields: [
//           {
//             type: 'textarea',
//             name: 'special_needs',
//             validationType: 'string',
//             validations: null,
//             label: 'Special needs',
//             placeholder: 'Ex. vegetarian, gluten free, etc.',
//             helperText: 'Any special need that could be useful (ex. food allergies, vegetarian, wheelchair access, etc.)',
//             hideToolbar: true
//           },
//           ...( room_preference_active ? [{
//             type: 'select',
//             name: 'room_preference',
//             label: room_preference_title || 'Room preference',
//             options: ROOMS_OPTIONS
//           }] : []),
//           ...( transfer_active ? [{
//             type: transfer_field_type?.toLowerCase() || 'select',
//             name: 'transfer',
//             label: transfer_title || 'Type of transfer',
//             options: transfer_options ? transfer_options.split('|').map(opt => ({ label: opt, value: opt })) : TRANSFER_OPTIONS,
//           }] : []),
//           ...( custom_preference_active ? [{
//             name: 'custom_preference',
//             label: custom_preference_title || 'Custom preference',
//             type: custom_preference_field_type?.toLowerCase() || 'text',
//             options: custom_preference_options ? custom_preference_options.split('|').map(opt => ({ label: opt, value: opt })) : []
//           }] : [])
//         ]
//       }
//     ]
//   }
// }

const getSchema = ({ fieldsets, options, tab, participant, offsiteId }) => {
  const hasUser = !!participant.user
  return {
    id: `preferences-${tab.id}`,
    name: `preferences-${tab.id}`,
    title: tab.id === 'custom' ? 'Other' : tab.title,
    subtitle: tab.description,
    watchedFields: fieldsets
      .reduce((acc, fieldset) => [
        ...acc,
        ...fieldset.fields.map((field) => (fieldset.isCustom ? { ...field, custom: true } : field))
      ], [])
      .map((field) => (field.custom ? field.field_key : field.name)),
    fieldsets: fieldsets.map((fieldset) => {
      const { fields } = fieldset
      return {
        ...fieldset,
        fields: fields.filter(f => {
          if (!hasUser) {
            return !['dietary_options', 'dietary_options_other', 'allergies', 'allergies_other'].includes(f.name)
          }
          return f
        }).map((field) =>
          fieldset.isCustom ? resolveCustomField(field) : resolveField({ field, step: fieldset, options, offsiteId })
        )
      }
    })
  }
}

export default getSchema
