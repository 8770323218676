import { useState, useEffect } from 'react'

// Form
import { createValidationSchema, createInitialValues, normalizeField } from 'utils/form'
import { Formik } from 'formik'

// Material Dashboard components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// MUI Components
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import LoadingButton from '@mui/lab/LoadingButton'
import Tooltip from '@mui/material/Tooltip'

// Custom components
import FieldController from './FieldController'
import FieldsetWrapper from './FieldsetWrapper'

import { getLoadingButtonColors } from 'utils/functions'

const Form = ({
  id,
  title,
  description,
  sx = {},
  fieldsets,
  values,
  afterSlot,
  watchedFields = null,
  resetAfterSubmit = false,
  needChangeToSubmit = true,
  onFieldChange,
  onSubmit,
  onCancel,
  submitButton,
  cancelButton,
  publishButton,
  ctasPosition = 'bottom'
}) => {
  const [initialValues, setInitialValues] = useState(createInitialValues(fieldsets, values))
  const findDefaultAccordion = () => {
    const firstAccordion = fieldsets.find((f) => f.type === 'accordion')
    return firstAccordion && !firstAccordion.defaultClosed ? firstAccordion.id : null
  }
  const validationSchema = createValidationSchema(fieldsets)
  const [accordionExpanded, setAccordionExpanded] = useState(findDefaultAccordion())

  // const [previewURL, setPreviewURL] = useState('')

  const submitHandler = async (values, actions) => {
    await onSubmit(values)
    actions.setSubmitting(false)
    if (resetAfterSubmit) {
      actions.resetForm()
    }
  }

  const handleOnCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel()
    }
  }

  useEffect(() => {
    if (!!values) {
      setInitialValues(createInitialValues(fieldsets, values))
    }
  }, [fieldsets, values])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}>
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        dirty,
        isSubmitting,
        setFieldValue,
        setFieldError,
        setFieldTouched
      }) => {
        const isDisabled =
          submitButton && submitButton.disabled !== undefined ? !!submitButton.disabled : false
        const submitDisabled =
          isDisabled ||
          isSubmitting ||
          (needChangeToSubmit ? (dirty ? !!Object.keys(errors).length : true) : false)

        // console.table(touched)
        // console.table(errors)

        const handleWatchedChange = async (event) => {
          const { needTouched, name, value } = event.target
          handleChange(event)
          if (needTouched && !touched[name]) {
            setFieldTouched(name, true)
          }
          const _errors = await setFieldValue(name, value, true)
          if (watchedFields && watchedFields.includes(name) && onFieldChange) {
            onFieldChange(event, values, { setFieldValue, setFieldError, errors: _errors })
          }
        }

        const handleFileChange = async (name, files, event) => {
          if (!touched[name]) {
            setFieldTouched(name, true)
          }
          const _errors = await setFieldValue(name, files, true)

          if (watchedFields && watchedFields.includes(name) && onFieldChange) {
            onFieldChange(event, values, { setFieldValue, setFieldError, errors: _errors })
          }
        }

        const getOtherValues = (field, values) => {
          let otherProps = {}
          switch (field.type) {
            case 'address':
              const longitudeProp = field.longitudeProp || 'longitude'
              const latitudeProp = field.latitudeProp || 'latitude'
              otherProps = {
                [latitudeProp]: values[latitudeProp] || 0,
                [longitudeProp]: values[longitudeProp] || 0
              }
              break
            case 'checkbox':
              otherProps = {
                defaultChecked: !!values[field.name]
              }
              break
            case 'file': {
              otherProps = {
                onFileChange: handleFileChange
              }
              break
            }
            case 'datepicker': {
              if (field.minDate) {
                const otherValue = values[field.minDate]
                otherProps = {
                  minDate: otherValue
                }
              }
              break
            }
            default:
              break
          }
          return otherProps
        }

        return (
          <MDBox sx={{ ...sx }}>
            <form
              style={{
                width: '100%',
                maxWidth: '-webkit-fill-available',
                backgroundColor: 'transparent'
              }}
              id={id}
              onSubmit={handleSubmit}>
              {title || (!!submitButton && ctasPosition === 'top') ? (
                <Grid container sx={{ py: 2 }}>
                  {title ? (
                    <Grid item xs={12} lg={6}>
                      <MDTypography variant="h5">{title}</MDTypography>
                    </Grid>
                  ) : null}

                  {ctasPosition === 'top' ? (
                    <Grid item xs={12} lg={6} sx={{ marginLeft: "auto" }}>
                      <MDBox display="flex" justifyContent="flex-end" gap={1} marginBottom={4}>
                        {!!cancelButton ? (
                          <MDButton variant="text" color="error" onClick={handleOnCancel}>
                            <Icon sx={{ mr: 0.5 }}>close</Icon>&nbsp;
                            {cancelButton.label}
                          </MDButton>
                        ) : null}
                        {!!submitButton ? (
                          <LoadingButton
                            variant="contained"
                            type="submit"
                            disabled={submitDisabled}
                            loading={isSubmitting}
                            {...submitButton}
                            {...getLoadingButtonColors(submitButton.color, submitButton.sx)}>
                            {submitButton.label}
                          </LoadingButton>
                        ) : null}
                        {!!publishButton ? (
                          publishButton.tooltip ? (
                            <Tooltip title={publishButton.tooltip}>
                              <MDButton {...publishButton}>
                                <Icon sx={{ mr: 0.5 }}>{publishButton.icon}</Icon>&nbsp;
                                {publishButton.label}
                              </MDButton>
                            </Tooltip>
                          ) : (
                            <MDButton {...publishButton}>
                              <Icon sx={{ mr: 0.5 }}>{publishButton.icon}</Icon>&nbsp;
                              {publishButton.label}
                            </MDButton>
                          )
                        ) : null}
                      </MDBox>
                    </Grid>
                  ) : null}
                  {description ? (
                    <Grid item xs={12}>
                      <MDTypography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: description }}></MDTypography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
              {fieldsets && !!fieldsets.length
                ? fieldsets.map(
                    ({ id, title, subtitle, type, fields, ...fieldset }, fieldsetIndex) => {
                      return (
                        <FieldsetWrapper
                          key={`${type}-${fieldsetIndex}`}
                          id={id}
                          title={title}
                          subtitle={subtitle}
                          type={type}
                          accordionExpanded={accordionExpanded}
                          onAccordionChange={setAccordionExpanded}
                          {...fieldset}>
                          {fields && !!fields.length
                            ? fields.map(({ name, ...field }, index) => {
                                const _field = normalizeField(field)
                                const otherProps = getOtherValues({ name, ...field }, values)
                                const grid = !!field.grid ? field.grid : { xs: 12 }
                                return (
                                  <Grid key={`${index}-${name}`} item {...grid}>
                                    <FieldController
                                      {..._field}
                                      {...otherProps}
                                      name={name}
                                      value={!!values && values[name]}
                                      errors={!!touched && touched[name] && errors[name]}
                                      success={!!touched && touched[name] && !errors[name]}
                                      setFieldValue={setFieldValue}
                                      setFieldTouched={setFieldTouched}
                                      onChange={handleWatchedChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                )
                              })
                            : null}
                        </FieldsetWrapper>
                      )
                    }
                  )
                : null}
              {afterSlot ? <MDBox mx={2}>{afterSlot}</MDBox> : null}
              {ctasPosition === 'bottom' && !!submitButton ? (
                <MDBox mt={3} display="flex" justifyContent="flex-end" mb={3}>
                  {!!cancelButton ? (
                    <MDButton variant="text" color="error" fullWidth onClick={handleOnCancel}>
                      <Icon>close</Icon>&nbsp;
                      {cancelButton.label}
                    </MDButton>
                  ) : null}
                  {!!submitButton ? (
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      disabled={submitDisabled}
                      loading={isSubmitting}
                      {...submitButton}
                      {...getLoadingButtonColors(submitButton.color, submitButton.sx)}>
                      {submitButton.label}
                    </LoadingButton>
                  ) : null}
                </MDBox>
              ) : null}
            </form>
          </MDBox>
        )
      }}
    </Formik>
  )
}

export default Form
