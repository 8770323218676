import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { smartwayApi } from 'services/api'
import { useDispatch } from 'react-redux'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import FormHelperText from '@mui/material/FormHelperText'

import Input from '../Input'
import Checkbox from '../Checkbox'
import Skeleton from '@mui/material/Skeleton'

const defaultStyles = {
  'span.MuiAutocomplete-tag': {
    backgroundColor: 'transparent !important',
    color: 'black !important'
  }
}

const Select = ({
  name,
  options,
  value,
  defaultValue,
  multiple,
  multipleLimit,
  freeSolo, // creatable
  helperText,
  errors,
  groupBy = null,
  link = null,
  optionsGetter = null,
  labelProp = 'label',
  valueProp = 'value',
  inputStyles = {},
  inputVariant = 'outlined',
  InputLabelProps,
  onNoResults,
  noOptionsText,
  onNoOptionsClick,
  onChange,
  onInputChange,
  setFieldTouched = () => {},
  setFieldValue = () => {},
  success,
  ...rest
}) => {
  const dispatch = useDispatch()
  const formatOptions = useCallback(
    (optionsToMap) => {
      return optionsToMap && optionsToMap.length
        ? optionsToMap.map(({ label, value, entity, prefix, disabled, groupBy, ...opt }) => ({
            label: label || opt[labelProp],
            value: value || (prefix ? `${prefix}_${opt[valueProp]}` : opt[valueProp]),
            entity,
            prefix,
            disabled,
            groupBy
          }))
        : null
    },
    [labelProp, valueProp]
  )

  const [selectOptions, setSelectOptions] = useState(formatOptions(options) || [])
  const [selectOptionsBeforeLimit, setSelectOptionsBeforeLimit] = useState(null)

  const getValue = useCallback(() => {
    if (selectOptions && selectOptions.length && value) {
      if (multiple) {
        return selectOptions.filter((o) => value.includes(o.value))
      }
      const selectedValue = selectOptions.find((o) => o.value === value)
      return selectedValue !== undefined ? selectedValue : null
    }
    if (freeSolo && value) {
      if (multiple) {
        // This allows to insert a list of values separated by comma
        const newValue = value
          .map((o) => {
            if (typeof o === 'string') {
              return o.split(',').map((s) => ({ label: s.toLowerCase(), value: s.toLowerCase() }))
            }
            return o
          })
          .flat()

        return newValue
      }
      return typeof value === 'string'
        ? { label: value.toLowerCase(), value: value.toLowerCase() }
        : value
    }
    return multiple ? [] : null
  }, [selectOptions, value, freeSolo, multiple])
  const [selectValue, setSelectValue] = useState(getValue())
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const wrapWithEvent = (fullValue, v) => {
    return {
      target: {
        name,
        value: v,
        fullValue,
        previousValue: selectValue,
        needTouched: freeSolo
      }
    }
  }

  const handleChange = (e, newValue) => {
    if (!newValue) {
      onChange(wrapWithEvent(null, null))
      return
    }
    if (multiple) {
      onChange(
        wrapWithEvent(
          newValue,
          newValue.map((o) => {
            if (typeof o === 'string') {
              return o
            }
            return o.value
          })
        )
      )
      if (multipleLimit) {
        if (newValue.length >= multipleLimit) {
          if (!selectOptionsBeforeLimit) {
            setSelectOptionsBeforeLimit(selectOptions)
          }
          setSelectOptions([...selectOptions.map((o) => ({ ...o, disabled: true }))])
        } else {
          setSelectOptions(selectOptionsBeforeLimit ? selectOptionsBeforeLimit : selectOptions)
        }
      }
    } else {
      let _value = typeof newValue === 'string' ? newValue : newValue.value
      onChange(wrapWithEvent(newValue, _value))
    }
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    if (onInputChange && typeof onInputChange === 'function') {
      onInputChange(e)
    }
  }

  const handleBlur = (e) => {
    const isValue = multiple ? selectValue && !!selectValue.length : selectValue
    if (!isValue) {
      setFieldTouched(name, true)
    }
    if (freeSolo && multiple && !!e.target.value) {
      handleChange(e, value && Array.isArray(value) ? [...value, e.target.value] : [e.target.value])
    }
  }

  const handleNoOptionsClick = () => {
    if (onNoOptionsClick && typeof onNoOptionsClick === 'function') {
      onNoOptionsClick(inputValue)
    }
  }

  const getAsyncOptions = useCallback(async () => {
    setIsLoading(true)
    let allResults = []
    for (let i = 0; i < optionsGetter.endpoints.length; i++) {
      const endpoint = optionsGetter.endpoints[i]
      const params = endpoint.params || {}
      const idParam = endpoint.id || null
      const response = await dispatch(
        smartwayApi.endpoints[endpoint.endpoint || 'fetchAllEntities'].initiate({
          entity: endpoint.entity,
          ...(idParam ? { id: idParam } : {}),
          ...params
        })
      )
      const entityResults = response.status === 'fulfilled' && response.data ? response.data : []
      allResults = [
        ...allResults,
        ...entityResults.map((r) => ({ ...r, entity: endpoint.name, prefix: endpoint.prefix }))
      ]
    }
    if (!allResults.length) {
      if (onNoResults && typeof onNoResults === 'function') {
        onNoResults()
      }
    }
    const newOptions = formatOptions(allResults)
    setSelectOptions(newOptions)
    setIsLoading(false)
  }, [dispatch, formatOptions, onNoResults, optionsGetter?.endpoints])

  useEffect(() => {
    if (!options && !!optionsGetter) {
      getAsyncOptions()
    }
  }, [getAsyncOptions, options, optionsGetter])

  useEffect(() => {
    if (((selectOptions && selectOptions.length) || freeSolo)) {
      const newValue = getValue()
      if (newValue !== selectValue) {
        const _newValue = newValue === undefined ? null : newValue
        setSelectValue(_newValue || null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, value, getValue])

  useEffect(() => {
    if (options) {
      setSelectOptions(formatOptions(options))
    }
  }, [formatOptions, options])

  const { fieldMargin, sx, ...autocompleteProps } = rest

  return (
    <MDBox position="relative" mb={fieldMargin !== undefined ? fieldMargin : 4}>
      {selectOptions || freeSolo ? (
        !isLoading ? (
          <>
            <Autocomplete
              {...autocompleteProps}
              sx={
                sx
                  ? {
                      ...sx,
                      ...defaultStyles
                    }
                  : defaultStyles
              }
              id={`${name}`}
              name={name}
              multiple={multiple}
              freeSolo={freeSolo}
              onChange={handleChange}
              onBlur={handleBlur}
              value={selectValue}
              options={selectOptions}
              getOptionDisabled={(option) => {
                return option.disabled
              }}
              disableCloseOnSelect={!!multiple}
              getOptionLabel={(option) => option.label}
              groupBy={groupBy || null}
              isOptionEqualToValue={(option, value) => {
                if (!value) return true
                return option.value === value.value
              }}
              noOptionsText={
                onNoOptionsClick ? (
                  <div style={{ cursor: 'pointer' }} onClick={handleNoOptionsClick}>
                    {noOptionsText || 'No results'}
                  </div>
                ) : (
                  noOptionsText || 'No results'
                )
              }
              renderOption={(props, option) => (
                <MDBox
                  component="li"
                  {...props}
                  key={`${option.label}-${option.value}`}
                  width="100% !important"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between">
                  { multiple ? (
                    <Checkbox 
                      value={value && value.includes(option.value)}
                      onChange={() => null}
                    />
                  ) : null }
                  <MDBox width="100%">{option.label}</MDBox>
                  {link ? (
                    <MDBox>
                      <Link to={link.replace('{value}', option.value)} target="_blank">
                        <Icon sx={{ color: 'dark' }}>open_in_new</Icon>
                      </Link>
                    </MDBox>
                  ) : null}
                </MDBox>
              )}
              renderTags={(value, getTagProps) => {
                return value.map(({ label, value }, index) => {
                  const errorProp =
                    errors && errors.context && errors.context.includes(value)
                      ? {
                          ...getTagProps({ index }),
                          className: '',
                          variant: 'outlined',
                          error: true,
                          color: 'error',
                          size: 'small',
                          sx: { margin: '3px' }
                        }
                      : { ...getTagProps({ index }) }

                  return <Chip key={`tag-${index}`} label={label} {...errorProp} />
                })
              }}
              renderInput={(params) => (
                <Input
                  id={`input-${name}`}
                  {...params}
                  sx={{
                    ...params.sx,
                    ...inputStyles,
                    ...(inputVariant === 'standard' && {
                      input: { paddingLeft: '0 !important' }
                    }),
                    ...(!!errors && {
                      '.Mui-focused .MuiOutlinedInput-notchedOutline, .Mui-focused:after': {
                        borderColor: '#f44335 !important'
                      }
                    })
                  }}
                  errors={!!errors}
                  required={!!rest.required && !multiple}
                  label={rest.label}
                  variant={inputVariant}
                  boxStyles={{ mb: 0 }}
                  inputProps={{
                    ...params.inputProps,
                    sx: { padding: '3px 8px !important' }
                  }}
                  value={value}
                  onChange={handleInputChange}
                />
              )}
            />
            {helperText || errors || (multiple && multipleLimit) ? (
              <MDBox position="absolute" width="100%" sx={{ lineHeight: 1 }}>
                {(helperText || (multiple && multipleLimit)) ? (
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    { errors ? (
                      <MDTypography variant="caption" color="error">{errors}</MDTypography>
                    ) : (
                      <MDBox>
                        <FormHelperText>{helperText || ' '}</FormHelperText>
                      </MDBox>
                    ) }
                    {multiple && multipleLimit ? (
                      <MDTypography variant="caption">
                        {selectValue?.length || 0} / {multipleLimit}
                      </MDTypography>
                    ) : null }
                  </MDBox>
                ) : null}
                {errors ? (
                  <MDBox display="flex" alignItems="center" width="100%">
                    <MDTypography variant="caption" color="error">
                      {errors}
                    </MDTypography>
                  </MDBox>
                ) : null}
              </MDBox>
            ) : null}
          </>
        ) : (
          <Skeleton variant="rounded" animation="wave" width="100%" height={48} />
        )
      ) : null}
    </MDBox>
  )
}

export default Select
