import { useState } from 'react'
import { format } from 'date-fns'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Image from 'components/Image'
import logo from 'assets/images/smartway_logo_white.png'
import EditableInput from 'components/Fields/EditableInput/EditableInput'
import Textarea from 'components/Fields/Textarea/Textarea'

const InvitationEmailRecap = ({ offsite, profile, onChange }) => {
  const [managerValue, setManagerValue] = useState(`by ${profile.first_name} ${profile.last_name}`)
  const [customTextValue, setCustomTextValue] = useState('')

  const handleChangeManager = (e) => {
    setManagerValue(e.target.value)
    onChange({ manager: e.target.value, custom_text: customTextValue })
  }
  const handleChangeCustomText = (e) => {
    setCustomTextValue(e.target.value)
    onChange({ custom_text: e.target.value, manager: managerValue })
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <MDBox display="flex" flexDirection="column" alignItems="center" my={3}>
          <MDBox textAlign="center" maxWidth="650px">
            <MDBox component="img" src={logo} alt="Smartway" width="8rem" maxHeight="2rem" mb={2} />
            <MDTypography variant="body2" fontSize="20px" mb={2}>
              Hi John,
              <br />
              You've been invited to
            </MDTypography>

            <MDTypography>
              <strong>{offsite.title}</strong>
              <br />
              <strong>
                {' '}
                {format(new Date(offsite.start_at), 'dd/MM/yyyy')} -{' '}
                {format(new Date(offsite.end_at), 'dd/MM/yyyy')}
              </strong>
              <br />
              <strong> in {offsite.venue.name}</strong>
              <br />
              <br />
              <EditableInput
                name="manager"
                variant="body"
                placeholder="Add a sender name"
                value={managerValue}
                onChange={handleChangeManager}
              />
            </MDTypography>

            <MDBox mt={3}>
              <Image
                sx={{ objectFit: 'cover', maxHeight: '300px' }}
                formats={offsite.venue.featured_image.formats}
                format="desktop"
              />
            </MDBox>

            <MDBox mt={3}>
              <MDTypography
                variant="body2"
                fontSize="20px"
                mb={2}
                style={{
                  display: 'inline-flex',
                  gap: '6px',
                  alignItems: 'center',
                  textAlign: 'left'
                }}>
                  <Textarea 
                    label="Add a custom message"
                    name="manager"
                    variant="body"
                    placeholder=""
                    value={customTextValue}
                    onChange={handleChangeCustomText}
                  />
                {/* <EditableInput
                  name="manager"
                  variant="body"
                  placeholder="Click to a custom message"
                  value={customTextValue}
                  onChange={handleChangeCustomText}
                /> */}
              </MDTypography>
              <MDTypography variant="body2" fontSize="20px" mb={3}>
                We're super excited to invite you to download Smartway and register with your
                company email to take part to your exclusive company event.
              </MDTypography>
              <MDButton color="primary" disabled>
                Join the offsite
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </CardContent>
    </Card>
  )
}

export default InvitationEmailRecap
