import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const LinkCard = ({ id, title, excerpt, description, link, onEdit, onDelete, ...props }) => {
  const handleEdit = () => {
    onEdit({ id, title, description, excerpt, link, ...props })
  }
  const handleRemove = () => {
    onDelete({ id, title, description, ...props })
  }
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <MDBox pt={2}>
          <MDTypography variant="h6" component="div">
            {title}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="secondary"
            dangerouslySetInnerHTML={{
              __html: excerpt
            }}></MDTypography>
          <a href={link} target="_blank" rel="noreferrer">
            <MDTypography variant="caption" color="secondary" display="flex" alignItems="center" mt={2}>
              {link} <Icon sx={{ ml: 0.5 }}>open_in_new</Icon>
            </MDTypography>
          </a>
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
        <MDButton color="primary" size="small" onClick={handleEdit} iconOnly>
          <Icon>edit</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default LinkCard
