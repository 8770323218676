const getSchema = ({ emailParam }) => {
  return {
    id: 'resetPassword',
    title: '',
    fieldsets: [
      {
        title: '',
        fields: [
          {
            type: 'text',
            name: 'first_name',
            label: 'Firstname',
            placeholder: 'John',
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: [
                  'This field is required'
                ]
              }
            ]
          },
          {
            type: 'text',
            name: 'last_name',
            label: 'Lastname',
            placeholder: 'Miller',
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: [
                  'This field is required'
                ]
              }
            ]
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            placeholder: 'Email',
            autoComplete: "email",
            disabled: !!emailParam,
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: [
                  'This field is required'
                ]
              }, 
              {
                type: 'email',
                params: [
                  'You should provide a valid email address'
                ]
              }
            ]
          },
          {
            type: 'password',
            name: 'password',
            label: 'Password',
            validationType: 'string',
            required: true,
            helperText: 'The password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
            validations: [
              {
                type: 'required',
                params: ['This field is required'],
              },
              {
                type: 'min',
                params: [8, 'The password must be at least 8 characters'],
              },
              {
                type: 'password',
                params: [
                  'The password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
                ]
              }
            ]
          },
          {
            type: 'checkbox',
            name: 'privacy_policy',
            styles: {
              mt: 2
            },
            required: true,
            label: <span style={{ display: 'block',fontSize: 14, fontWeight: 300, marginBottom: '14px' }}>I accept the <a style={{ borderBottom: '1px solid #000'}} onClick={(e) => {
              e.preventDefault()
              e.stopPropagation() 
              window._iub.cs.api.showCP()
            }}>privacy policy</a></span>,
            validationType: 'boolean',
            validations: [
              {
                type: 'required',
                params: [
                  'This field is required'
                ]
              },
              {
                type: 'oneOf',
                params: [
                  [true],
                  'You must accept the privacy policy'
                ]
              }
            ]
          }
        ]
      }
    ],
    submitButton: {
      label: 'Create account',
      color: 'info',
      sx: {
        mt: 2
      }
    }
  }
}

export default getSchema