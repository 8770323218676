/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'store'
import App from 'App'

//Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from 'context'

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </Provider>,
)
