import { useEffect } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import useAuthentication from 'hooks/useAuthentication'
import useAuthorization from 'hooks/useAuthorization'

import Spinner from 'components/Spinner'
import MDBox from 'components/MDBox'

import Root from 'layouts/Root'
import NotAuthorized from 'layouts/authentication/components/NotAuthorized'
import FirestoreWrapper from '../FirestoreWrapper/index'

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { isLoading, isAuthenticated, profile, error } = useAuthentication({ pathname })
  const { isAuthorized, isLoading: authorizationLoading } = useAuthorization({ pathname, isAuthenticated, profile })

  useEffect(() => {
    if (error) {
      dispatch(
        notify({
          type: 'error',
          title: 'Oops!',
          message: error.message,
          icon: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, error])

  // console.debug('--- Protected route ---', isAuthenticated, isAuthorized, isLoading)
  return !isLoading && !authorizationLoading ? (
    (isAuthenticated && !!profile) ? (
      <FirestoreWrapper>
        {isAuthorized ? (
          children
        ) : (
          <Root>
            <NotAuthorized />
          </Root>
        )}
      </FirestoreWrapper>
    ) : (
      <Navigate
        to={`/authentication/sign-in${pathname ? `?next=${pathname}` : ''}`}
      />
    )
  ) : (
    <MDBox minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </MDBox>
  )
}

export default ProtectedRoute
