import { useState, useEffect } from 'react'
import { useActionData, useSubmit } from 'react-router-dom'

import { getFirebaseError } from 'services/firebase'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// react router
import { Link } from 'react-router-dom'

// Custom components
import Form from 'components/Form'

// @mui material components
import Card from '@mui/material/Card'
import Alert from '@mui/material/Alert'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// form
import form from './form'

// Images
import bgImage from 'assets/images/montepulciano.jpeg'

const ResetPassword = () => {
  const [errorAlert, setErrorAlert] = useState(null)
  const submit = useSubmit()
  const actionData = useActionData()
  const dispatch = useDispatch()

  const handleForgotPassword = async (values) => {
    setErrorAlert(null)
    submit(
      { ...values, email: values.email?.toLowerCase() },
      { method: 'post', encType: 'application/json' }
    )
  }

  useEffect(() => {
    if (!!actionData) {
      if (actionData.success) {
        dispatch(
          notify({
            type: 'success',
            title: 'Well done!',
            message: 'Reset password email sent!',
            icon: 'done'
          })
        )
      }
      if (actionData.error) {
        const { code } = actionData.fullError
        if (code === 'auth/user-not-found') {
          setErrorAlert({
            severity: 'warning',
            message: 'No user found with this email. Please register and try again.',
            sx: { 
              flexWrap: 'wrap', 
              alignItems: 'center',
              '.MuiAlert-icon': { flex: 0 },
              '.MuiAlert-message': { flex: 1 },
              '.MuiAlert-action': { flexBasis: '100% !important', display: 'flex', justifyContent: 'flex-end' } },
            action: <MDButton sx={{ minHeight: 'auto !important', pt: '0 !important' }}>
              <Link to="/authentication/sign-up">Register</Link>
            </MDButton>
          })
        }
        if (code === 'auth/user-disabled') {
          setErrorAlert({
            severity: 'warning',
            message: 'Your must confirm your email to activate your account. Please check your emails (including spam) and click on the confirmation link.'
          })
        }
        dispatch(
          notify({
            type: 'error',
            title: 'Oops!',
            message: getFirebaseError(actionData.fullError),
            icon: 'error'
          })
        )
      }
    }
  }, [actionData, dispatch])

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      <Card sx={{ minWidth: 300, position: 'relative', zIndex: 100 }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center">
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={1} px={3}>
          <Form {...form} onSubmit={handleForgotPassword} />
          {errorAlert && (
            <MDBox mb={4}>
              <Alert variant="outlined" {...errorAlert} dismissible>
                {errorAlert.message}
              </Alert>
            </MDBox>
          )}
          <MDBox mb={1} display="flex" justifyContent="flex-end">
            <MDButton variant="gradient" color="info" type="submit" form="resetPassword">
              Reset password
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1}>
          <MDButton variant="text" size="small" fullWidth>
            <Link to="/authentication/sign-in">Back to login</Link>
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default ResetPassword
