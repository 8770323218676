import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PreferencesFieldsetValues from '../Preferences/PreferencesFieldsetValues'
import { TABS } from 'components/Dialogs/PreferencesDialog'

const ParticipantPreferences = ({ offsite, participant, options }) => {
  const {
    offsite_preference,
    user
  } = participant
  const userPreferences = {
    ...(offsite_preference ? offsite_preference : {}),
    ...(user?.user_preferences ? user.user_preferences : {})
  }
  const offsitePreferences = offsite?.preference_form
  const ACTUAL_TABS = TABS.filter((t) => {
    return t.id === 'custom'
      ? offsitePreferences?.custom_fields?.length > 0
      : offsitePreferences?.fieldsets?.some((f) => t.activeIf.includes(f.name) && f.active)
  })
  return (
    <MDBox mx={2} mb={4}>
      <MDTypography variant="h4" fontWeight="medium" mb={2}>
        Preferences
      </MDTypography>
      {ACTUAL_TABS && ACTUAL_TABS.length ? ACTUAL_TABS.map((tab, index) => {
        const fieldsets =
          tab.id === 'custom'
            ? [{ isCustom: true, fields: offsitePreferences?.custom_fields }]
            : offsitePreferences?.fieldsets?.filter((f) => tab.activeIf?.includes(f.name) && f.active)
        return fieldsets && fieldsets.length ? fieldsets.map((fieldset, fIndex) => {
          return (
            <PreferencesFieldsetValues
              key={`${fieldset.name}-${fIndex}`}
              fieldset={fieldset}
              options={options}
              preferences={userPreferences}
            />
          )
        }) : null
      }) : null}      
    </MDBox>
  )
}

export default ParticipantPreferences