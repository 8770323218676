import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { format } from 'date-fns'
import { useReactToPrint } from 'react-to-print'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Icon,
  IconButton
} from '@mui/material'
import MDBox from 'components/MDBox'

import GeneralInfos from './components/ParticipantItinerary/GeneralInfos'
import ParticipantAgenda from './components/ParticipantItinerary/ParticipantAgenda'
import ParticipantAccommodation from './components/ParticipantItinerary/ParticipantAccommodation'
import ParticipantPreferences from './components/ParticipantItinerary/ParticipantPreferences'
import ParticipantExperiences from './components/ParticipantItinerary/ParticipantExperiences'
import ParticipantCaterings from './components/ParticipantItinerary/ParticipantCaterings'
import ParticipantWorkshops from './components/ParticipantItinerary/ParticipantWorkshops'

import { ItinerarySkeleton } from './Skeletons'

const ParticipantItineraryDialog = ({
  open,
  participant,
  participants,
  offsite,
  options,
  onClose
}) => {
  const pageRef = useRef(null)
  const dispatch = useDispatch()
  const [rawAgenda, setRawAgenda] = useState(null)
  const [agenda, setAgenda] = useState(null)
  const [participantAgenda, setParticipantAgenda] = useState(null)
  const [accommodations, setAccommodations] = useState({})
  const [rooms, setRooms] = useState({})
  const [workshopsGroups, setWorkshopsGroups] = useState([])
  const [participantExperiences, setParticipantExperiences] = useState(null)
  const [participantCaterings, setParticipantCaterings] = useState(null)
  const [participantWorkshops, setParticipantWorkshops] = useState(null)
  const [resetAccordions, setResetAccordions] = useState(0)

  const [loading, setLoading] = useState(true)

  const resetState = () => {
    setParticipantAgenda(null)
    setLoading(true)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleOnBeforePrint = async () => {
    setResetAccordions(Math.random() * 10000)
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), 500)
    })
  }

  const handlePrint = useReactToPrint({
    documentTitle: `${participant?.first_name} ${participant?.last_name} - Itinerary`,
    content: () => pageRef.current,
    onBeforeGetContent: handleOnBeforePrint
  })

  const getActivitiesByDay = (activities) => {
    return activities.reduce((acc, activity) => {
      const day = format(new Date(activity.start_at), 'MM/dd/yyyy')
      if (!acc[day]) {
        acc[day] = [activity]
      } else {
        acc[day].push(activity)
      }
      return acc
    }, {})
  }

  const getAgendaSorted = (agenda) => {
    const agendaSorted = {}
    Object.keys(agenda).forEach((key) => {
      agendaSorted[key] = agenda[key].sort((a, b) => {
        return new Date(a.start_at) - new Date(b.start_at)
      })
    })
    return agendaSorted
  }

  const getActivitiesSorted = (activities) => {
    return [...activities].sort((a, b) => {
      return new Date(a.start_at) - new Date(b.start_at)
    })
  }

  const getActivityDescription = ({ activity, participant }) => {
    if (
      activity.type === 'LOGISTIC' &&
      participant.room &&
      participant.accommodation &&
      rooms[participant.room] &&
      accommodations[participant.accommodation]
    ) {
      const {
        detail: { title: roomName }
      } = rooms[participant.room]
      const { name } = accommodations[participant.accommodation]
      return `<p>${activity.title} - <a
      style="border-bottom: 1px solid #7b809a; color: inherit"
      href="https://listings.smartway.work/accommodations/${participant.accommodation}"
      target="_blank"
      rel="noreferrer">${name} | ${roomName}</a></p>`
    }
    return activity.description
  }

  const checkParticipantPresence = (participant, activity) => {
    return activity.participants && activity.participants.length
      ? activity.participants.includes(participant.id)
      : true
  }

  const getParticipantAgenda = (participant, agenda) => {
    const participantAgenda = {}
    Object.keys(agenda).forEach((key) => {
      participantAgenda[key] = agenda[key]
        .filter((activity) => {
          return checkParticipantPresence(participant, activity)
        })
        .map((activity) => {
          const { start_at, end_at, title, location, type } = activity
          return {
            time: `${format(new Date(start_at), 'HH:mm')} - ${format(new Date(end_at), 'HH:mm')}`,
            title,
            description: getActivityDescription({ activity, participant }),
            location,
            type
          }
        })
    })
    return participantAgenda
  }

  const getParticipantExperiences = (participant, activities) => {
    return activities
      .filter((activity) => {
        return (
          checkParticipantPresence(participant, activity) &&
          activity.location?.location_type === 'experience'
        )
      })
      .reduce((acc, activity) => {
        const {
          location: { id }
        } = activity
        if (!acc.find(({ location }) => location.id === id)) {
          acc.push(activity)
        }
        return acc
      }, [])
      .map((activity) => {
        const { title, description, location } = activity
        return {
          title,
          description,
          image: location.featured_image,
          location
        }
      })
  }

  const getParticipantCaterings = (participant, activities) => {
    return activities
      .filter((activity) => {
        return checkParticipantPresence(participant, activity) && activity.type === 'FOOD_DRINK'
      })
      .map((activity) => {
        const { start_at, title, description, location } = activity
        return {
          time: format(new Date(start_at), 'dd MMM - HH:mm'),
          title,
          description,
          location
        }
      })
  }
  const getParticipantWorkshops = (participant, activities) => {
    return activities
      .filter((activity) => {
        return (
          checkParticipantPresence(participant, activity) &&
          activity.location?.location_type === 'workshop'
        )
      })
      .reduce((acc, activity) => {
        const {
          location: { id }
        } = activity
        if (!acc.find(({ location }) => location.id === id)) {
          acc.push(activity)
        }
        return acc
      }, [])
      .map((activity) => {
        const { id, start_at, title, description, location } = activity
        let participantGroup = workshopsGroups.find(
          (g) => g.activities.includes(id) && g.participants.includes(participant.id)
        )
        if (
          participantGroup &&
          participantGroup.participants &&
          !!participantGroup.participants.length
        ) {
          participantGroup = {
            ...participantGroup,
            participants: [...participantGroup.participants].map((pId) => {
              return participants.find((p) => p.id === pId)
            })
          }
        }
        return {
          time: format(new Date(start_at), 'dd MMM - HH:mm'),
          title,
          description,
          location,
          participantGroup
        }
      })
  }

  useEffect(() => {
    const fetchHandler = async ({ mustFetchAgenda, mustFetchAccommodation, mustFetchRoom }) => {
      try {
        const agendaPromise = smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'agenda',
          id: offsite.agendas[0]
        })
        const accommodationPromise = smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'accommodation',
          id: participant.accommodation
        })

        const roomPromise = smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'offsite',
          id: offsite.id,
          action: `rooms/${participant.room}/`
        })

        const [agendaResponse, accommodationResponse, roomResponse] = await Promise.all([
          mustFetchAgenda ? dispatch(agendaPromise) : Promise.resolve(null),
          mustFetchAccommodation ? dispatch(accommodationPromise) : Promise.resolve(null),
          mustFetchRoom ? dispatch(roomPromise) : Promise.resolve(null)
        ])
        if (agendaResponse && agendaResponse.data) {
          const { activities } = agendaResponse.data
          const agenda = getAgendaSorted(getActivitiesByDay(activities))
          setAgenda(agenda)
          setRawAgenda(getActivitiesSorted(activities))
          const workshops = activities.filter(
            (a) => a.location && a.location.location_type === 'workshop'
          )
          if (
            !!workshops.length &&
            !!participant.id &&
            workshops.some((w) => w.groups && w.groups.length)
          ) {
            const workshopsGroups = await dispatch(
              smartwayApi.endpoints.fetchEntity.initiate({
                entity: 'activityGroup',
                activities: Array.from(new Set(workshops.map((w) => w.id))).join(',')
              })
            )
            if (workshopsGroups && workshopsGroups.data && workshopsGroups.data.results) {
              setWorkshopsGroups(workshopsGroups.data.results)
            }
          }
        }
        if (accommodationResponse && accommodationResponse.data) {
          const newAccommodations = {
            ...accommodations,
            [participant.accommodation]: accommodationResponse.data
          }
          setAccommodations(newAccommodations)
        }
        if (roomResponse && roomResponse.data) {
          const newRooms = { ...rooms, [participant.room]: roomResponse.data }
          setRooms(newRooms)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        setLoading(false)
      }
    }

    if (open) {
      const mustFetchAgenda = !agenda && offsite?.agendas && offsite.agendas[0]
      const mustFetchAccommodation =
        participant && participant.accommodation && !accommodations[participant.accommodation]
      const mustFetchRoom = participant && participant.room && !rooms[participant.room]
      fetchHandler({ mustFetchAgenda, mustFetchAccommodation, mustFetchRoom })
    }
  }, [open, agenda])

  useEffect(() => {
    if (open && participant && agenda && rawAgenda) {
      const participantAgenda = getParticipantAgenda(participant, agenda)
      const participantExperiences = getParticipantExperiences(participant, rawAgenda)
      const participantCaterings = getParticipantCaterings(participant, rawAgenda)

      setParticipantAgenda(participantAgenda)
      setParticipantExperiences(participantExperiences)
      setParticipantCaterings(participantCaterings)
      if (workshopsGroups && workshopsGroups.length) {
        const participantWorkshops = getParticipantWorkshops(participant, rawAgenda)
        setParticipantWorkshops(participantWorkshops)
      }
    }
  }, [participant, open, agenda, rawAgenda, workshopsGroups])

  return (
    <Dialog
      key={open}
      open={open}
      scroll="paper"
      maxWidth="lg"
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        marginLeft: { xl: '250px' },
        'div.dialogContent': {
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#eee',
            borderRadius: '2px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '2px'
          }
        }
      }}
      onClose={handleOnClose}>
      <DialogTitle id="scroll-dialog-title">
        <MDBox px={2}>
          Offsite itinerary
          <MDBox display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton
              aria-label="close"
              onClick={handlePrint}
              sx={{
                position: 'absolute',
                right: 60,
                top: 12,
                color: (theme) => theme.palette.grey[800]
              }}>
              <Icon>picture_as_pdf</Icon>
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={handleOnClose}
              sx={{
                position: 'absolute',
                right: 28,
                top: 12,
                color: (theme) => theme.palette.grey[500]
              }}>
              <Icon>close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </DialogTitle>

      <DialogContent dividers className="dialogContent">
        <div ref={pageRef}>
          <style type="text/css" media="print">
            {'\
            @page { size: portrait; }\
          '}
          </style>
          <Container>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <GeneralInfos participant={participant} />

              <ParticipantPreferences
                offsite={offsite}
                participant={participant}
                options={options}
              />

              {loading ? (
                <MDBox>
                  <ItinerarySkeleton />
                </MDBox>
              ) : (
                <>
                  {accommodations &&
                  Object.keys(accommodations).length &&
                  participant.accommodation &&
                  accommodations[participant.accommodation] ? (
                    <ParticipantAccommodation
                      participant={participant}
                      participants={participants}
                      room={rooms[participant.room]}
                      accommodation={accommodations[participant.accommodation]}
                    />
                  ) : null}

                  {participantAgenda ? (
                    <ParticipantAgenda key={resetAccordions} agenda={participantAgenda} />
                  ) : null}
                  {participantExperiences ? (
                    <ParticipantExperiences experiences={participantExperiences} />
                  ) : null}
                  {participantCaterings ? (
                    <ParticipantCaterings caterings={participantCaterings} />
                  ) : null}
                  {participantWorkshops && !!participantWorkshops.length ? (
                    <ParticipantWorkshops workshops={participantWorkshops} />
                  ) : null}
                </>
              )}
            </DialogContentText>
          </Container>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ParticipantItineraryDialog
