import { useState, useEffect, useCallback } from 'react'

import { smartwayApi } from 'services/api'

import { useDispatch, useSelector } from 'react-redux'
import { setAuthenticated, setProfile, setGroups } from 'store/reducers/AuthSlice'
import useInterval from './useInterval'

const useAuthentication = ({ pathname }) => {
  const profile = useSelector(state => state.auth.profile)
  const groups = useSelector(state => state.auth.groups)

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(undefined)
  const [step, setStep] = useState(0)

  useInterval(() => {
    console.debug('[FIREBASE] Check token')
    dispatch(smartwayApi.endpoints.verifyToken.initiate(null, { forceRefetch: true }))
  }, 30000)

  const checkToken = useCallback(async () => {
    const tokenData = await dispatch(smartwayApi.endpoints.verifyToken.initiate(null, { forceRefetch: true }))
    if (tokenData.status === 'fulfilled' && tokenData.data && !tokenData.data.unauthorized) {
      setStep(1)
    } else {
      setIsLoading(false)
      setIsAuthenticated(false)
      setError(tokenData.error)
      dispatch(smartwayApi.util.resetApiState())
    }
  }, [dispatch])

  // FIRST USE EFFECT TO CHECK TOKEN
  useEffect(() => {
    checkToken()
  }, [pathname, checkToken])


  const checkData = useCallback(async () => {
    if (step === 1) {
      if (!profile || (profile && profile.error) || Object.keys(profile).length <= 1) {
        const profileData = await dispatch(smartwayApi.endpoints.fetchBaseProfile.initiate(null, { forceRefetch: true }))
        if (profileData.status === 'fulfilled' && profileData.data && !profileData.data.unauthorized) {
          dispatch(setProfile(profileData.data))
          if (profileData.data.is_staff && (!groups || (groups && groups.error))) {
            const initData = await dispatch(smartwayApi.endpoints.fetchInit.initiate(null, { forceRefetch: true }))
            if (initData.status === 'fulfilled' && initData.data && !initData.data.unauthorized) {
              dispatch(setGroups(initData.data.groups))
            }
          }
        }
      }
      if (profile && profile.is_staff && (!groups || (groups && groups.error))) {
        const initData = await dispatch(smartwayApi.endpoints.fetchInit.initiate(null, { forceRefetch: true }))
        if (initData.status === 'fulfilled' && initData.data && !initData.data.unauthorized) {
          dispatch(setGroups(initData.data.groups))
        }
      }
      setStep(2)
    }
  }, [dispatch, step, profile, groups])
  
  // IF TOKEN IS VALID WE CHECK THE DATA
  useEffect(() => {
    if (step === 1) {
      checkData()
    }
  }, [step, checkData])
  
  useEffect(() => {
    const isGroups = !!profile && profile.is_staff ? !!groups : true
    if (step === 2 && !!profile && isGroups) {
      setIsLoading(false)
      setIsAuthenticated(true)
      dispatch(setAuthenticated(true))
    }
  }, [step, dispatch, profile, groups])


  return {
    isLoading,
    isAuthenticated,
    profile,
    isError: !!error,
    error: error
  }
}

export default useAuthentication