const defaultInputsProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true
  }
}

const getSchema = ({ offsite }) => {
  const { status } = offsite
  const disabled = status.includes('SIGNED_CONTRACT')
  return {
    id: 'offer-step-form',
    ctasPosition: 'bottom',
    fieldsets: [
      {
        id: 'general',
        title: 'Price',
        sx: { justifyContent: 'space-between' },
        fields: [
          {
            type: 'number',
            label: 'Price per person',
            name: 'price_per_pax',
            suffix: '€',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 },
            disabled,
            ...defaultInputsProps
          },
          {
            type: 'number',
            label: 'Discount',
            name: 'discount',
            validationType: 'number',
            validations: null,
            suffix: '%',
            disabled,
            grid: { xs: 6 },
            ...defaultInputsProps
          },
          {
            type: 'number',
            label: 'Nb of participants (indicative)',
            name: 'indicative_number_participants',
            validationType: 'number',
            validations: null,
            suffix: 'pp',
            disabled,
            grid: { xs: 6, mr: 10 },
            ...defaultInputsProps
          },
          {
            type: 'textarea',
            label: 'Payment policy',
            name: 'payment_policy',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Payment policy is too long. Keep it under 2000 characters']
              }
            ],
            hideToolbar: true,
            disabled,
            ...defaultInputsProps
          },
         
          {
            type: 'checkbox',
            label: 'Show economics in the offer',
            name: 'show_economics',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 6 },
            ...defaultInputsProps
          }
        ]
      },
      {
        id: 'offer-offer',
        title: 'Offer informations',
        fields: [
          {
            type: 'datepicker',
            name: 'valid_by',
            validationType: 'string',  
            validations: [
              {
                type: 'nullable'
              }
            ],
            label: 'Offer valid before',
            disabled,
            inputProps: {
              required: true,
              label: 'Offer valid before',
              placeholder: 'Select date',
              ...defaultInputsProps
            },
            grid: { xs: 6, mb: 4 },
            ...defaultInputsProps
          },
          {
            type: 'datepicker',
            name: 'last_offer_sent',
            validationType: 'string',
            validations: [
              {
                type: 'nullable'
              }
            ],
            label: 'Last offer sent',
            disabled,
            inputProps: {
              // required: true,
              label: 'Last offer sent',
              placeholder: 'Select date',
              ...defaultInputsProps
            },
            grid: { xs: 6, mb: 4 },
            ...defaultInputsProps
          },
          
          {
            type: 'select',
            name: 'related_offers',
            label: 'Related offers',
            multiple: true,
            disabled,
            optionsGetter: {
              endpoints: [
                {
                  entity: 'offsite',
                }
              ]
            },
            labelProp: 'title',
            valueProp: 'id',
            validationType: 'array',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ],
            grid: { xs: 12 },
            ...defaultInputsProps
          },
        ]
      },
      {
        id: 'offer-services',
        title: 'Services',
        fields: [
          {
            type: 'inputlist',
            label: 'Service in',
            name: 'service_in',
            validationType: 'string',
            validations: null,
            disabled,
            ...defaultInputsProps
          },
          {
            type: 'inputlist',
            label: 'Service out',
            name: 'service_out',
            validationType: 'string',
            validations: null,
            disabled,
            ...defaultInputsProps
          },
        ]
      },
      {
        id: 'offer-communication',
        title: 'Communication',
        fields: [
          {
            type: 'textarea',
            label: 'Offer description',
            name: 'offer_description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Offer description is too long. Keep it under 2000 characters']
              }
            ],
            disabled,
          },
          {
            type: 'textarea',
            label: 'Private notes',
            name: 'offer_private_note',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'max',
                params: [2000, 'Private notes is too long. Keep it under 2000 characters']
              }
            ],
            disabled,
            ...defaultInputsProps
          },
        ]  
      }
    ],
    submitButton: {
      label: 'Save changes',
      fullWidth: false,
      variant: 'gradient',
      color: 'primary',
      disabled,
      sx: {
        padding: '10px 24px'
      }
    }
  }
}

export default getSchema