import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { smartwayApi } from 'services/api/index'

import { styled } from '@mui/material/styles'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MDBox from 'components/MDBox/index'
import MDTypography from 'components/MDTypography/index'

const CustomToggleButton = styled(ToggleButton)(({ theme, size }) => {
  const {
    palette,
    functions: { pxToRem }
  } = theme

  const sizes = {
    small: {
      padding: `${pxToRem(2)} ${pxToRem(8)}`,
      marginRight: pxToRem(5),
      borderRadius: '10px !important',
      fontSize: '12px',
    },
    medium: {
      padding: `${pxToRem(6)} ${pxToRem(16)}`,
      marginRight: pxToRem(10),
      borderRadius: '17px !important',
      fontSize: '15px',

    },

  }

  return {
    ...sizes[size],
    marginLeft: '0 !important',
    border: '1px solid !important',
    borderColor: 'secondary',
    textTransform: 'none',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '&.Mui-selected': {
      backgroundColor: palette.grey['700'],
      color: palette.white.main,
      borderColor: palette.grey['700'],
      '&:hover': {
        backgroundColor: palette.grey['600'],
        color: palette.white.main
      }
    }
  }
})

const Badges = ({
  name,
  label,
  multiple,
  value,
  initialValue,
  options,
  optionsGetter,
  labelProp,
  valueProp,
  size = 'medium',
  sx = {},
  onChange
}) => {
  const formatOptions = useCallback(
    (optionsToMap) => {
      return optionsToMap && optionsToMap.length
        ? optionsToMap.map(({ label, value, entity, ...opt }) => ({
            label: label || opt[labelProp],
            value: value || opt[valueProp],
            entity
          }))
        : null
    },
    [labelProp, valueProp]
  )
  const getValues = useCallback(() => {
    if (initialValue && !value) {
      return initialValue
    }
    if (multiple) {
      return value
    }
    return value
  } , [initialValue, multiple, value])

  const dispatch = useDispatch()
  const [selectOptions, setSelectOptions] = useState(
    formatOptions(options) || []
  )
  const [values, setValues] = useState(getValues())

  const handleChange = (e, newValues) => {
    setValues(newValues)
    onChange({
      target: {
        name,
        value: newValues
      }
    })
  }

  const getAsyncOptions = useCallback(async () => {
    let allResults = []
    for (let i = 0; i < optionsGetter.endpoints.length; i++) {
      const endpoint = optionsGetter.endpoints[i]
      const params = endpoint.params || {}
      const idParam = endpoint.id || null
      const response = await dispatch(
        smartwayApi.endpoints.fetchAllEntities.initiate({
          entity: endpoint.entity,
          id: idParam,
          ...params
        })
      )
      const entityResults =
        response.status === 'fulfilled' && response.data ? response.data : []
      allResults = [
        ...allResults,
        ...entityResults.map((r) => ({ ...r, entity: endpoint.name }))
      ]
    }
    const newOptions = formatOptions(allResults)
    setSelectOptions(newOptions)
  }, [dispatch, formatOptions, optionsGetter?.endpoints])

  useEffect(() => {
    if (!options && !!optionsGetter) {
      getAsyncOptions()
    }
  }, [getAsyncOptions, options, optionsGetter])

  useEffect(() => {
    setValues(getValues())
  }, [getValues, value])

  return (
    <MDBox
      mb={3}
      sx={{ overflow: 'scroll', '::-webkit-scrollbar': { display: 'none' }, ...sx }}>
      {label ? (
        <MDTypography variant="subtitle2" sx={{ fontSize: 14 }} mb={1.5}>
          {label}
        </MDTypography>
      ) : null}
      {selectOptions && selectOptions.length ? (
        <ToggleButtonGroup
          value={values}
          exclusive={!multiple}
          onChange={handleChange}>
          {selectOptions.map((option, index) => (
            <CustomToggleButton key={`${option.value}-${index}`} value={option.value} size={size}>
              {option.label}
            </CustomToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : null}
    </MDBox>
  )
}

export default Badges
