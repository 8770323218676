import DataTable from 'components/Tables/DataTable'
import PageActionsCell from './PageActionsCell'

const PagesTableView = ({ pages, onDelete, onClone }) => {

  const table = {
    columns: [
      { Header: 'id', accessor: 'id' },
      { Header: 'slug', accessor: 'slug' },
      { Header: 'template', accessor: 'template' },
    ],
    rows: pages.map(
      (page) => {
        const { id, slug, template } = page
        return {
          __data: page,
          id,
          slug,
          template: template || '',
        }
      }
    )
  }

  const handleDeletePage = (page) => {
    onDelete(page.id)
  }

  const handleClonePage = (page) => {
    onClone(page.id)
  }

  return (
    <DataTable
        table={table}
        actionsOnRowHover={
          <PageActionsCell onClone={handleClonePage} onDelete={handleDeletePage} />
        }
      />
  )

}

export default PagesTableView