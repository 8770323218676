import { useState, useEffect } from 'react'
import { addDays } from 'date-fns'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import LoadingButton from '@mui/lab/LoadingButton'

import Form from 'components/Form/Form'

import getSchema from './schemas/offsite-transformation'

const OffsiteTransformationDialog = ({ open, offer, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [schema, setSchema] = useState(getSchema({ values: offer }))

  const initialValues = {
    start_at: new Date(),
    end_at: offer?.indicative_number_days ? addDays(new Date(), offer.indicative_number_days) : addDays(new Date(), 1)
  }

  const resetState = () => {
    setIsLoading(false)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleOnSave = async (values) => {
    const { start_at_end_at, ..._values } = values
    const { company } = offer
    setIsLoading(true)
    if (onSave && typeof onSave === 'function') {
      await onSave({ ..._values, company })
      setIsLoading(false)
    }
  }

  const handleFieldChange = (_, __, formik) => {
    const { errors } = formik
    if (!!Object.keys(errors).length) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }


  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>The client has signed the contract?!</DialogTitle>
        <MDTypography pl={2} variant="body2">By saving this offer it will become an offsite with a status of "Contract signed".<br/> Please note that when an offsite has the status "Contract signed" you can not change some key informations of the event, like the dates, the place and the company.</MDTypography>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Form
            {...schema}
            onFieldChange={handleFieldChange}
            values={initialValues}
            onSubmit={handleOnSave}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            sx={{
              color: 'white !important',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' }
            }}
            form="offsite-transformation-form"
            type="submit"
            variant="contained"
            disabled={isError || isLoading}
            loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default OffsiteTransformationDialog
