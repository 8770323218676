/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react'

const routesAuthorized = {
  '\/profile\/?$': 'view_userprofile',
  '\/dashboard\/?$': 'view_userprofile',
  '\/notifications\/?$': 'view_userprofile',
  '\/locations\/?$': 'view_town',
  '\/locations\/towns\/new\/?': 'add_town',
  '\/locations\/towns\/[\\S+]': 'change_town',
  '\/locations\/towns\/?$': 'view_town',
  '\/locations\/all-in-venues\/new\/?': 'add_allinvenue',
  '\/locations\/all-in-venues\/[\\S+]': 'change_allinvenue',
  '\/locations\/all-in-venues\/?$': 'view_allinvenue',
  '\/locations\/accommodations\/new\/?': 'add_accommodation',
  '\/locations\/accommodations\/[\\S+]': 'change_accommodation',
  '\/locations\/accommodations\/?$': 'view_accommodation',
  '\/locations\/venue-accommodations\/new\/?': 'add_allinvenueaccommodation',
  '\/locations\/venue-accommodations\/[\\S+]': 'change_allinvenueaccommodation',
  '\/locations\/venue-accommodations\/?$': 'view_allinvenueaccommodation',
  '\/locations\/experiences\/new\/?': 'add_experience',
  '\/locations\/experiences\/[\\S+]': 'change_experience',
  '\/locations\/experiences\/?$': 'view_experience',
  '\/locations\/workshops\/new\/?': 'add_experience',
  '\/locations\/workshops\/[\\S+]': 'change_experience',
  '\/locations\/workshops\/?$': 'view_experience',
  '\/locations\/caterings\/new\/?': 'add_catering',
  '\/locations\/caterings\/[\\S+]': 'change_catering',
  '\/locations\/caterings\/?$': 'view_catering',
  '\/locations\/coworkings\/new\/?': 'add_coworking',
  '\/locations\/coworkings\/[\\S+]': 'change_coworking',
  '\/locations\/coworkings\/?$': 'view_coworking',
  '\/locations\/event-spaces\/new\/?': 'add_plenaryhall',
  '\/locations\/event-spaces\/[\\S+]': 'change_plenaryhall',
  '\/locations\/event-spaces\/?$': 'view_plenaryhall',
  '\/premade-packages\/new\/?': 'add_premadepackage',
  '\/premade-packages\/[\\S+]': 'change_premadepackage',
  '\/premade-packages\/?$': 'view_premadepackage',
  '\/companies\/new\/?': 'add_company',
  '\/companies\/[\\S+]': 'change_company',
  '\/companies\/?$': 'view_company',
  '\/offsites\/?$': 'view_offsite',
  'offsites\/(?:[1-9][0-9]{0,3})\/review\/?$': 'view_offsite',
  '\/offsites\/new\/?': 'add_offsite',
  '\/offsites\/[\\S+]': 'change_offsite',
  '\/offers-offsites\/?$': 'view_offsite',
  '\/offers-offsites\/new\/?': 'add_offsite',
  '\/offers-offsites\/[\\S+]': 'change_offsite',
  '\/users\/?$': 'view_user',
  '\/users\/[\\S+]': 'change_user',
  '\/pages\/?$': 'view_page',
  '\/pages\/new\/?': 'add_page',
  '\/pages\/[\\S+]': 'change_page',
}

const specificRoutesAuthorizations = {
  offsiteManager: [
    '\/offsites\/?$',
    'offsites\/(?:[1-9][0-9]{0,3})\/review\/?$',
    '\/offsites\/[\\S+]',
    '\/offers-offsites\/?$',
    '\/offers-offsites\/[\\S+]'
  ]
}

const useAuthorization = ({ pathname, isAuthenticated, profile }) => {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isAuthenticated && !!profile) {
      for (const [reg, permission] of Object.entries(routesAuthorized)) {
        const regex = new RegExp(reg)
        // if (profile && profile.permissions) {
        //   console.debug('- useAuthorization -', pathname, regex, pathname.match(regex), permission)
        // }
        const isOffsiteManager = profile.offsites && !!profile.offsites.length && profile.offsites.some(o => o.your_role !== 'PARTICIPANT')
        const staffAuthorization = profile.is_staff
        const normalAuthorization = pathname.match(regex) && profile.permissions && Array.isArray(profile.permissions) && profile.permissions.includes(permission)
        const specificAuthorization = isOffsiteManager && specificRoutesAuthorizations.offsiteManager.includes(reg)
        if (staffAuthorization || normalAuthorization || specificAuthorization) {
          setIsAuthorized(true)
          break
        } else {
          setIsAuthorized(false)
        }
      }
      setIsLoading(false)
    } else if (isAuthenticated !== undefined && !isAuthenticated) {
      setIsAuthorized(false)
      setIsLoading(false)
    }
  }, [isAuthenticated, pathname, profile])


  return {isAuthorized, isLoading}
}

export default useAuthorization

