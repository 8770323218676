import { Suspense, useState, useEffect } from 'react'

import { Await, Link, useLoaderData, useSubmit, useSearchParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import Pagination from 'components/Pagination'
import { CardsSkeleton, FiltersButtonSkeleton } from './Skeletons'
import PackageCard from './PackageCard'
import PackagesTableView from './PackagesTableView'

import FiltersDialog from 'components/Dialogs/FiltersDialog'
import { FilterButton } from 'components/Filters/Filters'
import { convertQueryStringToFilters, getActiveFiltersNumber } from 'utils/filters'

import noResults from 'assets/illustrations/noresults.svg'

const PackagesListPage = () => {
  const { results, initialFilters } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const [view, setView] = useState('grid')
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [cloneConfirmationOpen, setCloneConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [markedForClone, setMarkedForClone] = useState(null)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  const openFiltersDialog = () => setFiltersDialogOpen(true)
  const closeFiltersDialog = () => setFiltersDialogOpen(false)

  const handleConfirmDeletePackage = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'delete', encType: 'application/json' }
    )
  }
  const handleConfirmClonePackage = () => {
    submit(
      {
        intent: 'clonePackage',
        entity: 'package',
        id: markedForClone.id,
        title: markedForClone.title
      },
      { method: 'post', encType: 'application/json' }
    )
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setCloneConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleDeletePackage = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }
  const handleClonePackage = ({ id, title }) => {
    setCloneConfirmationOpen(true)
    setMarkedForClone({ id, title })
  }

  const handleApplyFilters = (activeFilters) => {
    closeFiltersDialog()
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Package deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (searchParams.get('copied')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'copied') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Premade package copied successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  useEffect(() => {
    if (!!initialFilters) {
      const applied = convertQueryStringToFilters(searchParams, initialFilters)
      const activeFiltersNumber = getActiveFiltersNumber(applied, initialFilters)
      setAppliedFilters(applied)
      setActiveFiltersNumber(activeFiltersNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, initialFilters])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete this package?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleConfirmDeletePackage}
        onCancel={handleCancelConfirmation}
      />
      <ConfirmationDialog
        title={`Are you sure you want to copy this package?`}
        message="You will copy this package as is and will have to modify every detail after copying."
        open={cloneConfirmationOpen}
        setOpen={setCloneConfirmationOpen}
        onConfirm={handleConfirmClonePackage}
        onCancel={handleCancelConfirmation}
      />
      <Suspense>
        <Await resolve={initialFilters}>
          {(initialFilters) => (
            <FiltersDialog
              open={filtersDialogOpen}
              initialFilters={initialFilters}
              appliedFilters={appliedFilters}
              searchParams={searchParams}
              onApply={handleApplyFilters}
              onClose={closeFiltersDialog}
            />
          )}
        </Await>
      </Suspense>
      <MDBox sx={{ margin: 'auto' }}>
        <Grid mt={6} container justifyContent="space-between">
          <Suspense fallback={<FiltersButtonSkeleton />}>
            <Await resolve={initialFilters}>
              {() =>
                initialFilters ? (
                  <FilterButton activeFiltersNumber={activeFiltersNumber}>
                    <MDButton
                      color="white"
                      onClick={openFiltersDialog}
                      sx={{
                        border: !!activeFiltersNumber ? '2px solid grey' : '2px solid transparent'
                      }}>
                      Filters &nbsp;<Icon>tunesharp</Icon>
                    </MDButton>
                  </FilterButton>
                ) : (
                  <div></div>
                )
              }
            </Await>
          </Suspense>
          <MDBox display="flex">
            <Link to={`/dashboard/premade-packages/new`}>
              <MDButton color="primary">
                <Icon>add</Icon>&nbsp; Create a premade package
              </MDButton>
            </Link>
            <ButtonGroup sx={{ ml: 2 }}>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'grid' ? 'primary' : 'secondary'}
                onClick={() => setView('grid')}>
                <Icon>grid_view</Icon>
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'table' ? 'primary' : 'secondary'}
                onClick={() => setView('table')}>
                <Icon>view_list</Icon>
              </MDButton>
            </ButtonGroup>
          </MDBox>
        </Grid>
        <Suspense fallback={<CardsSkeleton />}>
          <Await resolve={results}>
            {({ results, page_number, count }) => {
              const pagination = {
                page: searchParams.get('page') || 1,
                lastPage: page_number || Math.ceil(count / 10) || 1,
                count,
                pageSize: parseInt(searchParams.get('page_size')) || 10
              }
              const packages = results && results.length ? results : []

              return view === 'grid' ? (
                <>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {packages.length ? (
                      packages.map((result) => {
                        return (
                          <Grid key={result.id} item xs={12} md={6}>
                            <PackageCard
                              {...result}
                              onClonePackage={handleClonePackage}
                              onDeletePackage={handleDeletePackage}
                            />
                          </Grid>
                        )
                      })
                    ) : (
                      <Grid item xs={12}>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="50vh">
                          <MDBox
                            component="img"
                            src={noResults}
                            alt="no results"
                            width="100%"
                            maxHeight={200}
                            mb={3}
                          />
                          <MDTypography variant="h5">No results found!</MDTypography>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                  {pagination && pagination.lastPage > 1 && !!results.length ? (
                    <Grid container justifyContent="center" marginTop={6}>
                      <Pagination
                        page={pagination.page || 1}
                        lastPage={pagination.lastPage}
                        searchParams={searchParams}
                      />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <MDBox mt={2}>
                  <PackagesTableView
                    packages={packages}
                    pagination={pagination}
                    onClone={handleClonePackage}
                    onDelete={handleDeletePackage}
                  />
                </MDBox>
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
    </>
  )
}

export default PackagesListPage
