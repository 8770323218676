import { useState, useEffect, useCallback } from 'react'

import useBunnyStorage from 'hooks/useBunnyStorage'

import MDBox from 'components/MDBox'
import Icon from '@mui/material/Icon'
import MDTypography from 'components/MDTypography'
import ImageGalleryDialog from 'components/Dialogs/ImageGalleryDialog'
import ImageComponent from './ImageComponent'

const ImageUploadBunny = ({
  value,
  label,
  containerStyles = {},
  offsiteId,
  onChange,
  ...props
}) => {
  const [uploadLoading, setUploadLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { images, isLoading, isError, uploadImage, getImageUrl } = useBunnyStorage({
    offsiteId
  })

  const openDialog = () => {
    setDialogOpen(true)
  }
  const closeDialog = () => {
    setDialogOpen(false)
  }

  const handleOnChange = async (name, file, e) => {
    setUploadLoading(true)
    try {
      const response = await uploadImage({ file })
      if (response && response.status === 'success') {
        onChange({ target: { name, value: response.filename, needTouched: true } })
      }
    } catch (error) {
      
    } finally {
      setUploadLoading(false)
    }
  }

  const handleOnSelect = (image) => {
    onChange({ target: { name: props.name, value: getImageUrl(image), needTouched: true } })
  }

  const handleOnDelete = useCallback(() => {
    setImage(null)
    onChange({ target: { name: props.name, value: '', needTouched: true } })
  }, [onChange, props.name])

  useEffect(() => {
    setImage(value)
  }, [value])

  return (
    <MDBox display="flex" justifyContent="center" mb={3} flexDirection="column" >
      {label ? (
        <MDBox width="40%" minWidth="240px" maxWidth="450px" sx={containerStyles}>
          <MDTypography variant="button" fontWeight="regular" color="text" ml={0.5} mb={1}>
            {label}
          </MDTypography>
        </MDBox>
      ) : null}
      {image ? (
        <MDBox width="40%" minWidth="240px" maxWidth="450px" minHeight="180px" sx={containerStyles}>
          <ImageComponent image={image} editable onEdit={openDialog} onDelete={handleOnDelete} />
        </MDBox>
      ) : (
        <MDBox
          width="40%"
          minWidth="240px"
          maxWidth="450px"
          height="180px"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed grey',
            borderRadius: 4,
            cursor: 'pointer',
            ...containerStyles
          }}
          onClick={openDialog}>
          <MDBox
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <MDTypography variant="body2">Add Image</MDTypography>
            <MDBox>
              <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
                image
              </Icon>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      <MDBox>
        <ImageGalleryDialog
          open={dialogOpen}flex-start
          images={images}
          selected={image}
          isLoading={isLoading}
          isError={isError}
          uploadProps={props}
          onImageSelect={handleOnSelect}
          onImageAdded={handleOnChange}
          onClose={closeDialog}
        />
      </MDBox>
    </MDBox>
  )
}

export default ImageUploadBunny
