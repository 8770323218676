const filters = {
  name: 'catering-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the place you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Town / Venue',
      subtitle: 'Choose the town or the venue of the working space',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Type of catering',
      subtitle: 'Select the category of the catering you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: { 
            endpoints: [
              {
                entity: 'locationCategories', id: 'catering'
              }
            ]
          },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Dietary options',
      subtitle: 'Select the dietary options of the catering you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'dietary_options',
          multiple: true,
          labelProp: 'name',
          valueProp: 'id',
          optionsGetter: { 
            endpoints: [
              {
                entity: 'dietaryOptions'
              }
            ]
          },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the catering is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters