import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Link } from 'react-router-dom'

const CompanyWall = ({ profile, context, children }) => {
  const [hideWall, setHideWall] = useState(context === 'user' || (profile.company && profile.company.id))

  useEffect(() => {
    if (profile && profile.isFull) {
      setHideWall(context === 'user' || (profile.company && profile.company.id))
    }
  }, [profile])
  return hideWall ? (
    <>{children}</>
  ) : (
    <Grid
      item
      sx={{
        position: 'relative',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // flexDirection: 'column'
      }}>
      
      <MDBox
        sx={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 11,
          width: '30%',
          marginTop: '100px',
          marginLeft: '15%',
          // transform: 'translateX(50%)',
          px: 6,
          py: 3,
          background: 'white',
          borderRadius: '10px',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
        }}>
        <MDTypography variant="h5" color="black" textAlign="center">
          Wait!
        </MDTypography>
        <MDTypography variant="h6" color="text" textAlign="center" py={2}>
          It seems that you're not associated to any company yet. Please create a company in order
          to get fully started!
        </MDTypography>

        <Link to="/dashboard/companies/new" style={{ width: '100%' }}>
          <MDButton variant="contained" color="primary" fullWidth>
            Create your company
          </MDButton>
        </Link>
      </MDBox>
      <div
        style={{
          position: 'absolute',
          content: '""',
          left: '16px',
          right: 0,
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 10,
          backdropFilter: 'blur(8px)',
          background: 'rgba(255, 255, 255, 0.15)',
          pointerEvents: 'none',
          userSelect: 'none'
        }}></div>
      <Grid container spacing={3} sx={{ zIndex: -1, pointerEvents: 'none', userSelect: 'none' }}>
        {children}
      </Grid>
    </Grid>
  )
}

export default CompanyWall
