import { useState } from 'react'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import accommodationIllustration from 'assets/illustrations/accommodation-secondary.svg' 
import { getBestFormat, getMainImage } from 'utils/image'

const AcccommodationSmallCard = ({ iconStyles, onRemove, ...accommodation }) => {
  const { name, images, normal_price, capacity, position } = accommodation
  const mainImage = images && images.length ? getMainImage(images) : null
  const image = mainImage && mainImage.formats ? getBestFormat(mainImage.formats, 'thumbnail') : null
  const [cardImage, setCardImage] = useState(image || accommodationIllustration)
  const _position = position > 0 ? `Ouside (${position / 1000}km)` : 'Center'

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(accommodation)
    }
  }

  return (
    <Card sx={{ boxShadow: '0px 0px 12px 2px rgba(0,0,0,0.2)', height: '100%', cursor: 'pointer', position: 'relative', '&:hover': {
      '.accommodation-small-card__icon': {
        opacity: 1,
        visibility: 'visible',
        transform: 'scale(1)'
      }
    }}}>
      <MDBox sx={iconStyles} className="accommodation-small-card__icon">
        <Icon onClick={handleOnRemove}>delete</Icon>
      </MDBox>
      <MDBox p={1} display="flex" height="100%">
        <MDBox
          component="img"
          src={cardImage}
          borderRadius="md"
          width="35%"
          minWidth="35%"
          onError={() => {
            setCardImage(accommodationIllustration)
          }}
          sx={{
            objectFit: 'cover',
            display: 'inline-block',
            position: 'relative',
            minHeight: '100%',
            '::before': {
              content: "''",
              border: 'none',
              width: '100%',
              height: 'calc(100% + 10px)',
              backgroundColor: 'rgba(230, 230, 230, 1)',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)'
            }
          }}
        />
        <MDBox ml={2} sx={{ overflow: 'hidden' }}>
          <MDTypography variant="h6" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: 'normal' }}>{name}</MDTypography>
          { capacity ? (
            <MDTypography variant="button" display="block" mt={1}>Capacity: {capacity}</MDTypography>
          ) : null }
          { normal_price ? (
            <MDTypography variant="button" display="block">Price: {normal_price}</MDTypography>
          ) : null}
          <MDTypography variant="button" display="block">Position: {_position}</MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default AcccommodationSmallCard