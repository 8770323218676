import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
  EmailAuthProvider
} from 'firebase/auth'

import { doc, setDoc, deleteDoc, query, collection, onSnapshot } from 'firebase/firestore'
import { db } from 'services/firebase'

import { setProfile, setNeedProfileUpdate, setNotifications } from 'store/reducers/AuthSlice'

const VERCEL_BUILD_HOOK =
  'https://api.vercel.com/v1/integrations/deploy/prj_z7O6lbmqglBrAnHpAlkoWKqPjNEh/cecDx5cWpA'

// const getParsedJwt = (token) => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]))
//   } catch (error) {
//     return undefined
//   }
// }

export const triggerVercelBuild = async () => {
  const request = await fetch(VERCEL_BUILD_HOOK, {
    method: 'POST'
  })
  const response = await request.json()
  return response
}

export async function processPromisesBatch(promises, limit) {
  let results = []
  for (let start = 0; start < promises.length; start += limit) {
    const end = start + limit > promises.length ? promises.length : start + limit

    const slicedResults = await Promise.all(promises.slice(start, end))

    results = [...results, ...slicedResults]
  }

  return results
}

export const ENDPOINTS = {
  accommodation: 'locations/accommodations',
  accommodationRoom: 'locations/accommodations',
  activities: 'agenda/activities',
  activityGroup: 'agenda/activity-groups',
  agenda: 'agenda',
  agendaCategories: 'agenda/categories',
  allInVenue: 'locations/all-in-venues',
  catering: 'locations/caterings',
  company: 'companies',
  confirmRegistration: 'registration/activate',
  coworking: 'locations/coworkings',
  dietaryOptions: 'locations/caterings/dietary-options',
  experience: 'locations/experiences',
  foodAllergies: 'locations/caterings/allergies',
  groups: 'accounts/groups',
  link: 'utils/links',
  locationCategories: 'locations/categories',
  locationFeatures: 'locations/features',
  locationPremiumFeatures: 'locations/premium-features',
  locationRanks: 'locations/experiences/ranks',
  offsite: 'offsites',
  package: 'premade-packages',
  packageCategories: 'premade-packages/categories',
  page: 'pages',
  plenary_hall: 'locations/plenary-halls',
  plenaryHall: 'locations/plenary-halls',
  profile: 'accounts/profiles',
  profilesByRole: 'accounts/profiles/by-roles',
  region: 'locations/regions',
  register: 'registration/register',
  resendActivation: 'registration/resend-activation',
  roomCategories: 'locations/rooms/categories',
  roomLevels: 'locations/rooms/levels',
  slugChecker: 'utils/slug-checker',
  login: 'accounts/login',
  logout: 'accounts/logout',
  sessionVerify: 'accounts/session-verify',
  town: 'locations/towns',
  user: 'accounts/users',
  venueAccommodation: 'locations/all-in-venues-accommodations',
  workshop: 'locations/workshops'
}

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    // const bearer = localStorage.getItem('bearer')

    if (!headers.get('Content-Type')) {
      headers.set('Content-Type', 'application/json')
      // This hack allows the Content-Type to be set with the right boundary when it's a multipart/data
    } else if (headers.get('Content-Type') === 'undefined') {
      headers.delete('Content-Type')
    }
    headers.append('ngrok-skip-browser-warning', '69420')
    headers.append('Cache-Control', 'no-cache')
    // headers.append('If-Modified-Since', 'Mon, 02 Sep 2024 12:45:53 GMT')
    headers.set('Accept', 'application/json, text/plain, */*')

    // if (bearer) {
    //   headers.set('Authorization', `Bearer ${bearer}`)
    // }
    return headers
  },
  validateStatus: (response, result) => {
    if (response.status >= 500) {
      return false
    }
    // if ([401, 403].includes(response.status)) {
    //   console.debug(response.status, 'Unauthorized request')
    //   localStorage.removeItem('bearer')
    //   window.location.href = '/authentication/sign-in'
    //   // By default the base query would return an error
    //   // We need to return the result in order to handle the unauthorized request in the protected route
    //   return result
    // }
    if (response.status >= 200 && response.status < 300) {
      return result ? result : { data: {} }
    }
    return result
  },
  credentials: 'include'
})

export const smartwayApi = createApi({
  reducerPath: 'smartwayApi',
  baseQuery: async (...args) => {
    const { data, meta } = await baseQuery(...args)
    if (data && meta?.response?.status === 400) {
      return {
        error: {
          status: 400,
          message: 'Bad request',
          ...(data.error ? data.error : data)
        },
        meta
      }
    }
    if (meta.response && meta.response.status && [401, 403].includes(meta.response.status)) {
      return {
        data: {
          ...data,
          status: meta.response.status,
          unauthorized: true
        },
        meta
      }
    }
    return { data, meta }
  },
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    // AUTH
    login: build.mutation({
      query: ({ email, password }) => {
        return {
          url: `${ENDPOINTS.login}/`,
          method: 'POST',
          body: {
            email,
            password
          }
        }
      },
      transformResponse: (response, meta) => {
        console.log(meta.response)
        const { status } = meta.response
        if (status === 201) {
          return {
            success: true
          }
        }
        return response
      }
      // async queryFn(args, { dispatch }) {
      //   const { email, password } = args

      //   // In case of reset password we must be sure that no previous token is still in use
      //   // localStorage.removeItem('bearer')
      //   // eraseCookie('Bearer')

      //   // const auth = getAuth()

      //   return new Promise((resolve, reject) => {
      //     signInWithEmailAndPassword(auth, email, password)
      //       .then(async (firebaseResponse) => {
      //         const bearer = firebaseResponse.user.accessToken

      //         // Set values to local storage
      //         // localStorage.setItem('bearer', bearer)
      //         // setCookie('Bearer', bearer)

      //         const session = await dispatch(
      //           smartwayApi.endpoints.sessionCookie.initiate({ id_token: bearer })
      //         )

      //         resolve({
      //           data: { email, id_token: bearer }
      //         })
      //       })
      //       .catch((error) => {
      //         let _error = error
      //         if (error.code) {
      //           _error = {
      //             code: error.code,
      //             message: error.message
      //           }
      //         }
      //         reject({ error: _error })
      //       })

      //   })
      // }
    }),

    register: build.mutation({
      query: (args) => ({
        url: `${ENDPOINTS.register}/`,
        method: 'POST',
        body: args
      }),
      transformResponse: (response, meta) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        if (status === 202) {
          return {
            success: true,
            active: true
          }
        }
        if (status === 409) {
          return {
            error: 'This email is already registered'
          }
        }
        return response
      }
    }),
    confirmRegistration: build.query({
      query: ({ token }) => `${ENDPOINTS.confirmRegistration}/${token}/`,
      transformResponse: (response, meta) => {
        const { status } = meta.response
        if (status === 202) {
          return {
            success: true
          }
        }
        return {
          error: response
        }
      }
    }),
    fetchBaseProfile: build.query({
      query: () => `${ENDPOINTS.profile}/me/`,
      transformResponse: (response) => {
        const { groups, profile, ...user } = response
        if (groups) {
          return {
            groups,
            permissions:
              groups && groups.length > 0
                ? groups.reduce((acc, curr) => {
                    curr.permissions.forEach((permission) => acc.push(permission.codename))
                    return acc
                  }, [])
                : ['view_userprofile'],
            ...profile,
            ...user
          }
        } else {
          console.warn('Profile error', response)
          return {
            error: {
              message: 'Profile not found'
            }
          }
        }
      }
    }),
    fetchFullProfile: build.query({
      async queryFn(args, { getState, dispatch }) {
        const state = getState()
        let profile = { ...state.auth.profile }
        // There is still an issue with this needUpdate thing
        // The loader does not wait for the end of the call to update the data
        // So the profile is not updated if multiples calls are made for different properties
        let needUpdate = state.auth.needProfileUpdate || (profile && !profile.isFull)
        if (needUpdate || !profile) {
          // At this point the only possible moment where there could be no profile
          // is when the user land directly on the profile page
          // and the first request is made by the auth.loader.fullProfileLoader
          const { status, data } = await dispatch(
            smartwayApi.endpoints.fetchBaseProfile.initiate(null, {
              forceRefetch: true
            })
          )
          if (status === 'fulfilled' && data) {
            profile = data
            dispatch(setProfile(profile))
            dispatch(setNeedProfileUpdate(false))
          }
        }
        let fullProfile = { ...profile }

        // full profile has already been fetched
        if (profile.isFull) {
          return { data: { success: true } }
        }

        // TODO: get other properties according to groups
        return { data: { ...fullProfile, isFull: !!Object.keys(fullProfile).length } }
      }
    }),
    fetchGroups: build.query({
      query: () => `${ENDPOINTS.groups}/`,
      transformResponse: (response) => response
    }),
    fetchInit: build.query({
      async queryFn(args, { getState, dispatch }) {
        const [groupsResponse] = await Promise.all([
          dispatch(
            smartwayApi.endpoints.fetchGroups.initiate(null, {
              forceRefetch: true
            })
          )
        ])
        if (
          groupsResponse.status === 'fulfilled' &&
          groupsResponse.data &&
          !groupsResponse.data.unauthorized
        ) {
          return { data: { success: true, groups: groupsResponse.data.results } }
        }
        return { error: groupsResponse.error }
      }
    }),
    verifyToken: build.query({
      query: () => `${ENDPOINTS.sessionVerify}/`,
      transformResponse: (response, meta) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        if (status === 401) {
          return {
            unauthorized: true,
            error: { message: 'Your session has expired, please login' }
          }
        }
        return response
      }
    }),
    firestoreSubscribe: build.query({
      async queryFn(args, { dispatch, getState }) {
        const profile = getState().auth.profile
        // Listening to Firestore notifications
        onSnapshot(
          query(collection(db, 'notifications', `${profile.id}`, 'messages')),
          (querySnapshot) => {
            const notifications = []
            querySnapshot.forEach((doc) => {
              notifications.push({ ...doc.data(), id: doc.id })
            })
            // console.log(notifications)
            dispatch(setNotifications(notifications))
          },
          (error) => {
            console.error(error)
          }
        )
        return { data: { success: true } }
      }
    }),
    firestoreUpdate: build.mutation({
      async queryFn({ id, ...data }, { getState }) {
        const profile = getState().auth.profile
        try {
          const docRef = doc(db, 'notifications', `${profile.id}`, 'messages', id)
          await setDoc(docRef, data, { merge: true })
          return { data: { success: true } }
        } catch (error) {
          return { error }
        }
      }
    }),
    firestoreDelete: build.mutation({
      async queryFn({ id }, { getState }) {
        const profile = getState().auth.profile
        try {
          const docRef = doc(db, 'notifications', `${profile.id}`, 'messages', id)
          await deleteDoc(docRef)
          return { data: { success: true } }
        } catch (error) {
          return { error }
        }
      }
    }),
    changePassword: build.mutation({
      async queryFn(args) {
        try {
          const auth = getAuth()
          const user = auth.currentUser
          const credentials = EmailAuthProvider.credential(user.email, args.password)
          return new Promise((resolve) =>
            reauthenticateWithCredential(user, credentials)
              .then(() => {
                updatePassword(user, args['new-password'])
                  .then(() => {
                    resolve({ data: { success: true } })
                  })
                  .catch((error) => {
                    resolve(error)
                  })
              })
              .catch((error) => {
                resolve({ error: { code: error.code, message: error.message } })
              })
          )
        } catch (error) {
          return { error }
        }
      }
    }),
    resetPassword: build.mutation({
      async queryFn(args) {
        try {
          const auth = getAuth()
          await sendPasswordResetEmail(auth, args.email)
          return {
            data: {
              success: true
            }
          }
        } catch (error) {
          console.log(error)
          let _error = error
          if (error.code) {
            _error = {
              code: error.code,
              message: error.message
            }
          }
          return { error: _error }
        }
      }
    }),
    logout: build.mutation({
      query: () => {
        return {
          url: `${ENDPOINTS.logout}/`,
          method: 'POST'
        }
      },
      transformResponse: (response, meta) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        return response
      }
    }),

    // ENTITIES
    fetchEntity: build.query({
      query: ({ entity, id, action, ...params }) => {
        const isParams = !!params && !!Object.keys(params).length
        return `${ENDPOINTS[entity]}/${id ? `${id}/` : ''}${action ? action : ''}${
          isParams ? `?${new URLSearchParams(params).toString()}` : ''
        }`
      }
    }),
    fetchEntities: build.query({
      query: ({ entity, id, action, ...params }) => {
        const isParams = !!params && !!Object.keys(params).length
        return `${ENDPOINTS[entity]}${id ? `/${id}` : ''}${action ? `/${action}` : ''}/${
          isParams ? `?${new URLSearchParams(params).toString()}` : ''
        }`
      },
      transformResponse: (response, meta, { entity, transformResponse }) => {
        if (['profilesByRole', 'user', 'participants'].includes(entity)) {
          const results =
            response && response.results && response.results.length ? response.results : response
          return transformResponse && results
            ? results.map(({ first_name, last_name, ...rest }) => {
                return {
                  ...rest,
                  name: `${first_name} ${last_name}`,
                  profileId: rest.profile.id
                }
              })
            : response
        }
        if (entity === 'agenda') {
          const fullResponse = {
            ...response,
            results:
              response && response.results && response.results.length
                ? response.results.map(({ title, nb_days, ...rest }) => {
                    return {
                      ...rest,
                      title,
                      nb_days,
                      selectTitle: `${title} (${nb_days} day${nb_days > 1 ? 's' : ''})`
                    }
                  })
                : []
          }
          return transformResponse ? fullResponse.results : fullResponse
        }
        if (entity === 'locationRanks') {
          return response.availableRanks.map((opt) => ({
            label: opt,
            value: opt
          }))
        }
        if (
          [
            'locationCategories',
            'packageCategories',
            'agendaCategories',
            'locationFeatures',
            'dietaryOptions'
          ].includes(entity)
        ) {
          return response && response.results
            ? response.results?.map(({ value, ...opt }) => ({
                ...opt,
                key: value
              }))
            : []
        }
        return response
      }
    }),
    fetchAllEntities: build.query({
      async queryFn(args, { dispatch }) {
        const { entity, ...params } = args
        const pagination = params.notPaginated ? {} : { page: 1, page_size: 100 }
        const firstResults = await dispatch(
          smartwayApi.endpoints[params.offsiteId ? 'fetchOffsiteEntity' : 'fetchEntities'].initiate(
            {
              entity,
              ...pagination,
              ...params
            }
          )
        )
        let results
        if (firstResults.data && firstResults.data.links) {
          let next = firstResults.data.links.next
          results = firstResults.data.results
          while (next) {
            let nextPage = new URL(next).searchParams.get('page')
            let otherPagesResults = await dispatch(
              smartwayApi.endpoints[
                params.offsiteId ? 'fetchOffsiteEntity' : 'fetchEntities'
              ].initiate({
                entity,
                page: nextPage,
                page_size: 100,
                ...params
              })
            )
            results = [...results, ...otherPagesResults.data.results]
            next = otherPagesResults.data.links.next
          }
        } else {
          results =
            firstResults.data && firstResults.data.results
              ? firstResults.data.results
              : firstResults.data
        }
        return { data: results }
      }
    }),
    createEntity: build.mutation({
      query: ({ entity, bodyType = 'json', formData, action, body = {}, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: { ...body, ...data } }
        return {
          url: `${ENDPOINTS[entity]}/${action ? action : ''}`,
          method: 'POST',
          ...bodyOptions
        }
      },
      transformResponse: (response, meta, { entity }) => {
        if (entity === 'slugChecker') {
          const { status } = meta.response
          return {
            exists: status === 412
          }
        }
        return response
      },
      refetchOnMountOrArgChange: true
    }),
    updateEntity: build.mutation({
      query: ({ entity, bodyType = 'json', formData, action, id, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: data }
        return {
          url: `${ENDPOINTS[entity]}/${id}/${action ? action : ''}`,
          method: 'PATCH',
          ...bodyOptions
        }
      },
      refetchOnMountOrArgChange: true,
      async onQueryStarted({ entity, ...data }, { dispatch, queryFulfilled }) {
        if (entity === 'profile') {
          try {
            dispatch(setNeedProfileUpdate(true))
          } catch (error) {}
        }
      }
    }),
    deleteEntity: build.mutation({
      query: ({ entity, action, id }) => ({
        url: `${ENDPOINTS[entity]}/${id}/${action ? action : ''}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ entity }, { dispatch, queryFulfilled }) {
        await queryFulfilled
      },
      transformResponse: (response, meta, arg) => {
        const { status } = meta.response
        if ([200, 201, 202, 203, 204].includes(status)) {
          return {
            success: true
          }
        } else {
          return { error: true }
        }
      }
    }),
    // OFFSITE ENTITIES
    fetchOffsiteEntity: build.query({
      query: ({ offsiteId, entity, params, id, ...otherParams }) => {
        // console.log('fetchOffsiteEntity', { entity })
        return `${ENDPOINTS.offsite}/${offsiteId}/${entity}/${id ? `${id}/` : ''}${
          !!params ? `?${new URLSearchParams({ ...params, ...otherParams }).toString()}` : ''
        }`
      },
      transformResponse: (response, meta, { entity, transformResponse, paginated }) => {
        if (transformResponse && entity === 'participants') {
          const results = response.results.map(({ first_name, last_name, ...rest }) => {
            return {
              ...rest,
              name: `${first_name} ${last_name}`
            }
          })
          return paginated
            ? {
                ...response,
                results
              }
            : results
        }
        return response
      }
    }),
    createOffsiteEntity: build.mutation({
      query: ({ offsiteId, entity, action, bodyType = 'json', formData, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: data }
        return {
          url: `${ENDPOINTS.offsite}/${offsiteId}/${entity}/${action ? `${action}/` : ''}`,
          method: 'POST',
          ...bodyOptions
        }
      },
      async onQueryStarted({ entity, ...data }, { dispatch, queryFulfilled }) {
        if (entity === 'participants') {
          try {
            dispatch(setNeedProfileUpdate(true))
          } catch (error) {}
        }
      },
      transformResponse: (response, meta, arg) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        return response
      }
    }),
    updateOffsiteEntity: build.mutation({
      query: ({ offsiteId, entity, id, bodyType = 'json', formData, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: data }
        return {
          url: `${ENDPOINTS.offsite}/${offsiteId}/${entity}/${id}/`,
          method: 'PATCH',
          ...bodyOptions
        }
      },
      async onQueryStarted({ entity, ...data }, { dispatch, queryFulfilled, getState }) {
        const { profile } = getState().auth
        const participantsIds = profile.offsites.map((offsite) => {
          return offsite.participation?.id
        })
        if (entity === 'participants') {
          if (participantsIds.includes(data.id)) {
            try {
              dispatch(setNeedProfileUpdate(true))
            } catch (error) {}
          }
        }
      }
    }),
    deleteOffsiteEntity: build.mutation({
      query: ({ offsiteId, entity, id }) => ({
        url: `${ENDPOINTS.offsite}/${offsiteId}/${entity}/${id}/`,
        method: 'DELETE'
      }),
      transformResponse: (response, meta, arg) => {
        if (!response) {
          return {
            data: {
              success: true
            }
          }
        }
      },
      async onQueryStarted({ entity, ...data }, { dispatch, getState }) {
        const { profile } = getState().auth
        const participantsIds = profile.offsites?.map((offsite) => {
          return offsite.participation?.id
        })
        if (entity === 'participants' && participantsIds.includes(data.id)) {
          try {
            dispatch(setNeedProfileUpdate(true))
          } catch (error) {}
        }
      }
    }),
    // PAGES ENTITIES
    fetchPageEntity: build.query({
      query: ({ pageId, entity, params, id }) => {
        // console.log('fetchOffsiteEntity', { entity })
        return `${ENDPOINTS.page}/${pageId}/${entity}/${id ? `${id}/` : ''}${
          !!params ? `?${new URLSearchParams(params).toString()}` : ''
        }`
      }
    }),
    createPageEntity: build.mutation({
      query: ({ pageId, entity, action, bodyType = 'json', formData, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: data }
        return {
          url: `${ENDPOINTS.page}/${pageId}/${entity}/${action ? `${action}/` : ''}`,
          method: 'POST',
          ...bodyOptions
        }
      },
      transformResponse: (response, meta, arg) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        return response
      }
    }),
    updatePageEntity: build.mutation({
      query: ({ pageId, entity, id, bodyType = 'json', formData, action, ...data }) => {
        const bodyOptions =
          bodyType === 'formData'
            ? { body: formData, formData: true, headers: { 'Content-Type': 'undefined' } }
            : { body: data }
        return {
          url: `${ENDPOINTS.page}/${pageId}/${entity}/${id}/${action ? `${action}/` : ''}`,
          method: 'PATCH',
          ...bodyOptions
        }
      }
    }),
    deletePageEntity: build.mutation({
      query: ({ pageId, entity, id, action }) => ({
        url: `${ENDPOINTS.page}/${pageId}/${entity}/${id}/${action ? `${action}/` : ''}`,
        method: 'DELETE'
      }),
      transformResponse: (response, meta, arg) => {
        if (!response) {
          return {
            data: {
              success: true
            }
          }
        }
      }
    }),
    // IMAGES
    fetchImages: build.query({
      query: ({ id, entity, ...params }) =>
        `${ENDPOINTS[entity]}/${id}/images/${
          !!params ? `?${new URLSearchParams(params).toString()}` : ''
        }`
    }),
    createImage: build.mutation({
      query: ({ id, entity, formData }) => ({
        url: `${ENDPOINTS[entity]}/${id}/images/`,
        method: 'POST',
        body: formData,
        formData: true,
        headers: {
          'Content-Type': 'undefined'
        }
      }),
      transformResponse: (response, meta, arg) => {
        const { status } = meta.response
        if (status === 413) {
          return {
            error: true,
            message: 'File too large'
          }
        }
        return response
      }
    }),
    updateImage: build.mutation({
      query: ({ id, entity, imageId, formData }) => ({
        url: `${ENDPOINTS[entity]}/${id}/images/${imageId}/`,
        method: 'PATCH',
        body: formData,
        formData: true,
        headers: {
          'Content-Type': 'undefined'
        }
      })
    }),
    deleteImage: build.mutation({
      query: ({ id, entity, imageId }) => ({
        url: `${ENDPOINTS[entity]}/${id}/images/${imageId}/`,
        method: 'DELETE'
      }),
      transformResponse: (response, meta, arg) => {
        if (!response) {
          return {
            data: {
              success: true
            }
          }
        }
      }
    }),
    // ROOMS
    fetchRooms: build.query({
      query: ({ id, entity, ...params }) =>
        `${ENDPOINTS[entity]}/${id}/rooms/${
          !!params ? `?${new URLSearchParams(params).toString()}` : ''
        }`
    }),
    createRoom: build.mutation({
      query: ({ accommodationId, entity, ...data }) => ({
        url: `${ENDPOINTS[entity]}/${accommodationId}/rooms/`,
        method: 'POST',
        body: data
      })
    }),
    updateRoom: build.mutation({
      query: ({ accommodationId, entity, roomId, ...data }) => ({
        url: `${ENDPOINTS[entity]}/${accommodationId}/rooms/${roomId}/`,
        method: 'PATCH',
        body: data
      })
    }),
    deleteRoom: build.mutation({
      query: ({ accommodationId, entity, roomId }) => ({
        url: `${ENDPOINTS[entity]}/${accommodationId}/rooms/${roomId}/`,
        method: 'DELETE'
      }),
      transformResponse: (response, meta, arg) => {
        const { status } = meta.response
        if (status === 204) {
          return {
            success: true
          }
        }
        return null
      }
    }),
    createBulkRoom: build.mutation({
      async queryFn({ accommodationId, roomNumber, ...data }, { dispatch }) {
        try {
          let promises = []
          for (let i = 0; i < roomNumber; i++) {
            promises.push(
              dispatch(smartwayApi.endpoints.createRoom.initiate({ accommodationId, ...data }))
            )
          }
          await processPromisesBatch(promises, 12)
          return {
            data: {
              success: true
            }
          }
        } catch (error) {
          console.warn(error)
          return { error }
        }
      }
    }),
    // FILTERS
    fetchFilters: build.query({
      query: ({ entity }) => `${ENDPOINTS[entity]}/filters`
    }),
    // CLONE
    cloneEntity: build.mutation({
      query: ({ entity, id, ...data }) => ({
        url: `${ENDPOINTS[entity]}/${id}/clone/`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useVerifyTokenQuery,
  useLogoutMutation,
  useFetchBaseProfileQuery,
  useFetchInitQuery
} = smartwayApi
