import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { smartwayApi } from 'services/api'

import { format } from 'date-fns'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Icon from '@mui/material/Icon'

import Notification from 'components/Notification'

import noResults from 'assets/illustrations/relax.svg'
import { getNotificationPropsByType } from 'utils/notifications'

const Notifications = () => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(0)
  const notifications = useSelector((state) => state.auth.notifications)
  const [filteredNotifications, setFilteredNotifications] = useState(
    notifications && notifications.length
      ? [...notifications].sort(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      : []
  )

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue)
    setFilteredNotifications(
      newValue === 1
        ? [...notifications].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        : notifications.filter((n) => !n.read)
    )
  }

  const markAllNotificationsAsRead = async () => {
    try {
      const promises = notifications.map(n => smartwayApi.endpoints.firestoreUpdate.initiate({ id: n.id, read: true }))
      const result = await Promise.all(promises.map(p => dispatch(p)))
      if (result) {
      }
    } catch (error) {
      console.error(error)
    }
  }

  const markNotificationAsRead = async (id) => {
    try {
      const result = await dispatch(
        smartwayApi.endpoints.firestoreUpdate.initiate({ id, read: true })
      )
      if (result) {
      }
    } catch (error) {
      console.error(error)
    }
  }
  // const deleteNotification = async (id) => {
  //   try {
  //     const result = await dispatch(smartwayApi.endpoints.firestoreDelete.initiate({ id }))
  //     if (result) {
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  useEffect(() => {
    setFilteredNotifications([...notifications].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    ))
  }, [notifications])

  return (
    <MDBox p={2}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography>Notifications</MDTypography>
        { notifications && notifications.length && notifications.some((n) => !n.read) ? (
          <MDTypography
            variant="button"
            color="text"
            fontWeight="medium"
            onClick={markAllNotificationsAsRead}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Mark all as read
          </MDTypography>
        ) : null }
      </MDBox>
      <MDBox mt={4}>
        { notifications && notifications.length ? (
          <AppBar position="static">
            <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
              <Tab
                label="Not read"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    mark_email_unread
                  </Icon>
                }
              />
              <Tab
                label="All"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    inbox
                  </Icon>
                }
              />
            </Tabs>
          </AppBar>
        ) : null }
      </MDBox>
      {filteredNotifications && filteredNotifications.length ? (
        <Grid container mt={2} mb={2} spacing={2}>
          {filteredNotifications.map((notification) => {
            const { id, title, message, created_at, read, channelId } = notification

            return (
              <Grid item xs={12}>
                <Notification
                  {...getNotificationPropsByType(channelId)}
                  id={id}
                  read={read}
                  title={title}
                  createdAt={format(new Date(created_at), 'dd/MM/yyyy HH:mm')}
                  message={message}
                  onRead={markNotificationAsRead}
                  onReadAll={markAllNotificationsAsRead}
                  // onDelete={deleteNotification}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          minHeight="500px">
          <MDBox
            component="img"
            src={noResults}
            alt="no results"
            width="100%"
            maxHeight={200}
            mb={3}
          />
          <MDTypography variant="h5" fontWeight="light" textAlign="center">
            There is nothing more to see here. <br />
            Time to relax!
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  )
}

export default Notifications
