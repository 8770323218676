import { useState, useEffect } from 'react'

import { smartwayApi } from 'services/api'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// @mui material components
import Grid from '@mui/material/Grid'
import AddCircleIcon from '@mui/icons-material/AddCircle'
// import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

import EditImageDialog from 'components/Dialogs/EditImageDialog'
import EditableImage from 'components/EditableImage'
import { getMainImage, getBestFormat } from 'utils/image'

const mapImage = (image) => {
  return { ...image, src: getBestFormat(image.formats, 'desktop') }
}
const mapImages = (images) => {
  return images.map(mapImage)
}

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  zIndex: '10',
  right: '5px',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const ImageCarousel = ({ id, entity, images, additionalInfos = {}, columns = 2, onChange }) => {
  const dispatch = useDispatch()
  const [mainImage, setMainImage] = useState(images && !!images.length ? getMainImage(images) : {})
  const [mappedImages, setMappedImages] = useState(
    images ? mapImages([...images]).filter((image) => !image.featured) : []
  )
  const [editingImage, setEditingImage] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [additionalPayload, setAdditionalPayload] = useState(additionalInfos)

  const notifyFeedback = ({ success, title, message }) => {
    if (success) {
      dispatch(
        notify({
          title: title || 'Well done!',
          message: message || 'Image was saved successfully',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: title || 'Ops!',
          message: message || 'There was an error uploading the image',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }


  const openEditDialog = () => setIsEditDialogOpen(true)
  const closeEditDialog = () => {
    setEditingImage(null)
    setIsEditDialogOpen(false)
  }

  const handleProblematicImage = (size) => (e) => {
    e.target.src = `https://via.placeholder.com/${size}.jpg?text=Add Image`
  }

  const handleEditImage = (image) => {
    setEditingImage(image)
  }

  const handleSaveImage = async (values) => {
    return new Promise(async (resolve, reject) => {
      const action = !!editingImage ? 'updateImage' : 'createImage'
      const imageProperties = !!editingImage ? { imageId: editingImage.id } : {}

      const multipleImages = Array.isArray(values.image) && Array.from(values.image).length > 1
      let result
      if (multipleImages) {
        let promises = []
        for (const image of values.image) {
          let multipleFormData = new FormData()
          multipleFormData.append('image', image)
          if (additionalPayload && !!Object.keys(additionalPayload).length) {
            for (const [key, value] of Object.entries(additionalPayload)) {
              multipleFormData.append(key, value)
            }
          }
          promises.push(
            dispatch(
              smartwayApi.endpoints[action].initiate({
                entity,
                id,
                formData: multipleFormData,
                ...imageProperties
              })
            )
          )
        }
        result = await Promise.all(promises)
      } else {
        const formData = new FormData()
        for (const [key, value] of Object.entries(values)) {
          if (key === 'image') {
            formData.append(key, value[0])
          } else {
            formData.append(key, value)
          }
        }
        if (additionalPayload && !!Object.keys(additionalPayload).length) {
          for (const [key, value] of Object.entries(additionalPayload)) {
            formData.append(key, value)
          }
        }
        result = await dispatch(
          smartwayApi.endpoints[action].initiate({
            entity,
            id,
            formData,
            ...imageProperties
          })
        )
      }
      const resultData = Array.isArray(result) ? result.every((r) => r.data) : result.data
      if (resultData) {
        if (resultData.error) {
          reject(resultData.message)
          notifyFeedback({ success: false, message: 'File seems to be too large' })
          setEditingImage(null)
        } else {
          resolve(resultData)
          setIsEditDialogOpen(false)
          setEditingImage(null)
          onChange()
          notifyFeedback({ success: true })
        }
      } else {
        reject('Error during upload')
        notifyFeedback({ success: false  })
      }
    })
  }

  const handleDeleteImage = async (image) => {
    const result = await dispatch(
      smartwayApi.endpoints.deleteImage.initiate({
        entity,
        id,
        imageId: image.id
      })
    )
    if (result && result.data) {
      onChange()
      notifyFeedback({ success: true })
    } else {
      notifyFeedback({ success: false, message: 'There was an error deleting the image' })
    }
  }

  useEffect(() => {
    if (editingImage) {
      openEditDialog()
    }
  }, [editingImage])

  useEffect(() => {
    const mapped = images ? mapImages([...images]) : []
    setMappedImages(mapped.filter((image) => !image.featured))
    if (!!mapped.length) {
      setMainImage(getMainImage(mapped))
    } else {
      setMainImage(null)
    }
  }, [images])

  useEffect(() => {
    if (additionalInfos && Object.keys(additionalInfos).length) {
      setAdditionalPayload(additionalInfos)
    }
  }, [additionalInfos])

  return (
    <MDBox>
      <EditImageDialog
        multiple
        image={editingImage}
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        onSave={handleSaveImage}
      />

      {mainImage && mainImage.featured ? (
        <EditableImage
          image={mainImage}
          iconStyles={iconStyles}
          onEdit={handleEditImage}
          onDelete={handleDeleteImage}
          onError={handleProblematicImage('1200x900')}
        />
      ) : null}
      <MDBox mt={1}>
        <Grid container spacing={1}>
          {mappedImages && mappedImages.length
            ? mappedImages.map((img, ind) => {
                return (
                  <Grid item key={img.id} position="relative" xs={12 / columns}>
                    <EditableImage
                      id={`image-${ind}`}
                      iconStyles={iconStyles}
                      image={{
                        ...img,
                        cursor: 'pointer',
                        objectFit: 'cover',
                        minHeight: '100px',
                        maxHeight: '100px'
                      }}
                      format="mobile"
                      borderRadius="lg"
                      shadow="md"
                      onEdit={handleEditImage}
                      onDelete={handleDeleteImage}
                    />
                  </Grid>
                )
              })
            : null}

          <Grid item xs={12 / columns}>
            <MDBox
              id="new-img" 
              onClick={openEditDialog}
              height="5rem"
              minHeight="100px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="primary"
              sx={{
                cursor: 'pointer',
                fontSize: '2rem',
                border: '1px dashed',
                borderColor: 'primary',
                borderRadius: '16px'
              }}>
              <AddCircleIcon />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}

export default ImageCarousel
