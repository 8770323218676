import { useState, useEffect, useRef } from 'react'
import Input from '../Input'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const InputList = ({ name, value, boxStyles, hidden, onChange, ...props }) => {
  const inputRef = useRef(null)
  const [list, setList] = useState(!!value ? value.split('|') : [])

  const handleOnEnter = (e) => {
    const value = e.target.value
    if (value) {
      const newList = [...list, value]
      setList([...list, value])
      inputRef.current.value = ''
      onChange({
        target: {
          name,
          value: newList.join('|')
        }
      })
    }
  }

  const handleRemove = (index) => {
    const newList = [...list]
    newList.splice(index, 1)
    setList(newList)
    onChange({
      target: {
        name,
        value: newList.join('|')
      }
    })
  }

  useEffect(() => {
    if (value) setList(value.split('|'))
  }, [value])

  return (
    <MDBox
      mb={hidden ? 0 : 4}
      position="relative"
      {...boxStyles}
      sx={{
        opacity: hidden ? 0 : 1,
        visibility: hidden ? 'hidden' : 'visible',
        height: hidden ? 0 : 'auto',
        transition: 'opacity 0.2s'
      }}
    >
      <Input
        {...props}
        inputRef={inputRef}
        success={false}
        error={false}
        helperText="Write something and press Enter to add it to the list"
        onEnter={handleOnEnter}
      />

      {list && list.length ? (
        <MDBox component="ul" ml={4} mb={2} sx={{ 'li::marker': { color: 'primary.main' } }}>
          {list.map((item, index) => (
            <MDBox component="li" key={index} position="relative" width="fit-content" sx={{ ':hover .list-item__icon': { opacity: 1, transform: 'scale(1)' } }}>
              <MDTypography variant="button">{item}</MDTypography>
              <MDBox sx={{ 
                  position: 'absolute',
                  top: '5px',
                  right: '-24px',
                  cursor: 'pointer',
                  opacity: 0,
                  transition: 'all 0.2s',
                  transform: 'scale(0.8)',
                }} 
                className="list-item__icon"
                onClick={() => handleRemove(index)}
              >
                <Icon>close</Icon>
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      ) : null}
    </MDBox>
  )
}

export default InputList
