import { defer } from 'react-router-dom'

import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'
import initialFilters from 'layouts/pages/packages/list/packages-filters'

export class PackagesLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    try {
      const results = this._loader(
          smartwayApi.endpoints.fetchEntities,
          request,
          { entity: 'package', page: 1, ...Object.fromEntries(url.searchParams), page_size: url.searchParams.get('page_size') || 10 }
        )
      return defer({
        results,
        initialFilters
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    if (isNew) {
      return {}
    }
    const [pack, images] = await Promise.all([
      await this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        { entity: 'package', id: params.id },
      ),
      await this._loader(
        smartwayApi.endpoints.fetchImages,
        request,
        { entity: 'package', id: params.id, page_size: 30 },
      ),
    ])

    return {
      entity: {
        ...pack,
        images: images && images.results ? images.results : []
      },
    }

  }
}