import { capitalizeFirstLetter } from 'utils/functions'

export const resolveCustomField = (field) => {
  return {
    ...field,
    name: field.field_key,
    type: field.type,
    label: capitalizeFirstLetter(field.title)
  }
}

export const resolveField = ({ field, step, offsiteId, options }) => {
  const { required, title } = step
  if (!field) return null
  switch (field.name) {
    case 'roommates':
      return {
        ...field,
        name: 'roommates',
        options: null,
        labelProp: 'name',
        valueProp: 'id',
        label: 'Roommates',
        required,
        multiple: true,
        multipleLimit: 3,
        grid: { xs: 12 },
        optionsGetter: {
          endpoints: [
            {
              entity: 'participants',
              params: {
                offsiteId,
                transformResponse: true,
                paginated: true,
                params: {
                  page_size: 100
                }
              }
            }
          ]
        },
        ...(required
          ? {
              validationType: 'array',
              validations: [{ type: 'required', params: ['This field is required'] }]
            }
          : {})
      }
    case 'dietary_options':
      return {
        ...field,
        name: 'dietary_options',
        options: null,
        labelProp: 'name',
        valueProp: 'id',
        label: 'Dietary options',
        // required,
        // ...(required
        //   ? {
        //       validationType: 'array',
        //       validations: [{ type: 'required', params: ['This field is required'] }]
        //     }
        //   : {}),
        optionsGetter: {
          endpoints: [
            {
              entity: 'dietaryOptions'
            }
          ]
        },
        grid: { xs: 6 }
      }

    case 'allergies':
      return {
        ...field,
        name: 'allergies',
        options: null,
        labelProp: 'name',
        valueProp: 'id',
        label: 'Allergens',
        grid: { xs: 6 },
        // required,
        // ...(required
        //   ? {
        //       validationType: 'array',
        //       validations: [{ type: 'required', params: ['This field is required'] }]
        //     }
        //   : {}),
        optionsGetter: {
          endpoints: [
            {
              entity: 'foodAllergies'
            }
          ]
        }
      }
    case 'dietary_options_other':
    case 'allergies_other':
      return {
        ...field,
        label: 'Other',
        placeholder: 'Other',
        grid: { xs: 6 }
      }
    case 'transfer_from':
    case 'transfer_to':
    case 'arrival_reservation_number':
    case 'departure_reservation_number':
      return {
        ...field,
        required,
        ...(required
          ? {
              validationType: 'string',
              validations: [{ type: 'required', params: ['This field is required'] }]
            }
          : {}),
        label: capitalizeFirstLetter(field.name.replaceAll('_', ' ')),
        grid: { xs: 6, sx: { '> .MuiBox-root': { marginBottom: '8px !important' } } }
      }
    case 'arrival_date':
    case 'departure_date':
      return {
        ...field,
        label: capitalizeFirstLetter(field.name.replaceAll('_', ' ')),
        required,
        ...(required
          ? {
              validationType: 'string',
              validations: [{ type: 'required', params: ['This field is required'] }]
            }
          : {}),
        grid: { xs: 6, mb: 1 },
        timepicker: {
          label: 'Time'
        },
        inputProps: {
          variant: 'outlined'
        }
      }
    case 'transfer':
    case 'transfer_return':
      return {
        ...field,
        grid: { xs: 6 },
        required,
        ...(required
          ? {
              validationType: 'string',
              validations: [{ type: 'required', params: ['This field is required'] }]
            }
          : {})
      }
    default:
      return {...field, label: title, required,  ...(required
        ? {
            validationType: 'string',
            validations: [{ type: 'required', params: ['This field is required'] }]
          }
        : {}),}
      break
  }
}

export const formatPreferencesToServer = (values, originalResponses) => {
  let _values = values
  const customKeys = Object.keys(values).filter((k) => k.startsWith('custom_question'))
  if (!!customKeys.length) {
    _values.customs = customKeys.map((key) => {
      const originalResponse =
        originalResponses && originalResponses.customs
          ? originalResponses.customs.find((r) => r.field_key === key)
          : null
      return {
        field_key: key,
        value: values[key],
        value_type: 'STRING',
        ...(originalResponse ? { id: originalResponse.id } : {})
      }
    })
  }
  return _values
}

const getSchema = ({ step, preferencesSteps, offsiteId, values }) => {
  return {
    id: 'preference-step',
    name: 'preference-step',
    fieldsets: [
      {
        id: preferencesSteps[step].id,
        watchedFields: preferencesSteps[step].fields.map((field) => field.name),
        fields: preferencesSteps[step].fields
          .filter(field => {
            const transferAutonomous = values?.transfer === 'My own vehicle' // please kill me
            const toExclude = ['transfer_from', 'transfer_to', 'arrival_reservation_number', 'departure_reservation_number']
            return transferAutonomous ? !toExclude.includes(field.name) : true
          })
          .map((field) => {
            const activeStep = preferencesSteps[step]
            return field.custom ? resolveCustomField(field) : resolveField({ field, step: activeStep, offsiteId })
          }
            
          )
          .flat()
      }
    ]
  }
}

export default getSchema
