const schema = {
  name: 'emails-list-form',
  fieldsets: [
    {
      id: 'emails-list',
      fields: [
        {
          type: 'file',
          name: 'file',
          label: 'Upload a list of users',
          validationType: 'mixed',
          maxSize: 5242880,
          validations: [
            {
              type: 'fileSize',
              params: ['File too large', '5242880']
            },
            {
              type: 'fileFormat',
              params: ['Unsupported Format', ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']]
            },
            {
              type: 'nullable'
            }
          ],
          accept: '.xls, .xlsx, .csv',
          acceptReadable: 'CSV or Excel File'
        }
      ]
    }
  ]
}

export default schema