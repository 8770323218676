const durationOptions = [
  {
    label: '30mn',
    value: 30
  },
  {
    label: '1hr',
    value: 60
  },
  {
    label: '1.30hr',
    value: 90
  },
  {
    label: '2hr',
    value: 120
  },
  {
    label: '2hr+',
    value: 121
  }
]

const filters = {
  name: 'experience-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: "Enter the name of the experience you're looking for",
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    
    {
      title: 'Town / Venue',
      subtitle: 'Choose the town or venue of the experience',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Category',
      subtitle: "Select the category of the experiences you're looking for",
      fields: [
        {
          type: 'badges',
          name: 'ranks',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                name: 'ranks',
                entity: 'locationRanks',
                notPaginated: true
              }
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Tipology',
      subtitle: "Select the tipology of the experiences you're looking for",
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [{ entity: 'locationCategories', id: 'experience' }]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Partcipants number',
      subtitle: 'How many participants must the experience accodomate?',
      fields: [
        {
          type: 'number',
          name: 'participants',
          grid: { xs: 3, display: 'block' }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Duration',
      subtitle: 'Filter for the minimum duration and the max duration of the experience',
      fields: [
        {
          type: 'select',
          name: 'min_duration',
          label: 'Min. duration',
          grid: { xs: 3 },
          options: durationOptions
        },
        {
          type: 'select',
          name: 'max_duration',
          label: 'Max. duration',
          grid: { xs: 3 },
          options: durationOptions
        }
      ]
    },
    {
      title: 'Price',
      subtitle: "Select the price range you're looking for",
      fields: [
        {
          type: 'range',
          name: 'price',
          min: 0,
          max: 250,
          prefix: '€',
          initialValue: [50, 150],
          minDistance: 20,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Video',
      subtitle: 'Select whether the experience has a video',
      fields: [
        {
          type: 'switch',
          name: 'video'
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the experience is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters
