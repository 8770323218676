import { OFFSITE_STATUSES } from "utils/offsiteStatus"

const filters = {
  name: 'offsite-filters',
  fieldsets: [
    {
      title: 'Name or Company',
      subtitle: 'Enter the name or the company of the offsite you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'search',
          grid: { xs: 6 },
        },
        {
          type: 'select',
          name: 'company',
          grid: { xs: 6 },
          valueProp: 'id',
          labelProp: 'name',
          optionsGetter: {
            endpoints: [
              { entity: 'company' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Town / Venue',
      subtitle: 'Filter by town or venue of the offsite',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Number of days',
      subtitle: 'Filter by duration of the offsite',
      fields: [
        {
          type: 'range',
          name: 'nb_days',
          min: 1,
          max: 7,
          initialValue: [1, 3],
          minDistance: 1,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Status',
      subtitle: "Select the status of the offsites you're looking for",
      fields: [
        {
          type: 'badges',
          name: 'status',
          labelProp: 'label',
          valueProp: 'name',
          multiple: true,
          options: OFFSITE_STATUSES
        }
      ]
    },
    {
      id: 'past',
      fields: [
        {
          type: 'switch',
          name: 'is_past',
          hidden: true
        }
      ]
    }
  ]
}

export default filters