const filters = {
  name: 'town-filters',
  form: 'inline',
  watchedFields: ['name'],
  fieldsets: [
    {
      sx: { '*': { mb: 0 }},
      fields: [
        {
          type: 'text',
          name: 'name',
          label: 'Search by name',
          sx: { minWidth: 250, mb: 0 }
        }
      ]
    }
  ]
}

export default filters