import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  profile: null,
  groups: null,
  notifications: [],
  needProfileUpdate: false
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setProfile: (state, action) => {
      state.profile = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setNeedProfileUpdate: (state, action) => {
      state.needProfileUpdate = action.payload
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload.reduce((acc, n) => {
        if (n.channelId === 'CHAT') {
          const chatNotification = acc.find((notif) => notif.channelId === 'CHAT')
          if (chatNotification) {
            chatNotification.messages += 1
            chatNotification.title = `You have ${chatNotification.messages} unread messages!`
            chatNotification.message = 'Open the app to read them'
          } else {
            const newChatNotification = {
              ...n,
              messages: 1,
              title: `You have 1 unread message!`,
              message: 'Open the app to read it'
            }
            acc.push({
              ...newChatNotification,
              // Prevent bad date format
              created_at: newChatNotification.created_at
                ? newChatNotification.created_at.split('+')[0]
                : new Date()
            })
          }
        } else {
          acc.push({
            ...n, 
            // Prevent bad date format
            created_at: n.created_at
              ? n.created_at.split('+')[0]
              : new Date()
          })
        }
        return acc
      }, [])
    }
  }
})

export const selectors = {
  profile: (state) => state.auth.profile,
  notifications: (state) => state.auth.notifications
}

export const { setAuthenticated, setProfile, setGroups, setNeedProfileUpdate, setNotifications } =
  slice.actions

export default slice.reducer
