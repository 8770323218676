import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export const VideoSkeleton = ({ status }) => {
  const ske = { variant: 'rounded', animation: 'wave' }
  return (
    <MDBox display="flex" alignItems="center" position="relative">
      <Skeleton {...ske} width="100%" height={200} sx={{ ml: 1 }} />
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 0,
          width: '100%',
          px: 4
        }}>
        <MDTypography display="inline-block" variant="caption" textAlign="center" maxWidth="250px" dangerouslySetInnerHTML={{ __html: status }}>
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}
