import { useState, useEffect, useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import DataTable from 'components/Tables/DataTable'
import PackageActionsCell from './PackageActionsCell'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { ListSkeleton } from './Skeletons'

import noResults from 'assets/illustrations/noresults.svg'

const PackagesTableView = ({ packages = [], hideColumns = [], pagination, onDelete, onClone }) => {
  const [table, setTable] = useState(null)
  const [tableReady, setTableReady] = useState(false)

  const handleDeletePackage = (pack) => {
    onDelete(pack.id)
  }

  const handleClonePackage = (pack) => {
    onClone(pack.id)
  }

  const getPackagesTable = useCallback(async () => {
    setTableReady(true)
    setTable({
      columns: [
        { Header: 'venue', accessor: 'venue' },
        { Header: 'title', accessor: 'title' },
        // { Header: 'agendas', accessor: 'agendas' },
        { Header: 'excerpt', accessor: 'excerpt', width: '15%' }
      ].filter((c) => !hideColumns.includes(c.accessor)),
      rows:
        packages && !!packages.length
          ? packages.map((pack) => {
              const { title, excerpt, town, all_in_venue, agendas } = pack
              return {
                __data: pack,
                venue: town && town.id ? town.name : all_in_venue && all_in_venue.name,
                // agendas: agendas?.length || 'No agenda',
                title,
                excerpt: (
                  <MDBox maxWidth="230px" dangerouslySetInnerHTML={{ __html: excerpt }} />
                )
              }
            })
          : []
    })
  }, [packages, hideColumns])

  useEffect(() => {
    if (packages && !!packages.length && !tableReady) {
      getPackagesTable()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPackagesTable, packages])

  useEffect(() => {
    if (tableReady && pagination) {
      getPackagesTable()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return packages && packages.length ? (
    tableReady ? (
      <DataTable
        hideEntriesPerPage
        table={table}
        pagination={pagination}
        actionsOnRowHover={
          <PackageActionsCell onClone={handleClonePackage} onDelete={handleDeletePackage} />
        }
      />
    ) : (
      <ListSkeleton />
    )
  ) : (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh">
      <MDBox component="img" src={noResults} alt="no results" width="100%" maxHeight={200} mb={3} />
      <MDTypography variant="h5">No results found!</MDTypography>
    </MDBox>
  )
}

export default PackagesTableView
