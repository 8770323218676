import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
} from '@mui/material'

import MDButton from 'components/MDButton'

import Form from 'components/Form/Form'

import schema from './schemas/link'

const LinkDialog = ({ open, title, link = {}, onSave, onClose }) => {
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      onSave(values)
    }
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' }}}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0}}>
          <Form
            {...schema}
            values={link}
            onSubmit={handleOnSave}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton
            type="submit"
            form="link-form"
            variant="contained"
            color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default LinkDialog
