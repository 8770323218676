import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import accessDenied from 'assets/illustrations/accessdenied.svg'
import { Link } from 'react-router-dom'

const NotAuthorized = () => {
  return (
    <MDBox display="flex" flexDirection="column" justifyContent="center" textAlign="center" height="80vh" pb={12}>
      <MDBox mb={1}>
        <MDTypography variant="h1" fontWeight="bold" mb={3}>
          Sorry :-(
        </MDTypography>
        <MDBox component="img" src={accessDenied} width="200px" mb={3}></MDBox>
      </MDBox>
      <MDTypography variant="h5" fontWeight="regular" color="secondary">
        You're not authorized to see this page.
        <br />
        You can navigate to another page or write to your administrator to solve the problem.
      </MDTypography>
      <MDBox mt={3}>
        <Link to={`/dashboard`}>
          <MDButton color="primary">Take me home</MDButton>
        </Link>
      </MDBox>
    </MDBox>
  )
}

export default NotAuthorized