import { Suspense } from 'react'
import { Await, useLoaderData, useRouteLoaderData } from 'react-router-dom'
import AgendaStep from './AgendaStep'
import { AgendaSkeleton } from 'components/AgendaBuilder/Skeleton'

const AgendaStepWrapper = ({ title, subtitle, parent = 'offsite' }) => {
  const { offsite, profile } = useRouteLoaderData(`${parent}-detail`)
  const { participants } = useLoaderData()
  return (
    <Suspense fallback={<AgendaSkeleton />}>
      <Await resolve={participants}>
        {(data) => {
          return (
            <AgendaStep
              offsite={offsite}
              profile={profile}
              title={title}
              subtitle={subtitle}
              parent={parent}
              participants={data.filter(p => p.confirmation !== false)}
            />
          )
        }}
      </Await>
    </Suspense>
  )
}

export default AgendaStepWrapper
