import { memo, useMemo } from 'react'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PreferencesFieldsetValues from './PreferencesFieldsetValues'

import Form from 'components/Form'
import getSchema from 'components/Dialogs/schemas/preferences'

const PreferencesFieldset = ({ offsiteId, preferences, participant, options, tab, isEditing, onChange }) => {
  const fieldsets =
    tab.id === 'custom'
      ? [{ isCustom: true, fields: preferences?.custom_fields }]
      : preferences?.fieldsets?.filter((f) => tab.activeIf?.includes(f.name) && f.active)
  const {
    offsite_preference,
    user
  } = participant
  const userPreferences = {
    ...(offsite_preference ? offsite_preference : {}),
    ...(user?.user_preferences ? user.user_preferences : {})
  }
  const schema = useMemo(() => getSchema({fieldsets, options, tab, participant, offsiteId}))

  const initialValues = {
    ...userPreferences,
    arrival_date: userPreferences.arrival_date ? new Date(userPreferences.arrival_date) : null,
    departure_date: userPreferences.departure_date ? new Date(userPreferences.departure_date) : null,
    ...( userPreferences.customs && userPreferences.customs.length ? userPreferences.customs.reduce((acc, curr) => ({ ...acc, [curr.field_key]: curr.value }), {}) : {})
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    if (onChange && typeof onChange === 'function') {
      onChange({ newValues: {[name]: value}, initialValues })
    }
  }

  return (
    <MDBox id={`fieldset-${tab.id}`}>
      {isEditing ? (
        <MDBox>
          <Form
            {...schema}            
            values={initialValues}
            onFieldChange={handleFieldChange}
          />
        </MDBox>
      ) : (
        <div>
          <MDTypography
            display="flex"
            alignItems="center"
            variant="h5"
            fontWeight="regular"
            mb={2.5}>
            <Icon sx={{ mr: 1 }}>{tab.icon}</Icon>
            {tab.label}
          </MDTypography>
          {fieldsets.map((f, fIndex) => {
            return (
              <PreferencesFieldsetValues 
                key={`${f.name}-${fIndex}`}
                fieldset={f}
                options={options}
                preferences={userPreferences}
              />
              
            )
          })}
        </div>
      )}
    </MDBox>
  )
}
const PreferencesFieldsetMemoized = memo(PreferencesFieldset, (prevProps, nextProps) => {
  return prevProps.participant && nextProps.participant && prevProps.participant.id === nextProps.participant.id && prevProps.isEditing === nextProps.isEditing
})

export default PreferencesFieldsetMemoized

