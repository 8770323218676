import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const TeamCard = ({ id, title, offsiteParticipants, onEdit, onDelete, ...team }) => {
  const handleEdit = () => {
    onEdit({ id, title, ...team })
  }
  const handleRemove = () => {
    onDelete({ id, title, ...team })
  }

  const teamParticipants = offsiteParticipants
    .filter((participant) => {
      return team.participants ? team.participants.includes(participant.id) : []
    })
    .map(({ first_name, last_name, email }) => {
      return { name: !first_name && !last_name ? email : `${first_name} ${last_name}` }
    })

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <MDBox pt={2}>
          <MDTypography variant="h6" component="div" lineHeight={1}>
            {title}
          </MDTypography>
          {teamParticipants && !!teamParticipants.length ? (
            <MDTypography variant="caption" fontWeight="regular" mt={0}>
              ({teamParticipants.length} participant{teamParticipants.length > 1 ? 's' : ''})
            </MDTypography>
          ) : null}
          <MDBox component="ul" pt={1} pl={2}>
            {teamParticipants.map((participant) => (
              <MDBox component="li" key={participant.name}>
                <MDTypography variant="button" fontWeight="regular">
                  {participant.name}
                </MDTypography>
              </MDBox>
            ))}
          </MDBox>
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
        <MDButton color="primary" size="small" onClick={handleEdit} iconOnly>
          <Icon>edit</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default TeamCard
