// Material Dashboard components
import Box from '@mui/material/Box';

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// MUI Components
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'

const FieldsetWrapper = ({
  id,
  title,
  subtitle,
  type,
  accordionExpanded,
  accordionType,
  sx = {},
  children,
  onAccordionChange
}) => {
  if (type === 'accordion') {
    const handleAccordionChange = () => {
      const value = accordionExpanded === id ? null : id
      onAccordionChange(value)
    }

    return (
      <Accordion
        sx={accordionType === 'minimal' ? { boxShadow: 'none', '&.MuiAccordion-root.Mui-expanded': { margin: '0 !important'}, ...sx } : { backgroundColor: 'rgba(255,255,255,0.5)', ...sx }}
        expanded={accordionExpanded === id}
        onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${id}-content`}
          id={`panel-${id}-header`}
          {...(accordionType === 'minimal' ? { sx: {
            backgroundColor: 'transparent !important',
            minHeight: '48px !important',
            '> .MuiAccordionSummary-content.Mui-expanded': {
              margin: '12px 0 !important'
            }
          }} : {})}>
          <MDBox display="flex" flexDirection="column">
            {title ? (
              <MDTypography {...(accordionType === 'minimal' ? { variant: 'button', fontWeight: 'bold' } : { variant: 'subtitle' })}>{title}</MDTypography>
            ) : null}
            {subtitle ? (
              <MDTypography {...(accordionType === 'minimal' ? { variant: 'button' } : { variant: 'button' })}>{subtitle}</MDTypography>
            ) : null}
          </MDBox>
        </AccordionSummary>
        <AccordionDetails
          sx={{ p: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'end', gap: 1 }}>
            <Grid container spacing={2}>
              {children}
            </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
  return (
    <MDBox
      bgColor="white"
      py={2}
      mt={1}
      gap={1}
      key={`fieldset-${id}`}
      sx={{ display: 'flex', flexWrap: 'wrap', background: 'transparent', ...sx }}>
      {title ? (
        <Grid item mb={3} xs={12}>
          <MDBox>
            <MDTypography variant="body1">{title}</MDTypography>
          </MDBox>
          { subtitle ? (
            <MDTypography variant="body2">{subtitle}</MDTypography>
          ) : null }
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        {children}
      </Grid>
    </MDBox>
  )
}

export default FieldsetWrapper
