import { useRef, useState, useEffect } from 'react'

import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const EditableInput = ({
  name,
  value,
  placeholder = '',
  variant = 'body2',
  wrapperStyles = {},
  typographyProps = {},
  iconsColor = 'primary',
  canReset = true,
  onChange = () => {},
}) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(value)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const handleBlur = () => {
    if (!inputRef.current) return

    if (!inputValue) {
      setShowPlaceholder(true)
    }
    onChange({ target: { name, value: inputRef.current.innerHTML } })
  }

  const handleFocus = () => {
    if (!inputRef.current) return

    inputRef.current.focus()
    setShowPlaceholder(false)
    window.getSelection().selectAllChildren(inputRef.current)
    window.getSelection(inputRef.current).collapseToEnd()
    // setInputValue('')
  }

  const handleReset = () => {
    onChange({ target: { name, value: '' } })
  }

  const handlePlaceholderClick = () => {
    inputRef.current.focus()
    setShowPlaceholder(false)
  }

  useEffect(() => {
    if (!value) {
      setShowPlaceholder(true)
    } else {
      setShowPlaceholder(false)
    }
    if (value !== inputValue) {
      setInputValue(value)
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <MDBox sx={wrapperStyles}>
      <MDTypography
        variant={variant}
        mb={2}
        {...typographyProps}
        style={{
          display: 'inline-flex',
          gap: '6px',
          alignItems: 'center',
          textAlign: 'left',
        }}>
        <span
          ref={inputRef}
          onBlur={handleBlur}
          style={{ paddingInline: '4px' }}
          contentEditable
          suppressContentEditableWarning={true}>
          {inputValue}
        </span>
        {showPlaceholder ? (
          <span
            onClick={handlePlaceholderClick}
            style={{
              fontStyle: 'italic',
              color: '#9e9e9e',
              fontSize: '18px',
              fontWeight: 300,
              curso: 'pointer'
            }}>
            {placeholder}
          </span>
        ) : null}

        <Icon fontSize="small" color={iconsColor} sx={{ cursor: 'pointer' }} onClick={handleFocus}>
          edit
        </Icon>
        { canReset ? (
          <Icon fontSize="small" color={iconsColor} sx={{ cursor: 'pointer' }} onClick={handleReset}>
            close
          </Icon>
        ) : null }
      </MDTypography>
    </MDBox>
  )
}

export default EditableInput
