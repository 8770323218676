import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'

const ske = { variant: 'rounded', animation: 'wave' }


const getSlotsByIndex = (index) => {
  return (
    <Grid container sx={{ height: "calc(100vh - 600px)",  mt: index === 0 ? 12 : 0 }} spacing={2}>
      { new Array(8).fill(' ').map((_, index) => (
        <Grid item xs={12} sx={{ height: `${Math.floor(Math.random() * (200 - 80 + 1) + 80)}px` }}>
          <Skeleton {...ske} height="100%"/>
        </Grid>
      ))}
    </Grid>
  )
}

const AgendaSkeleton = ({ days = 2 }) => {
  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={0.8}>
        </Grid>
        {new Array(days).fill(' ').map((day, index) => (
          <Grid item xs={11 / days} key={`a-b-${index}`} sx={{ display: 'flex', justifyContent: 'center'}}>
            <Skeleton {...ske} height={20} width={80}/>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} sx={{ height: "calc(100vh - 300px)", mt: 2, overflow: 'hidden' }}>
        <Grid item xs={0.8} mt={6}>
          { new Array(10).fill(' ').map((_, index) => (
            <MDBox key={`a-c-${index}`} mt={8}>
              <Skeleton {...ske} height={20} key={index} />
            </MDBox>
          ))}
        </Grid>
        {new Array(days).fill(' ').map((day, index) => (
          <Grid item xs={11 / days} key={`a-d-${index}`}>
            {getSlotsByIndex(index)}
          </Grid>
        ))}
      </Grid>
    </MDBox>
  )
}

export { AgendaSkeleton }