import { useState, useCallback } from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import Form from 'components/Form'
import CustomQuestion from './components/ParticipantsSettings/CustomQuestion'

import getSchema, {
  getCustomQuestionInitialValues,
  getFieldsetsInitialValues,
} from './schemas/participants-settings'
import Divider from 'components/Fields/Divider/Divider'

const ParticipantsSettingsDialog = ({ open, title, participants, settings, onSave, onClose }) => {
  const [initialValues, setInitialValues] = useState(getFieldsetsInitialValues({ settings, options: { participants } }))
  const [settingsValue, setSettingsValue] = useState(initialValues)
  const [customQuestions, setCustomQuestions] = useState(settings?.custom_fields || [])
  const [customQuestionsValues, setCustomQuestionsValues] = useState(settings?.custom_fields ? getCustomQuestionInitialValues(settings?.custom_fields) : {})
  const [schema, setSchema] = useState(
    getSchema({ settings, values: initialValues, options: { participants } })
  )
  
  const getNewCustomQuestion = () => {
    return {
      field_key: `custom_question_${customQuestions.length}`,
      type: 'TEXT',
      required: false,
      title: `Custom question ${customQuestions.length}`,
      description: `Custom question description ${customQuestions.length}`,
      options: []
    }
  }

  const handleCreateCustomQuestion = () => {
    setCustomQuestions([...customQuestions, getNewCustomQuestion()])
  }
  const handleDeleteCustomQuestion = useCallback((name) => {
    const newCustomQuestions = [...customQuestions].filter((c) => c.field_key !== name)
    setCustomQuestions(newCustomQuestions)
    const newValues = {
      ...customQuestionsValues
    }
    delete newValues[name]
    setCustomQuestionsValues(newValues)
  }, [customQuestions, customQuestionsValues])

  const handleCustomQuestionChange = (values) => {
    const newValues = {
      ...customQuestionsValues,
      ...values
    }
    setCustomQuestionsValues(newValues)

  }

  const handleFieldChange = (event, formValues, formik) => {
    const { value, name } = event.target
    const isFieldType = name.includes('field_type')
    const isShowQuestion = name.includes('active')
    let otherValues = {}
    if (isFieldType || isShowQuestion) {
      const rootFieldName = name.replace(/_field_type|_active/, '')
      if (isFieldType) {
        otherValues = {
          [rootFieldName]: ''
        }
        formik.setFieldValue(rootFieldName, '')
      }
      if (isShowQuestion && value === false) {
        otherValues = {
          [`${rootFieldName}_required`]: false
        }
        formik.setFieldValue(`${rootFieldName}_required`, false)
      }
      const newValues = {
        ...settingsValue,
        ...otherValues,
        [name]: value
      }
      setSchema(
        getSchema({
          settings,
          values: newValues,
          options: {
            participants
          }
        })
      )
      setSettingsValue({ ...newValues, ...formValues, [name]: value })
      setInitialValues(newValues)
    } else {
      const newValues = {
        ...settingsValue,
        [name]: value
      }
      setSettingsValue({ ...newValues, ...formValues, [name]: value })
      setInitialValues(newValues)
    }
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      onSave({ values, customValues: customQuestionsValues })
    }
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0, overflow: 'hidden' }}>
          <Form
            {...schema}
            onFieldChange={handleFieldChange}
            values={initialValues || {}}
            onSubmit={handleOnSave}
          />
          <Divider hr />
          <MDBox my={2}>
            <MDTypography variant="h4">Custom questions:</MDTypography>
          </MDBox>
          {customQuestions.length
            ? customQuestions.map((question, index) => {
                return (
                  <CustomQuestion
                    key={`custom-question-${index}-${question.field_key}`}
                    question={question}
                    index={index}
                    onChange={handleCustomQuestionChange}
                    onDelete={handleDeleteCustomQuestion}
                  />
                )
              })
            : null}
          <MDButton variant="outlined" color="secondary" onClick={handleCreateCustomQuestion}>
            Add custom question <Icon sx={{ ml: 0.5 }}>add</Icon>
          </MDButton>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton type="submit" form="preferences-form" variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ParticipantsSettingsDialog
