import { useEffect } from 'react'
import { useLogoutMutation } from 'services/api'
import { Navigate } from 'react-router-dom'

const Logout = () => {
  const [logout, { status, data }] = useLogoutMutation()

  useEffect(() => {
    logout()
  }, [logout])

  if (status === 'fulfilled' && data && data.success) {
    return <Navigate to="/authentication/sign-in" />
  }
  
  return <></>
}

export default Logout