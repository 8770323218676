import { createContext, useReducer, useCallback } from 'react'
// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'UPDATE':
//     const newState = {
//       ...state, ...action.payload
//     }
//     return {
//       ...newState,
//     }
//     case 'RESET':
//       return {
//         ...state, ...action.payload
//       }
//     default:
//       return state
//   }
// }

// const initialState = {
//   profile: null
// }

export const ActivitiesContext = createContext()

const ActivitiesContextProvider = ({
  children,
  saveActivity,
  saveCustomActivity,
  cloneCustomActivity,
  deleteActivity,
  deleteCustomActivity
}) => {
  // const [state, dispatch] = useReducer(reducer, initialState)


  return (
    <ActivitiesContext.Provider
      value={{
        saveActivity,
        saveCustomActivity,
        cloneCustomActivity,
        deleteActivity,
        deleteCustomActivity
      }}>
      {children}
    </ActivitiesContext.Provider>
  )
}

export default ActivitiesContextProvider
