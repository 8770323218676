import { useEffect, useState } from 'react'

import EditImageDialog from 'components/Dialogs/EditImageDialog'
import EditableImage from 'components/EditableImage'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import Icon from '@mui/material/Icon'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  zIndex: '10',
  right: '5px',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const ImageUpload = ({ name = 'image', multiple = false, value, errors, onChange }) => {
  const [previewURL, setPreviewURL] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  const openEditDialog = () => setIsEditDialogOpen(true)
  const closeEditDialog = () => setIsEditDialogOpen(false)

  const handleSaveImage = ({ image }) => {
    const file = image[0]
    const reader = new FileReader()
    reader.onload = () => {
      setPreviewURL(reader.result)
    }
    reader.readAsDataURL(file)
    onChange({ target: { name, value: file } })
    closeEditDialog()
  }

  const handleDeleteImage = () => {
    setPreviewURL(null)
    onChange({ target: { name, value: null } })
  }

  useEffect(() => {
    if (value && value instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewURL(reader.result)
      }
      reader.readAsDataURL(value)
    } else if (value && typeof value === 'string') {
      setPreviewURL(value)
    }
  }, [value])
  
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      my={3}
      minHeight="200px">
      <EditImageDialog
        hideOtherFields
        image={value}
        multiple={multiple}
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        onSave={handleSaveImage}
      />
      {previewURL ? (
        <MDBox width="40%" minWidth="350px" maxWidth="500px" minHeight="200px">
          <EditableImage
            image={{ formats: { mobile: previewURL } }}
            iconStyles={iconStyles}
            onEdit={openEditDialog}
            onDelete={handleDeleteImage}
          />
        </MDBox>
      ) : (
        <MDBox
          width="40%"
          minWidth="350px"
          maxWidth="500px"
          height="200px"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed grey',
            borderRadius: 4,
            cursor: 'pointer'
          }}
          onClick={openEditDialog}>
          <MDBox
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <MDTypography variant="body2">Add Image</MDTypography>
            <MDBox>
              <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
                image
              </Icon>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      {errors ? (
        <MDTypography color="error" variant="caption">
          {errors}
        </MDTypography>
      ) : null}
    </MDBox>
  )
}
export default ImageUpload
