import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

export const CardsSkeleton = () => {
  return (
    <Grid
      container
      alignItems="start"
      spacing={3}
      sx={{ height: '100%', mt: 2 }}>
      {Array.from(new Array(10)).map((_, index) => (
        <Grid key={`cards-skeleton-${index}`} item xs={12} md={6} lg={6}>
          <Skeleton animation="wave" variant="rounded" height={180} />
        </Grid>
      ))}
    </Grid>
  )
}

