import { useState, useEffect } from 'react'
import { Link, useSubmit, useActionData, useSearchParams } from 'react-router-dom'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

import BasicLayout from 'layouts/authentication/components/BasicLayout'

import loading from 'assets/illustrations/exploring.svg'
import fireworks from 'assets/illustrations/fireworks.svg'
import errorIllustration from 'assets/illustrations/error.svg'
import bgImage from 'assets/images/montepulciano.jpeg'


const ConfirmRegistration = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const submit = useSubmit()
  const actionData = useActionData()
  const [searchParams] = useSearchParams()

  const texts = {
    loading: {
      title: 'Just one moment!',
      description: 'We are activating your account'
    },
    error: {
      title: 'Something went wrong!',
      description: 'Please try again'
    },
    success: {
      title: 'Account activated!',
      description: 'You can now log in'
    }
  }

  useEffect(() => {
    if (searchParams && searchParams.get('token')) {
      const token = searchParams.get('token')
      const redirect_url = searchParams.get('redirect_url')
      submit({ token, redirect_url }, { method: 'post', encType: 'application/json' })
    } else if (searchParams && searchParams.get('activation')) {
      const activation = searchParams.get('activation')
      const decoded = atob(activation)
      if (decoded === 'active') {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
      setError('You should provide a valid activation key')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (actionData && actionData.success) {
      setIsLoading(false)
    }
    if (actionData && actionData.error) {
      setIsLoading(false)
      setError(actionData.error && actionData.error.detail ? actionData.error.detail : actionData.error)
    }
  }, [actionData])

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ background: 'rgba(255, 255, 255, 0.8)'}}>
        <MDBox width="100%" minHeight="50vh" display="flex" justifyContent="center" alignItems="center">
          <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <MDBox
              component="img"
              src={isLoading ? loading : error ? errorIllustration : fireworks }
              alt="select an accommodation"
              width="100%"
              maxHeight={200}
              mb={3}
            />
            <MDTypography variant="body" mt={4}>{texts[isLoading ? 'loading' : error ? 'error' : 'success'].title}</MDTypography>
            <MDTypography variant="body2">{texts[isLoading ? 'loading' : error ? 'error' : 'success'].description}</MDTypography>
            { !isLoading && error ? (
              <MDTypography variant="body2" color="text" mt={2}>{error}</MDTypography>
            ) : null }
            { !isLoading && !error ? (
              <MDBox mt={4}>
                <Link to="/authentication/sign-in?next=/dashboard/profile">
                  <MDButton variant="text" color="primary">
                    Go to login
                    <Icon sx={{ ml: 1 }}>rocket_launch</Icon>
                  </MDButton>
                </Link>
              </MDBox>
            ) : null }
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default ConfirmRegistration
