import CircularProgress from '@mui/material/CircularProgress'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const GuestsCounter = ({ size = 120, value, total, percentage }) => {
  return (
    <MDBox
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}>
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'relative',
          left: 0,

          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={size}
        thickness={3}
        value={100}
      />
      <CircularProgress
        color="primary"
        size={size}
        thickness={3}
        variant="determinate"
        value={percentage}
        sx={{
          position: 'absolute',
          strokeLinecap: 'round'
        }}
      />
      <MDBox
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <MDTypography variant="h4" color="text">
          {value}/{total}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

export default GuestsCounter
