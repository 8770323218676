import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import Avatar from '@mui/material/Avatar'
import { stringAvatar } from "utils/functions"

const GeneralInfos = ({ participant }) => {
  const { user, first_name, last_name, email } = participant
  let name = `${first_name} ${last_name}`
  let avatar = null
  if (user) {
    if (user.first_name && user.last_name) {
      name = `${user.first_name} ${user.last_name}`
    }
    avatar = user.avatar_formats && Object.keys(user.avatar_formats).length ? user.avatar_formats : null
  }
  const defaultAvatarSx = { width: 92, height: 92 }
  const avatarProps = avatar ? { src: avatar.mobile, sx: defaultAvatarSx } : { ...stringAvatar(name, defaultAvatarSx ) }
  
  return (
    <MDBox display="flex" alignItems="center" ml={2} my={4}>
      <Avatar
        alt="Avatar"
        {...avatarProps}
      />
      <MDBox ml={2}>
        <MDTypography variant="h4" fontWeight="medium" sx={{ lineHeight: 1}}>{name}</MDTypography>
        { user && user.company ? (
          <MDTypography variant="body2">{user.company.name}</MDTypography>

        ) : null }
        <MDTypography variant="button" display="block" sx={{ mt: 1, mb: 0, lineHeight: 0.6 }}>{email}</MDTypography>
        { user && user.mobile ? (
          <MDTypography variant="button">{user.mobile}</MDTypography>
        ) : null }
      </MDBox>
    </MDBox>
  )
}

export default GeneralInfos