import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'

export const RoomSkeleton = ({ capacity }) => {
  return (
    <>
      <Grid
        container
        alignItems="start"
        spacing={2}
        sx={{ height: '100%', mt: 2 }}>
        {Array.from(new Array(capacity)).map((_, index) => (
          <Grid key={`cards-skeleton-${index}`} item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={46} />
          </Grid>
        ))}
      </Grid>
      <MDBox
        display="flex"
        justifyContent={'flex-end'}
        gap={1}
        mt={2}
        mb={1}>
        <Skeleton animation="wave" variant="rounded" height={46} width={46} />
        <Skeleton animation="wave" variant="rounded" height={46} width={46} />
        </MDBox>
    </>
  )
}