import { useState, useEffect } from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'

import Form from 'components/Form/Form'

import getSchema from './schemas/notification'

const NotificationDialog = ({ open, offsiteId, title, notification, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)

  const getInitialValues = (n) => {
    return n && !!Object.keys(n).length
      ? {
          ...n,
          confirmed:
            n.confirmed !== null ? (n.confirmed ? 'confirmed' : 'not_confirmed') : 'everyone',
          send_at: n.send_at ? new Date(n.send_at) : null,
          scheduled: !!n.send_at
        }
      : {
          confirmed: 'everyone'
        }
  }
  const [schema, setSchema] = useState(getSchema({ values: getInitialValues(notification), offsiteId }))

  const resetState = () => {
    setIsLoading(false)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleOnSave = async (values) => {
    setIsLoading(true)
    if (onSave && typeof onSave === 'function') {
      await onSave(values)
      setIsLoading(false)
    }
  }

  const handleFieldChange = (event) => {
    const { value, name } = event.target
    if (name === 'scheduled') {
      setSchema(getSchema({ values: { ...notification, [name]: value }, offsiteId }))
    }
  }

  useEffect(() => {
    if (notification) {
      setSchema(getSchema({ values: getInitialValues(notification), offsiteId }))
    }
  }, [notification])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Form
            {...schema}
            onFieldChange={handleFieldChange}
            values={getInitialValues(notification)}
            onSubmit={handleOnSave}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            sx={{
              color: 'white !important',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' }
            }}
            form="notification-form"
            type="submit"
            variant="contained"
            disabled={isLoading}
            loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default NotificationDialog
