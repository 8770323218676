const schema = {
  id: "person-form",
  name: 'person-form',
  fieldsets: [
    {
      id: 'person',
      fields: [
        {
          type: 'text',
          name: 'first_name',
          label: 'Firstname',
          grid: { xs: 4 },
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ]
        },
        {
          type: 'text',
          name: 'last_name',
          label: 'Lastname',
          grid: { xs: 4 },
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ]
        },
        {
          type: 'text',
          name: 'email',
          label: 'Email',
          grid: { xs: 4 },
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            },
            {
              type: 'email',
              params: ['Invalid email']
            }
          ]
        }
      ]
    }
  ]
}

export default schema