import { useState, useEffect } from 'react'

import { Outlet, ScrollRestoration, useSearchParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// @mui material components
import Sidenav from "components/Sidenav";

import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/DashboardNavbar';

import { getNavigation } from "navigation";

// Images
import smartwayLogo from "assets/images/logo_sw.svg";
import smartwayLogoSmall from "assets/images/logo_sw_small.svg";

const RootLayout = ({ children }) => {
  const [miniSidenav, setMiniSidenav] = useState(window.innerWidth < 1200);
  const profile = useSelector(state => state.auth.profile)
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()


  const controlRoutePermission = (route) => {
    const { permissions, is_staff } = profile
    if (is_staff && route.permission === 'is_staff') {
      return true
    }
    if (permissions && route.permission) {
      return permissions.includes(route.permission)
    }
    return true
  }

  const navigationWithProfile = getNavigation(profile)
    .filter(route => {
      if (route.collapse && route.collapse.length) {
        route.collapse = route.collapse.filter(r => controlRoutePermission(r))
        if (!route.collapse.length) {
          route.permission = 'hide'
        }
      }
      return controlRoutePermission(route)
    })

  useEffect(() => {
    if (searchParams.has('email')) {
      setSearchParams({})
      dispatch(notify({
        icon: 'check',
        title: 'Login success',
        message: `Welcome back ${searchParams.get('email')}!`,
        type: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      }))
    }
  }, [dispatch, searchParams, setSearchParams])

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  return (
    <>
      <ScrollRestoration />
      <Sidenav
        color="info"
        miniSidenav={miniSidenav}
        setMiniSidenav={setMiniSidenav}
        brand={miniSidenav ? smartwayLogoSmall : smartwayLogo}
        brandName=""
        routes={navigationWithProfile}
      />
      <DashboardLayout miniSidenav={miniSidenav}>
        <DashboardNavbar miniSidenav={miniSidenav} setMiniSidenav={setMiniSidenav} />
        {children ? children : <Outlet />}
      </DashboardLayout>
    </>
  )
}

export default RootLayout