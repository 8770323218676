import { Suspense, useState, useEffect } from 'react'

import { Await, Link, useLoaderData, useSubmit, useSearchParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import { ListSkeleton, FiltersButtonSkeleton } from 'layouts/pages/offsites/list/Skeletons'
import {
  convertQueryStringToFilters,
  getActiveFiltersNumber
} from 'utils/filters'
import FiltersDialog from 'components/Dialogs/FiltersDialog'
import { FilterButton } from 'components/Filters/Filters'

import OffersList from './OffersList'

const OffersListPage = () => {
  const { results, initialFilters } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const isStaff = useSelector((state) => state.auth.profile.is_staff)
  const [view, setView] = useState('table')
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [cloneConfirmationOpen, setCloneConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [markedForClone, setMarkedForClone] = useState(null)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  const openFiltersDialog = () => setFiltersDialogOpen(true)
  const closeFiltersDialog = () => setFiltersDialogOpen(false)

  const handleConfirmDeleteOffsite = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'delete', encType: 'application/json' }
    )
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)

    setCloneConfirmationOpen(false)
    setMarkedForClone(null)
  }

  const handleDeleteOffsite = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }

  const handleCloneOffsite = (id) => {
    setMarkedForClone(id)
    setCloneConfirmationOpen(true)
  }

  const handleConfirmCloneOffsite = () => {
    submit(
      { intent: 'cloneOffsite', id: markedForClone },
      { method: 'post', encType: 'application/json' }
    )
  }

  const handleApplyFilters = (activeFilters) => {
    closeFiltersDialog()
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Offer deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  useEffect(() => {
    if (!!initialFilters) {
      const applied = convertQueryStringToFilters(searchParams, initialFilters)
      const activeFiltersNumber = getActiveFiltersNumber(applied, initialFilters)
      setAppliedFilters(applied)
      setActiveFiltersNumber(activeFiltersNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, initialFilters])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete this offer?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleConfirmDeleteOffsite}
        onCancel={handleCancelConfirmation}
      />
      <ConfirmationDialog
        title={`Are you sure you want to copy this offer?`}
        message="You will copy this offer as is and will have to modify every detail after copying."
        open={cloneConfirmationOpen}
        setOpen={setCloneConfirmationOpen}
        onConfirm={handleConfirmCloneOffsite}
        onCancel={handleCancelConfirmation}
      />
      <Suspense>
        <Await resolve={initialFilters}>
          {(initialFilters) => (
            <FiltersDialog
              open={filtersDialogOpen}
              initialFilters={initialFilters}
              appliedFilters={appliedFilters}
              searchParams={searchParams}
              onApply={handleApplyFilters}
              onClose={closeFiltersDialog}
            />
          )}
        </Await>
      </Suspense>
      <MDBox sx={{ margin: 'auto', marginBottom: '32px' }}>
        <Grid mt={6} container justifyContent="space-between">
        <Suspense fallback={<FiltersButtonSkeleton />}>
            <Await resolve={initialFilters}>
              {() => initialFilters ? (
                <MDBox display="flex" gap={2} alignItems="center">
                  <FilterButton activeFiltersNumber={activeFiltersNumber}>
                    <MDButton
                      color="white"
                      onClick={openFiltersDialog}
                      sx={{
                        border: !!activeFiltersNumber
                          ? '2px solid grey'
                          : '2px solid transparent'
                      }}>
                      Filters &nbsp;<Icon>tunesharp</Icon>
                    </MDButton>
                  </FilterButton>
                  {/* <Tabs value={activeTab} onChange={handleTabChange} aria-label="Upcoming / past offsites" sx={{ '.MuiTab-root': { py: '4px', px: 2}}}>
                    <Tab label="Upcoming" id="upcoming" />
                    <Tab label="Past offsites" id="past"/>
                  </Tabs> */}
                </MDBox>
              ) : <div></div> }
            </Await>
          </Suspense>
          <MDBox>
            <Link to={`/dashboard/offers-offsites/new`}>
              <MDButton color="primary">
                <Icon>add</Icon>&nbsp; Create an offer
              </MDButton>
            </Link>
            <ButtonGroup sx={{ ml: 2 }}>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'grid' ? 'primary' : 'secondary'}
                onClick={() => setView('grid')}>
                <Icon>grid_view</Icon>
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color={view === 'table' ? 'primary' : 'secondary'}
                onClick={() => setView('table')}>
                <Icon>view_list</Icon>
              </MDButton>
            </ButtonGroup>
          </MDBox>
        </Grid>
        <Suspense fallback={<ListSkeleton />}>
          <Await resolve={results}>
            {({ results, page_number, count }) => {
              const pagination = {
                page: searchParams.get('page') || 1,
                lastPage: page_number || Math.ceil(count / 25) || 1,
                count,
                pageSize: parseInt(searchParams.get('page_size')) || 25
              }
              const offers =
                results && results.length
                  ? [...results]
                      .sort((a, b) => {
                        return a.id - b.id
                      })
                      .map((o) => {
                        return {
                          ...o,
                          venue: o.all_in_venue || o.town,
                          venueType: o.all_in_venue ? 'allInVenue' : 'town'
                        }
                      })
                  : []

              return (
                <OffersList 
                  offsites={offers}
                  view={view}
                  pagination={pagination}
                  searchParams={searchParams}
                  onDelete={handleDeleteOffsite}
                  onClone={handleCloneOffsite}
                />
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
    </>
  )
}

export default OffersListPage
