/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.noOverride ? {} : {
        position: "relative",
        backgroundColor: grey[100],
        borderRadius: borderRadius.xl,
        minHeight: "unset",
        padding: pxToRem(4)
      })
    }),

    flexContainer: ({ ownerState }) => ({
      ...(ownerState.noOverride ? {} : {
        height: "100%",
        position: "relative",
        zIndex: 10
      })
    }),

    fixed: ({ ownerState }) => ({
      ...(ownerState.noOverride ? {} : {
        overflow: "unset !important",
        overflowX: "unset !important"
      })
    }),

    vertical: ({ ownerState }) => ({
      ...(ownerState.noOverride ? {} : {
        "& .MuiTabs-indicator": {
          width: "100%",
        }
      })
    }),

    indicator: ({ ownerState }) => ({
      ...(ownerState.noOverride ? {} : {
        height: "100%",
        borderRadius: borderRadius.lg,
        backgroundColor: white.main,
        boxShadow: tabsBoxShadow.indicator,
        transition: "all 500ms ease"
      })
    }),
  },
};

export default tabs;
