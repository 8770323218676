import { useEffect, useState } from 'react'

import Slider, { SliderThumb } from '@mui/material/Slider'
import Input from 'components/Fields/Input'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { styled } from '@mui/material/styles'

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey['700'],
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.grey['700'],
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    },
    '&:active': {
      transform: 'translate(-50%, -50%) scale(1.1)'
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    }
  },
  '& .MuiSlider-track': {
    height: 2,
    background: `${theme.palette.grey['700']} !important`
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : theme.palette.grey[700],
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 2
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: theme.palette.grey['700'],
    transform: 'translate(0, -75%) scale(1)',

    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {}
  }
}))

const CustomThumbComponent = (props) => {
  const { children, ...other } = props
  return <SliderThumb {...other}>{children}</SliderThumb>
}

const RangeSlider = ({
  name,
  label,
  minLabel = 'Min',
  maxLabel = 'Max',
  prefix,
  suffix,
  min = 0,
  max = 100,
  minDistance = 50,
  initialValue = [0, 100],
  value = [0, 100],
  boxStyles = {},
  withInputs,
  onChange
}) => {
  const [intermediateValues, setIntermediateValues] = useState(value || initialValue)
  const handleOnChange = (e, newValues, activeThumb) => {
    if (newValues[1] - newValues[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValues[0], max - minDistance);
        setIntermediateValues([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValues[1], minDistance);
        setIntermediateValues([clamped - minDistance, clamped]);
      }
    } else {
      setIntermediateValues(newValues);
    }
  }

  const onMinChange = (e) => {
    const { value } = e.target
    const _min = Number(value)
    const _max = Number(intermediateValues[1])
    if (Number.isNaN(_min)) return
    const isLowerThanMax = _min < _max
    if (isLowerThanMax) {
      if ((_max - _min) < minDistance) {
        // max should be min + minDistance but also lower than absolute max
        setIntermediateValues([_min, Math.min(_min + minDistance, max)])
      } else {
        setIntermediateValues([Math.max(min, _min), _max])
      }
    } else {
      setIntermediateValues([Math.min(_min + minDistance, max - minDistance), Math.min(max, _min + minDistance)])
    }
  }
  const onMaxChange = (e) => {
    const { value } = e.target
    const _min = Number(intermediateValues[0])
    const _max = Number(value)
    if (Number.isNaN(_max)) return
    const isHigherThanMin = _max > _min
    if (isHigherThanMin) {
      if ((_max - _min) < minDistance) {
        setIntermediateValues([Math.max(min, _max - minDistance), _max])
      } else {
        setIntermediateValues([_min, Math.min(max, _max)])
      }
    } else {
      setIntermediateValues([Math.max(min, _max - minDistance), Math.min(max, _max)])
    }
  }


  useEffect(() => {
    onChange({ target: { name, value: intermediateValues } })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intermediateValues])

  return (
    <MDBox mb={4} {...boxStyles}>
      <MDBox mb={1} mx={2}>
        {label ? <MDTypography variant="button" display="block" mb={3}>{label}</MDTypography> : null}
      </MDBox>
      <MDBox mx={2}>
        <CustomSlider
          name={name}
          slots={{ thumb: CustomThumbComponent }}
          getAriaLabel={() => `${label} Range`}
          valueLabelDisplay="auto"
          valueLabelFormat={value => `${prefix ? `${prefix} ` : ' '}${value} ${suffix ? ` ${suffix}` : ''}`}
          min={min}
          max={max}
          value={intermediateValues}
          defaultValue={[value.min, value.max]}
          onChange={handleOnChange}
        />
      </MDBox>
      {withInputs ? (
        <MDBox display="flex" justifyContent="space-evenly" ml={1} mt={1}>
          <Input
            type="number"
            label={minLabel}
            prefix={prefix}
            boxStyles={{ marginBottom: 0 }}
            max={intermediateValues[1]}
            value={intermediateValues[0]}
            onChange={onMinChange}
            />
          <Input
            type="number"
            label={maxLabel}
            prefix={prefix}
            boxStyles={{ marginBottom: 0 }}
            min={intermediateValues[0]}
            value={intermediateValues[1]}
            onChange={onMaxChange}
          />
        </MDBox>
      ) : null}
    </MDBox>
  )
}

export default RangeSlider
