import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import Image from "components/Image"
import Icon from '@mui/material/Icon'

const DefaultLink = ({ children, href }) => (
  <MDTypography color="dark" variant="body2">
    <a style={{ borderBottom: '1px solid #7b809a', color: 'inherit' }} href={href} target="_blank" rel="noreferrer">{children}</a>
  </MDTypography>
)

const ParticipantAccommodation = ({ accommodation, participant, participants, room }) => {
  const roommates = participants.filter(p => p.room && p.room === participant.room)
  const housemates = participants.filter(p => p.accommodation && p.accommodation !== participant.accommodation)

  const { detail: { title: roomName } } = room
  const { name, address, latitude, longitude, park_name, park_address, park_latitude, park_longitude, phone_number } = accommodation
  const getInfos = () => {
    let infos = []
    if (name && roomName) {
      infos.push({
        icon: 'home_outlined',
        node: <DefaultLink href={`https://listings.smartway.work/accommodations/${accommodation.id}`}>{`${name} | ${roomName}`}</DefaultLink>
      })
    }
    if (address && latitude && longitude) {
      infos.push({
        icon: 'fmd_good_outlined',
        node: <DefaultLink href={`https://maps.google.com/maps?q=${latitude},${longitude}`}>{address}</DefaultLink>
      })
    }
    if (park_name && park_address && park_latitude && park_longitude) {
      infos.push({
        icon: 'directions_car_filled_outlined',
        node: <DefaultLink href={`https://maps.google.com/maps?q=${park_latitude},${park_longitude}`}>{park_name}</DefaultLink>
      })
    }
    if (phone_number) {
      infos.push({
        icon: 'call_outlined',
        node: <DefaultLink href={`tel:${phone_number}`}>{phone_number}</DefaultLink>
      })
    }
    return infos
  }

  return (
    <MDBox mx={2} mb={4}>
      <MDTypography variant="h4" fontWeight="medium" mb={2}>
        Accommodation
      </MDTypography>
      <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={8}>
        <MDBox width={{ xs: '100%', sm: '50%' }}>
          <Image formats={accommodation.featured_image?.formats} format="desktop" alt={accommodation.name} sx={{ maxHeight: 320 }}/>
        </MDBox>
        <MDBox width={{ xs: '100%', sm: '50%' }} mt={{ xs: 2, sm: 0 }}>
          <MDBox>
            <MDTypography variant="h6" color="dark">Accommodation Informations</MDTypography>
            {getInfos().map((info, index) => (
              <MDBox key={index} display="flex" alignItems="center" gap={1} mt={index ? 1 : 2}>
                <MDBox
                  display="flex"  
                  alignItems="center"
                  justifyContent="center"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <Icon>{info.icon}</Icon>
                </MDBox>
                <MDBox>
                  {info.node}
                </MDBox>
              </MDBox>
            ))}
          </MDBox>
          { housemates && !!housemates.length ? (
            <MDBox mt={2}>
              <MDTypography variant="h6" color="dark">Housemates</MDTypography>
              <MDTypography variant="body2" color="dark">{housemates.map(h => h.name).join(', ')}</MDTypography>
            </MDBox>
          ) : null }
          { roommates && !!roommates.length ? (
            <MDBox mt={2}>
              <MDTypography variant="h6" color="dark">Roommates</MDTypography>
              <MDTypography variant="body2" color="dark">{roommates.map(h => h.name).join(', ')}</MDTypography>
            </MDBox>
          ) : null }

        </MDBox>

      </MDBox>
    </MDBox>
  )
}

export default ParticipantAccommodation