import { useState, useEffect } from 'react'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDPagination from 'components/MDPagination'
import MDTypography from 'components/MDTypography'
import { grey } from '@mui/material/colors'

import { useLocation, Link } from 'react-router-dom'

const Pagination = ({ page, lastPage, searchParams }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()
  const _page = Number(page)
  const _lastPage = Number(lastPage)

  const PageLink = ({ to, children }) => {
    const isAllowedUrl = to >= 1 && to <= _lastPage && to !== _page
    if (isAllowedUrl) {
      return (
        <Link
          to={`${location.pathname}?page=${to}${search ? `&${search}` : ''}`}>
          {children}
        </Link>
      )
    }
    return (
      <MDBox
        sx={{
          pointerEvents: 'none',
          '> button': {
            cursor: 'default !important',
            pointerEvents: 'none !important'
          }
        }}>
        {children}
      </MDBox>
    )
  }

  useEffect(() => {
    const _searchParams = new URLSearchParams(searchParams)
    _searchParams.delete('page')
    const stringifiedSearchParams = _searchParams.toString()
    if (stringifiedSearchParams !== search) {
      setSearch(stringifiedSearchParams)
    }
  }, [searchParams, search])

  return (
    <MDPagination>
      <PageLink
        to={1}
        style={{ pointerEvents: _page === 1 ? 'none' : 'inherit' }}>
        <MDPagination item>
          <Icon sx={{ marginRight: '-10px' }}>keyboard_arrow_left</Icon>
          <Icon>keyboard_arrow_left</Icon>
        </MDPagination>
      </PageLink>
      <PageLink to={_page === 1 ? 1 : _page - 1}>
        <MDPagination item>
          <Icon>keyboard_arrow_left</Icon>
        </MDPagination>
      </PageLink>
      {_page > 2 ? (
        <MDTypography paddingLeft={1} paddingRight={1} paddingBottom={1}>
          ...
        </MDTypography>
      ) : null}
      {_page > 1 ? (
        <PageLink to={_page - 1}>
          <MDPagination item>{_page - 1}</MDPagination>
        </PageLink>
      ) : null}
      <MDPagination
        item
        active
        sx={{
          background: `linear-gradient(195deg, ${grey[500]}, ${grey[600]})`
        }}>
        {page}
      </MDPagination>
      {_page < _lastPage ? (
        <PageLink to={_page + 1}>
          <MDPagination item>{_page + 1}</MDPagination>
        </PageLink>
      ) : null}
      {_page < _lastPage - 1 ? (
        <MDTypography paddingLeft={1} paddingRight={1} paddingBottom={1}>
          ...
        </MDTypography>
      ) : null}
      <PageLink to={_page + 1}>
        <MDPagination item>
          <Icon>keyboard_arrow_right</Icon>
        </MDPagination>
      </PageLink>
      <PageLink to={_lastPage}>
        <MDPagination item>
          <Icon>keyboard_arrow_right</Icon>
          <Icon sx={{ marginLeft: '-10px' }}>keyboard_arrow_right</Icon>
        </MDPagination>
      </PageLink>
    </MDPagination>
  )
}

export default Pagination
