import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'

import {
  Card,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon
} from '@mui/material'
import SimpleLocationCard from 'components/Cards/SimpleLocationCard/SimpleLocationCard'

const SampleAccommodationsDialog = ({ open, selected, venue, venueType, title, error, iconStyles, onClose, onSave }) => {
  const dispatch = useDispatch()
  const [venueAccommodations, setVenueAccommodations] = useState(null)
  const [selectedAccommodations, setSelectedAccommodations] = useState(selected || [])

  const handleSelectAccommodation = (acc) => {
    if (selectedAccommodations && selectedAccommodations.find((a) => a.id === acc.id)) {
      setSelectedAccommodations(selectedAccommodations.filter((a) => a.id !== acc.id))
    } else {
      setSelectedAccommodations([...(selectedAccommodations || []), acc])
    }
  }
 

  const handleOnSave = () => {
    if (onSave && typeof onSave === 'function') {
      onSave(selectedAccommodations)
    }
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    const fetchAccommodations = async () => {
      const venueIsObject = typeof venue === 'object'
      const entity = venueType === 'allInVenue' ? 'venueAccommodation' : 'accommodation'
      const action = venueType === 'allInVenue' ? 'fetchEntities' : 'fetchEntities'
      const params = venueType === 'allInVenue' ? { venue: venueIsObject ? venue.id : venue } : { town: venueIsObject ? venue.id : venue }
      const response = await dispatch(smartwayApi.endpoints[action].initiate({ entity, ...params }))
      if (response && response.data) {
        setVenueAccommodations(response.data.results)
      }
    }
    if (!venueAccommodations && !!venue) {
      fetchAccommodations()
    }
  }, [dispatch, venue, venueAccommodations, venueType])

  useEffect(() => {
    setSelectedAccommodations(selected)
  }, [selected])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ marginLeft: { xl: '250px' }, 'div[role=dialog]': {
        '&::-webkit-scrollbar': {
          width: '6px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#eee',
          borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ccc',
          borderRadius: '2px'
        }
      }}}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="dialogContent" sx={{
         
        }}>
          <Grid container spacing={4}>
            { venueAccommodations && !!venueAccommodations.length ? venueAccommodations.map((accommodation) => {
              const selected = selectedAccommodations && selectedAccommodations.find(a => a.id === accommodation.id)
              const defaultCardStyles = {
                position: 'relative',
                cursor: 'pointer',
                p: 2,
                height: '100%'
              }
              return (
                <Grid key={`${accommodation.id}-accommodation`} item xs={6}>
                  <Card 
                    sx={selected ? {...defaultCardStyles, boxShadow: 'none', '.sample-accommodation__icon': { opacity: 1, visibility: 'visible' } } : defaultCardStyles}
                    onClick={() => handleSelectAccommodation(accommodation)}
                  >
                    <MDBox className="sample-accommodation__icon" sx={iconStyles}>
                      <Icon fontSize="small">check</Icon>
                    </MDBox>
                    <SimpleLocationCard {...accommodation} showThumbnails={2}/>
                  </Card>

                </Grid>
              )
            }) : null }
          
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'sticky',
            bottom: 0,
            p: 2,
            background: 'white',
            zIndex: 10
          }}
        >
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={handleOnSave}
            disabled={!selectedAccommodations}
          >
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default SampleAccommodationsDialog
