import { useState, useEffect } from 'react'
import { Link, useActionData, useLoaderData, useSubmit, useSearchParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import Pagination from 'components/Pagination'
import DataTable from 'components/Tables/DataTable'
import CompaniesActionsCell from './components/CompaniesActionsCell'

import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'

import { debounce } from '@mui/material/utils'
import Form from 'components/Form/Form'


const CompanyListPage = () => {
  const actionData = useActionData()
  const { results, pagination, initialFilters } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const submit = useSubmit()
  const dispatch = useDispatch()
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [appliedFilters] = useState({})

  const { page, lastPage } = pagination

  const table = {
    columns: [
      { Header: 'logo', accessor: 'logo' },
      { Header: 'name', accessor: 'name' },
      { Header: 'email domains', accessor: 'accepted_domains' },
      { Header: 'vat', accessor: 'vat' },
    ],
    rows: results && !!results.length ? results.map(
      (company) => {
        const { name, vat, logo_formats } = company
        return {
          logo: <MDAvatar src={logo_formats.mobile} alt={name} />,
          name,
          accepted_domains: company.accepted_domains.join(', '),
          vat,
          __data: company
        }
      }
    ) : []}

  const handleDelete = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'DELETE', encType: 'application/json' }
    )
  }

  const handleDeleteCompany = (company) => {
    setMarkedForDel(company.id)
    setDelConfirmationOpen(true)
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleApplyFilters = (activeFilters) => {
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  const debouncedSearch = (request) => {
    debounce(handleApplyFilters(request), 700)
  }
  const handleFieldChange = (event) => {
    const { name, value } = event.target
    debouncedSearch({ [name]: value })
  }

  if (actionData && actionData.error) {
    dispatch(
      notify({
        type: 'error',
        title: 'There was an error',
        message: 'Company could not be deleted. Check if there are offsites associated with it',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
    )
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Company deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  return (
    <>
      <ConfirmationDialog
        title="Are you sure you want to delete the company?"
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleDelete}
        onCancel={handleCancelConfirmation}
      />
      <MDBox sx={{ margin: 'auto' }}>
        <Grid container justifyContent="space-between" mt={6}>
          { initialFilters ? (
            <Form
              id={initialFilters.id}
              {...initialFilters}
              values={appliedFilters}
              watchedFields={initialFilters.watchedFields}
              onFieldChange={handleFieldChange}
            />
          ) : <div></div> }
          <Link to="/dashboard/companies/new">
            <MDButton color="primary">
              <Icon>add</Icon>&nbsp; Add Company
            </MDButton>
          </Link>
        </Grid>
        { results && !!results.length ? (
          <Grid
            container
            justifyContent="center"
            alignItems="start"
            sx={{ height: '100%', mt: 4, gap: 4 }}>
          
            <DataTable
              title="Companies"
              table={table}
              actionsOnRowHover={
                <CompaniesActionsCell
                  onDelete={(company) => handleDeleteCompany(company)}
                />
              }
            />
          </Grid>
        
        ) : (
          <MDTypography variant="h5" color="text">
            No Companies
          </MDTypography>
        )}
      </MDBox>
      { lastPage > 1 ? (
        <Grid container justifyContent="center" marginTop={6}>
          <Pagination page={page || 1} lastPage={lastPage} />
        </Grid>
      ) : null }
    </>
  )
}

export default CompanyListPage
