import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'

const ske = { variant: 'rounded', animation: 'wave' }
export const ReviewSkeleton = () => {
  return (
    <MDBox>
      <MDBox>
        <Skeleton {...ske} width="140px" height={25} />
        <MDBox display="flex" gap={2} mt={1}>
          <Skeleton {...ske} width="30px" height={25} />
          <Skeleton {...ske} width="240px" height={25} />
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <Skeleton {...ske} width="120px" height={20} />
        <MDBox display="flex" gap={2} mt={1}>
          <Skeleton {...ske} width="30px" height={25} />
          <Skeleton {...ske} width="240px" height={25} />
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <Skeleton {...ske} width="100px" height={20} sx={{ mt: 1 }}/>
        <Skeleton {...ske} width="140px" height={25} sx={{ mt: 1 }} />
        <MDBox display="flex" gap={2} mt={1}>
          <Skeleton {...ske} width="30px" height={25} />
          <Skeleton {...ske} width="240px" height={25} />
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export const AgendaSkeleton = () => {
  return (
    <MDBox>
      <Skeleton {...ske} width="140px" height={25} sx={{ mt: 1 }} />
        <MDBox display="flex" gap={2} mt={1}>
          <Skeleton {...ske} width="30px" height={25} />
          <Skeleton {...ske} width="240px" height={25} />
        </MDBox>
    </MDBox>
  )
}