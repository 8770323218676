import { useState, useEffect, useCallback } from 'react'

import { Link, useSubmit, useActionData } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'
import { format } from 'date-fns'

import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import NotificationItem from 'components/Items/NotificationItem'
import PeopleListDialog from 'components/Dialogs/PeopleListDialog'
import SinglePeopleDialog from 'components/Dialogs/SinglePeopleDialog'
import PeopleCompanyDialog from 'components/Dialogs/PeopleCompanyDialog'
import PreferencesDialog from 'components/Dialogs/PreferencesDialog'
import PeopleCardsDialog from 'components/Dialogs/PeopleCardsDialog'
import ParticipantsSettingsDialog from 'components/Dialogs/ParticipantsSettingsDialog'
import ParticipantsExportDialog from 'components/Dialogs/ParticipantsExportDialog'
import ParticipantStatusDialog from 'components/Dialogs/ParticipantStatusDialog'
import { FilterButton } from 'components/Filters/Filters'

import ParticipantStatusCell from '../components/participants/ParticipantStatusCell'
import AddPeopleButton from '../components/participants/AddPeopleButton'
import SettingsButton from 'components/SettingsButton'
import BulkActionsToolbar from '../components/participants/BulkActionsToolbar'

import { STATUSES_NAMES } from 'utils/offsiteStatus'
import { bulkDeleteParticipants, bulkChangeParticipantsStatus } from 'utils/participants'

import { removeDuplicates } from 'utils/functions'
import PeopleCard from '../components/participants/PeopleCard'
import SendInvitationsDialog from 'components/Dialogs/SendInvitationsDialog'

import noResults from 'assets/illustrations/noresults.svg'
import { formatValuesToServer } from 'components/Dialogs/schemas/participants-settings.js'
import Table from 'components/Tables/Table/Table'
import ParticipantItineraryDialog from 'components/Dialogs/ParticipantItineraryDialog'

const INVITE_OPTIONS = [
  {
    type: 'company',
    title: 'Invite everyone from your company',
    subtitle: 'Add to the list everyone from your company already registered on Smartway',
    icon: 'group_add'
  },
  {
    type: 'list',
    title: 'Add people with a list of emails (xls, csv)',
    subtitle:
      'Everyone will be notified via email, people not yet registered on Smartway will be invited',
    icon: 'file_upload'
  },
  {
    type: 'single',
    title: 'Add employees',
    subtitle: 'Add single employee choosing from the users list or by adding its email',
    icon: 'person_add'
  }
]

const OPTIONS = [
  {
    id: 'settings',
    label: 'Customize preferences questions',
    icon: 'settings'
  },
  {
    id: 'export_xls',
    label: 'Export list as XLS',
    icon: 'list'
  }
]

const FILTER_MENU = [
  {
    label: 'By Status',
    id: 'status',
    options: [
      { label: 'Not invited', value: 'not_invited' },
      { label: 'Not registered', value: 'not_registered' },
      { label: 'Not confirmed', value: 'not_confirmed' },
      { label: 'Confirmed', value: 'confirmed' },
      { label: 'Declined', value: 'declined' }
    ]
  }
  // {
  //   label: 'By Needs',
  //   id: 'needs',
  //   options: [
  //     { label: 'Has special needs', value: 'yes' },
  //     { label: 'No special needs', value: 'no' }
  //   ]
  // },
  // {
  //   label: 'By Transfer',
  //   id: 'needs',
  //   options: [
  //     { label: 'Has special needs', value: 'yes' },
  //     { label: 'No special needs', value: 'no' }
  //   ]
  // }
]

const PeopleCardWrapper = ({ children, open, peopleListTable, activeFilters, onClose }) => {
  return peopleListTable || activeFilters.length ? (
    <PeopleCardsDialog open={open} title="Add people to your offsite" onClose={onClose}>
      {children}
    </PeopleCardsDialog>
  ) : (
    <MDBox>{children}</MDBox>
  )
}

const NumberCard = ({ number, label, color }) => {
  return (
    <Card variant="outlined">
      <MDBox width="88px" p={1} display="flex" flexDirection="column" alignItems="center">
        <MDTypography variant="h2">{number}</MDTypography>
        <Chip label={label} color={color} variant="outlined" size="small" />
      </MDBox>
    </Card>
  )
}

const ParticipantsStep = ({
  profile,
  offsite,
  offsiteVenue,
  participants,
  dietaryOptions,
  allergiesOptions,
  onBack
}) => {
  const dispatch = useDispatch()
  const actionData = useActionData()

  const mapParticipants = useCallback(
    (_participants) => {
      return [..._participants]
        .sort((a, b) => {
          return a.last_name && b.last_name ? a.last_name.localeCompare(b.last_name) : 0
        })
        .map((participant) => {
          if (!participant) return
          const { last_invited_at, confirmation, user, ...p } = participant
          const status = []
          status.push(last_invited_at ? 'invited' : 'not_invited')
          status.push(
            confirmation !== null ? (!!confirmation ? 'confirmed' : 'declined') : 'not_confirmed'
          )
          status.push(user ? 'registered' : 'not_registered')

          return {
            ...participant,
            name: `${participant.first_name} ${participant.last_name}`,
            status: status
              .filter((s) => !!s)
              .sort()
              .join('|')
          }
        })
    },
    [participants]
  )

  const [peopleList, setPeopleList] = useState(participants ? mapParticipants(participants) : [])
  const [invitationsList, setInvitationsList] = useState(participants || [])
  const [invitationsInitialStep, setInvitationsInitialStep] = useState(undefined)
  const [peopleListTable, setPeopleListTable] = useState(null)
  const [peopleDialogOpen, setPeopleDialogOpen] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [participantsSelection, setParticipantsSelection] = useState([])
  const [defaultSelectedParticipants, setDefaultSelectedParticipants] = useState(null)
  const [sendInvitesInitialValues, setSendInvitesInitialValues] = useState({})

  const [options, setOptions] = useState(INVITE_OPTIONS)
  const submit = useSubmit()

  const inviteDisabled =
    offsite?.status.includes(STATUSES_NAMES.CONFIRMED_PARTICIPANTS_LIST) ||
    offsite?.max_number_participants <= participants.length
  const inviteTooltip = offsite?.status.includes(STATUSES_NAMES.CONFIRMED_PARTICIPANTS_LIST)
    ? 'Participants list already confirmed. Contact your Smartway referent in case of need.'
    : offsite?.max_number_participants <= participants.length
    ? 'Maximum number of participants reached. Contact your Smartway referent in case of need.'
    : null

  const numberCards = [
    {
      number: peopleList?.filter((p) => p.confirmation === true).length || 0,
      label: 'Confirmed',
      color: 'success'
    },
    {
      number: peopleList?.filter((p) => p.confirmation === false).length || 0,
      label: 'Declined',
      color: 'error'
    },
    {
      number: peopleList?.filter((p) => p.confirmation === null).length || 0,
      label: 'No reply',
      color: 'warning'
    }
  ]

  const handleCardClick = (type) => {
    setPeopleDialogOpen(type)
  }

  const handlePreferencesClick = (participant) => {
    setPeopleDialogOpen('preferences')
    setSelectedParticipant(participant)
  }
  const handleItineraryClick = (participant) => {
    setPeopleDialogOpen('itinerary')
    setSelectedParticipant(participant)
  }

  const handleAddPeopleListClick = () => {
    setPeopleDialogOpen('cards')
  }

  const handleCloseMenu = () => setOpenFilterMenu(false)
  const handleOpenFilterMenu = (e) => {
    setOpenFilterMenu(e.currentTarget)
  }

  const handleBack = () => {
    if (onBack && typeof onBack === 'function') {
      onBack()
    }
  }

  const handleFilterChange = (filter, filterSection) => {
    setOpenFilterMenu(false)
    const existingActiveFilterSection = activeFilters.find((f) => f.id === filterSection)
    if (existingActiveFilterSection) {
      const isActiveFilter = existingActiveFilterSection.value.split('|').find((f) => f === filter)
      setActiveFilters(
        activeFilters.map((section) => {
          if (section.id === filterSection) {
            return {
              ...section,
              value: isActiveFilter
                ? section.value
                    .split('|')
                    .filter((f) => !!f && f !== filter)
                    .sort()
                    .join('|')
                : [existingActiveFilterSection.value, filter]
                    .filter((f) => !!f)
                    .sort()
                    .join('|')
            }
          }
          return section
        })
      )
    } else {
      setActiveFilters([
        ...activeFilters,
        {
          id: filterSection,
          value: filter
        }
      ])
    }
  }

  const handleInviteParticipant = useCallback(
    ({ emails, manager, custom_text, force = false }) => {
      console.log({ emails, manager, custom_text })
      submit(
        {
          emails,
          force,
          manager: !!manager ? manager : '',
          custom_text,
          offsiteId: offsite.id,
          entity: 'participants',
          action: 'invite'
        },
        { method: 'post', encType: 'application/json' }
      )
    },
    [offsite?.id, profile.first_name, profile.last_name, submit]
  )

  const handleInviteSingleParticipant = useCallback(
    (participant) => {
      setInvitationsList([participant])
      setInvitationsInitialStep(1)
      setPeopleDialogOpen('invite')
    },
    [setInvitationsList, setInvitationsInitialStep]
  )

  const handleRemoveParticipant = useCallback(
    (participant) => {
      submit(
        {
          id: participant.id,
          offsiteId: offsite.id,
          entity: 'participants'
        },
        {
          method: 'delete',
          encType: 'application/json'
        }
      )
    },
    [offsite?.id, submit]
  )

  const handleRowClick = (row) => {
    if (!peopleDialogOpen !== 'invite') {
      handlePreferencesClick(row)
    }
  }

  const handleParticipantSelection = (selection) => {
    setDefaultSelectedParticipants(null)
    setParticipantsSelection(selection)
  }

  const handleSelectAll = () => {
    setDefaultSelectedParticipants(peopleList)
    setParticipantsSelection(peopleList)
  }

  const handlePeopleDialogClose = () => {
    setPeopleDialogOpen(false)
  }

  const handlePeopleListSave = (list) => {
    setPeopleDialogOpen(false)
    submitPeopleList(list)
  }

  const handlePreferencesSave = ({ values, participant, participantInfos = {} }) => {
    const profilePreferencesMethod = !!participant.offsite_preference ? 'patch' : 'post'
    submit(
      {
        intent: 'participant-preferences',
        ...values,
        id: offsite.id,
        participantId: participant.id,
        user: participant.user,
        profilePreferencesMethod,
        participantInfos
      },
      { method: 'patch', encType: 'application/json' }
    )
    handlePeopleDialogClose()
  }

  const handleSettingsPreferencesSave = ({ values, customValues }) => {
    const formValues = formatValuesToServer({
      values,
      customValues,
      options: { participants: peopleList }
    })

    submit(
      {
        preference_form: formValues,
        id: offsite.id,
        company: offsite.company,
        entity: 'offsite',
        subentity: 'preferences'
      },
      { method: 'patch', encType: 'application/json' }
    )
    handlePeopleDialogClose()
  }

  const submitPeopleList = useCallback(
    (list) => {
      const uniquePeopleList = removeDuplicates(
        [...peopleList, ...list].map(({ first_name, last_name, email }) => ({
          first_name,
          last_name,
          email
        })),
        'email'
      )
      submit(
        {
          participants: uniquePeopleList,
          offsiteId: offsite.id,
          entity: 'participants'
        },
        { method: 'post', encType: 'application/json' }
      )
    },
    [offsite?.id, peopleList, submit]
  )

  const sendInvitationsConfirm = (action) => {
    if (action === 'main') {
      const someoneAlreadyInvited = removeDuplicates(peopleList, 'email').some(
        (p) => !!p.last_invited_at
      )
      setSendInvitesInitialValues(
        someoneAlreadyInvited ? { not_invited: true } : { everyone: true }
      )
    }
    if (action === 'selection') {
      setSendInvitesInitialValues({ selected: true })
    }

    setPeopleDialogOpen('invite')
  }

  const sendInvitations = async ({ peopleValues, mailValues }) => {
    setPeopleDialogOpen(null)
    const { not_invited, not_confirmed, not_registered, selected } = peopleValues
    let inviteList = invitationsList
    if (selected) {
      inviteList = participantsSelection
    }
    if (not_invited) {
      inviteList = inviteList.filter((p) => !p.last_invited_at)
    }
    if (not_confirmed) {
      inviteList = inviteList.filter(
        (p) => p.user && p.confirmation === null && !p.replied_at && p.user
      )
    }
    if (not_registered) {
      inviteList = inviteList.filter((p) => !p.user)
    }

    handleInviteParticipant({
      emails: inviteList.map(({ email }) => email),
      force: true,
      ...mailValues
    })
  }

  // BULK ACTIONS
  const handleBulkDelete = async () => {
    const response = await bulkDeleteParticipants({
      offsiteId: offsite.id,
      selection: participantsSelection,
      dispatch
    })
    if (response) {
      const { success, error } = response
      dispatch(
        notify({
          title: success ? 'All right!' : 'Ops!',
          message: success ? 'Participants deleted successfully' : error,
          color: success ? 'success' : 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
      submit({ onlyReload: true }, { method: 'post', encType: 'application/json' })
    }
  }
  const handleBulkChangeStatus = async ({ confirmation }) => {
    const response = await bulkChangeParticipantsStatus({
      offsiteId: offsite.id,
      selection: participantsSelection,
      dispatch,
      confirmation: confirmation === 'confirmed'
    })
    if (response) {
      const { success, error } = response
      dispatch(
        notify({
          title: success ? 'All right!' : 'Ops!',
          message: success ? 'Participants status updated successfully' : error,
          color: success ? 'success' : 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
      submit({ onlyReload: true }, { method: 'post', encType: 'application/json' })
      setPeopleDialogOpen(null)
    }
  }

  const createTableFromList = useCallback(
    (list) => {
      const preferenceForm = offsite?.preference_form
      const showSpecialNeeds = preferenceForm?.fieldsets.find(
        (f) => f.name === 'personal_infos'
      )?.active
      const showTransfer = preferenceForm?.fieldsets.find((f) => f.name === 'transfer')?.active
      const showRoom = preferenceForm?.fieldsets.find((f) => f.name === 'room_sharing')?.active
      return {
        columns: [
          {
            header: 'name',
            size: 150,
            enableSorting: true,
            accessorFn: (row) => `${row.first_name} ${row.last_name}`
          },
          {
            header: 'contact',
            size: 200,
            accessorKey: 'email',
            enableSorting: true,
            Cell: ({ row, renderedCellValue }) => {
              const { user } = row.original
              return (
                <MDBox display="block">
                  <MDTypography variant="button" color="text">
                    {renderedCellValue}
                  </MDTypography>
                  <MDTypography variant="button" color="text" display="flex" alignItems="center">
                    {user && user.mobile ? (
                      user.mobile
                    ) : (
                      <>
                        <Icon fontSize="small" color="text" sx={{ mr: 1 }}>
                          warning
                        </Icon>{' '}
                        No mobile phone
                      </>
                    )}
                  </MDTypography>
                </MDBox>
              )
            }
          },
          ...(showSpecialNeeds
            ? [
                {
                  header: 'needs',
                  accessorKey: 'needs',
                  size: 180,
                  Cell: ({ row }) => {
                    const { offsite_preference, user } = row.original

                    let preferences = offsite_preference
                    let userPreferences = user && user.user_preferences
                    if (!preferences) {
                      preferences = {}
                    }
                    if (!userPreferences) {
                      userPreferences = {}
                    }

                    const { special_needs } = preferences
                    const { dietary_options, dietary_options_other, allergies, allergies_other } =
                      userPreferences

                    return (
                      <MDBox display="block">
                        <MDTypography
                          variant="button"
                          color="text"
                          dangerouslySetInnerHTML={{ __html: special_needs || '' }}></MDTypography>
                        {(dietary_options && dietary_options.length) || dietary_options_other ? (
                          <>
                            <MDTypography
                              display="block"
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              Dietary options
                            </MDTypography>
                            <MDTypography display="inline" variant="button" color="text">
                              {dietary_options
                                ? dietary_options.map((o, i) => (
                                    <>
                                      <span key={o}>
                                        {dietaryOptions.find((a) => a.id === o).name}
                                        {i === dietary_options.length - 1 ? '' : ', '}
                                      </span>
                                      {i === dietary_options.length - 1 && dietary_options_other ? (
                                        <span key="other">, {dietary_options_other}</span>
                                      ) : null}
                                    </>
                                  ))
                                : null}
                            </MDTypography>
                          </>
                        ) : null}
                        {(allergies && allergies.length) || allergies_other ? (
                          <>
                            <MDTypography
                              display="block"
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              Allergies
                            </MDTypography>
                            <MDTypography variant="button" color="text" display="inline">
                              {allergies
                                ? allergies.map((o, i) => (
                                    <>
                                      <span key={o}>
                                        {allergiesOptions.find((a) => a.id === o).name}
                                        {i === allergies.length - 1 ? '' : ', '}
                                      </span>
                                      {i === allergies.length - 1 && allergies_other ? (
                                        <span key="other">, {allergies_other}</span>
                                      ) : null}
                                    </>
                                  ))
                                : null}
                            </MDTypography>
                          </>
                        ) : null}
                      </MDBox>
                    )
                  }
                }
              ]
            : []),
          ...(showTransfer
            ? [
                {
                  header: 'transfer',
                  size: 260,
                  accessorFn: (row) =>
                    row.offsite_preference
                      ? `${row.offsite_preference.transfer} ${
                          row.offsite_preference.transfer_return
                            ? ` - ${row.offsite_preference.transfer_return}`
                            : ''
                        }`
                      : '',
                  Cell: ({ row, renderedCellValue }) => {
                    const { offsite_preference } = row.original
                    const transferDetails = offsite?.preference_form?.fieldsets?.find(
                      (f) => f.name === 'transfer_details'
                    )
                    return renderedCellValue ? (
                      <MDBox>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="medium">
                          Transfer {offsite_preference?.transfer_return ? '(round trip)' : ''}
                        </MDTypography>
                        <MDTypography variant="button" color="text">
                          {renderedCellValue}
                        </MDTypography>
                        {transferDetails?.active ? (
                          <>
                            <MDTypography
                              display="block"
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              Transfer details
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                              {offsite_preference?.transfer_from
                                ? `From ${offsite_preference.transfer_from}`
                                : ''}
                              {offsite_preference?.transfer_to
                                ? ` - to ${offsite_preference.transfer_to}`
                                : ''}{' '}
                            </MDTypography>
                            <MDTypography variant="button" color="text" display="block">
                              {offsite_preference?.arrival_date
                                ? `Arrival: ${format(
                                    new Date(offsite_preference.arrival_date),
                                    'dd/MM/yyyy HH:mm'
                                  )} ${
                                    offsite_preference.arrival_reservation_number
                                      ? `(${offsite_preference.arrival_reservation_number})`
                                      : ''
                                  }`
                                : ''}
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                              {offsite_preference?.departure_date
                                ? `Departure: ${format(
                                    new Date(offsite_preference.departure_date),
                                    'dd/MM/yyyy HH:mm'
                                  )} ${
                                    offsite_preference.departure_reservation_number
                                      ? `(${offsite_preference.departure_reservation_number})`
                                      : ''
                                  }`
                                : ''}{' '}
                            </MDTypography>
                          </>
                        ) : null}
                      </MDBox>
                    ) : null
                  }
                }
              ]
            : []),
          ...(showRoom
            ? [
                {
                  header: 'room',
                  accessorFn: (row) => row.offsite_preference?.room_sharing === 'DOUBLE',
                  Cell: ({ row, renderedCellValue }) => {
                    const { offsite_preference } = row.original
                    const roommates = offsite?.preference_form?.fieldsets?.find(
                      (f) => f.name === 'roommates'
                    )
                    const fieldType = roommates ? roommates.field_type : null
                    return (
                      <MDBox>
                        <span style={{ display: 'none' }}>{renderedCellValue}</span>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="medium">
                          Shares room?
                        </MDTypography>
                        <MDTypography variant="button" color="text">
                          {offsite_preference?.room_sharing
                            ? offsite_preference?.room_sharing === 'DOUBLE'
                              ? 'Yes'
                              : 'No'
                            : 'Not specified'}
                        </MDTypography>
                        {roommates?.active && offsite_preference?.room_sharing === 'DOUBLE' ? (
                          <>
                            <MDTypography
                              display="block"
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              Roommate preference
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                              {fieldType === 'SELECT'
                                ? peopleList
                                    .filter((o) => offsite_preference?.roommates.includes(o.id))
                                    .map((r) => r.name)
                                    .join(', ')
                                : offsite_preference?.roommates_string.join(', ')}
                            </MDTypography>
                          </>
                        ) : null}
                      </MDBox>
                    )
                  }
                }
              ]
            : []),
          {
            header: 'status',
            accessorKey: 'status',
            align: 'center',
            filterFn: (row, id, filterValue) => {
              const splittedFilterValue = filterValue.split('|')
              const rowValue = row.getValue(id).split('|')
              return splittedFilterValue.every((v) => rowValue.includes(v))
            },
            size: 150,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <>
                  <span style={{ display: 'none' }}>{renderedCellValue}</span>
                  <ParticipantStatusCell
                    participant={row.original}
                    offsite={{ ...offsite, venue: offsiteVenue }}
                    profile={profile}
                    onInvite={handleInviteSingleParticipant}
                  />
                </>
              )
            }
          }
        ],
        rows: list
      }
    },
    [offsite, handleInviteSingleParticipant]
  )

  useEffect(() => {
    if (!!peopleList.length) {
      const uniquePeopleList = removeDuplicates(peopleList, 'email')
      setPeopleListTable(createTableFromList(uniquePeopleList))
      setInvitationsList(uniquePeopleList)
    } else {
      setInvitationsList([])
      setPeopleListTable(activeFilters.length ? createTableFromList([]) : null)
    }
  }, [createTableFromList, offsite, peopleList])

  useEffect(() => {
    if (!participants) return
    setPeopleList(mapParticipants(participants))
    setInvitationsList(participants)
  }, [participants, activeFilters])

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (actionData?.success) {
      setParticipantsSelection([])
      setDefaultSelectedParticipants([])
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Participants list saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch])

  useEffect(() => {
    if (profile && profile.isFull && !profile.company) {
      setOptions(INVITE_OPTIONS.filter((option) => option.type !== 'company'))
    }
  }, [profile, profile.isFull])

  return (
    <MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={6}
        px={{ xs: 2, md: 3 }}
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 6 }}>
        <MDBox>
          <MDTypography variant="h4">Invite your colleagues!</MDTypography>
          <MDTypography variant="body2">
            You can bulk invite everyone from your company registered on Smartway, add via list of
            email or add single persons!
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" gap={1}>
          {numberCards.map((card, index) => (
            <NumberCard key={`card-${card.label}-${index}`} {...card} />
          ))}
        </MDBox>
      </MDBox>
      {peopleListTable || activeFilters.length ? (
        <MDBox>
          <Table
            table={peopleListTable}
            filtersOptions={{ type: 'client-side' }}
            columnFilters={activeFilters}
            defaultSelected={
              defaultSelectedParticipants
                ? defaultSelectedParticipants.reduce((acc, _curr, index) => {
                    acc[index] = true
                    return acc
                  }, {})
                : null
            }
            rowActions={[
              {
                id: 'delete',
                title: 'Delete',
                icon: 'delete',
                color: 'error.focus',
                onClick: handleRemoveParticipant
              },
              {
                id: 'preferences',
                title: 'Preferences',
                icon: 'settings',
                color: 'secondary',
                onClick: handleRowClick
              },
              {
                id: 'itinerary',
                title: 'See full itinerary',
                icon: 'hiking',
                color: 'secondary',
                onClick: handleItineraryClick
              }
            ]}
            options={{
              initialState: {
                columnPinning: {
                  left: ['mrt-row-select', 'name'],
                  right: ['status', 'mrt-row-actions']
                }
              }
            }}
            slots={{
              toolbarRight: () => (
                <MDBox display="flex" gap={2}>
                  <AddPeopleButton
                    tooltip={inviteTooltip}
                    disabled={inviteDisabled}
                    onClick={handleAddPeopleListClick}
                  />
                  <SettingsButton options={OPTIONS} onClick={handleCardClick} />
                </MDBox>
              ),
              toolbarLeft: () => (
                <FilterButton
                  activeFiltersNumber={activeFilters.reduce((acc, curr) => {
                    return curr && curr.value ? acc + curr.value.split('|').length : acc
                  }, 0)}>
                  <IconButton
                    color="secondary"
                    variant="outlined"
                    aria-controls="filter-menu"
                    aria-haspopup="true"
                    sx={{ border: '1px solid #0000008a', borderRadius: '50%' }}
                    onClick={handleOpenFilterMenu}>
                    <Icon>tunesharp</Icon>
                  </IconButton>
                  <Menu
                    key="filter-menu"
                    anchorEl={openFilterMenu}
                    anchorReference={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    open={Boolean(openFilterMenu)}
                    onClose={handleCloseMenu}
                    sx={{ mt: 2 }}>
                    <MDBox maxWidth={{ xs: 320, sm: 420 }}>
                      {FILTER_MENU.map((filterSection, i) => (
                        <MDBox key={`${filterSection.label}-${i}`} p={2}>
                          <MDTypography variant="body2" color="text">
                            {filterSection.label}
                          </MDTypography>
                          <MDBox mt={1}>
                            {i !== 0 && i !== FILTER_MENU.length - 1 ? <Divider /> : null}
                            {filterSection.options.map((option) => {
                              const handleFilterClick = () => {
                                handleFilterChange(option.value, filterSection.id)
                              }

                              const isActiveSection = activeFilters.find(
                                (f) => f.id === filterSection.id
                              )
                              const isActiveFilter = isActiveSection
                                ? isActiveSection.value.split('|').includes(option.value)
                                : false

                              return (
                                <NotificationItem
                                  key={option.value}
                                  onClick={handleFilterClick}
                                  icon={isActiveFilter ? <Icon>check</Icon> : null}
                                  title={option.label}
                                />
                              )
                            })}
                          </MDBox>
                        </MDBox>
                      ))}
                    </MDBox>
                  </Menu>
                </FilterButton>
              )
            }}
            noResultsComponent={
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <MDBox
                  component="img"
                  src={noResults}
                  alt="No results with these filters"
                  width="100%"
                  maxHeight={200}
                  mb={3}
                />
                <MDTypography variant="body2">There are no results with these filters</MDTypography>
              </MDBox>
            }
            onRowSelectionChange={handleParticipantSelection}
          />
          <MDBox sx={{ position: 'relative' }}>
            <BulkActionsToolbar
              selected={participantsSelection.length}
              onSelectAll={handleSelectAll}
              onDelete={handleBulkDelete}
              onChangeStatus={() => setPeopleDialogOpen('status')}
              onSendInvites={() => sendInvitationsConfirm('selection')}
            />
          </MDBox>

          <MDBox
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="space-between"
            marginBottom={4}
            sx={{
              mx: {
                xs: 0,
                md: 3
              }
            }}>
            <Link to={`/dashboard/offsites/${offsite.id}/definition`}>
              <MDButton
                sx={{
                  mb: { xs: 1, md: 0 },
                  width: window.document.body.clientWidth <= 768 ? '100%' : 'unset'
                }}
                variant="outlined"
                color="dark"
                onClick={handleBack}>
                Go back
              </MDButton>
            </Link>
            <MDBox display={{ xs: 'block', md: 'flex' }} gap={1}>
              <Link to={`/dashboard/offsites/${offsite.id}/agenda`}>
                <MDButton
                  sx={{
                    mb: { xs: 1, md: 0 },
                    width: window.document.body.clientWidth <= 768 ? '100%' : 'unset'
                  }}
                  variant="outlined"
                  color="primary">
                  Next step
                </MDButton>
              </Link>
              <MDButton
                sx={{
                  mb: { xs: 1, md: 3 },
                  width: window.document.body.clientWidth <= 768 ? '100%' : 'unset'
                }}
                variant="gradient"
                color="primary"
                onClick={() => sendInvitationsConfirm('main')}>
                <Icon>mail</Icon>&nbsp; Send invitations
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      <PeopleCardWrapper
        open={peopleDialogOpen === 'cards'}
        peopleListTable={peopleListTable}
        activeFilters={activeFilters}
        onClose={handlePeopleDialogClose}>
        {options.map((option, index) => {
          return (
            <MDBox key={`option-${index}`} mb={3}>
              <PeopleCard {...option} onClick={handleCardClick} />
            </MDBox>
          )
        })}
      </PeopleCardWrapper>

      {offsite && offsite.company ? (
        <>
          <PeopleCompanyDialog
            company={offsite.company}
            open={peopleDialogOpen === 'company'}
            onSave={handlePeopleListSave}
            onClose={handlePeopleDialogClose}
          />
          <SinglePeopleDialog
            open={peopleDialogOpen === 'single'}
            offsite={offsite}
            company={offsite.company}
            profile={profile}
            onSave={handlePeopleListSave}
            onClose={handlePeopleDialogClose}
          />
        </>
      ) : null}
      <PeopleListDialog
        open={peopleDialogOpen === 'list'}
        onSave={handlePeopleListSave}
        onClose={handlePeopleDialogClose}
      />
      <PreferencesDialog
        offsiteId={offsite?.id}
        preferences={offsite?.preference_form}
        participant={selectedParticipant}
        options={{
          dietary_options: dietaryOptions,
          allergies: allergiesOptions,
          roommates: peopleList
        }}
        open={peopleDialogOpen === 'preferences'}
        onSave={handlePreferencesSave}
        onClose={handlePeopleDialogClose}
      />
      {peopleDialogOpen === 'settings' ? (
        <ParticipantsSettingsDialog
          title="Customize the preferences that will be asked to each participant"
          open={peopleDialogOpen === 'settings'}
          participants={peopleList}
          settings={offsite?.preference_form || {}}
          onSave={handleSettingsPreferencesSave}
          onClose={handlePeopleDialogClose}
        />
      ) : null}
      <ParticipantsExportDialog
        title="Export the list of participants"
        open={peopleDialogOpen === 'export_xls'}
        participants={peopleList}
        offsite={offsite}
        options={{
          dietary_options: dietaryOptions,
          allergies: allergiesOptions,
          roommates: peopleList
        }}
        settings={offsite.preference_form}
        onSave={handleSettingsPreferencesSave}
        onClose={handlePeopleDialogClose}
      />
      <SendInvitationsDialog
        offsite={{ ...offsite, venue: offsiteVenue }}
        profile={profile}
        open={peopleDialogOpen === 'invite'}
        participants={invitationsList}
        initialStep={invitationsInitialStep}
        initialValues={sendInvitesInitialValues}
        onSave={sendInvitations}
        onClose={handlePeopleDialogClose}
      />
      <ParticipantStatusDialog
        open={peopleDialogOpen === 'status'}
        selectedParticipants={participantsSelection}
        title="Change participant status"
        onSave={handleBulkChangeStatus}
        onClose={handlePeopleDialogClose}
      />
      <ParticipantItineraryDialog
        open={peopleDialogOpen === 'itinerary'}
        offsite={offsite}
        options={{
          dietary_options: dietaryOptions,
          allergies: allergiesOptions,
          roommates: peopleList
        }}
        participant={selectedParticipant}
        participants={peopleList}
        onClose={handlePeopleDialogClose}
      />
    </MDBox>
  )
}

export default ParticipantsStep
