import { smartwayApi } from 'services/api'
import { redirectDocument, redirect } from 'react-router-dom'

export const editOrCreatePageAction =
  (dispatch, entity, redirectBaseUrl) =>
  async ({ params, request }) => {
    try {
      const payload = await request.json()
      const subentity = payload.subentity || 'default'
      delete payload.subentity
      const actions = {
        page: {
          POST: 'createPageEntity',
          PATCH: 'updatePageEntity',
          DELETE: 'deletePageEntity'
        },
        default: {
          POST: 'createEntity',
          PATCH: 'updateEntity'
        }
      }
      const action = actions[subentity][request.method]

      if (payload.onlyImages) {
        return { success: true, entity }
      }

      const result = await dispatch(
        smartwayApi.endpoints[action].initiate({ entity, ...payload }, { forceRefetch: true })
      )

      if (action === 'createEntity') {
        if (result.data) {
          // TODO: understand why redirect doesn't work and does not trigger reload of the routes
          return redirectDocument(`${redirectBaseUrl}/${result.data.id}?new=true`)
        } else {
          let message = result.error?.message || 'Error during creation'
          return {
            error: 'Error during creation',
            fullError: message
          }
        }
      }
      if (action === 'deletePageEntity') {
        if (result.data) {
          return redirect(`${redirectBaseUrl}/${params.id}?deleted=true`)
        }
        return { error: 'There was an error!' }
      }
      
      if (result && result.data) {
        return { success: true, entity, data: result.data }
      }
      return { error: 'There was an error!' }

    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

