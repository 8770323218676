import { useState, useEffect, useCallback } from "react";

// react-router components
import { Link } from "react-router-dom";

// Redux
import { useSelector } from 'react-redux'

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import Notifications from "components/Notifications";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

import { getBestFormat } from 'utils/image';

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
} from "context";

const DashboardNavbar = ({ absolute, light, isMini, miniSidenav, setMiniSidenav }) => {
  const profile = useSelector(state => state.auth.profile)
  const notifications = useSelector(state => state.auth.notifications)
  const [openProfile, setOpenProfile] = useState();
  const [controller] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(notifications.filter(notification => !notification.read))

  useEffect(() => {
    setUnreadNotifications(notifications.filter(notification => !notification.read))
  }, [notifications])

  const handleMiniSidenav = () => setMiniSidenav(!miniSidenav);
  const handleProfileOpen = (event) => setOpenProfile(event.currentTarget);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setOpenProfile(false);
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      key="notification-menu"
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MDBox maxWidth={{ xs: 320, sm: 420 }} minWidth={{ xs: 320, sm: 420 }}>
        <Notifications />
      </MDBox>
    </Menu>
  );

   const renderProfileMenu = () => {
    return (
      <Menu
        key="profile-menu"
        anchorEl={openProfile}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(openProfile)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <Link to="/dashboard/profile">
          <NotificationItem icon={<Icon>account_circle</Icon>} title="Profile" />
        </Link>
        {/* <NotificationItem icon={<Icon>settings</Icon>} title="Settings" /> */}
        <Link to="/authentication/logout">
          <NotificationItem icon={<Icon>logout</Icon>} title="Logout" />
        </Link>
      </Menu>
    )
   }

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : "static"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} justifyContent="flex-end !important">
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleProfileOpen}>
                <MDAvatar src={getBestFormat(profile.avatar_formats, 'mobile')} alt={`${profile.first_name} ${profile.last_name}`} size="sm"  />
                {/* <Icon sx={iconsStyle}>account_circle</Icon> */}
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge {...( unreadNotifications && unreadNotifications.length ? { badgeContent: unreadNotifications.length } : {})} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>
              {renderMenu()}
              {renderProfileMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
