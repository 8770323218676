import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'
import { defer } from 'react-router-dom'
import { setProfile } from 'store/reducers/AuthSlice'


const waiter = (resolver, time = 3000) => {
  return new Promise((resolve) => setTimeout(() => {
    resolve(resolver)
  }, time))
}

export class AuthLoader extends BaseLoader {
  fullProfileLoader = async ({ params, request }) => {
    try {
      const profile = waiter(this._loader(
        smartwayApi.endpoints.fetchFullProfile,
        request
      ), 1500)
      
      return defer({
        profile
      })
    } catch (error) {
      return { error }
    }
  }
}
