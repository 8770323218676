import { useReducer, useEffect } from 'react'
import SectionForm from './SectionForm'
import SectionExtraFields from './SectionExtraFields'

const PageSection = ({
  page,
  type,
  sectionValues,
  sectionTemplate,
  onDeleteEntity,
  onSaveEntity,
  onMediaSave,
  onMediaDelete,
  onDeleteRepeaterSection,
  onSaveRepeaterSection
}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'set':
        return action.payload
      case 'update':
        return {
          ...state,
          ...action.payload
        }
      case 'delete':
        return null
      default:
        return state
    }
  }
  const initialState = { type, ...sectionValues }

  const [sectionState, dispatch] = useReducer(reducer, initialState)

  const { media, entity, repeater } = sectionTemplate

  const handleSectionSave = ({ sectionValues }) => {
    onSaveRepeaterSection({ sectionValues })
  }

  const handleSectionDelete = (section) => {
    onDeleteRepeaterSection(section)
  }
  const handleSaveEntity = (_, entity, isRepeaterItem) => {
    onSaveEntity(sectionState, entity, isRepeaterItem)
  }
  const handleDeleteEntity = (_, entity, isRepeaterItem) => {
    onDeleteEntity(sectionState, entity, isRepeaterItem)
  }
  const handleMediaSave = () => {
    onMediaSave()
  }
  const handleMediaDelete = (media) => {
    onMediaDelete(sectionState, media)
    dispatch({ type: 'update', payload: { media: null } })
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    dispatch({ type: 'update', payload: { [name]: value } })
  }

  useEffect(() => {
    if (sectionValues) {
      dispatch({ type: 'set', payload: sectionValues })
    }
  }, [sectionValues])

  return (
    <>
      <SectionForm
        page={page}
        type={type}
        section={initialState}
        onChange={handleFieldChange}
        onSave={handleSectionSave}
      />

      <SectionExtraFields
        page={page}
        section={sectionState}
        type={type}
        sectionFields={{
          entity,
          repeater,
          media
        }}
        inRepeaterSection={false}
        onSaveRepeaterSection={handleSectionSave}
        onDeleteRepeaterSection={handleSectionDelete}
        onSaveEntity={handleSaveEntity}
        onDeleteEntity={handleDeleteEntity}
        onMediaSave={handleMediaSave}
        onMediaDelete={handleMediaDelete}
      />
    </>
  )
}

export default PageSection
