const schema = {
  name: 'custom-activity-form',
  fieldsets: [
    {
      id: 'custom-activity',
      fields: [
        {
          type: 'text',
          name: 'title',
          label: 'Title',
          placeholder: 'Give this activity a title',
          required: true,
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ]
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description',
          required: true,
          validationType: 'string',
          validations: [
            {
              type: 'max',
              params: [1000, 'Description is too long. Keep it under 1000 characters']
            }
          ],
          maxLength: 1000,
        },
        {
          type: 'file',
          name: 'custom_image',
          label: 'Upload an image',
          validationType: 'mixed',
          // showPreview: true,
          maxSize: 5242880,
          validations: [
            { type: 'nullable' },
            {
              type: 'fileSize',
              params: ['File too large', '5242880']
            },
            {
              type: 'fileFormat',
              params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
            }
          ],
          accept: 'image/png,image/jpeg,image/jpg'
        },
        {
          type: 'address',
          name: 'custom_address',
          label: 'Location',
          required: true,
          latitudeProp: 'custom_latitude',
          longitudeProp: 'custom_longitude',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: 'Address is required'
            }
          ]
        },
        {
          type: 'text',
          label: 'Meeting point name',
          name: 'meeting_point_name',
          validationType: 'string',
          validations: null,
          grid: { xs: 6 }
        },
        {
          type: 'address',
          name: 'meeting_point_address',
          label: 'Meeting point',
          latitudeProp: 'meeting_point_latitude',
          longitudeProp: 'meeting_point_longitude',
          validationType: 'string',
          grid: { xs: 6 }
        },
      ]
    }
  ],
  submitButton: {
    label: 'Save',
    fullWidth: false,
    color: 'primary'
  }
}

export default schema