import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Image from 'components/Image/Image'
import { getPublicLinkByLocation } from 'utils/links'

const ExperienceCard = ({ experience }) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <MDBox position="relative">
        <Image
          wrapperStyles={{
            position: 'relative',
            sx: {
              '&:after': {
                content: '""',
                position: 'absolute',
                maxHeight: '110px',
                borderRadius: '4px',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)'
              }
            }
          }}
          format="mobile"
          maxHeight={110}
          formats={experience.image?.formats}
          alt={experience.image?.title}
        />
        <MDBox position="absolute" bottom={12} px={1}>
          <a style={{ borderBottom: '1px solid #7b809a', color: 'inherit' }} target="_blank" rel="noreferrer" href={getPublicLinkByLocation(experience.location)}>
            <MDTypography fontSize="18px" lineHeight={1.3} color="white">
              {experience.title}
            </MDTypography>
          </a>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: experience.description }}></MDTypography>
      </MDBox>
    </Grid>
  )
}

const ParticipantExperiences = ({ experiences }) => {
  return experiences && experiences.length ? (
    <MDBox mb={4}>
      <MDTypography variant="h4" fontWeight="medium" mx={2} mb={2}>
        Experiences
      </MDTypography>
      <MDBox mx={2}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          {experiences.map((e, index) => (
            <ExperienceCard experience={e} key={`experience-${index}`} />
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  ) : null
}

export default ParticipantExperiences
