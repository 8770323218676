import { useState, useEffect, useMemo } from 'react'
import { useSubmit, useLoaderData, useRouteLoaderData, useNavigate } from 'react-router-dom'
import { startOfDay, endOfDay } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getUTCValue } from 'utils/dates'

import { useDispatch, useSelector } from 'react-redux'
import { smartwayApi } from 'services/api'

import Form from 'components/Form'
import getDefinitionSchema from '../schemas/definition'

import CompanyDialog from 'components/Dialogs/CompanyDialog'
import ImageCarousel from 'components/ImageCarousel'
import StepHeader from '../components/StepHeader'

import Grid from '@mui/material/Grid'

const DefinitionStep = ({ title, subtitle, parent = 'offsite', ...props }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loaderData = useLoaderData()
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false)
  const [companyValue, setCompanyValue] = useState('')
  const [newCompany, setNewCompany] = useState(null)

  let offsite = props.offsite
  let profile = props.profile
  let images = []
  let activitiesNumber = 0
  let editingMode = false
  const submit = useSubmit()
  const parentLoaderData = useRouteLoaderData(`${parent}-detail`)
  if (!offsite && !profile) {
    offsite = parentLoaderData.offsite
    profile = parentLoaderData.profile
    images = parentLoaderData.images
    activitiesNumber = loaderData.activitiesNumber
    editingMode = true
  }
  const groups = useSelector((state) => state.auth.groups) || profile?.groups || []
  const TIMEZONE = 'UTC'

  const values = useMemo(() => {
    const initial = offsite || {
      status: parent === 'offer' ? ['OFFER'] : ['DRAFT']
    }
    return {
      ...initial,
      managed_by_sales: offsite?.managed_by
        ? offsite.managed_by.filter(({ role }) => role === 'SALES').map((u) => u.user.user_id)
        : [],
      managed_by_admin: offsite?.managed_by
        ? offsite.managed_by.filter(({ role }) => role === 'ADMIN').map((u) => u.user.user_id)
        : [],
      managed_by_operations: offsite?.managed_by
        ? offsite.managed_by.filter(({ role }) => role === 'OPERATIONS').map((u) => u.user.user_id)
        : [],
      managed_by_town_angel: offsite?.managed_by
        ? offsite.managed_by.filter(({ role }) => role === 'TOWN_ANGEL').map((u) => u.user.user_id)
        : [],
      company_managers: offsite?.company_managers || [],
      dates_switch: editingMode ? !initial.indicative_number_days : false,
      start_at: !initial.indicative_number_days ? initial.start_at : null,
      end_at: !initial.indicative_number_days ? initial.end_at : null,
      town: editingMode
        ? initial.all_in_venue
          ? `allInVenue_${initial.all_in_venue}`
          : `town_${initial.town}`
        : null,
      company: initial.company ? initial.company : profile.company ? profile.company.id : null
    }
  }, [offsite, parent, editingMode, profile.company])

  const [isVenue, setIsVenue] = useState(offsite?.all_in_venue)
  const [venuePrefix, setVenuePrefix] = useState(offsite?.all_in_venue ? 'allInVenue' : 'town')

  const onNoCompanyClick = (inputValue) => {
    setCompanyValue(inputValue)
    setCompanyDialogOpen(true)
  }
  const [schema, setSchema] = useState(
    getDefinitionSchema({
      profile,
      editingMode,
      values: { ...values, activitiesNumber },
      parent,
      groups,
      timezone: TIMEZONE,
      onNoCompanyClick
    })
  )

  const handleFieldChange = (event, formValues) => {
    const { name, value, fullValue } = event.target
    if (name === 'town') {
      if (fullValue) {
        setVenuePrefix(fullValue.prefix)
        setIsVenue(fullValue.entity === 'Venues')
      } else {
        setVenuePrefix(null)
        setIsVenue(false)
      }
      setSchema(
        getDefinitionSchema({
          profile,
          editingMode,
          values: { ...formValues, [name]: value },
          parent,
          groups,
          timezone: TIMEZONE,
          onNoCompanyClick
        })
      )
    }
    if (['dates_switch', 'company'].includes(name)) {
      setSchema(
        getDefinitionSchema({
          profile,
          editingMode,
          values: { ...formValues, activitiesNumber, [name]: value },
          parent,
          groups,
          timezone: TIMEZONE,
          onNoCompanyClick
        })
      )
    }
  }

  const formatPayload = (values) => {
    let payload = { ...offsite, ...values, entity: 'offsite', id: offsite?.id }

    payload.managed_by = Object.keys(payload)
      .filter((k) => k.startsWith('managed_by_'))
      .reduce((acc, curr) => {
        const value = payload[curr]
        if (value.length >= 1) {
          value.forEach((u) =>
            acc.push({ role: curr.replace('managed_by_', '').toUpperCase(), user: u })
          )
        }
        return acc
      }, [])
    delete payload.managed_by_admin
    delete payload.managed_by_sales
    delete payload.managed_by_operations
    delete payload.managed_by_town_angel
    delete payload.event_logo
    delete payload.event_logo_negative

    if (payload.offsite_preferences) {
      delete payload.offsite_preferences
    }

    if (payload.town) {
      if (isVenue) {
        payload.all_in_venue = Number(payload.town.replace(`${venuePrefix}_`, ''))
        payload.town = null
      } else {
        payload.town = Number(payload.town.replace(`${venuePrefix}_`, ''))
        payload.all_in_venue = null
      }
    }
    if (payload.linked_venues) {
      if (!!payload.linked_venues.length) {
        let towns = []
        let allInVenues = []
        payload.linked_venues.forEach((venue) => {
          if (venue.startsWith('town_')) {
            towns.push(Number(venue.replace('town_', '')))
          }
          if (venue.startsWith('allInVenue_')) {
            allInVenues.push(Number(venue.replace('allInVenue_', '')))
          }
        })
        payload.linked_towns = towns
        payload.linked_all_in_venues = allInVenues
      }
      delete payload.linked_venues
    }
    if (payload.start_at_end_at) {
      delete payload.start_at_end_at
      // payload.start_at = startOfDay(new Date(payload.start_at)).toISOString()
      // payload.end_at = endOfDay(new Date(payload.end_at)).toISOString()
    }
    if (payload.dates_switch) {
      delete payload.indicative_number_days
    } else {
      delete payload.start_at
      delete payload.end_at
    }

    delete payload.dates_switch
    if (editingMode) {
      if (!values.rooms) {
        payload.rooms = []
      }
      if (payload.start_at && payload.end_at) {
        const utcPayloadStartAt = getUTCValue(new Date(payload.start_at)).toISOString()
        const utcPayloadEndAt = getUTCValue(new Date(payload.end_at)).toISOString()
        const utcStartAt = new Date(offsite.start_at).toISOString()
        const utcEndAt = new Date(offsite.end_at).toISOString()
        if (
          !!activitiesNumber &&
          (utcPayloadStartAt !== utcStartAt || utcPayloadEndAt !== utcEndAt)
        ) {
          /*
           * When dates of an offsite are changed, we need to update the dates of all activities
           */
          payload.changeOffsiteDates = true
          payload.oldDates = { start_at: offsite.start_at, end_at: offsite.end_at }
        }
      }
    }
    if (editingMode && payload.status.includes('SIGNED_CONTRACT')) {
      delete payload.all_in_venue
      delete payload.town
      delete payload.start_at
      delete payload.end_at
      delete payload.changeOffsiteDates
      delete payload.oldDates
    }

    return payload
  }

  const handleDefinitionSubmit = async (values) => {
    const payload = formatPayload(values)
    if (editingMode) {
      submit(payload, {
        method: !!offsite && offsite.id ? 'patch' : 'post',
        encType: 'application/json'
      })
    } else {
      try {
        const response = await dispatch(smartwayApi.endpoints.createEntity.initiate(payload))
        if (response && response.data) {
          const agenda = await dispatch(
            smartwayApi.endpoints.createEntity.initiate({
              entity: 'agenda',
              title: `Agenda for ${response.data.title}`,
              offsite: response.data.id,
              is_template: false
            })
          )
          if (agenda && agenda.data) {
            navigate(
              `/dashboard/${parent === 'offer' ? 'offers-offsites' : 'offsites'}/${
                response.data.id
              }/definition?new=true`,
              { replace: true }
            )
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleSaveCompany = async (company) => {
    try {
      let payloadJSON = {}
      const logoFormData = new FormData()
      if (company.logo && Array.isArray(company.logo) && company.logo[0] instanceof File) {
        logoFormData.append('logo', company.logo[0])
      }

      for (let [key, value] of Object.entries(company)) {
        const exclude = ['logo', '__address', 'hq_longitude', 'hq_latitude']
        if (!exclude.includes(key)) {
          payloadJSON[key] = value
        }
      }
      const newCompany = await dispatch(
        smartwayApi.endpoints.createEntity.initiate({
          entity: 'company',
          ...payloadJSON
        })
      )
      if (newCompany && newCompany.data) {
        await dispatch(
          smartwayApi.endpoints.updateEntity.initiate({
            bodyType: 'formData',
            entity: 'company',
            id: newCompany.data.id,
            action: 'logo/',
            formData: logoFormData
          })
        )
        setSchema(
          getDefinitionSchema({
            profile,
            editingMode,
            values: { ...values, company: newCompany.data.id, activitiesNumber },
            parent,
            groups,
            timezone: TIMEZONE,
            onNoCompanyClick
          })
        )
        setNewCompany(newCompany.data)
        setCompanyValue('')
        setCompanyDialogOpen(false)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const handleImagesChange = () => {
    submit({ onlyImages: true }, { method: 'post', encType: 'application/json' })
  }

  useEffect(() => {
    if (profile && profile.id) {
      setSchema(
        getDefinitionSchema({
          profile,
          editingMode,
          values: { ...values, activitiesNumber },
          parent,
          groups,
          timezone: TIMEZONE,
          onNoCompanyClick
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const initialValues = {
    ...values,
    company: newCompany ? newCompany.id : values.company,
    linked_venues: [
      ...(values.linked_towns && !!values.linked_towns.length
        ? values.linked_towns.map((t) => `town_${t}`)
        : []),
      ...(values.linked_all_in_venues && !!values.linked_all_in_venues.length
        ? values.linked_all_in_venues?.map((a) => `allInVenue_${a}`)
        : [])
    ]
  }

  return (
    <>
      <StepHeader title={title} subtitle={subtitle} />
      <Grid container spacing={3}>
        {editingMode ? (
          <Grid item xs={12} lg={4}>
            <ImageCarousel
              id={offsite.id}
              entity="offsite"
              images={images}
              onChange={handleImagesChange}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} lg={editingMode ? 8 : 12}>
          {schema ? (
            <Form
              {...schema}
              watchedFields={['town', 'dates_switch', 'company']}
              additionalData={{ profile }}
              needChangeToSubmit={!editingMode}
              onFieldChange={handleFieldChange}
              onSubmit={handleDefinitionSubmit}
              values={initialValues}
            />
          ) : null}
        </Grid>
      </Grid>
      <CompanyDialog
        companyName={companyValue}
        open={companyDialogOpen}
        title="Create a company"
        onSave={handleSaveCompany}
        onClose={() => setCompanyDialogOpen(false)}
      />
    </>
  )
}

export default DefinitionStep
