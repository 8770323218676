import { addDays, differenceInDays } from 'date-fns'
import { stripTags } from 'utils/functions'

export const generateAgendaDescription = async ({ dispatch, smartwayApi, params, ...rest }) => {
  let start_at = rest.start_at
  let end_at = rest.end_at
  if (rest.nb_days) {
    start_at = new Date('1970-01-01')
    end_at = addDays(start_at, rest.nb_days)
  }

  const action = params.id ? 'fetchEntity' : 'fetchEntities'
  const _params = params.id ? params : {
    page: 1,
    page_size: 100,
    ...params
  }

  const agendasResponse = await dispatch(
    smartwayApi.endpoints[action].initiate({
      entity: 'agenda',
      ..._params,
    })
  )
  const agendas = agendasResponse && agendasResponse.data ? params.id ? [agendasResponse.data] : agendasResponse.data.results : null
  const events = agendas[0] && agendas[0].activities ? agendas[0].activities : null
  if (events && events.length) {
    const startOffsite = new Date(start_at).getTime()
    // We need to add one full day because the end date of the offsite is set at the beginning of the day
    const endOffsite = addDays(new Date(end_at), 1).getTime()
    
    const filteredSlots = events.filter(({ start_at, end_at, type }) => {
      const startAt = new Date(start_at).getTime()
      const endAt = new Date(end_at).getTime()
      return (
        startAt >= startOffsite && endAt <= endOffsite && !['FREE_TIME', 'LOGISTIC'].includes(type)
      )
    })
    const slotsByDay = filteredSlots.reduce((acc, slot) => {
      const dayNumber = differenceInDays(new Date(slot.start_at), new Date(start_at))
      if (acc[dayNumber]) {
        acc[dayNumber].push(slot)
      } else {
        acc[dayNumber] = [slot]
      }
      return acc
    }, {})
    const prompt = `Write an emotional description of max 500 characters which depicts what the participants will experience during the offsite based on the following agenda. Emphasize on all the experiences ordered by day
        ${Object.keys(slotsByDay).map((day) => {
          return `
            Day_${Number(day) + 1}: ${slotsByDay[day]
            .map(({ title, description }) => {
              return `${title} ${description ? `(${stripTags(description)})` : ''}`
            })
            .join(', ')}`
        })}`
    console.log(prompt)
  
    try {
      const request = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo-0125',
          messages: [
            {
              role: 'system',
              content: prompt
            }
          ],
          temperature: 0.7,
          max_tokens: 230
        })
      })
      const response = await request.json()
      return response.choices[0].message.content
    } catch (error) {
      console.warn(error)
    return { error: true}
     }
  }
  return null
}

export const generateExperiencesDescription = async ({ dispatch, smartwayApi, offsite }) => {
  const agendasResponse = await dispatch(
    smartwayApi.endpoints.fetchEntities.initiate({
      entity: 'agenda',
      offsite: offsite.id,
      page: 1, 
      page_size: 100
    })
  )
  const agendas = agendasResponse && agendasResponse.data ? agendasResponse.data.results : null
  const events = agendas[0] && agendas[0].activities ? agendas[0].activities : null
  if (events && events.length) {
    const startOffsite = new Date(offsite.start_at).getTime()
    // We need to add one full day because the end date of the offsite is set at the beginning of the day
    const endOffsite = addDays(new Date(offsite.end_at), 1).getTime()
    
    const filteredSlots = events.filter(({ start_at, end_at, type }) => {
      const startAt = new Date(start_at).getTime()
      const endAt = new Date(end_at).getTime()
      return (
        startAt >= startOffsite && endAt <= endOffsite && type === 'EXPERIENTIAL_ACTIVITY'
      )
    })
    const prompt = `Write an emotional description of max 500 characters which depicts how "Smartway" designs truly local and memorable experiences to engage a team or a whole company. Include all the following experiences:
        ${filteredSlots.map(({ title, description }) => {
          return `- ${title} ${description ? `(${stripTags(description)})` : ''}`
        })}`
    console.log(prompt)
  
    try {
      const request = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo-0125',
          messages: [
            {
              role: 'system',
              content: prompt
            }
          ],
          temperature: 0.7,
          max_tokens: 230
        })
      })
      const response = await request.json()
      return response.choices[0].message.content
    } catch (error) {
      console.warn(error)
    return { error: true}
     }
  }
  return null
}
