import { Link } from 'react-router-dom'

import MDBox from "components/MDBox"
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

const PageActionsCell = ({ data, onDelete, onClone }) => {

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Link to={`/dashboard/pages/${data.id}`}>
        <IconButton size="small" sx={{ border: '1px solid #0000008a', borderRadius: '50%'}}>
          <Icon>edit</Icon>
        </IconButton>
      </Link>
      <IconButton size="small" sx={{ border: '1px solid #0000008a', borderRadius: '50%'}} onClick={() => onClone(data)}>
        <Icon>copy</Icon>
      </IconButton>
      <IconButton size="small" sx={{ border: '1px solid #0000008a', borderRadius: '50%'}} onClick={() => onDelete(data)}>
        <Icon>delete</Icon>
      </IconButton>
    </MDBox>
  )
}

export default PageActionsCell