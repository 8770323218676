import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import DataTable from 'components/Tables/DataTable'
import MDBox from 'components/MDBox'

export const PaginationSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton
          key={`pagination-skeleton-${index}`}
          variant="circular"
          width={33}
          height={33}
          sx={{ mr: 1 }}
        />
      ))}
    </div>
  )
}

export const CardsSkeleton = () => {
  return (
    <Grid
      container
      alignItems="start"
      spacing={3}
      sx={{ height: '100%', mt: 2 }}>
      {Array.from(new Array(6)).map((_, index) => (
        <Grid key={`cards-skeleton-${index}`} item xs={12} md={6} lg={6}>
          <Skeleton animation="wave" variant="rounded" height={250} />
        </Grid>
      ))}
      <Grid container justifyContent="center" marginTop={6}>
        <PaginationSkeleton />
      </Grid>
    </Grid>
  )
}

export const ListSkeleton = ({ isStaff }) => {
  const table = {
    columns: [
      { Header: 'company', accessor: 'company' },
      { Header: 'town', accessor: 'town' },
      { Header: 'title', accessor: 'title' },
      { Header: 'dates', accessor: 'dates' },
      { Header: 'welcome_text', accessor: 'welcome_text', width: "15%" },
    ].filter(c => isStaff ? true : c.accessor !== 'company'),
    rows: new Array(10).fill({}).map(
      () => {
        return {
          company: <Skeleton animation="wave" variant="rounded" width={100} />,
          town:  <Skeleton animation="wave" variant="rounded" width={100} />,
          title: <Skeleton animation="wave" variant="rounded" width={100} />,
          dates:  <Skeleton animation="wave" variant="rounded" width={100} />,
          welcome_text:  <Skeleton animation="wave" variant="rounded" width={100} />
        }
      }
    )
  }
  return (
    <MDBox mt={2}>
      <DataTable table={table} />
    </MDBox>
  )
}


export const FiltersButtonSkeleton = () => {
  return <Skeleton animation="wave" variant="rounded" width={120} height={40} />
}