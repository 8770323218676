import { useDispatch } from "react-redux"
import Form from "components/Form"

import { getPageSchema } from "./schema"

const PageForm = ({ page, onSave }) => {
  const dispatch = useDispatch()
  const editingMode = !!page.slug
  const schema = getPageSchema({ editingMode, page, dispatch })
  const handleSubmit = (values) => {
    if (onSave && typeof onSave === 'function') {
      const payload = { ...page, ...values }
      if (payload.sections) {
        delete payload.sections
      }
      onSave(payload)
    }
  }
  return (
      <Form
        {...schema}
        values={page}
        onSubmit={handleSubmit}
      />
  )
}

export default PageForm