import { useState, useEffect, useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import DataTable from 'components/Tables/DataTable'
import AgendaActionsCell from './AgendaActionsCell'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { ListSkeleton } from './Skeletons'

import noResults from 'assets/illustrations/noresults.svg'

const AgendasTableView = ({ agendas, pagination, hideColumns = [], onDelete, onClone }) => {
  const dispatch = useDispatch()
  const [table, setTable] = useState(null)
  const [tableReady, setTableReady] = useState(false)

  const handleDeleteAgenda = (agenda) => {
    onDelete(agenda.id)
  }

  const handleCloneAgenda = (agenda) => {
    onClone(agenda.id)
  }

  const getFullAgendas = useCallback(
    async (agendas) => {
      const venuePromises = []
      agendas.forEach(({ town, all_in_venue }) => {
        if (!town && !all_in_venue) return
        venuePromises.push(
          dispatch(
            smartwayApi.endpoints.fetchEntity.initiate({
              entity: town ? 'town' : 'allInVenue',
              id: town ?  town : all_in_venue
            })
          )
        )
      })

      const [venueRequest] = await Promise.all([
        Promise.all(venuePromises),
      ])
      const venueResponse = venueRequest.filter((r) => r.status === 'fulfilled' && r.data).map((r) => r.data)
      return agendas.map((agenda) => {
        const { town, all_in_venue } = agenda
        const venue = venueResponse.find(({ id }) => town ? id === town : id === all_in_venue)
        return {
          ...agenda,
          venue,
        }
      })
    },
    [dispatch]
  )

  const getAgendasTable = useCallback(async () => {
    const fullAgendas = await getFullAgendas(agendas)
    setTableReady(true)
    setTable({
      columns: [
        { Header: 'title', accessor: 'title' },
        { Header: 'venue', accessor: 'venue' },
      ].filter((c) => !hideColumns.includes(c.accessor)),
      rows:
        fullAgendas && !!fullAgendas.length
          ? fullAgendas.map((agenda) => {
              const { title, venue } = agenda
              return {
                __data: agenda,
                venue: venue ? venue.name : '',
                agendas: agendas?.length || 'No agenda',
                title
              }
            })
          : []
    })
  }, [getFullAgendas, hideColumns, agendas])

  useEffect(() => {
    if (agendas && agendas.length && !tableReady) {
      getAgendasTable()
    }
  }, [getAgendasTable, agendas, tableReady])

  useEffect(() => {
    getAgendasTable()
  }, [pagination])

  return agendas && agendas.length ? (
    tableReady ? (
      <DataTable
        table={table}
        hideEntriesPerPage
        pagination={pagination}
        actionsOnRowHover={
          <AgendaActionsCell onClone={handleCloneAgenda} onDelete={handleDeleteAgenda} />
        }
      />
    ) : (
      <ListSkeleton />
    )
  ) : (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh">
      <MDBox component="img" src={noResults} alt="no results" width="100%" maxHeight={200} mb={3} />
      <MDTypography variant="h5">No results found!</MDTypography>
    </MDBox>
  )
}

export default AgendasTableView
