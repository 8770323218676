import { useState, useEffect, useCallback, useRef } from 'react'

import { handleInitialSlotDuration } from 'utils/events'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ActivityPicker from './components/ActivityPicker'
import ActivityTypePicker from './components/ActivityPicker/ActivityTypePicker'
import ActivityCustomization from './components/ActivityPicker/ActivityCustomization'

import { IconButton, Icon, Chip, Dialog, DialogContent, DialogActions } from '@mui/material'

const ActivityDialog = ({
  open,
  slot,
  calendarSlot,
  activity,
  activityType: initialActivityType,
  customActivities,
  showCustomActivities,
  venueType,
  venue,
  agendaId,
  agendaInfos,
  profile,
  participants,
  onClose,
  onSave,
  onRefresh,
  onUpdateCurrentSlot,
}) => {
  const isSlot = !!slot && !!Object.keys(slot).length
  const [activeStep, setActiveStep] = useState(0)
  const [activityType, setActivityType] = useState(initialActivityType)
  const [slotDetails, setSlotDetails] = useState(isSlot ? slot : null)
  const [selectedActivity, setSelectedActivity] = useState(activity)
  const [createdActivity, setCreatedActivity] = useState(null)
  const isCustomizationStep = useRef(false)

  const resetState = () => {
    setActiveStep(0)
    setSelectedActivity({})
    setSlotDetails(null)
  }

  const handleActivityDetailsSave = (values) => {
    if (!createdActivity || !createdActivity.id) {
      setSlotDetails(values)
      handleActivitySave(values)
    } else {
      handleOnClose()
      if (onRefresh && typeof onRefresh === 'function') {
        onRefresh()
      }
    }
  }


  const handleActivitySave = (values) => {
    const isEvent = !!values.target
    let payload = {}
    if (isEvent) {
      // new slot created on calendar
      const slotWithRightDuration = handleInitialSlotDuration(
        slotDetails,
        activityType,
        selectedActivity
      )
      let _activityType = activityType
      if (selectedActivity?.activityType?.name !== activityType.name) {
        _activityType = selectedActivity?.activityType
      }
      payload = { ...slotWithRightDuration, activityType: _activityType, activity: selectedActivity }
    } else if (['CUSTOM', 'FREE_TIME', 'TRANSFER', 'LOGISTIC'].includes(activityType.name)) {
      // Activity without location
      payload = { ...values, activityType, activity: { ...slotDetails, ...values } }
    } else if (!isEvent && selectedActivity) {
      // existing slot
      payload = { ...values, activityType, activity: selectedActivity }
    }
    if (onSave && typeof onSave === 'function') {
      resetState()
      onSave(payload)
    }
  }

  const handleActivityTypeChange = useCallback((mainType) => {
    setActivityType(mainType)
    if (isCustomizationStep.current) {
      setActiveStep(2)
      isCustomizationStep.current = false
    } else if (mainType && ['FREE_TIME', 'TRANSFER', 'LOGISTIC'].includes(mainType.type)) {
      setActiveStep(2)
      setSelectedActivity({})
    } else {
      handleNext()
    }
  }, [])

  const handleSaveSlot = (values) => {
    if (onUpdateCurrentSlot && typeof onUpdateCurrentSlot === 'function') {
      onUpdateCurrentSlot(values)
      isCustomizationStep.current = true
    }
    setCreatedActivity(values.slot)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      resetState()
      onClose()
    }
  }

  const handleNext = () => {
    const nextStep = activeStep + 1 >= steps.length ? activeStep : activeStep + 1
    setActiveStep(nextStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const steps = [
    {
      id: 'type-picker',
      label: 'Choose an activity type',
      back: null,
      profile,
      showCustomActivities,
      component: ActivityTypePicker,
      onTypeChange: handleActivityTypeChange
    },
    {
      id: 'activity-picker',
      label: 'Choose activity',
      slot,
      activityType,
      activity: selectedActivity ? selectedActivity : activity,
      customActivities,
      back: {
        label: 'Back',
        handler: handleBack,
        otherProps: {}
      },
      secondaryAction: {
        label: 'Customize',
        handler: handleNext,
        otherProps: {
          disabled: !selectedActivity
        }
      },
      mainAction: {
        label: 'Save',
        handler: handleActivitySave,
        otherProps: {
          disabled: !selectedActivity
        }
      },
      onActivityChange: (a) => {
        setSelectedActivity(a)
      },
      component: ActivityPicker,
      venueType,
      venue,
      company: profile?.company
    },
    {
      label: 'Activity',
      selectedActivity,
      values: !!slotDetails ? slotDetails : slot,
      calendarSlot,
      id: 'activity-form',
      agendaId,
      offsiteId: agendaInfos?.offsite,
      participants,
      activityType: slot && slot.activityType ? slot.activityType : activityType,
      onSaveSlot: handleSaveSlot,
      onSubmit: handleActivityDetailsSave,
      component: ActivityCustomization,
      secondaryAction: {
        label: 'Back',
        handler: () => {
          if (activityType && ['FREE_TIME', 'TRANSFER', 'LOGISTIC'].includes(activityType.type)) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2)
          } else {
            handleBack()
          }
        },
        otherProps: {}
      },
      mainAction: {
        label: 'Save',
        otherProps: {
          form: 'activity-form',
          type: 'submit'
        }
      },
    }
  ]

  useEffect(() => {
    if (activity) {
      setSelectedActivity(activity)
      handleActivityTypeChange(activityType)
    }
  }, [activity, activityType, handleActivityTypeChange])

  useEffect(() => {
    if (slot) {
      setSlotDetails(slot)
    }
  }, [slot])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <Icon>close</Icon>
      </IconButton>
      <DialogContent
        dividers
        className="dialogContent"
        sx={{ minHeight: '200px', '&::-webkit-scrollbar': { width: '4px' } }}>
        <MDBox sx={{ maxHeight: 'calc(100vh - 220px)' }} p={3}>
          {steps.map(({ component, description, ...rest }, index) => {
            const Step = component
            const visible = index === activeStep
            return visible ? (
              <MDBox key={`${rest.id}-${index}`}>
                <Step {...rest} key={`step-${index}`} />
              </MDBox>
            ) : null
          })}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDBox width="100%">
          {activeStep && steps[activeStep] && steps[activeStep].back ? (
            <MDButton
              onClick={steps[activeStep].back.handler}
              {...steps[activeStep].back.otherProps}>
              {steps[activeStep].back.label}
            </MDButton>
          ) : null}
          {!!selectedActivity && selectedActivity.name ? (
            <Chip label={selectedActivity.name} color="primary" />
          ) : null}
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          {steps[activeStep].secondaryAction ? (
            <MDButton
              sx={{ mr: 3 }}
              onClick={steps[activeStep].secondaryAction.handler}
              {...steps[activeStep].secondaryAction.otherProps}>
              {steps[activeStep].secondaryAction.label}
            </MDButton>
          ) : null}
          {steps[activeStep].mainAction ? (
            <MDButton
              {...steps[activeStep].mainAction.otherProps}
              onClick={steps[activeStep].mainAction.handler}
              variant="contained"
              color="primary">
              {steps[activeStep].mainAction.label}
            </MDButton>
          ) : null}
        </MDBox>
      </DialogActions>
    </Dialog>
  )
}

export default ActivityDialog
