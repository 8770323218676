import { useState, useEffect } from 'react'

import { Link, useLoaderData, useSubmit } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import PagesTableView from './PagesTableView'

import noResults from 'assets/illustrations/noresults.svg'
import { ListSkeleton } from './Skeletons'

const DiscoveryListPage = () => {
  const { pages, pagination, searchParams } = useLoaderData()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)

  const handleConfirmDeletePage = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'delete', encType: 'application/json' }
    )
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleDeletePage = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Page deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete this page?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleConfirmDeletePage}
        onCancel={handleCancelConfirmation}
      />
      <MDBox sx={{ margin: 'auto' }}>
        <Grid mt={6} container justifyContent="flex-end">
          <Link to={`/dashboard/pages/new`}>
            <MDButton color="primary">
              <Icon>add</Icon>&nbsp; Create a page
            </MDButton>
          </Link>
        </Grid>
        {pages && !!pages.length ? (
          <MDBox mt={2}>
            <PagesTableView pages={pages} onDelete={handleDeletePage} />
          </MDBox>
        ) : (
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="50vh">
              <MDBox
                component="img"
                src={noResults}
                alt="no results"
                width="100%"
                maxHeight={200}
                mb={3}
              />
              <MDTypography variant="h5">No results found!</MDTypography>
            </MDBox>
          </Grid>
        )}
      </MDBox>
    </>
  )
}

export default DiscoveryListPage
