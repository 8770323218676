export const STATUSES = [
  {
    name: 'OFFER',
    label: 'Offer',
    description: '',
    icon: ''
  },
  {
    name: 'OFFER_DEFINED',
    label: 'Offer defined',
    description: '',
    icon: ''
  },
  {
    name: 'OFFER_SENT',
    label: 'Offer sent',
    description: '',
    icon: ''
  },
  {
    name: 'DRAFT',
    label: 'Draft',
    description: '',
    icon: ''
  },
  {
    name: 'SIGNED_CONTRACT',
    label: 'Signed contract',
    description: '',
    icon: ''
  },
  {
    name: 'CONFIRMED_PARTICIPANTS_LIST',
    label: 'Confirmed participants list',
    description: '',
    icon: ''
  },
  {
    name: 'CONFIRMED_AGENDA',
    label: 'Confirmed agenda',
    description: '',
    icon: ''
  },
  {
    name: 'CONFIRMED_ROOMING_LIST',
    label: 'Confirmed rooming list',
    description: '',
    icon: ''
  },
  {
    name: 'COMPLETE',
    label: 'Complete',
    description: '',
    icon: ''
  }
]

export const STATUSES_NAMES = {
  OFFER: 'OFFER',
  OFFER_DEFINED: 'OFFER_DEFINED',
  OFFER_SENT: 'OFFER_SENT',
  DRAFT: 'DRAFT',
  SIGNED_CONTRACT: 'SIGNED_CONTRACT',
  CONFIRMED_PARTICIPANTS_LIST: 'CONFIRMED_PARTICIPANTS_LIST',
  CONFIRMED_AGENDA: 'CONFIRMED_AGENDA',
  CONFIRMED_ROOMING_LIST: 'CONFIRMED_ROOMING_LIST',
  COMPLETE: 'COMPLETE'
}

export const OFFER_STATUSES = STATUSES.filter((status) => ['OFFER', 'OFFER_DEFINED', 'OFFER_SENT'].includes(status.name))
export const OFFSITE_STATUSES = STATUSES.filter((status) => ['DRAFT', 'SIGNED_CONTRACT', 'CONFIRMED_PARTICIPANTS_LIST', 'CONFIRMED_AGENDA', 'CONFIRMED_ROOMING_LIST', 'COMPLETE'].includes(status.name))