const mainActivityField = {
  company: {
    optionsGetter: {
      endpoints: [
        {
          name: 'company',
          entity: 'company'
        }
      ]
    }
  }
}

const getMainActivityField = () => {
  const role = 'company'
  return {
    type: 'select',
    name: role,
    disableClearable: true,
    fieldMargin: 0,
    labelProp: 'name',
    valueProp: 'id',
    inputStyles: {
      '& > :before': { border: 'none !important' },
      minWidth: '200px'
    },
    inputVariant: 'standard',
    grid: { xs: 6 },
    ...mainActivityField[role]
  }
}

const schema = ({ groups }) => {
  return {
    name: 'role-form',
    fieldsets: [
      {
        id: 'role',
        sx: { m: 0, p: 0 },
        fields: [
          {
            type: 'select',
            multiple: true,
            name: 'groups',
            fieldMargin: 0,
            inputStyles: {
              '& > :before': { border: 'none !important' },
              minWidth: '150px'
            },
            inputVariant: 'standard',
            grid: { paddingLeft: '0 !important' },
            disableClearable: true,
            options: groups,
            labelProp: 'name',
            valueProp: 'id'
          }
        ]
      }
    ]
  }
}

const getSchema = ({ groups }) => {
  const _schema = { ...schema({ groups }) }
  _schema.fieldsets[0].fields.push(
    getMainActivityField(groups)
  )
  return _schema
}

export default getSchema
