const filters = {
  name: 'agendas-filters',
  fieldsets: [
    {
      title: 'Title',
      subtitle: 'Enter the title of the agenda you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'title',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Package',
      subtitle: 'Filter by the package in which the agenda is inserted',
      fields: [
        {
          type: 'select',
          name: 'premade_package',
          label: 'Package',
          valueProp: 'id',
          labelProp: 'title',
          grid: { xs: 12 },
          optionsGetter: {
            endpoints: [
              { entity: 'package' },
            ]
          }
        }
      ]
    },
    {
      title: 'Town / Venue',
      subtitle: 'Filter by town or venue of the offsite',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Number of days',
      subtitle: 'Filter by duration of the agenda',
      fields: [
        {
          type: 'range',
          name: 'nb_days',
          min: 1,
          max: 7,
          initialValue: [1, 3],
          minDistance: 1,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Price per person',
      subtitle: 'Filter by price per person of the agenda',
      fields: [
        {
          type: 'range',
          name: 'price_per_person',
          min: 200,
          max: 2000,
          prefix: '€',
          initialValue: [200, 600],
          minDistance: 50,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Category',
      subtitle: 'Select the category of the agenda you\'re looking for',
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                entity: 'agendaCategories'
              }
            ]
          }
        }
      ]
    }
  ]
}

export default filters