// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

const Notifications = () => {
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Card>
            <Notifications />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Notifications
