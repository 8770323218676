
const filters = {
  name: 'all-in-venues-accommodations-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the accommodation you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Venue',
      subtitle: 'Choose the venue of the accommodation',
      fields: [
        {
          type: 'select',
          name: 'venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 12 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' }
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Features',
      subtitle: 'Select the features you are looking for',
      fields: [
        {
          type: 'checkboxgroup',
          name: 'features',
          label: '',
          optionsGetter: {
            endpoints: [
             {
               name: 'feature',
               entity: 'locationFeatures',
               id: 'all_in_venues',
               formatGroup: (options) => {
                return options.reduce((acc, curr) => {
                  const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                  if (alreadyInAcc) {
                    alreadyInAcc.controls.push({
                      name: curr.id,
                      label: curr.name
                    })
                  } else {
                    acc.push({
                      label: curr.subtype,
                      controls: [{ name: curr.id, label: curr.name }]
                    })
                  }
                  return acc
                }, [])
               }
             }
            ]
          }
        }
      ]
    },
    {
      title: 'Premium features',
      subtitle: 'Select the premium features you are looking for',
      fields: [
        {
          type: 'checkboxgroup',
          name: 'premium_features',
          label: '',
          optionsGetter: {
            endpoints: [
             {
               name: 'feature',
               entity: 'locationPremiumFeatures',
               formatGroup: (options) => {
                return options.reduce((acc, curr) => {
                  const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                  if (alreadyInAcc) {
                    alreadyInAcc.controls.push({
                      name: curr.id,
                      label: curr.name
                    })
                  } else {
                    acc.push({
                      label: curr.subtype,
                      controls: [{ name: curr.id, label: curr.name }]
                    })
                  }
                  return acc
                }, [])
               }
             }
            ]
          }
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the accommodation is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters