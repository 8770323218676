const getFiltersSchema = () => {
  return {
    id: 'rooms-filters',
    fieldsets: [
      {
        id: 'room-search',
        fields: [
          {
            type: 'text',
            name: 'search',
            label: 'Search for surname or room',
            placeholder: 'Search here',
            validationType: 'string',
            validations: null,
            boxStyles: { mb: 0 }
          }
        ]
      },
      {
        id: 'room-level',
        type: 'accordion',
        accordionType: 'minimal',
        title: 'Room category',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'level',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'roomLevels',
                  formatGroup: (options) => {
                    return [
                      {
                        label: '',
                        controls: options.map(({ label, value }) => ({
                          name: value,
                          label
                        }))
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      {
        id: 'room-category',
        type: 'accordion',
        accordionType: 'minimal',
        title: 'Room typology',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'category',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'roomCategories',
                  formatGroup: (options) => {
                    return [
                      {
                        label: '',
                        controls: options.map(({ label, value }) => ({
                          name: value,
                          label
                        }))
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      },
      {
        id: 'room-status',
        type: 'accordion',
        accordionType: 'minimal',
        title: 'Empty or not',
        fields: [
          {
            type: 'switchgroup',
            name: 'empty',
            options: [
              {
                name: 'all',
                label: 'Show all',
                inverted: true
              },
              {
                name: 'done',
                label: 'Show only done rooms',
                inverted: true
              },
              {
                name: 'not_done',
                label: 'Show only not done rooms',
                inverted: true
              },

            ]
          }
        ]
      },
    ]
  }
}

export default getFiltersSchema