import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const ItemCard = ({ id, title, onEdit, onDelete, ...item }) => {
  const handleEdit = () => {
    onEdit({ id, title, ...item })
  }
  const handleRemove = () => {
    onDelete({ id, title, ...item })
  }

  let image = null
  if (item.media && item.media.formats) {
    image = item.media.formats.thumbnail
  }

  return (
    <Card sx={{ height: '100%' }}>
      {image ? (
        <CardMedia
          sx={{ height: 80 }}
          image={image}
          title="green iguana"
        />
      ) : null}
      <CardContent sx={{ flexGrow: 1 }}>
        <MDBox pt={1}>
          <MDTypography variant="h6" component="div">
            {title}
          </MDTypography>
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
        <MDButton color="primary" size="small" onClick={handleEdit} iconOnly>
          <Icon>edit</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default ItemCard
