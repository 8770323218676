import { useState, useEffect, useRef, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
  Tab,
  Tabs
} from '@mui/material'
import MDBox from 'components/MDBox'

import MDButton from 'components/MDButton'

import PreferencesFieldset from './components/Preferences/PreferencesFieldset'
import MDTypography from 'components/MDTypography/index'
import Phone from 'components/Fields/Phone'
import Input from 'components/Fields/Input'
import { formatPreferencesToServer } from 'components/Dialogs/schemas/offsite-confirmation'

export const TABS = [
  {
    id: 'rooming',
    icon: 'bed',
    label: 'Room',
    activeIf: ['room_sharing', 'roommates']
  },
  {
    id: 'transfer',
    icon: 'train',
    label: 'Transfer',
    activeIf: ['transfer', 'transfer_details']
  },
  {
    id: 'personal',
    icon: 'person',
    label: 'Personal infos',
    activeIf: ['personal_infos']
  },
  {
    id: 'custom',
    icon: 'settings',
    label: 'Other',
    activeIf: ['custom_fields']
  }
]

const CustomTabPanel = ({ children, value, tab, ...other }) => {
  return (
    <div
      role="tabpanel"
      // hidden={value !== tab.id}
      id={`tabpanel-${tab.id}`}
      aria-labelledby={`tab-${tab.id}`}
      {...other}>
      <MDBox sx={{ pt: 2 }}>{children}</MDBox>
    </div>
  )
}

const PreferencesDialog = ({
  open,
  tab,
  offsiteId,
  preferences,
  options,
  participant,
  onSave,
  onClose
}) => {
  const ACTUAL_TABS = TABS.filter((t) => {
    return t.id === 'custom'
      ? preferences?.custom_fields?.length > 0
      : preferences?.fieldsets?.some((f) => t.activeIf.includes(f.name) && f.active)
  })
  const [selectedTab, setSelectedTab] = useState(tab || ACTUAL_TABS[0]?.id || 'rooming')
  const [isEditing, setIsEditing] = useState(false)
  const [preferencesValues, setPreferencesValues] = useState(null)
  const [participantInfos, setParticipantInfos] = useState({
    email: participant?.email,
    first_name: participant?.first_name,
    last_name: participant?.last_name,
    touched: false
  })
  const [phone, setPhone] = useState(null)
  const preferencesRef = useRef(null)

  const resetState = () => {
    setPreferencesValues(null)
    setPhone(null)
    preferencesRef.current = null
    setIsEditing(false)
    setSelectedTab(tab || ACTUAL_TABS[0]?.id || 'rooming')
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      resetState()
      onClose()
    }
  }

  const handleOnSave = () => {
    if ((preferencesValues || phone || participantInfos.touched) && onSave && typeof onSave === 'function') {
      resetState()
      const _preferences = formatPreferencesToServer(
        preferencesValues || {},
        participant?.offsite_preference
      )
      onSave({ values: phone ? { mobile: phone, ..._preferences } : _preferences, participant, participantInfos })
    }
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(TABS[newValue].id)
  }

  const handleEdit = () => {
    setIsEditing(!isEditing)
  }

  const handlePreferencesChange = ({ newValues, initialValues }) => {
    let preferences = null
    if (preferencesRef.current) {
      preferences = { ...preferencesRef.current, ...newValues }
      setPreferencesValues(preferences)
    } else {
      preferences = { ...initialValues, ...newValues }
      setPreferencesValues(preferences)
    }
    preferencesRef.current = preferences
  }

  const handleInfoChange = (e) => {
    const { value, name } = e.target
    const newInfos = { ...participantInfos, [name]: value, touched: true }
    setParticipantInfos(newInfos)
  }

  const handlePhoneChange = (e) => {
    const { value } = e.target
    setPhone(value)
  }

  useEffect(() => {
    if (selectedTab) {
      const element = document.getElementById(`fieldset-${selectedTab}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedTab])

  useEffect(() => {
    if (participant) {
      setParticipantInfos({
        email: participant?.email,
        first_name: participant?.first_name,
        last_name: participant?.last_name
      })
    }
  }, [participant])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ '.MuiPaper-root': { overflowY: 'auto' }, marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <DialogTitle>{''}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <Icon>close</Icon>
      </IconButton>
      <DialogContent
        className="dialogContent"
        sx={{
          pt: 0,
          flex: '1 1 auto',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          overflowY: 'hidden',
          flexDirection: 'column',
          gap: '24px'
        }}>
        <MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            my={3}>
            {isEditing && !participant?.user ? (
              <MDBox display="flex" gap={1}>
                <Input
                  name="first_name"
                  label="First name"
                  value={participantInfos.first_name}
                  boxStyles={{ my: 1, width: '100px' }}
                  size="small"
                  onChange={handleInfoChange}
                />
                <Input
                  name="last_name"
                  label="Last name"
                  value={participantInfos.last_name}
                  boxStyles={{ my: 1, width: '100px' }}
                  size="small"
                  onChange={handleInfoChange}
                />
              </MDBox>
            ) : (
              <MDTypography>
                {participant?.first_name
                  ? `${participant?.first_name} ${participant?.last_name}`
                  : ''}
              </MDTypography>
            )}
            {isEditing && participant && !participant.user ? (
              <Input
                value={participantInfos.email}
                name="email"
                label="Email"
                boxStyles={{ my: 1 }}
                size="small"
                onChange={handleInfoChange}
              />
            ) : (
              <MDTypography variant="button" display="flex" alignItems="center">
                <Icon sx={{ mr: 1, fontSize: 16 }}>mail_outline</Icon>
                {participant?.email}
              </MDTypography>
            )}

            {isEditing && participant && participant.user ? (
              <MDBox mt={1}>
                <Phone name="mobile" value={participant.user.mobile} onChange={handlePhoneChange} />
              </MDBox>
            ) : (
              <MDTypography variant="button" display="flex" alignItems="center">
                <Icon sx={{ mr: 1, fontSize: 16 }}>phone</Icon>
                {participant && participant.user && participant.user.mobile
                  ? participant.user.mobile
                  : 'No phone number'}
              </MDTypography>
            )}
            <MDBox mt={2}>
              <MDButton variant="outlined" color="secondary" size="small" onClick={handleEdit}>
                {isEditing ? 'Cancel' : 'Edit'}
                <Icon sx={{ ml: 1 }}>{isEditing ? 'cancel' : 'edit'}</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
          {ACTUAL_TABS && ACTUAL_TABS.length ? (
            <Tabs
              value={ACTUAL_TABS.findIndex((t) => t.id === selectedTab)}
              onChange={handleTabChange}
              sx={{ position: 'sticky', top: 0 }}
              aria-label="icon label example"
              noOverride>
              {ACTUAL_TABS.map((tab, index) => (
                <Tab key={`tab-${index}`} icon={<Icon>{tab.icon}</Icon>} label={tab.label} />
              ))}
            </Tabs>
          ) : null}
        </MDBox>
        <MDBox sx={{ overflow: 'auto' }}>
          {ACTUAL_TABS && ACTUAL_TABS.length
            ? ACTUAL_TABS.map((tab, index) => (
                <CustomTabPanel key={`tabpanel-${index}`} value={selectedTab} tab={tab}>
                  <PreferencesFieldset
                    offsiteId={offsiteId}
                    isEditing={isEditing}
                    preferences={preferences}
                    tab={tab}
                    participant={participant}
                    options={options}
                    onChange={handlePreferencesChange}
                    onSave={handleOnSave}
                  />
                </CustomTabPanel>
              ))
            : null}
        </MDBox>
      </DialogContent>
      {isEditing ? (
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton
            disabled={!preferencesValues && !phone && !participantInfos?.touched}
            onClick={handleOnSave}
            variant="contained"
            color="primary">
            Save
          </MDButton>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default PreferencesDialog
