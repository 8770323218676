import { useState, useEffect, useMemo } from 'react'
import { useActionData, useSubmit, useLoaderData, useRouteLoaderData } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'
import { capitalizeFirstLetter } from 'utils/functions'

import {
  generateAgendaDescription,
  generateExperiencesDescription
} from 'services/ai/generation-functions'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Skeleton from '@mui/material/Skeleton'
import { debounce } from '@mui/material/utils'

import Form from 'components/Form'
import SampleAccommodationsDialog from 'components/Dialogs/SampleAccommodationsDialog'
import AccommodationsSmallCard from '../components/communication/AccommodationSmallCard'
import OffsiteNotifications from '../components/communication/OffsiteNotifications'
import OffsiteAdditionalInformations from '../components/communication/OffsiteAdditionalInformations'

import { getCommunicationSchema, getGeneralCommunicationSchema } from '../schemas/communication'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const AddAccommodationCard = ({ onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        py={4}
        px={2}
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">Add an accommodation</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            house
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const CommunicationStep = ({ parent = 'offsite' }) => {
  const submit = useSubmit()
  const dispatch = useDispatch()
  const actionData = useActionData()
  const { communication } = useLoaderData()
  const { offsite, offsiteVenue, profile } = useRouteLoaderData(`${parent}-detail`)
  const [accommodationsLoading, setAccommodationsLoading] = useState(false)
  const [accommodationDialogOpen, setAccommodationDialogOpen] = useState(false)
  const [selectedAccommodations, setSelectedAccommodations] = useState(null)
  const [sampleProp, setSampleProp] = useState(null)

  const offsiteUrl = btoa(`{"offer":${offsite.id}}`)

  const handleGenerateClick = ({ name, value }) => {
    if (name === 'agenda_description') {
      return new Promise(async (resolve) => {
        const description = await generateAgendaDescription({
          dispatch,
          smartwayApi,
          params: { offsite: offsite.id },
          start_at: offsite.start_at,
          end_at: offsite.end_at
        })
        resolve({ [name]: description })
      })
    }
    if (name === 'experiences_description') {
      return new Promise(async (resolve) => {
        const description = await generateExperiencesDescription({ dispatch, smartwayApi, offsite })
        resolve({ [name]: description })
      })
    }
  }

  const schema = profile && profile.is_staff ? getCommunicationSchema({ handleGenerateClick }) : {}
  const generalSchema = getGeneralCommunicationSchema({ profile, parent })
  const initialGeneralValues = {
    ...offsite,
    ...communication,
    transport_description:
      communication.transport_description ||
      `${offsiteVenue.transports_description}<br/> ${offsiteVenue.transports?.replaceAll(
        '|',
        '<br/>'
      )}`
  }

  const handleAddAccommodations = () => {
    setAccommodationDialogOpen(true)
  }

  const handleRemoveAccommodation = (accommodation) => {
    const newAccommodations = selectedAccommodations.filter((a) => a.id !== accommodation.id)
    setAccommodationsLoading(true)
    submit(
      {
        id: offsite.id,
        company: offsite.company,
        [sampleProp]: newAccommodations.map((a) => a.id)
      },
      { method: 'patch', encType: 'application/json' }
    )
  }

  const handleSaveAccommodations = (accommodations) => {
    submit(
      { id: offsite.id, company: offsite.company, [sampleProp]: accommodations.map((a) => a.id) },
      { method: 'patch', encType: 'application/json' }
    )
    setAccommodationsLoading(true)
    setAccommodationDialogOpen(false)
  }

  const handleGeneralSubmit = async (values) => {

    const isEventLogo = (values.event_logo && values.event_logo[0] && values.event_logo[0] instanceof File) || (values.event_logo && values.event_logo === 'RESET') 
    const isEventLogoNegative = (values.event_logo && values.event_logo[0] && values.event_logo[0] instanceof File) || (values.event_logo_negative && values.event_logo_negative === 'RESET')
    if (isEventLogo || isEventLogoNegative) {
      const formData = new FormData()
      if (isEventLogo) {
        formData.append('event_logo', values.event_logo[0] instanceof File ? values.event_logo[0] : '')
      }
      if (isEventLogoNegative) {
        formData.append('event_logo_negative', values.event_logo_negative[0] instanceof File ? values.event_logo_negative[0] : '')
      }
      await dispatch(
        smartwayApi.endpoints.updateEntity.initiate({
          entity: 'offsite',
          id: offsite.id,
          bodyType: 'formData',
          action: 'logo/',
          formData
        })
      )
    }
    delete values.event_logo
    delete values.event_logo_negative
    submit(
      { id: offsite.id, company: offsite.company, ...values },
      { method: 'patch', encType: 'application/json' }
    )
  }

  const debouncedSave = useMemo(() => {
    return debounce(({ name, value }) => {
      submit(
        { id: offsite.id, company: offsite.company, [name]: value },
        { method: 'patch', encType: 'application/json' }
      )
    }, 1500)
  }, [offsite.id, submit])

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    debouncedSave({ name, value })
  }

  useEffect(() => {
    const _sampleProp = offsite.town
      ? 'sample_accommodations'
      : 'sample_all_in_venues_accommodations'
    const entity = offsite.town ? 'accommodation' : 'venueAccommodation'
    setSampleProp(_sampleProp)
    const fetchFullAccommodations = async () => {
      const promises = communication[_sampleProp].map((a) =>
        dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity, id: a }))
      )
      const results = await Promise.all(promises)
      setSelectedAccommodations(results.map((r) => r.data))
      setAccommodationsLoading(false)
    }
    if (profile && profile.is_staff) {
      if (communication[_sampleProp] && communication[_sampleProp].length) {
        const sortedOffsite = [...communication[_sampleProp]].sort().join(',')
        const sortedSelected =
          selectedAccommodations && selectedAccommodations.length
            ? [...selectedAccommodations]
                .map((a) => a.id)
                .sort()
                .join(',')
            : ''
        if (!selectedAccommodations || sortedOffsite !== sortedSelected) {
          fetchFullAccommodations()
        } else {
          setAccommodationsLoading(false)
        }
      } else {
        setSelectedAccommodations(null)
        setAccommodationsLoading(false)
      }
    }
  }, [
    dispatch,
    profile,
    offsite,
    communication.sample_accommodations,
    communication.sample_all_in_venues_accommodations,
    selectedAccommodations,
    communication
  ])

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(actionData?.entity) || 'Offer'} saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch])

  return (
    <MDBox>
      <SampleAccommodationsDialog
        open={accommodationDialogOpen}
        setOpen={() => setAccommodationDialogOpen(true)}
        onClose={() => setAccommodationDialogOpen(false)}
        venue={offsite.all_in_venue || offsite.town}
        venueType={offsite.all_in_venue ? 'allInVenue' : 'town'}
        selected={selectedAccommodations}
        iconStyles={iconStyles}
        onSave={handleSaveAccommodations}
      />
      <MDBox ml={4}>
        { parent === 'offsite' ? (
          <MDBox display="flex" justifyContent="flex-end" mb={2}>
            <a
              href={`https://listings.smartway.work/offsites/${offsiteUrl}`}
              target="_blank"
              rel="noreferrer">
              <MDButton variant="outlined" color="dark" size="small">
                See the public page
                <Icon sx={{ ml: 1 }}>open_in_new</Icon>
              </MDButton>
            </a>
          </MDBox>
        ) : null}
        <MDBox mb={4}>
          <Form {...generalSchema} values={initialGeneralValues} onSubmit={handleGeneralSubmit} />
        </MDBox>

        <MDBox mb={4}>
          <OffsiteNotifications offsiteId={offsite.id} />
        </MDBox>
        <MDBox mb={4}>
          <OffsiteAdditionalInformations offsiteId={offsite.id} />
        </MDBox>

        {profile && profile.is_staff ? (
          <>
            <MDBox>
              <MDTypography variant="h3" fontWeight="medium">
                Accommodations
              </MDTypography>
              <MDBox mt={4}>
                <MDTypography variant="h5" fontWeight="medium">
                  Choose up to 3 accommodations sample
                </MDTypography>
                <MDTypography variant="subtitle2">
                  Show only the best in town in order to impress your future guests!
                </MDTypography>
              </MDBox>
              <MDBox mt={4} width="100%">
                {accommodationsLoading ? (
                  <Grid container spacing={4}>
                    {Array.from(new Array(4)).map((_, index) => (
                      <Grid item xs={3} key={`cards-skeleton-${index}`}>
                        <Skeleton animation="wave" variant="rounded" width="100%" height={136} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <AddAccommodationCard onAdd={handleAddAccommodations} />
                    </Grid>
                    {selectedAccommodations &&
                      selectedAccommodations.map((accommodation) => {
                        return (
                          <Grid item xs={3} key={`selected-accommodation-${accommodation.id}`}>
                            <AccommodationsSmallCard
                              {...accommodation}
                              iconStyles={iconStyles}
                              onRemove={handleRemoveAccommodation}
                            />
                          </Grid>
                        )
                      })}
                  </Grid>
                )}
              </MDBox>
            </MDBox>

            <MDBox mt={6}>
              <MDTypography variant="h3" fontWeight="medium">
                Descriptions
              </MDTypography>
              <Form
                {...schema}
                values={offsite}
                watchedFields={[
                  'accommodations_description',
                  'agenda_description',
                  'experiences_description'
                ]}
                onFieldChange={handleFieldChange}
                onSubmit={() => {}}
              />
            </MDBox>
          </>
        ) : null}
      </MDBox>
    </MDBox>
  )
}

export default CommunicationStep
