import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import MDBox from 'components/MDBox'
import { OffsiteCardSkeleton } from './components/OffsiteCard/Skeletons'

const ske = { variant: 'rounded', animation: 'wave' }

const SidenavSkeleton = () => {
  return (
    <Card>
      <MDBox px={3} pt={4} pb={0}>
        {[80, 120, 150, 100].map((l, index) => (
          <MDBox display="flex" alignItems="center" pb={4} pl={1} key={`sidenav-skeleton-${index}`}>
            <Skeleton {...ske} width={20} height={20} variant="circular" />
            <Skeleton {...ske} width={l} height={20} sx={{ ml: 1 }} />
          </MDBox>
        ))}
      </MDBox>
    </Card>
  )
}
const HeaderSkeleton = () => {
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <Skeleton {...ske} width={60} height={60} variant="circular" />
          <MDBox ml={2}>
            <MDBox display="flex" alignItems="center">
              <Skeleton {...ske} width={70} height={28} />
              <Skeleton {...ske} width={80} height={28} sx={{ ml: 1 }} />
            </MDBox>
            <MDBox display="flex" mt={1}>
              <Skeleton {...ske} width={70} height={20} />
              <Skeleton {...ske} width={100} height={20} sx={{ ml: 8 }} />
            </MDBox>

          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const InputSkeleton = ({ width }) => {
  return (
    <MDBox width="48%" mb={2} sx={{ borderBottom: '0.0625rem solid #f0f2f5' }}>
      <Skeleton {...ske} width={70} height={11} />
      <Skeleton {...ske} width={width} height={20} sx={{ my: 1 }} />
    </MDBox>
  )
}

const BasicInfoSkeleton = () => {
  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" alignItems="center">
          <Skeleton {...ske} width={70} height={28} />
          <Skeleton {...ske} width={80} height={28} sx={{ ml: 1 }} />
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
          { [80, 90, 60, 110, 170, 90, 60, 240].map((l, index) => <InputSkeleton key={`${l}-${index}`} width={l}/> )}
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end" mt={6} >
          <Skeleton {...ske} width={140} height={32} />
        </MDBox>
      </MDBox>
    </Card>
  )
}

const ChangePasswordSkeleton = () => {
  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" alignItems="center" mb={5}>
          <Skeleton {...ske} width={90} height={28} />
          <Skeleton {...ske} width={100} height={28} sx={{ ml: 1 }} />
        </MDBox>
        <Skeleton {...ske} width="100%" height={40} sx={{ mb: 3 }}/>
        <Skeleton {...ske} width="100%" height={40} sx={{ mb: 3 }}/>
        <Skeleton {...ske} width="100%" height={40} sx={{ mb: 3 }}/>
      </MDBox>
    </Card>
  )
}

export const ProfilePageSkeleton = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
        <SidenavSkeleton />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <HeaderSkeleton />
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex" alignItems="center" gap={1} mb={2}>
                <Skeleton {...ske} width="24px" height={28}/>
                <Skeleton {...ske} width="150px" height={28}/>
              </MDBox>
              <OffsiteCardSkeleton />
            </Grid>
            <Grid item xs={12}>
              <BasicInfoSkeleton />
            </Grid>
            <Grid item xs={12}>
              <ChangePasswordSkeleton />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
}
