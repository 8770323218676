import { useState, useEffect } from 'react'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAlert from 'components/MDAlert'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import VideoComponent from 'components/Fields/VideoUpload/VideoComponent'
import { BunnyGallerySkeleton } from './Skeletons'
import FileUpload from 'components/Fields/FileUpload'

import noVideos from 'assets/illustrations/no_videos.svg'
import internalError from 'assets/illustrations/error.svg'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon
} from '@mui/material'
import Form from 'components/Form/Form'

const CustomTabPanel = ({ children, tab, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={tab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {tab === index && <MDBox sx={{ pb: 2 }}>{children}</MDBox>}
    </div>
  )
}

const VideoGalleryDialog = ({
  open,
  videos,
  selected,
  isLoading,
  isError,
  uploadProps,
  error,
  onVideoAdded,
  onVideoSelect,
  onClose
}) => {
  const [tab, setTab] = useState(0)
  const [videoEvent, setVideoEvent] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(selected)
  const [videoError, setVideoError] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      setVideoEvent(null)
      setSelectedVideo(null)
      setTab(0)
      onClose()
    }
  }

  const handleOnSave = () => {
    if (videoEvent) {
      const { name, file, event } = videoEvent
      onVideoAdded(name, file, event)
    } else if (selectedVideo) {
      onVideoSelect(selectedVideo)
    }
    handleOnClose()
  }

  const handleSelectVideo = (video) => {
    if (selectedVideo && selectedVideo.guid === video.guid) {
      setSelectedVideo(null)
    } else {
      setSelectedVideo(video)
    }
  }

  const handleOnVideoChange = (event, _values, formik) => {
    setVideoError(false)
    const { files } = event.target
    if (formik.errors?.video) {
      setVideoError(true)
    }
    setVideoEvent({ name: uploadProps.name, file: files[0], event })
  }

  useEffect(() => {
    if (selected && selected !== selectedVideo) {
      setSelectedVideo(selected)
    }
    // We do not put selectedVideo in the deps because it would not be possible change the selectedVideo otherwise
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="icon label tabs example">
            <Tab label="Choose from gallery" />
            <Tab label="Upload a new video" />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers className="dialogContent">
          <MDBox sx={{ maxHeight: 'calc(100vh - 220px)'}}>
            <CustomTabPanel tab={tab} index={0}>
              {isLoading ? (
                <BunnyGallerySkeleton />
              ) : isError ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%">
                  <MDBox
                    component="img"
                    src={internalError}
                    width="300px"
                    height="250px"
                    ml={4}
                    mb={3}
                    alt="internal error"
                  />
                  <MDTypography
                    variant="body2"
                    textAlign="center"
                    maxWidth="450px"
                    mb={4}>
                    Sorry it seems that there was an error loading the video
                    gallery. Please try again later or contact your
                    administrator!
                  </MDTypography>
                </MDBox>
              ) : videos && !!videos.length ? (
                <Grid
                  container
                  display="grid"
                  gridTemplateColumns="repeat(2, 1fr)"
                  alignItems="start"
                  spacing={2}
                  sx={{ height: '100%' }}>
                  {videos.map((video) => {
                    return (
                      <Grid item key={`video-${video.guid}`}>
                        <VideoComponent
                          key={video.guid}
                          selectable
                          selected={video.guid === selectedVideo?.guid}
                          video={video}
                          onSelect={handleSelectVideo}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%">
                  <MDBox
                    component="img"
                    src={noVideos}
                    width="300px"
                    height="250px"
                    ml={4}
                    mb={3}
                    alt="internal error"
                  />
                  <MDTypography
                    variant="body2"
                    textAlign="center"
                    maxWidth="450px"
                    mb={4}>
                    We cannot find any video for the moment. Upload your first
                    video!
                  </MDTypography>
                </MDBox>
              )}
            </CustomTabPanel>
            <CustomTabPanel tab={tab} index={1}>
              <Form fieldsets={[
                {
                  name: 'video',
                  fields: [
                    {
                      name: 'video',
                      ...uploadProps.innerField,
                      type: 'file',
                      isVideo: true,
                    }
                  ]
                }
                
              ]}
              watchedFields={['video']}
              onFieldChange={handleOnVideoChange}/>
              {/* <FileUpload
                {...uploadProps}
                isVideo
                onFileChange={handleOnVideoChange}
              /> */}
            </CustomTabPanel>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton disabled={videoError} onClick={handleOnSave} variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default VideoGalleryDialog
