import { format } from 'date-fns'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const NotificationCard = ({
  id,
  title,
  body,
  sent,
  send_at,
  image_url,
  onEdit,
  onDelete,
  onAdd,
  ...notification
}) => {
  const handleEdit = () => {
    onEdit({ id, title, body, image_url, sent, send_at, ...notification })
  }
  const handleRemove = () => {
    onDelete({ id, title, body, image_url, sent, send_at, ...notification })
  }
  return (
    <Card sx={{ height: '100%', ...(sent ? { ':after': { 
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      zIndex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.6)'
    }} : {}) }}>
      {image_url ? <CardMedia sx={{ height: 80 }} image={image_url} title="Notification image" /> : null}
      <CardContent sx={{ flexGrow: 1, pb: 0.5 }}>
        <MDBox>
          <MDTypography variant="button" sx={{ fontWeight: 500 }} component="div">
            {title}
          </MDTypography>
          <MDTypography
            display="block"
            mb={1}
            variant="caption"
            color="secondary"
            dangerouslySetInnerHTML={{
              __html: body
            }}></MDTypography>
          
          { !sent && send_at ? (
            <MDTypography display="block" variant="caption">
              Scheduled for: {format(new Date(send_at), 'dd/MM/yyyy HH:mm')}
            </MDTypography>
          ) : null }
          { sent ? (
            <MDTypography display="block" variant="caption">
              Sent: {format(new Date(send_at), 'dd/MM/yyyy HH:mm')}
            </MDTypography>
          ) : null }
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
        <MDButton color="primary" size="small" onClick={handleEdit} iconOnly>
          <Icon>edit</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default NotificationCard
