import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAlert from 'components/MDAlert'

import { AddCard } from './Repeater'
import EntityCard from './EntityCard'
import EntityDialog from './EntityDialog'

import Select from 'components/Fields/Select'
import { CardsSkeleton } from './Skeletons'

const TYPES = {
  // TOWN: 'town',
  // ACCOMMODATION: 'accommodation',
  // CATERING: 'catering',
  // COWORKING: 'coworking',
  // EXPERIENCE: 'experience',
  // PLENARY_ALL: 'plenaryHall',
  // WORKSHOP: 'workshop',
  PACKAGE: 'package',
  ALL_IN_VENUE: 'allInVenue'
}
export const getApiEntityByType = (entityType) => {
  return TYPES[entityType]
}

const TYPES_OPTIONS = Object.keys(TYPES).map((key) => ({ label: TYPES[key], value: key }))

const EntityPicker = ({ section, entity, onSave, onDelete }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [entityType, setEntityType] = useState(entity && entity.type ? entity.type : null)
  const [selected, setSelected] = useState([])
  const [entityDialogOpen, setEntityDialogOpen] = useState(false)

  const handleAddEntity = () => {
    setEntityDialogOpen(true)
  }

  const handleDeleteEntity = (entity) => {
    if (onDelete && typeof onDelete === 'function') {
      const isRepeaterItem = section.type === 'INNER_SECTION'
      onDelete(section, entity, isRepeaterItem)
    }
  }

  const handleSaveEntity = (selectedItems) => {
    setEntityDialogOpen(false)
    if (onSave && typeof onSave === 'function') {
      onSave(section, { type: entityType, selected: selectedItems.map((r) => r.id) })
    }
  }

  const handleEntityTypeChange = (event) => {
    const { value } = event.target
    if (value !== entityType) {
      setEntityType(value)
      setSelected([])
    }
  }

  useEffect(() => {
    if (entity && entity.type) {
      setIsLoading(true)
      const fetchSelected = async () => {
        try {
          const promises = entity.selected.map((id) => {
            const _entity = getApiEntityByType(entity.type)
            return dispatch(
              smartwayApi.endpoints.fetchEntity.initiate({
                entity: _entity,
                id
              })
            )
          })
          const results = await Promise.all(promises)
          if (results && results.length) {
            const _results = results.reduce((acc, curr) => {
              if (curr.status === 'fulfilled' && curr.data) {
                acc.push(curr.data)
              }
              return acc
            }, [])
            setSelected(_results)
          }
        } catch (error) {
          console.warn(error)
        } finally {
          setIsLoading(false)
        }
      }
      if (entity.selected && !!entity.selected.length) {
        fetchSelected()
      } else {
        setIsLoading(false)
      }
    }
  }, [dispatch, entity])

  return (
    <MDBox p={2} sx={{ borderRadius: '8px', backgroundColor: 'grey.200' }}>
      <MDTypography mb={3}>Select associated items</MDTypography>
      <Grid container rowSpacing={6} columnSpacing={3} pb={8}>
        <Grid item xs={12}>
          { entityType && selected && selected.length ? (
             <MDAlert color="secondary" >
             <Icon fontSize="small" sx={{ mr: 2 }}>
               warning
             </Icon>
             <MDTypography variant="body2" fontWeight="regular" color="white">
               If you change the type, all previously selected items will be deleted
             </MDTypography>
           </MDAlert>
          ) : null }
          <Select
            label="Type"
            value={entityType}
            onChange={handleEntityTypeChange}
            options={TYPES_OPTIONS}
            sx={{ mb: 2 }}
          />
        </Grid>
        <>
          {entityType ? isLoading ? (
            <MDBox width="100%" ml={4} mt={4}>
              <CardsSkeleton />
            </MDBox>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={4} sx={{ paddingTop: '16px !important' }}>
                <AddCard title="Add an item" icon="people" onAdd={handleAddEntity} />
              </Grid>
              {selected.map((selectedEntity, index) => (
                <Grid key={`card-${index}`} item xs={12} sm={6} md={4} sx={{ paddingTop: '16px !important' }}>
                  <EntityCard {...selectedEntity} type={entityType} onDelete={handleDeleteEntity} />
                </Grid>
              ))}
            </>
          ) : null}
        </>
        <EntityDialog
          open={entityDialogOpen}
          title="Add an item"
          onSave={handleSaveEntity}
          onClose={() => setEntityDialogOpen(false)}
          type={entityType}
          selected={selected}
        />
      </Grid>
    </MDBox>
  )
}

export default EntityPicker
