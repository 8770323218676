const positionOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Center',
    value: 'center'
  },
  {
    label: 'Outside',
    value: 'outside'
  }
]

const filters = {
  id: 'offsite-rooms-filters',
  name: 'offsite-rooms-filters',
  fieldsets: [
    {
      id: 'offsite-rooms-filters',
      sx: { pt: 1, pb: 0 },
      fields: [
        {
          type: 'range',
          label: 'Price',
          name: 'price',
          min: 25,
          max: 200,
          prefix: '€',
          initialValue: [50, 150],
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            marginBottom: '0px !important'
          }
        },
        {
          type: 'badges',
          name: 'position',
          size: 'small',
          label: 'Position',
          sx: { ml: 2, marginBottom: '8px !important' },
          options: positionOptions
        },
        {
          type: 'badges',
          size: 'small',
          name: 'categories',
          label: 'Room typology',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          sx: { ml: 2, marginBottom: '8px !important' },
          optionsGetter: {
            endpoints: [
              {
                entity: 'roomLevels',
              }
            ]
          }
        },
        {
          type: 'checkboxgroup',
          name: 'amenities',
          groups: [
            { 
              label: 'Amenities', 
              controls: [
                { label: 'Breakfast in accommodation', name: 'breakfast_included' }
              ] 
            },
          ],
          validations: null,
          sx: { marginBottom: '0px !important', ml: 2 }
        }
      ]
    }
  ]
}

export default filters