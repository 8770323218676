const schema = {
  title: 'Change Password',
  id: 'change-password-form',
  fieldsets: [
    {
      id: 'change-password',
      fields: [
        {
          type: 'password',
          name: 'password',
          label: 'Current password',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required'],
            }
          ]
        },
        {
          type: 'password',
          name: 'new-password',
          label: 'New password',
          helperText: 'Please follow the guide below to create a strong password',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required'],
            },
            {
              type: 'min',
              params: [8, 'The password must be at least 8 characters'],
            },
            {
              type: 'password',
              params: [
                'The password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
              ]
            }
          ]
        },
        {
          type: 'password',
          name: 'confirm-new-password',
          label: 'Confirm new password',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required'],
            },
            {
              type: 'min',
              params: [8, 'The password must be at least 8 characters'],
            },
            {
              type: 'isEqualTo',
              params: ['The passwords do not match', 'new-password'],
            }
          ]
        },
      ]
    }
  ],
  ctasPosition: 'bottom',
  submitButton: {
    label: 'Update Password',
    fullWidth: false,
    size: 'small',
    variant: 'gradient',
    color: 'dark'
  }
}

export default schema