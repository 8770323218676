import MDBox from 'components/MDBox'
import Switch from '../Switch'
import MDTypography from 'components/MDTypography/index'

const SwitchGroup = ({ name, value, label, options, onChange }) => {

  const handleOnChange = (event) => {
    const { name: eventName } = event.target
    const newValue = eventName === value ? null : eventName 
    onChange({ target: { name, value: newValue } })
  }
  return (
    <MDBox>
      { options && options.length ? (
        <MDBox>
          { label ? (
            <MDTypography>{label}</MDTypography>
          ) : null}
          { options.map(({ name: optionName, label, ...control }, index) => {
            return (
              <Switch
                key={index}
                name={optionName}
                label={label}
                value={optionName === value}
                {...control}
                containerStyles={{ mb: 0, ml: 0 }}
                onChange={handleOnChange}
              />
            )
          })}
        </MDBox>
      ) : null }

    </MDBox>
  )
}

export default SwitchGroup