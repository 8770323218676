import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

const ske = { variant: 'rounded', animation: 'wave' }

const UsersTableRowSkeleton = () => {
  return (
    <>
      <MDBox display="flex" alignItems="center">
        <Skeleton {...ske} variant="circular" width={40} height={40} />
        <Skeleton {...ske} width={40} height={20} sx={{ ml: 2 }} />
        <Skeleton {...ske} width={60} height={20} sx={{ ml: 1 }} />
      </MDBox>
      <Skeleton {...ske} width={80} height={20} />
      <Skeleton {...ske} width={80} height={20} />
      <MDBox display="flex" alignItems="center">
        <Skeleton {...ske} width={30} height={20} />
        <Skeleton {...ske} width={70} height={20} sx={{ ml: 1 }} />
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <Skeleton {...ske} variant="circular" width={40} height={40} />
        <Skeleton {...ske} width={50} height={20} sx={{ ml: 2 }}/>
        <Skeleton {...ske} width={60} height={20} sx={{ ml: 1 }}/>
      </MDBox>
    </>
  )
}

export const UsersTableSkeleton = () => {
  return (
    <Card sx={{ mt: 4 }}>
      <MDBox px={3} pt={3} pb={4}>
        <Skeleton {...ske} width={80} height={35}/>
        <Skeleton {...ske} width={400} height={20} sx={{ mt: 1}} />
      </MDBox>
      <MDBox p={3} display="flex" alignItems="center">
        <Skeleton {...ske} width={80} height={40}/>
        <Skeleton {...ske} width={100} height={20} sx={{ ml: 1 }}/>
      </MDBox>
      <MDBox px={3} pb={2} display="grid" alignItems="center" gridTemplateColumns="repeat(5, 1fr)" sx={{ borderBottom: '0.0625rem solid #f0f2f5'}}>
        <Skeleton {...ske} width={40} height={20} />
        <Skeleton {...ske} width={60} height={20} />
        <Skeleton {...ske} width={80} height={20} />
        <Skeleton {...ske} width={40} height={20} />
        <Skeleton {...ske} width={50} height={20} />
      </MDBox>
      {[...Array(10)].map((_, index) => (
        <MDBox key={`user-row-skeleton-${index}`} px={3} py={2} display="grid" alignItems="center" gridTemplateColumns="repeat(5, 1fr)" sx={{ borderBottom: '0.0625rem solid #f0f2f5'}}>
          <UsersTableRowSkeleton />
        </MDBox>
      ))}
      <MDBox p={3} display="flex">
        <Skeleton {...ske} width={200} height={20}/>
      </MDBox>
    </Card>
  )
}