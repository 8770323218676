import DataTable from "components/Tables/DataTable"
import MDBox from 'components/MDBox'
import RoomBeds from "components/RoomBeds/RoomBeds"
import RoomActionsCell from "./RoomActionsCell"
const RoomsTableView = ({ rooms, selectedRooms, onSelect }) => {
  const roomsTable = {
    columns: [
      { Header: '', accessor: 'selected' },
      { Header: 'title', accessor: 'title' },
      { Header: 'category', accessor: 'category' },
      { Header: 'level', accessor: 'level' },
      { Header: 'capacity', accessor: 'capacity' },
      { Header: 'bathrooms', accessor: 'bathrooms' },
      { Header: 'price', accessor: 'price' },
    ],
    rows: rooms && !!rooms.length ? rooms.map(
      (room) => {
        const { title, level, capacity, category, price_min, price_max, bathrooms } = room
        
        return {
          __data: room,
          __styles: ({ id }) => {
            if (selectedRooms.find(rId => rId === id)) {
              return {
                backgroundColor: 'rgba(216, 27, 96, 0.05)'
              }
            }
            return {}
          },
          selected: <RoomActionsCell
            room={room}
            selected={!!selectedRooms.find(r => r === room.id)}
            onSelect={onSelect}
          />,
          title,
          category: <RoomBeds category={category} />,
          level,
          capacity,
          bathrooms,
          price: `${Number(price_min)}€ - ${Number(price_max)}€`
        }
      }
    ) : []
  }
  return (
    <MDBox>
    <DataTable 
      canSearch
      table={roomsTable}
      showTotalEntries={false}
    />
    </MDBox>
  )
}

export default RoomsTableView