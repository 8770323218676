import { Suspense } from 'react'
import { Await, useRouteLoaderData, useLoaderData } from 'react-router-dom'
import ParticipantsStep from './ParticipantsStep'
import { ParticipantsSkeleton } from '../components/participants/Skeletons'

const ParticipantsStepWrapper = ({ onBack }) => {
  const { profile, offsite, offsiteVenue } = useRouteLoaderData('offsite-detail')
  const { data: loaderData } = useLoaderData()

  return (
    <Suspense fallback={<ParticipantsSkeleton />}>
      <Await resolve={loaderData}>
        {(data) => {
          return (
            <ParticipantsStep
              profile={profile}
              offsite={offsite}
              offsiteVenue={offsiteVenue}
              participants={data[0]}
              dietaryOptions={data[1]}
              allergiesOptions={data[2]}
              onBack={onBack}
            />
          )
        }}
      </Await>
    </Suspense>
  )
}
export default ParticipantsStepWrapper
