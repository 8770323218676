import { useRef } from 'react'
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'

import user from 'assets/icons/user.png'


const UserCell = ({ image, name, id, baseUrl = '' }) => {
  const _image = useRef(image && image.thumbnail ? image.thumbnail : null)

  if (!_image.current) {
    _image.current = user
  }

  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox mr={2}>
        <MDAvatar src={_image.current} alt={name} />
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <Link to={`${baseUrl}${id}`}>
          <MDTypography variant="button" fontWeight="medium">
            {name}
          </MDTypography>
        </Link>
      </MDBox>
    </MDBox>
  )
}

// Typechecking props for the UserCell
UserCell.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string.isRequired
}

export default UserCell
