import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import NotificationDialog from 'components/Dialogs/NotificationDialog'
import NotificationCard from './NotificationCard'

import { CardsSkeleton } from 'components/Dialogs/Skeletons'

const AddCard = ({ title, icon, onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        minHeight="180px"
        minWidth="120px"
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">{title}</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            {icon}
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const OffsiteNotifications = ({ offsiteId }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [notifications, setNotifications] = useState(null)
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)
  const [editingNotification, setEditingNotification] = useState({})

  const handleEditNotification = (notification) => {
    setNotificationDialogOpen(true)
    setEditingNotification(notification)
  }

  const handleAddNotification = () => {
    setNotificationDialogOpen(true)
  }
  
  const handleSaveNotification = async ({ confirmed, send_at, scheduled, ...notification }) => {
    return new Promise(async (resolve, reject) => {
      const { id } = editingNotification
      setIsLoading(true)
      try {
        const sendTo = {
          'everyone': null,
          'confirmed': true,
          'not_confirmed': false
        }
        const values = {
          ...notification,
          ...(scheduled ? { send_at } : { now: true }),
          confirmed: confirmed !== null ? sendTo[confirmed] : null,
        }
        const response = await dispatch(smartwayApi.endpoints[id ? 'updateEntity' : 'createEntity'].initiate({
          entity: 'offsite',
          ...(id ? { id: offsiteId } : {}),
          action: `${id ? '' : `${offsiteId}/`}service-notifications/${id ? `${id}/` : ''}`,
          ...values
        }))
        if (response && response.data) {
          await fetchNotifications(offsiteId)
          notifyFeedback({ success: true })
        }
        resolve()
      } catch (error) {
        console.warn(error)
        notifyFeedback({ success: false })
        reject()
      } finally {
        setIsLoading(false)
        setNotificationDialogOpen(false)
        setEditingNotification({})
      }
    })
  }


  const handleDeleteNotification = ({ id, ...notification}) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true)
      try {
        const response = await dispatch(smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'offsite',
          id: offsiteId,
          action: `service-notifications/${id}/`,
        }))
        if (response && response.data) {
          await fetchNotifications(offsiteId)
          notifyFeedback({ success: true })
        }
        resolve()
      } catch (error) {
        console.warn(error)
        notifyFeedback({ success: false })
        reject()
      } finally {
        setIsLoading(false)
        setNotificationDialogOpen(false)
        setEditingNotification({})
      }
    })
  }

  const handleCloseNotificationDialog = () => {
    setNotificationDialogOpen(false)
    setEditingNotification({})
  }

  const fetchNotifications = async (offsiteId) => {
    try {
      const notifications = await dispatch(smartwayApi.endpoints.fetchAllEntities.initiate({
        entity: 'service-notifications',
        offsiteId,
        params: {
          page_size: 100
        }
      }))
      if (notifications && notifications.data) {
        setNotifications(notifications.data)
      } else {
        setNotifications([])
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const notifyFeedback = ({ success }) => {
    if (success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Everything worked fine',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: 'Ops!',
          message: 'There was an error updating the notifications',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }

  useEffect(() => {
    if (offsiteId) {
      if (!notifications) {
        fetchNotifications(offsiteId)      
      }
    } else {
      setIsLoading(false)
      setNotifications([])
    }
  }, [offsiteId])

  return (
    <MDBox sx={{
      marginLeft: '-12px',
      marginBottom: '-12px',
      marginRight: '12px',
      padding: '24px',
      backgroundColor: 'grey.100',
      borderRadius: '8px'
    }}>
      <MDBox mb={3}>
        <MDTypography variant="h5">Notifications</MDTypography>
        <MDTypography variant="body2">You can send notifications or schedule them in the future</MDTypography>
      </MDBox>

      { isLoading ? <CardsSkeleton /> : (
        <Grid container rowSpacing={3} columnSpacing={3} pb={8}>
          <Grid item xs={12} sm={6} md={3}>
            <AddCard title="Add a notification" icon="message" onAdd={handleAddNotification} />
          </Grid>
          {[...notifications].sort((a, b) => {
            return a.sent ? 1 : -1
          }).map((notification, index) => (
            <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
              <NotificationCard
                {...notification}
                onEdit={handleEditNotification}
                onSave={handleSaveNotification}
                onDelete={handleDeleteNotification}
              />
            </Grid>
          ))}
        </Grid>
      ) }
      <NotificationDialog
        open={notificationDialogOpen}
        offsiteId={offsiteId}
        title="Send or schedule a notification"
        notification={editingNotification}
        onSave={handleSaveNotification}
        onClose={handleCloseNotificationDialog}
      />
    </MDBox>
  )
}

export default OffsiteNotifications
