import { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

import Input from '../Input';

const autocompleteService = { current: null };

const MapsAutocomplete = ({ place = '', countries, onPlaceChange }) => {
  const [value, setValue] = useState(place);
  const [inputValue, setInputValue] = useState(place);
  const [options, setOptions] = useState([]);

  const debouncedGetPlacePredictions = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const handlePlaceSelect = (event, newValue) => {
    const placesNode = document.getElementById('places')
    const PlacesService = new window.google.maps.places.PlacesService(placesNode)
    setOptions(newValue ? [newValue, ...options] : options);

    PlacesService.getDetails({reference: newValue.reference}, (place, status) => {
      if (status === 'OK') {
        const { geometry, address_components } = place
        const lat = geometry.location.lat()
        const lng = geometry.location.lng()
        const fullPlace = {...newValue, address_components, lat, lng}
        setValue(fullPlace)
        if (onPlaceChange && typeof onPlaceChange === 'function') {
          onPlaceChange(fullPlace)
        }
      }
    })
  }

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedGetPlacePredictions({ input: inputValue, componentRestrictions: { country: countries } }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, debouncedGetPlacePredictions]);

  return (
    <>
      <Autocomplete
        id="google-map-demo"
        sx={{ width: 300 }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        disableClearable
        onChange={handlePlaceSelect}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <Input
            {...params}
            label="Add a location"
            boxStyles={{ mb: 0 }}
            inputProps={{
              ...params.inputProps,
              sx: { padding: '3px 8px !important' }
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography variant="body2" color="text.secondary">
                    {option.description}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <div id="places"></div>
    </>
  );
}

export default MapsAutocomplete