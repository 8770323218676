import { useRef, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

const FirestoreWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current && process.env.NODE_ENV === 'production') {
      dispatch(smartwayApi.endpoints.firestoreSubscribe.initiate(null, { forceRefetch: true }))
      initialized.current = true
    }
  }, [dispatch])

  return children
} 

export default FirestoreWrapper