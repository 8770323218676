import { capitalizeFirstLetter } from 'utils/functions'
import { STATUSES } from 'utils/offsiteStatus'
const defaultInputsProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true
  }
}

const getDefinitionSchema = ({
  profile,
  editingMode,
  values,
  parent,
  groups,
  timezone,
  onNoCompanyClick
}) => {
  const managers = groups && groups.length ? groups.find((g) => g.name === 'Manager') : {}
  const isStaff = profile && profile.is_staff
  const buttonLabel = editingMode ? 'Next' : `Create ${parent}`
  let warning = null
  const disabledField = editingMode && values.status?.includes('SIGNED_CONTRACT')

  const indicativeNumberDaysField = {
    type: 'number',
    name: 'indicative_number_days',
    label: 'Indicative number of days',
    grid: { xs: 6 },
    required: true,
    validationType: 'number',
    validations: values.dates_switch
      ? null
      : [
          {
            type: 'positive',
            params: ['The number of days must be greater than 0']
          }
        ],
    hidden: values.dates_switch,
    helperText: 'This field will be ignored if you choose a date range',
    ...defaultInputsProps
  }

  const datepickerRangeField = {
    type: 'datepickerrange',
    name: 'start_at_end_at',
    hidden: !values.dates_switch,
    timezone,
    start: {
      name: 'start_at',
      label: 'Check-in',
      placeholder: 'Select a check-in date',
      minDate: !editingMode ? new Date() : null
    },
    end: {
      name: 'end_at',
      label: 'Check-out',
      placeholder: 'Select a check-out date'
    },
    disabled: disabledField,
    validationType: 'string',
    grid: { xs: 12 },
    minDate: 'start_at',
    warning,
    validations: [
      {
        type: 'nullable'
      }
    ],
    inputProps: {
      label: 'Check-out',
      placeholder: 'Select date',
      ...defaultInputsProps
    },
    ...defaultInputsProps
  }

  const datesSwitch = {
    type: 'switch',
    name: 'dates_switch',
    label: 'I know the dates',
    grid: { xs: 12 },
    ...defaultInputsProps
  }

  const { start_at, end_at, activitiesNumber, company } = values
  if (start_at && end_at && !!activitiesNumber) {
    warning = `If you change the dates, please be aware that all the activities will be moved to the new dates. If the number of days changes, you may not be able to see all activities.`
  }

  return {
    id: 'definition-step-form',
    ctasPosition: 'bottom',
    fieldsets: [
      {
        id: 'admin',
        title: `${capitalizeFirstLetter(parent)} key informations`,
        subtitle: 'These fields are only visible to Smartway staff',
        sx: {
          ...(!isStaff
            ? { display: 'none !important' }
            : {
                xs: 6,
                margin: '-12px',
                padding: '12px',
                backgroundColor: 'grey.200',
                borderRadius: '8px'
              })
        },
        fields: [
          {
            type: 'select',
            multiple: true,
            name: 'status',
            label: `${capitalizeFirstLetter(parent)} status`,
            options: STATUSES,
            validationType: 'array',
            labelProp: 'label',
            valueProp: 'name',
            helperText: 'Please select one or more status'
          },
          ...(profile.is_staff && profile.company
            ? [
                {
                  type: 'select',
                  multiple: true,
                  label: 'Smartway Sales referent(s)',
                  name: 'managed_by_sales',
                  multiple: true,
                  optionsGetter: {
                    endpoints: [
                      {
                        entity: 'user',
                        params: {
                          transformResponse: true,
                          company: profile.company?.id
                        }
                      }
                    ]
                  },
                  validationType: 'array',
                  labelProp: 'name',
                  valueProp: 'id',
                  grid: { xs: 6 },
                  helperText: 'Please select one or more Smartway users'
                },
                {
                  type: 'select',
                  multiple: true,
                  label: 'Smartway Operations referent(s)',
                  name: 'managed_by_operations',
                  multiple: true,
                  optionsGetter: {
                    endpoints: [
                      {
                        entity: 'user',
                        params: {
                          transformResponse: true,
                          company: profile.company?.id
                        }
                      }
                    ]
                  },
                  validationType: 'array',
                  labelProp: 'name',
                  valueProp: 'id',
                  grid: { xs: 6 },
                  helperText: 'Please select one or more Smartway users'
                },
                {
                  type: 'select',
                  multiple: true,
                  label: 'Smartway Venue referent(s)',
                  name: 'managed_by_town_angel',
                  multiple: true,
                  optionsGetter: {
                    endpoints: [
                      {
                        entity: 'user',
                        params: {
                          transformResponse: true,
                          company: profile.company?.id
                        }
                      }
                    ]
                  },
                  validationType: 'array',
                  labelProp: 'name',
                  valueProp: 'id',
                  grid: { xs: 6 },
                  helperText: 'Please select one or more Smartway users'
                },
                {
                  type: 'select',
                  multiple: true,
                  label: 'Smartway Admin referent(s)',
                  name: 'managed_by_admin',
                  optionsGetter: {
                    endpoints: [
                      {
                        entity: 'user',
                        params: {
                          transformResponse: true,
                          company: profile.company?.id
                        }
                      }
                    ]
                  },
                  validationType: 'array',
                  labelProp: 'name',
                  valueProp: 'id',
                  grid: { xs: 6 },
                  helperText: 'Please select one or more Smartway users'
                }
              ]
            : []),
          {
            type: 'select',
            multiple: true,
            label: 'Company managers',
            name: 'company_managers',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'profilesByRole',
                  params: { roles: managers?.id, company, transformResponse: true }
                }
              ]
            },
            validationType: 'array',
            labelProp: 'name',
            valueProp: 'id',
            helperText: 'Please select one or more Company managers'
          },
          {
            type: 'number',
            label: 'Max number of participants',
            helperText: 'Max. number of participants allowed for this offsite.',
            name: 'max_number_participants',
            required: true,
            validationType: 'number',
            validations: [
              {
                type: 'positive',
                params: ['The number of participants must be greater than 0']
              }
            ],
            grid: { xs: 6 },
            ...defaultInputsProps
          }
        ]
      },
      {
        id: 'general',
        title: 'General infos',
        sx: { justifyContent: 'space-between' },
        fields: [
          ...(!editingMode
            ? [
                {
                  type: 'text',
                  label: `${capitalizeFirstLetter(parent)} Name`,
                  name: 'title',
                  required: true,
                  validationType: 'string',
                  validations: [
                    {
                      type: 'required',
                      params: ['This field is required']
                    }
                  ],
                  grid: { xs: 12 },
                  ...defaultInputsProps
                }
              ]
            : []),
          {
            type: 'select',
            name: 'company',
            label: 'Company',
            inputVariant: 'standard',
            noOptionsText: 'No companies found. Click to create one',
            onNoOptionsClick: onNoCompanyClick,
            optionsGetter: {
              endpoints: [
                {
                  name: 'companies',
                  entity: 'company'
                }
              ]
            },
            disabled: disabledField,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ],
            labelProp: 'name',
            valueProp: 'id',
            ...defaultInputsProps,
            grid: { ...(!isStaff ? { display: 'none !important' } : { xs: 6 }) }
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            disabled: disabledField,
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 6 },
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ],
            inputVariant: 'standard',
            ...defaultInputsProps
          },
          {
            type: 'select',
            name: 'linked_venues',
            label: 'Linked Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            disabled: !values?.town,
            multiple: true,
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 6 },
            validationType: 'mixed',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ],
            inputVariant: 'standard',
            ...defaultInputsProps
          }
        ]
      },
      {
        id: 'dates',
        title: 'Dates',
        subtitle: `Choose the dates for the offsite. If you are not sure of the dates you can add an indicative number of days`,
        fields: [datesSwitch, indicativeNumberDaysField, datepickerRangeField]
      }
    ],
    submitButton: {
      label: buttonLabel,
      fullWidth: false,
      variant: 'gradient',
      color: 'primary',
      sx: {
        padding: '10px 24px'
      }
    }
  }
}

export default getDefinitionSchema
