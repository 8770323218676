import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const AdditionalInformationCard = ({
  id,
  title,
  subtitle,
  description,
  category,
  button_url,
  phone,
  url, // image url
  onEdit,
  onDelete,
  onAdd,
  ...additionalInformation
}) => {
  const handleEdit = () => {
    onEdit({ id, title, description, subtitle, category, button_url, phone, url, ...additionalInformation })
  }
  const handleRemove = () => {
    onDelete({ id, title, description, subtitle, category, button_url, phone, url, ...additionalInformation })
  }
  return (
    <Card sx={{ height: '100%' }}>
      {url ? <CardMedia sx={{ height: 80 }} image={url} title="Information image" /> : null}
      <CardContent sx={{ flexGrow: 1, pb: 0.5 }}>
        <MDBox>
          <MDTypography variant="button" sx={{ fontWeight: 500 }} component="div">
            {title}
          </MDTypography>
          { subtitle ? (
            <MDTypography variant="button" component="div">
              {subtitle}
            </MDTypography>
          ) : null }
          <MDTypography
            display="block"
            mb={1}
            variant="caption"
            color="secondary"
            dangerouslySetInnerHTML={{
              __html: description
            }}></MDTypography>
          
          { button_url ? (
            <MDTypography display="flex" alignItems="center" variant="caption" mb={0.5}>
              <Icon sx={{ mr: 1 }}>open_in_new</Icon>
              {button_url}
            </MDTypography>
          ) : null }
          { phone ? (
            <MDTypography display="flex" alignItems="center" variant="caption">
               <Icon sx={{ mr: 1 }}>phone</Icon>
                {phone}
            </MDTypography>
          ) : null }
        </MDBox>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <MDButton color="secondary" size="small" iconOnly onClick={handleRemove}>
          <Icon>delete</Icon>
        </MDButton>
        <MDButton color="primary" size="small" onClick={handleEdit} iconOnly>
          <Icon>edit</Icon>
        </MDButton>
      </CardActions>
    </Card>
  )
}

export default AdditionalInformationCard
