import { smartwayApi } from 'services/api'
import { redirect } from 'react-router-dom'
export const updateUserAction =
  (dispatch) =>
  async ({ request }) => {
    try {
      const payload = await request.json()
      const intent = payload.intent
      delete payload.intent
      let response
      switch (intent) {
        case 'basic-info':
          const {
            mobile,
            address,
            language,
            company,
            first_name,
            last_name,
            username
          } = payload
          const _payload = {
            first_name,
            last_name,
            username,
            profile: {
              mobile,
              address,
              language,
              company,
            }
          }
          response = await dispatch(
            smartwayApi.endpoints.updateEntity.initiate({
              entity: 'user',
              ..._payload,
              id: payload.id
            }, { forceRefetch: true })
          )
          break
        case 'delete':
          response = await dispatch(
            smartwayApi.endpoints.deleteEntity.initiate({
              entity: 'user',
              id: payload.id
            })
          )
          return redirect((`/dashboard/users?deleted=true`))
        default:
          break
      }

      if (response.data) {
        return {
          intent,
          success: true,
          ...response.data
        }
      }
      if (response.error) {
        return { intent, error: response.error }
      }
    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

export const usersListAction = (dispatch) => async ({ request, params }) => {
  try {
    const payload = await request.json()
    const searchParams = new URLSearchParams(payload)
    return redirect(`/dashboard/users?${searchParams.toString()}`)
  } catch(error) {
    console.warn(error)
    return {
      error: 'There was an error!',
      fullError: error
    }
  }
}