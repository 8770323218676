const filtersSchema = () => {
  return {
    id: 'agenda',
    watchedFields: ['types'],
    fieldsets: [
      {
        id: 'types',
        type: 'accordion',
        accordionType: 'minimal',
        title: 'Filters',
        sx: {
          paddingTop: 0,
          '.MuiGrid-item': {
            paddingLeft: 0,
            paddingTop: 0,
          }
        },
        fields: [
          {
            type: 'checkboxgroup',
            name: 'types',
            groups: [
              { 
                label: 'Show/hide activities', 
                controls: [
                  { label: 'Experiences', name: 'EXPERIENTIAL_ACTIVITY', color: 'pink', colorIntensity: 500 },
                  { label: 'Business', name: 'BUSINESS_ACTIVITY', color: 'teal', colorIntensity: 200 },
                  { label: 'Food & drinks', name: 'FOOD_DRINK', color: 'lime', colorIntensity: 300 },
                  { label: 'Transfers', name: 'TRANSFER', color: 'blue', colorIntensity: 500 },
                  { label: 'Free time', name: 'FREE_TIME', color: 'lime', colorIntensity: 300 },
                  { label: 'Check-in/Check-out', name: 'LOGISTIC', color: 'lime', colorIntensity: 300 },
                  { label: 'Custom activities', name: 'CUSTOM', color: 'pink', colorIntensity: 500 },
                ] 
              },
            ],
            validations: null,
            sx: { marginBottom: '0px !important', ml: 2 }
          }
        ]
      }
    ]
  }
}

export default filtersSchema