const schema = {
  id: "link-form",
  name: 'link-form',
  fieldsets: [
    {
      id: 'link',
      fields: [
        {
          type: 'text',
          name: 'title',
          label: 'Title',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ]
        },
        {
          type: 'textarea',
          name: 'excerpt',
          label: 'Excerpt',
          placeholder: 'Write an excerpt',
          validationType: 'string',
          validations: null
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description',
          required: true,
          placeholder: 'Write a description',
          validationType: 'string',
          validations: null
        },
        {
          type: 'text',
          name: 'link',
          label: 'URL',
          placeholder: 'https://example.com',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ]
        }
      ]
    }
  ]
}

export default schema