const getFiltersObjectFromInitialFilters = (initialFilters) => {
  const filtersObject = initialFilters.fieldsets.reduce((acc, fieldset) => {
    fieldset.fields.forEach((field) => {
      if (field.name) {
        acc[field.name] = field
      }
    })
    return acc
  }, {})
  return filtersObject
}

const getMulipleFilterValue = (qsValue) => {
  return qsValue.toString().split(',').map((v) => Number.isNaN(Number(v)) ? v : Number(v))
}

export const convertQueryStringToFilters = (query = '', initialFilters) => {
  const initialFiltersObject = getFiltersObjectFromInitialFilters(initialFilters)
  const searchParams = new URLSearchParams(query);
  const searchParamsObject = {}
  for (const [key, value] of searchParams.entries()) {
    const _value = Number.isNaN(Number(value)) ? value : Number(value)
    if (!!searchParamsObject[key]) {
      if (Array.isArray(searchParamsObject[key])) {
        searchParamsObject[key].push(_value)
      } else {
        searchParamsObject[key] = [searchParamsObject[key], _value]
      }
    } else {
      searchParamsObject[key] = _value
    }
  }

  const values = {}
  for (const filter in initialFiltersObject) {
    const _filter = initialFiltersObject[filter]
    switch (_filter.type) {
      case 'range':
        const isNotMin = [null, undefined].includes(searchParamsObject[`min_${filter}`]) 
        const min = isNotMin ? _filter.initialValue[0] : searchParamsObject[`min_${filter}`]
        const max = searchParamsObject[`max_${filter}`] || _filter.initialValue[1]
        values[filter] = [min, max];
        break;
      case 'badges':
        if (!searchParamsObject[filter]) {
          values[filter] = null
          break
        }
        const badgesValues = _filter.multiple ? getMulipleFilterValue(searchParamsObject[filter]) : searchParamsObject[filter]
        values[filter] = badgesValues
        break
      case 'checkboxgroup':
        if (!searchParamsObject[filter]) {
          values[filter] = null
          break
        }
        values[filter] = getMulipleFilterValue(searchParamsObject[filter]);
        break
      case 'select':
        if (!searchParamsObject[filter]) {
          values[filter] = null
          break
        }
        values[filter] = _filter.multiple ? getMulipleFilterValue(searchParamsObject[filter]) : searchParamsObject[filter]
        break
      default:
        values[filter] = searchParamsObject[filter]
        break;
    }
  }
  return values
  
} 


/**
 * 
area -> range / badges
bathroom_qty -> range / badges
capacity -> no more present 
description -> no more present
excerpt -> no more present
name -> no more present
price -> range / range
room_qty -> range / badges
town -> choice / select
 * 
 */


export const convertFiltersToQueryString = (filters, values) => {
  const filtersObject = getFiltersObjectFromInitialFilters(filters)
  const searchParams = new URLSearchParams('')
  for (const [key, value] of Object.entries(values)) {
    if (filtersObject[key] && filtersObject[key].type) {
      switch (filtersObject[key].type) {
        case 'range':
          const initialValues = filtersObject[key].initialValue
          if (!!value) {
            if (value[0] !== initialValues[0] || value[1] !== initialValues[1]) {
              searchParams.set(`min_${key}`, value[0])
              searchParams.set(`max_${key}`, value[1])
            }
          } else {
            searchParams.delete(`min_${key}`)
            searchParams.delete(`max_${key}`)
          }
          break;
        case 'badges':
          if (!!value) {
            if (filtersObject[key].multiple) {
              if (Array.isArray(value) && !!value.length) {
                searchParams.set(key, value.join(','))
              } else {
                searchParams.delete(key)
              }
            } else if (value !== 'all') {
              searchParams.set(key, value)
            }
          } else {
            searchParams.delete(key)
          }
          break
        case 'select':
          if (!!value) {
            if (filtersObject[key].multiple) {
              searchParams.set(key, value.join(','))
            } else if (value !== 'all') {
              searchParams.set(key, value)
            }
          } else {
            searchParams.delete(key)
          }
          break
        case 'checkboxgroup':
          if (!!value) {
            searchParams.append(key, value.join(','))
          } else {
            searchParams.delete(key)
          }
          break
        default:
          if (!!value) {
            searchParams.set(key, value)
          } else {
            searchParams.delete(key)
          }
          break;
      }
    }
  }
  return searchParams
}

export const getActiveFiltersNumber = (appliedFilters, initialFilters) => {
  const initialFiltersObject = getFiltersObjectFromInitialFilters(initialFilters)
  const realAppliedFilters = {}
  for (const [key, value] of Object.entries(appliedFilters)) {
    if ([null, undefined, 'all', 'any', ''].includes(value) || (Array.isArray(value) && !value.length) || !initialFiltersObject[key] || initialFiltersObject[key].hidden) continue
    if (initialFiltersObject[key] && initialFiltersObject[key].type === 'range') {
      const initialFilter = initialFiltersObject[key]
      const isValueDifferentFromInitial = value[0] !== initialFilter.initialValue[0] || value[1] !== initialFilter.initialValue[1]
      if (isValueDifferentFromInitial) {
        realAppliedFilters[key] = value
      }
    } else {
      realAppliedFilters[key] = value
    }
  }
  return Object.keys(realAppliedFilters).length 
}