import { useReducer, useEffect, useMemo } from 'react'
import { useLoaderData, useSubmit, useActionData, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import PackageTypeStep from './components/PackageTypeStep'
import PackageVenueStep from './components/PackageVenueStep'
import PackageFormStep from './components/PackageFormStep'

const PackageDetailPage = ({ editingMode }) => {
  const submit = useSubmit()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const actionData = useActionData()
  const { entity } = useLoaderData()

  const reducer = (state, action) => {
    if (action.type === 'update') {
      return {
        ...state,
        ...action.payload
      }
    }
  }

  const initialState = useMemo(
    () => ({
      step: editingMode ? 2 : 0,
      type: entity?.all_in_venue ? 'allInVenue' : 'town',
      venue: entity ? (entity.all_in_venue ? entity.all_in_venue : entity.town) : null
    }),
    [editingMode, entity]
  )

  const [state, reducerDispatch] = useReducer(reducer, initialState)

  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
      all_in_venue: state.type === 'allInVenue' ? state.venue.id : null,
      town: state.type === 'town' ? state.venue.id : null,
      ...(values.sample_all_in_venues_accommodations
        ? {
            sample_all_in_venues_accommodations: values.sample_all_in_venues_accommodations.map(
              (a) => a.id
            )
          }
        : {}),
      ...(values.sample_accommodations
        ? { sample_accommodations: values.sample_accommodations.map((a) => a.id) }
        : {})
    }
    if (editingMode) {
      payload.id = entity.id
    }
    submit(payload, { method: editingMode ? 'patch' : 'post', encType: 'application/json' })
  }

  const onPublishChange = (published) => {
    const { id, title } = entity
    submit(
      { id, title, published_at: published ? new Date() : null },
      { method: 'patch', encType: 'application/json' }
    )
  }

  const handleImagesChange = () => {
    submit({ onlyImages: true }, { method: 'post', encType: 'application/json' })
  }

  const handleTypeChange = (type) => {
    reducerDispatch({ type: 'update', payload: { step: 1, type } })
  }
  const handleVenueChange = (venue) => {
    reducerDispatch({ type: 'update', payload: { step: 2, venue } })
  }

  const handleVenueBack = () => {
    reducerDispatch({ type: 'update', payload: { step: 0 } })
  }
  const handleOnCancel = () => {
    if (editingMode) {
      navigate('/dashboard/premade-packages')
    } else {
      reducerDispatch({ type: 'update', payload: { step: 1, venue: null } })
    }
  }

  const steps = [
    {
      key: 'venue-type',
      component: <PackageTypeStep type={state.type} onChange={handleTypeChange} />
    },
    {
      key: 'venue',
      component: (
        <PackageVenueStep
          type={state.type}
          onBack={handleVenueBack}
          onVenueChange={handleVenueChange}
        />
      )
    },
    {
      key: 'form',
      component: (
        <PackageFormStep
          entity={entity}
          venueType={state.type}
          venue={state.venue}
          editingMode={editingMode}
          onImagesChange={handleImagesChange}
          onPublishChange={onPublishChange}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      )
    }
  ]

  useEffect(() => {
    if (actionData?.success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Premade package saved successfully',
          type: 'success'
        })
      )
    }
    if (actionData?.error) {
      dispatch(notify({ title: 'There was an error', message: actionData.error, type: 'error' }))
    }
  }, [actionData, dispatch])

  useEffect(() => {
    if (!editingMode) {
      reducerDispatch({ type: 'update', payload: initialState })
    }
  }, [editingMode, initialState])

  return steps[state.step].component
}

export default PackageDetailPage
