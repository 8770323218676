import { useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

const AgendaTypeStep = ({ type, onChange }) => {
  const [venueType, setVenueType] = useState(type)

  const types = [
    {
      value: 'town',
      label: 'Town'
    },
    {
      value: 'allInVenue',
      label: 'Venue'
    }
  ]

  const handleChange = (value) => {
    setVenueType(value)
  }

  const handleNext = () => {
    onChange(venueType)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8}>
        <Card>
          <MDBox p={8}>
            <MDTypography variant="h5">
              Choose for which type of venue you want to create this agenda
            </MDTypography>
            <MDBox display="flex" gap={2} mt={4}>
              {types.map(({ label, value }) => {
                const selected = venueType === value
                const handleClick = () => handleChange(value)
                return (
                  <MDBox
                    key={value}
                    width={'calc(50% - 8px)'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={4}
                    sx={{
                      cursor: 'pointer',
                      border: '1px solid',
                      borderRadius: '8px',
                      borderColor: selected ? 'primary.main' : 'primary.light',
                      transition: 'all 0.1s ease-in-out',
                      backgroundColor: selected ? 'primary.light' : 'transparent',
                    }}
                    onClick={handleClick}>
                      <MDTypography variant="h5" sx={{ fontWeight: '500'}}>
                        {label}
                      </MDTypography>
                  </MDBox>
                )
              })}
            </MDBox>
          </MDBox>
          <MDBox px={8} pb={8} display="flex" justifyContent="flex-end">
            <MDButton variant="gradient" color="primary" onClick={handleNext}>Next</MDButton>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AgendaTypeStep
