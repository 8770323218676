import { useSelector, useDispatch } from 'react-redux'
import { closeNotification } from 'store/reducers/UISlice'
import MDSnackbar from 'components/MDSnackbar'
const NotificationAlert =  () => {
  const dispatch = useDispatch()
  const notification = useSelector(state => state.ui.notification)
  
  if (!!notification && notification.timeout > 0) {
    setTimeout(() => {
      dispatch(closeNotification())
    }, notification.timeout)
  }

  const handleClose = () => {
    if (notification.onClose) {
      notification.onClose()
    }
    dispatch(closeNotification())
  }

  return !!notification ? (
    <MDSnackbar
      color={(notification.type) ? notification.type : 'success'}
      icon={notification.icon}
      title={(notification.title) ? notification.title : ''}
      content={notification.message}
      open={!!notification}
      dateTime={(notification.date) ? notification.date : ''}
      close={handleClose}
      anchorOrigin={notification.anchorOrigin ? notification.anchorOrigin : {
        vertical: 'top',
        horizontal: 'right'
      }}
      onClick={(!!notification && notification.onClick) ? notification.onClick : () => {}}
    />
  ) : null
}

export default NotificationAlert