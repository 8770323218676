const filters = {
  name: 'workshop-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the workshop you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Price',
      subtitle: 'Select the price range you\'re looking for',
      fields: [
        {
          type: 'range',
          name: 'price',
          min: 0,
          max: 300,
          prefix: '€',
          minDistance: 10,
          initialValue: [20, 200],
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Published or not',
      subtitle: 'Select whether the workshop is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters