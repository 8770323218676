import { format } from 'date-fns'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
// MUI Components
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { getPublicLinkByLocation } from 'utils/links'

const COLUMNS = [
  {
    name: 'time',
    cols: 2
  },
  {
    name: 'activity',
    cols: 3
  },
  {
    name: 'description',
    cols: 4
  },
]

const ParticipantAgenda = ({ agenda }) => {
  const getCellValue = (activity, column) => {
    const { time, title, description, location } = activity
    switch (column) {
      case 'time':
        return <MDTypography variant="button" fontWeight="medium" color="text">{time}</MDTypography>
      case 'activity':
        return <MDTypography variant="button" fontWeight="regular" color="text">
          { location ? (
            <a style={{ borderBottom: '1px solid #7b809a', color: 'inherit' }} href={getPublicLinkByLocation(location)} target="_blank" rel="noreferrer">{title}</a>
          ) : title }
          
        </MDTypography>
      case 'description':
        return <MDTypography variant="button" fontWeight="regular" color="text" dangerouslySetInnerHTML={{ __html: description }}></MDTypography>
      default:
        return ''
    }
  }

  return (
    <MDBox mb={4}>
      <MDTypography variant="h4" fontWeight="medium" mx={2} mb={2}>
        Agenda
      </MDTypography>
      {Object.keys(agenda).map((day) => (
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: 'none',
            '&.MuiAccordion-root.Mui-expanded': { margin: '0 !important' }
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`agenda-${day}-content`}
            id={`agenda-${day}-header`}
            sx={{
              minHeight: '48px !important',
              '> .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0 !important'
              }
            }}
          >
            <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
              {format(new Date(day), 'MMM dd yyyy')}
            </MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox>
              <Grid container spacing={2} mb={2} sx={{ borderBottom: '1px solid #E2E8F0'}}>
                {COLUMNS.map((col, index) => {
                  return (
                    <Grid item xs={col.cols} key={`day-header-${index}`}>
                      <MDTypography variant="button" color="text" fontWeight="medium" textTransform="uppercase">
                        {col.name}
                      </MDTypography>
                    </Grid>
                  )
                })}
              </Grid>
                { agenda[day].map((activity, index) => {
                  return (
                    <Grid container spacing={2} key={`activity-${index}`} mt={0}>
                      {COLUMNS.map((col, colIndex) => {
                        return (
                          <Grid item xs={col.cols} key={`activity-${index}-${colIndex}`}>
                            {getCellValue(activity, col.name)}
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
            </MDBox>
          </AccordionDetails>

        </Accordion>
      ))}
    </MDBox>
  )
}

export default ParticipantAgenda
