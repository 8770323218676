const genders = [
  {
    label: "Male",
    value: "MALE"
  },
  {
    label: "Female",
    value: "FEMALE"
  },
  {
    label: "Other",
    value: "OTHER"
  }
]

const languages = [
  {
    label: 'English',
    value: 'English'
  },
  {
    label: 'French',
    value: 'French'
  },
  {
    label: 'Spanish',
    value: 'Spanish'
  },
  {
    label: 'Italian',
    value: 'Italian'
  }
]

const defaultInputsProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true
  }
}

const schema = {
  id: 'basic-info-form',
  title: 'Basic Info',
  ctasPosition: 'bottom',
  fieldsets: [
    {
      id: 'general',
      title: '',
      fields: [
        {
          type: 'text',
          label: 'First Name',
          name: 'first_name',
          required: true,
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ],
          grid: { xs: 12, md: 6 },
          ...defaultInputsProps
        },
        {
          type: 'text',
          label: 'Last Name',
          name: 'last_name',
          required: true,
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            }
          ],
          grid: { xs: 12, md: 6 },
          ...defaultInputsProps
        },
        {
          type: 'select',
          name: 'gender',
          label: 'I\'m',
          inputVariant: 'standard',
          options: genders,
          validationType: 'string',
          grid: { xs: 12, md: 6 },
          ...defaultInputsProps
        },
        {
          type: 'datepicker',
          name: 'birthdate',
          validationType: 'string',
          validations: [
            {
              type: 'nullable'
            }
          ],
          grid: { xs: 12, md: 6 },
          inputProps: { label: 'Birth Date', placeholder: 'Select date', ...defaultInputsProps },
          ...defaultInputsProps
        },
        {
          name: 'email',
          type: 'text',
          label: 'Email',
          placeholder: 'example@email.com',
          grid: { xs: 12, md: 6 },
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['This field is required']
            },
            {
              type: 'email',
              params: ['Invalid email']
            }
          ],
          ...defaultInputsProps
        },
        {
          name: 'mobile',
          type: 'phone',
          label: 'Phone number',
          validationType: 'string',
          grid: { xs: 12, md: 6 },
          placeholder: '+39 123 456 7890',
          validations: [
            {
              type: 'phone',
              params: ['This must be a valid phone number']
            }
          ],
          ...defaultInputsProps
        },
        {
          name: 'language',
          type: 'select',
          label: 'Language',
          validationType: 'string',
          grid: { xs: 12, md: 6 },
          placeholder: 'English',
          validations: null,
          options: languages,
          inputVariant: 'standard',
          ...defaultInputsProps,
        },
        {
          type: 'address',
          name: 'address',
          label: 'Your location',
          latitudeProp: 'latitude',
          longitudeProp: 'longitude',
          validationType: 'string',
          placeholder: 'Milano',
          validations: null,
          countries: null,
          grid: { xs: 12, md: 6 },
          ...defaultInputsProps
        }
      ]
    }
  ],
  submitButton: {
    label: 'Update profile',
    fullWidth: false,
    variant: 'gradient',
    color: 'dark'
  },
}

export default schema
