const filters = {
  name: 'event-spaces-filters',
  fieldsets: [
    {
      title: 'Name',
      subtitle: 'Enter the name of the event space you\'re looking for',
      fields: [
        {
          type: 'text',
          name: 'name',
          grid: { xs: 12 },
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    {
      title: 'Town / Venue',
      subtitle: 'Choose the town or the venue of the event space',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true,
        }
      ]
    },
    // {
    //   title: 'Capacity',
    //   subtitle: 'How many will partecipate in plenary space',
    //   fields: [
    //     {
    //       type: 'number',
    //       name: 'capacity',
    //       label: 'Min. capacity',
    //       grid: { xs: 3 },
    //     },
    //     {
    //       type: 'divider',
    //       hr: true,
    //     }
    //   ]
    // },
    {
      title: 'Published or not',
      subtitle: 'Select whether the event space is published or not',
      fields: [
        {
          type: 'switch',
          name: 'published'
        }
      ]
    }
  ]
}

export default filters