import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Image from 'components/Image'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { excerptHtml } from 'utils/functions'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: 10,
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const CustomActivityCard = ({
  activity,
  selected,
  illustration,
  onCardClick,
  onEdit,
  onDelete,
}) => {
  const image = activity && activity.custom_image_formats ? activity.custom_image_formats : illustration

  const handleCardClick = () => {
    onCardClick(activity)
  }

  const handleEdit = () => {
    onEdit(activity.id)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    onDelete(activity)
  }

  return (
    <Card
      position="relative"
      className={
        selected ? 'custom-activity-card selected' : 'custom-activity-card'
      }
      sx={{
        minHeight: '200px',
        width: 200,
        height: '100%',
        '&.selected': {
          '.custom-activity-card__icon--check': {
            opacity: 1,
            transform: 'scale(1)'
          }
        },
        '&:hover': {
          '.custom-activity-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <MDBox
        className="custom-activity-card__icon custom-activity-card__icon--check"
        sx={iconStyles}>
        <Icon fontSize="small">check</Icon>
      </MDBox>
      <MDBox
        className="custom-activity-card__icon custom-activity-card__icon--edit"
        sx={{ ...iconStyles, right: '75px' }}
        onClick={handleEdit}>
        <Icon fontSize="small">edit</Icon>
      </MDBox>
      <MDBox
        className="custom-activity-card__icon custom-activity-card__icon--delete"
        sx={{ ...iconStyles, right: '40px' }}
        onClick={handleDelete}>
        <Icon fontSize="small">delete</Icon>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={2}
        sx={{ cursor: 'pointer' }}
        onClick={handleCardClick}>
        <Image
          {...image}
          formats={activity.custom_image_formats}
          alt={activity.title}
          height={120}
          minHeight={120}
          maxHeight={120}
          mb={2}
          errorImage={illustration}
        />

        <MDTypography variant="body">{activity.title}</MDTypography>
        <MDTypography variant="caption" dangerouslySetInnerHTML={{ __html: excerptHtml(activity.description, 250) }}></MDTypography>
      </MDBox>
    </Card>
  )
}

export default CustomActivityCard
