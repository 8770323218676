import { Link, useLoaderData, useActionData, useSearchParams, Outlet } from 'react-router-dom'

import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import Card from '@mui/material/Card'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

import { capitalizeFirstLetter } from 'utils/functions'
import DefinitionStep from 'layouts/pages/offsites/detail/steps/DefinitionStep'

const OfferDetailPage = ({ editingMode }) => {
  const actionData = useActionData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const data = useLoaderData()
  const { profile, offsite } = data

  let offerUrl = ''
  if (editingMode) {
    offerUrl = btoa(`{"offer":${offsite.id}}`)
  }

  const steps = [
    {
      key: 'definition',
      label: 'Basic',
      title: 'Offer informations',
      subtitle: 'Select the type of offsite you want to build'
    },
    {
      key: 'agenda',
      label: 'Agenda',
      title: 'Offer agenda',
      subtitle: 'Select the activities of your offsite'
    },
    {
      key: 'economics',
      label: 'Economics',
      title: 'Commercial & Economics'
    },
    {
      key: 'communication',
      label: 'Communication',
      title: 'Details & communication'
    },
    {
      key: 'review',
      label: 'Review',
      description: 'Confirm the informations of your offsite'
    }
  ]

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if ((searchParams && searchParams.get('new')) || actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(actionData?.entity) || 'Offer'} saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
      setSearchParams({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, actionData, dispatch])

  return (
    <>
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%', mt: 8 }}>
          <Grid item xs={12} lg={12}>
            {editingMode ? (
              <MDBox display="flex" justifyContent="flex-end" mb={6}>
                <a
                  href={`https://listings.smartway.work/offers/${offerUrl}`}
                  target="_blank"
                  rel="noreferrer">
                  <MDButton variant="outlined" color="dark" size="small">
                    See the public page
                    <Icon sx={{ ml: 1 }}>open_in_new</Icon>
                  </MDButton>
                </a>
              </MDBox>
            ) : null}
            <Card sx={{ height: '100%' }}>
              {editingMode ? (
                <MDBox mx={2} mt={-3}>
                  <Stepper
                    sx={{
                      p: 3,
                      color: 'white !important',
                      background: 'linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))',
                      '*': {
                        stroke: 'white !important',
                        fill: 'white !important',
                        color: 'white !important'
                      }
                    }}
                    alternativeLabel>
                    {steps.map(({ label, key }, index) => (
                      <Step key={`${label}-${index}`} disabled={!editingMode}>
                        {editingMode ? (
                          <Link to={`${key}`}>
                            <StepButton>
                              <StepLabel>{label}</StepLabel>
                            </StepButton>
                          </Link>
                        ) : (
                          <StepButton>
                            <StepLabel>{label}</StepLabel>
                          </StepButton>
                        )}
                      </Step>
                    ))}
                  </Stepper>
                </MDBox>
              ) : null}

              <MDBox p={3}>
                {editingMode ? (
                  <Outlet />
                ) : (
                  <DefinitionStep
                    title="Create an offer"
                    offsite={offsite}
                    profile={profile}
                    parent="offer"
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  )
}

export default OfferDetailPage
