import { useState } from 'react'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import { excerptHtml } from 'utils/functions'

import RoomBeds from 'components/RoomBeds'

import platinum from 'assets/icons/platinum.svg'
import gold from 'assets/icons/gold.svg'
import silver from 'assets/icons/silver.svg'
import bronze from 'assets/icons/bronze.svg'

const RoomCard = ({ selected, onSelect, ...room }) => {
  const [seeAllDescription, setSeeAllDescription] = useState(false)
  const { title, category, description, level } = room

  const levelIcons = {
    SILVER: silver,
    GOLD: gold,
    DIAMOND: platinum,
    BRONZE: bronze
  }

  const handleOnSelect = () => {
    onSelect(room)
  }

  const toggleSeeAllDescription = () => {
    setSeeAllDescription(!seeAllDescription)
  }

  return (
    <Card>
      {selected ? (
        <MDBox
          position="absolute"
          top="-12px"
          right="-12px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          color="white"
          sx={{
            p: 0.5,
            backgroundColor: ({ palette: { success } }) => success.main
          }}
          className="no-opacity">
          <Icon>check</Icon>
        </MDBox>
      ) : null}
      <MDBox
        p={1}
        sx={selected ? { '> *:not(.no-opacity)': { opacity: '0.3' } } : {}}>
        <MDBox display="flex" alignItems="flex-start">
          <MDAvatar
            src={levelIcons[level]}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor="dark"
            sx={{
              p: 2,
              mt: -3,
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl
            }}
          />

          <MDBox display="flex" justifyContent="space-between" width="100%">
            <MDBox ml={2} lineHeight={0}>
              <MDTypography
                variant="h6"
                fontWeight="medium">
                {title}
              </MDTypography>
              <MDTypography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                onClick={toggleSeeAllDescription}
                dangerouslySetInnerHTML={{ __html: seeAllDescription ? description : excerptHtml(description, 70, false) }}
              />
            </MDBox>
            <MDBox>
              <RoomBeds category={category} />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          width="100%"
          justifyContent="center"
          className="no-opacity"
          mt={2}
          mb={1}>
          {selected ? (
            <MDButton
              color="secondary"
              size="small"
              onClick={handleOnSelect}>
              Remove
            </MDButton>
          ) : (
            <MDButton
              variant="gradient"
              color="primary"
              size="small"
              onClick={handleOnSelect}>
              Select
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default RoomCard
