import { useState, useEffect, useCallback } from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'

import Form from 'components/Form/Form'

import { getTeamSchema } from './schemas/activity'

const TeamDialog = ({
  open,
  participants,
  selectedParticipants,
  title,
  team = {},
  onSave,
  onClose
}) => {
  const mapParticipants = useCallback((p, selected) => {
    const _selected = selected && selected.length ? selected : []
    const teamParticipants = team && team.participants ? team.participants : []
    const getName = (participant) => {
      let _name
      if (!!participant.first_name && !!participant.last_name) {
        _name = `${participant.first_name} ${participant.last_name}`
      } else {
        _name = participant.email
      }
      const selectedInThisTeam = teamParticipants.includes(participant.id)
      const selectedParticipant = _selected.find(_p => _p.id === participant.id)
      if (!selectedInThisTeam && selectedParticipant) {
        _name = `${_name} (${selectedParticipant.team.name})`
      }
      return _name
    }
    return p.map((_p) => ({
      ..._p,
      name: getName(_p),
      disabled: _selected.map(p => p.id).includes(_p.id) && !teamParticipants.includes(_p.id)
    }))
  }, [team])
  const [schema, setSchema] = useState(
    getTeamSchema(mapParticipants(participants, selectedParticipants))
  )
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      onSave(values)
    }
  }

  useEffect(() => {
    setSchema(getTeamSchema(mapParticipants(participants, selectedParticipants)))
  }, [participants, selectedParticipants, mapParticipants, setSchema])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Form {...schema} values={team} onSubmit={handleOnSave} />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton type="submit" form="team-form" variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default TeamDialog
