import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import { debounce } from '@mui/material/utils'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Input from 'components/Fields/Input'

import SimpleLocationCard from 'components/Cards/SimpleLocationCard/SimpleLocationCard'

import { useMaterialUIController } from 'context'
import { CardsSkeleton } from './Skeletons'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const PackageVenueStep = ({ type, onBack, onVenueChange }) => {
  const [controller] = useMaterialUIController()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState('')
  const [venues, setVenues] = useState(null)
  const [filteredVenues, setFilteredVenues] = useState(null)
  const [selectedVenue, setSelectedVenue] = useState(null)
  const { miniSidenav } = controller

  const handleVenueChange = (venue) => {
    if (selectedVenue && selectedVenue.id === venue.id) {
      setSelectedVenue(null)
      return
    }
    setSelectedVenue(venue)
  }

  const handleNext = () => {
    if (!selectedVenue) return
    onVenueChange(selectedVenue)
  }

  const handleBack = () => {
    setSelectedVenue(null)
    onBack()
  }

  const handleSearch = (search) => {
    if (search) {
      setFilteredVenues(venues.filter((v) => v.name.toLowerCase().includes(search.toLowerCase())))
    } else {
      setFilteredVenues(venues)
    }
    setSearch(search)
  }
  const debouncedSearch = (event) => {
    const search = event.target.value
    debounce(handleSearch(search), 700)
  }

  useEffect(() => {
    const getVenues = async () => {
      setIsError(false)
      try {
        const response = await dispatch(
          smartwayApi.endpoints.fetchAllEntities.initiate({ entity: type })
        )
        if (response && response.data) {
          setVenues(response.data)
          setFilteredVenues(response.data)
          setIsLoading(false)
        } else {
          throw new Error(response)
        }
      } catch (error) {
        setIsError(true)
        console.warn(error)
      }
    }
    if (type) {
      getVenues()
    }
  }, [dispatch, type])

  return isLoading ? (
    <CardsSkeleton />
  ) : isError ? (
    <>Error</>
  ) : (
    <MDBox>
      <MDTypography variant="h5">Venues</MDTypography>
      <MDBox sx={{ minHeight: 'calc(100vh - 180px)' }}>
        <MDBox mt={2} sx={{ maxWidth: '240px'}}>
          <Input placeholder="Search" name="search" value={search} onChange={debouncedSearch} />
        </MDBox>
        <Grid
          container
          spacing={3}
          mt={4}
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'scroll' }}>
          {filteredVenues && !!filteredVenues.length
            ? filteredVenues.map((venue) => {
                const selected = venue.id === selectedVenue?.id
                return (
                  <Grid item key={`venue-${venue.id}`} xs={12} display="grid" height="100%" sx={
                    !!selectedVenue
                      ? {
                          '.location-card:not(.selected):not(:hover)': {
                            backgroundColor: 'rgba(200, 200, 200, 0.2)',
                            boxShadow: 'none'
                          }
                        }
                      : {}
                  }>
                    <Card
                      onClick={() => handleVenueChange(venue)}
                      sx={{
                        position: 'relative',
                        cursor: 'pointer',
                        display: '-webkit-box',
                        '&:hover, &.selected': {
                          '.location-card__icon': {
                            opacity: 1,
                            transform: 'scale(1)'
                          }
                        }
                      }}
                      className={selected ? 'location-card selected' : 'location-card'}>
                      <MDBox className="location-card__icon" sx={iconStyles}>
                        <Icon fontSize="small">check</Icon>
                      </MDBox>
                      <SimpleLocationCard {...venue} showThumbnails={0} />
                    </Card>
                  </Grid>
                )
              })
            : null}
        </Grid>
      </MDBox>
      <MDBox
        position="fixed"
        bottom={0}
        width="100%"
        p={4}
        sx={{
          background: 'white',
          marginLeft: '-32px',
          width: miniSidenav ? '100vw' : `calc(100vw - 16.6rem )`
        }}>
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="text" color="primary" onClick={handleBack}>
            Back
          </MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            onClick={handleNext}
            disabled={!selectedVenue}>
            Continue
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default PackageVenueStep
