import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

export const CardsSkeleton = () => {
  return (
    <Grid
      container
      alignItems="start"
      spacing={2}
      sx={{ height: '100%', mt: 2, ml: 2 }}>
      {Array.from(new Array(6)).map((_, index) => (
        <Grid key={`cards-skeleton-${index}`} item xs={12} md={6} lg={4}>
          <Skeleton animation="wave" variant="rounded" height={300} />
        </Grid>
      ))}
    </Grid>
  )
}

export const WorkshopSkeleton = () => {
  return (
    <Grid
    container rowSpacing={6} columnSpacing={3} pb={8}>
      {Array.from(new Array(8)).map((_, index) => (
        <Grid key={`workshop-card-skeleton-${index}`} item xs={12} sm={6} md={3}>
          <Skeleton animation="wave" variant="rounded" height={205} />
        </Grid>
      ))}
    </Grid>
  )
}
