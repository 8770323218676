const coworking = ({ coworking, dispatch, onPublishChange}) => {

  const publishButton = coworking.id ? coworking.published_at ? {
    label: 'Unpublish',
    color: 'warning',
    tooltip: 'By unpublishing, you will not be able to see the public page anymore',
    icon: 'visibility_off',
    onClick: () => onPublishChange(false, coworking)
  } : {
    label: 'Publish',
    color: 'success',
    tooltip: 'Be sure to have saved before publishing',
    icon: 'rocket_launch',
    onClick: () => onPublishChange(true, coworking)
  } : null

  const showIsInsideVenue = coworking && coworking.all_in_venue
  
  return   {
    id: 'coworking-form',
    title: 'Coworking Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            required: !!coworking.id,
            hidden: !coworking.id,
            validationType: 'string',
            validations: coworking.id ? [
              {
                type: 'slug',
                params: ['coworkings', 'This slug is not available', coworking.slug, dispatch],
              },
              {
                type: 'required',
                params: ['This field is required']
              }
            ] : null
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            required: true,
            validationType: 'string',
            grid: { xs: 6 },
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'switch',
            name: 'is_inside_venue',
            label: 'Coworking is inside the venue?',
            grid: { xs: 6 },
            validationType: 'boolean',
            validations: null,
            hidden: !showIsInsideVenue
          },
          {
            type: 'text',
            label: 'Phone Number',
            name: 'phone_number',
            validationType: 'text',
            validations: null,
            grid: { xs: 6, mr: 12 }
          },
          {
            type: 'number',
            label: 'Capacity work day-by-day',
            required: true,
            name: 'capacity',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Capacity plenary',
            name: 'capacity_plenary',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Surface',
            name: 'surface',
            validationType: 'number',
            validations: null,
            grid: { xs: 6, mr: 32 },
            suffix: 'm2'
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            validationType: 'string',
            suffix: '€',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Weekday price',
            name: 'weekday_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'switch',
            name: 'available',
            label: 'Is the coworking available?',
            validationType: 'boolean',
            validations: null
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'coworkings',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      },
      {
        id: 'settings',
        title: 'Coworking settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'categories',
            label: 'Categories of coworking *',
            multiple: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'categories',
                  entity: 'locationCategories',
                  id: 'coworking'
                }
              ]
            },
            labelProp: 'name',
            valueProp: 'id',
            validationType: 'array',
            validations: [
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'min',
                params: [1, 'You must select at least one category']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'text',
            label: 'Wifi name',
            name: 'wifi_name',
            validationType: 'text',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'password',
            label: 'Wifi password',
            name: 'wifi_password',
            validationType: 'text',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Download speed',
            name: 'download_speed',
            suffix: 'Mbps',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Upload speed',
            name: 'upload_speed',
            validationType: 'number',
            suffix: 'Mbps',
            validations: null,
            grid: { xs: 6 }
          }
        ]
      },
      {
        id: 'features',
        title: 'Features',
        type: 'accordion',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: '',
            optionsGetter: {
              endpoints: [
               {
                 name: 'feature',
                 entity: 'locationFeatures',
                 id: 'coworking',
                 formatGroup: (options) => {
                  return options.reduce((acc, curr) => {
                    const alreadyInAcc = acc.find(g => g.label === curr.subtype)
                    if (alreadyInAcc) {
                      alreadyInAcc.controls.push({
                        name: curr.id,
                        label: curr.name
                      })
                    } else {
                      acc.push({
                        label: curr.subtype,
                        controls: [{name: curr.id, label: curr.name}]
                      })
                    }
                    return acc
                  }, [])
                 }
               }
              ]
            }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default coworking