import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import LoadingButton from '@mui/lab/LoadingButton'

import Select from 'components/Fields/Select'

import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'

import AccommodationsSidebar from '../components/accommodations/AcccommodationsSidebar'
import RoomCard from '../components/accommodations/RoomCard'
import SimpleLocationCard from 'components/Cards/SimpleLocationCard'
import OffsiteRoomsDialog from 'components/Dialogs/OffsiteRoomsDialog'
import RoomingListTable from '../components/accommodations/RoomingListTable'
import SettingsButton from 'components/SettingsButton'

import noResults from 'assets/illustrations/noresults.svg'
import underConstruction from 'assets/illustrations/under-construction.svg'

import { STATUSES_NAMES } from 'utils/offsiteStatus'

const AddRoomCard = ({ onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        py={4}
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">Add a room</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            bed
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const DEFAULT_SETTINGS_OPTIONS = [
  {
    id: 'table_view',
    label: 'Export rooming list as XLS',
    icon: 'list'
  }
]

export const TABLE_SETTINGS_OPTIONS = [
  {
    id: 'grid_view',
    label: 'Go back to edit mode',
    icon: 'edit'
  }
]

const AccommodationStep = ({
  offsite,
  profile,
  participants,
  rooms,
  offsiteVenue,
  offsiteVenueType,
  linkedVenues,
  roomsAccommodations,
  onRandomFillRoom,
  onRandomFillAllRooms,
  onSaveRooms,
  onUpdateParticipant,
  onUpdateRoom,
  onRemoveRoom,
  onRemoveParticipantsFromRoom
}) => {
  const [globalView, setGlobalView] = useState('grid')
  const [settingsButtonOptions, setSettingsButtonOptions] = useState(DEFAULT_SETTINGS_OPTIONS)
  const [filteredRooms, setFilteredRooms] = useState([...rooms])
  const [appliedFilters, setAppliedFilters] = useState({})
  const [availableParticipants, setAvailableParticipants] = useState(
    participants?.filter((p) => p.confirmation !== false && !p.room)
  )
  const [sidebarOpen, setSidebarOpen] = useState(window.document.body.clientWidth >= 768)
  const [roomsDialogOpen, setRoomsDialogOpen] = useState(false)
  const [fullAccommodation, setFullAccommodation] = useState(null)

  const [roomsLoading, setRoomsLoading] = useState([])

  const roomingListClosedToModification = offsite.status.includes(
    STATUSES_NAMES.CONFIRMED_ROOMING_LIST
  )

  const isSmartwayStaffOrManagedBy =
    profile.is_staff || !!offsite.managed_by.find((m) => m.user.user_id === profile.id)

  const allVenues = [ {...offsiteVenue, type: offsiteVenueType}, ...linkedVenues ]
  const multipleVenues = !!linkedVenues && linkedVenues.length

  const fillAllRooms = () => {
    let newAvailableParticipants = [...availableParticipants]
    const filledRooms = rooms.reduce((acc, curr) => {
      const {
        id,
        participants,
        detail: { capacity }
      } = curr
      const isInAcc = acc.find((a) => a.roomId === id)
      const availableBedsInRoom = participants ? capacity - participants.length : capacity
      if (!isInAcc && !!availableBedsInRoom) {
        const participantsIds = newAvailableParticipants
          .slice(0, availableBedsInRoom)
          .map((p) => p.id)
        // const full = participantsIds.length === capacity
        newAvailableParticipants = newAvailableParticipants.filter(
          ({ id }) => !participantsIds.includes(id)
        )
        if (participantsIds.length) {
          acc.push({ id, participants: participantsIds })
        }
      }
      return acc
    }, [])
    return filledRooms
  }

  const handleOpenSidebar = () => setSidebarOpen(true)
  const handleCloseSidebar = () => setSidebarOpen(false)

  const handleOpenRoomsDialog = () => setRoomsDialogOpen(true)
  const handleCloseRoomsDialog = useCallback(() => setRoomsDialogOpen(false), [])

  const handleAccommodationChange = ({ target: { value } }) => {
    setAppliedFilters({ ...appliedFilters, accommodation: value })
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target
    setAppliedFilters({
      ...appliedFilters,
      [name]: value
    })
  }

  const handleAddRooms = () => {
    setRoomsDialogOpen(true)
  }

  const handleFilterRooms = useCallback(() => {
    const { search, level, category, empty, accommodation } = appliedFilters
    let filtered = rooms
    if (
      Object.keys(appliedFilters).some((k) =>
        Array.isArray(appliedFilters[k]) ? !!appliedFilters[k].length : !!appliedFilters[k]
      )
    ) {
      if (level && !!level.length) {
        filtered = filtered.filter((r) => level.includes(r.detail.level))
      }
      if (category && !!category.length) {
        filtered = filtered.filter((r) => category.includes(r.detail.category))
      }
      if (accommodation) {
        filtered = filtered.filter((r) => r.detail.accommodation === accommodation)
        setFullAccommodation(roomsAccommodations.find((a) => a.id === accommodation))
      }
      if (search && search.length > 2) {
        filtered = filtered.filter(({ participants, detail: { title, description } }) => {
          const isParticipant = participants.find(({ first_name, last_name, email }) => {
            return [first_name, last_name, email].some((v) =>
              v.toLowerCase().includes(search.toLowerCase())
            )
          })
          const isRoom = [title, description].some((v) =>
            v.toLowerCase().includes(search.toLowerCase())
          )
          return isParticipant || isRoom
        })
      }
      if (empty && empty !== 'all') {
        const value = empty === 'done'
        filtered = filtered.filter((r) => r.full === value)
      }
    }
    setFilteredRooms(filtered)
  }, [appliedFilters, rooms, roomsAccommodations])

  const onParticipantChange = async ({
    roomId,
    participantId,
    previousParticipant = null,
    intent = 'add'
  }) => {
    setRoomsLoading([roomId])
    const value = intent === 'remove' ? null : roomId
    await onUpdateParticipant({ roomId: value, participantId, previousParticipant })
    setRoomsLoading([])
  }

  const onDoneChange = async ({ id, full = true }) => {
    setRoomsLoading([id])
    await onUpdateRoom({ id, full })
    setRoomsLoading([])
  }

  const handleRandomFillAllRooms = async () => {
    const filledRooms = fillAllRooms()
    setRoomsLoading(rooms.map(({ id }) => id))
    await onRandomFillAllRooms({ rooms: filledRooms })
    setRoomsLoading([])
  }

  const handleRandomFillRoom = async ({ id, capacity }) => {
    setRoomsLoading([id])
    const alreadyInsertedParticipants = participants
      .filter((p) => {
        return p.room ? p.room.id === id : false
      })
      .map((p) => ({ id: p.id }))
    await onRandomFillRoom({
      id,
      participants: [
        ...alreadyInsertedParticipants,
        ...availableParticipants.slice(0, capacity).map((p) => ({ id: p.id }))
      ]
    })
    setRoomsLoading([])
  }

  const handleRemoveRoom = (offsiteRoom) => {
    setRoomsLoading([offsiteRoom.id])
    onRemoveRoom(offsiteRoom)
  }

  const handleSaveRooms = (selectedRooms) => {
    onSaveRooms(selectedRooms.map(({ id, venueType }) => ({ id, venueType })))
    handleCloseRoomsDialog()
  }

  const handleRemoveParticipantsFromRoom = async ({ id, participantsToRemove }) => {
    setRoomsLoading([id])
    await onRemoveParticipantsFromRoom({ participantsToRemove })
    setRoomsLoading([])
  }

  const handleSettingsClick = (option) => {
    if (option === 'table_view') {
      setGlobalView('table')
      setSettingsButtonOptions(TABLE_SETTINGS_OPTIONS)
    }
    if (option === 'grid_view') {
      setGlobalView('grid')
      setSettingsButtonOptions(DEFAULT_SETTINGS_OPTIONS)
    }
  }

  useEffect(() => {
    setAvailableParticipants(participants.filter((p) => p.confirmation !== false && !p.room))
  }, [participants])

  useEffect(() => {
    handleFilterRooms()
  }, [appliedFilters, handleFilterRooms])

  useEffect(() => {
    setFilteredRooms(rooms)
  }, [rooms])

  useEffect(() => {
    const isOneAccommodation = (offsiteVenueType === 'allInVenue' &&
    offsiteVenue.accommodations &&
    offsiteVenue.accommodations?.length === 1) && !linkedVenues.length
    if (isOneAccommodation) {
      const accommodation = offsiteVenue.accommodations[0]
      setAppliedFilters({ ...appliedFilters, accommodation })
      setFullAccommodation(roomsAccommodations.find((a) => a.id === accommodation))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsAccommodations, offsiteVenue, offsiteVenueType])

  return (
    <MDBox mb={4}>
      <MDBox mt={2} mx={{ xs: 0, md: 3 }}>
        <MDTypography variant="h3" fontWeight="medium">
          Accommodations
        </MDTypography>
        <MDTypography variant="subtitle2">
          Smartway selected for you the best available accommodations in {offsiteVenue.name}. Fill
          them with guests!
        </MDTypography>
        {roomingListClosedToModification ? (
          <MDAlert color="warning" sx={{ marginBlock: '32px' }}>
            <Icon fontSize="large" sx={{ mr: 2 }}>
              warning
            </Icon>
            <MDTypography variant="body2" fontWeight="regular" color="white">
              Rooming list is closed to modification because there is less that 2 weeks before the
              offsite!
              <br />
              If you need to make a change contact your Smartway referent.
            </MDTypography>
          </MDAlert>
        ) : null}
      </MDBox>

      {roomingListClosedToModification || globalView === 'table' ? (
        <RoomingListTable
          offsiteVenue={offsiteVenue}
          participants={participants}
          rooms={rooms}
          roomsAccommodations={roomsAccommodations}
          onSettingsClick={handleSettingsClick}
        />
      ) : (
        <MDBox>
          {rooms && !!rooms.length ? (
            <MDBox
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: 'column-reverse', md: 'row' }}
              mt={{ xs: 2, md: 4 }}
              mb={{ xs: 2, md: 4 }}
              mx={{ xs: 0, md: 3 }}>
              <MDBox minWidth={{ xs: '100%', md: '300px' }}>
                {offsiteVenueType === 'town' ||
                (offsiteVenueType === 'allInVenue' && roomsAccommodations.length > 1) ? (
                  <Select
                    value={appliedFilters?.accommodation}
                    options={roomsAccommodations}
                    placeholder="All"
                    label="Selected accommodations"
                    labelProp="name"
                    valueProp="id"
                    fieldMargin={0}
                    onChange={handleAccommodationChange}
                  />
                ) : null}
              </MDBox>
              <MDBox display="flex" alignItems="center" gap={2} mb={{ xs: 3, md: 0 }}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="medium"
                  onClick={handleRandomFillAllRooms}>
                  Random fill all rooms
                  <Icon sx={{ ml: 1 }}>auto_awesome</Icon>
                </MDButton>
                <SettingsButton options={settingsButtonOptions} onClick={handleSettingsClick} />
              </MDBox>
            </MDBox>
          ) : null}

          {appliedFilters && appliedFilters.accommodation ? (
            <MDBox mx={{ xs: 0, md: 3 }}>
              <SimpleLocationCard
                {...fullAccommodation}
                cta={{
                  color: 'primary',
                  label: 'Discover more',
                  href: isSmartwayStaffOrManagedBy
                    ? `/dashboard/locations/${
                        offsiteVenueType === 'town'
                          ? 'accommodations'
                          : 'all-in-venues-accommodations'
                      }/${fullAccommodation?.id}`
                    : `https://listings.smartway.work/${
                        offsiteVenueType === 'town'
                          ? `accommodations/${fullAccommodation?.id}`
                          : `venues/${fullAccommodation?.venue}`
                      }`
                }}
              />
            </MDBox>
          ) : null}

          <Grid mt={{ xs: 0, md: 8 }} container spacing={3}>
            <Grid
              item
              md={sidebarOpen ? 4 : 1}
              sx={sidebarOpen ? {} : { flexBasis: '3% !important', maxWidth: '3% !important' }}>
              {rooms && !!rooms.length ? (
                <AccommodationsSidebar
                  open={sidebarOpen}
                  participants={participants.filter((p) => p.confirmation !== false)}
                  remainingParticipants={availableParticipants}
                  onFilterChange={handleFilterChange}
                  onOpen={handleOpenSidebar}
                  onClose={handleCloseSidebar}
                />
              ) : null}
            </Grid>
            <Grid
              item
              width="100%"
              xs={12}
              md={sidebarOpen ? 8 : 11}
              sx={
                sidebarOpen
                  ? {}
                  : {
                      flexBasis: { md: '97% !important' },
                      maxWidth: { md: '97% !important' },
                      ml: { md: '-8px' }
                    }
              }>
              <Grid container rowSpacing={6} columnSpacing={3}>
                {isSmartwayStaffOrManagedBy ? (
                  <Grid
                    item
                    {...(sidebarOpen ? { xs: 12, sm: 6, md: 6 } : { xs: 12, sm: 4, md: 4 })}>
                    <AddRoomCard onAdd={handleAddRooms} />
                  </Grid>
                ) : !rooms || !rooms.length ? (
                  <Grid
                    item
                    {...(sidebarOpen ? { xs: 12, sm: 6, md: 6 } : { xs: 12, sm: 4, md: 4 })}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center">
                      <MDBox
                        component="img"
                        src={underConstruction}
                        alt="no results"
                        width="100%"
                        maxHeight={200}
                        mb={3}
                      />
                      <MDTypography mb={2} variant="button" textAlign="center">
                        There are still no accommodation to choose! <br /> It could be for two
                        reasons: <br /> there are no participants for the offsite or Smartway does
                        not have selected all the accommodations yet.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                ) : null}
                {filteredRooms && !!filteredRooms.length ? (
                  filteredRooms.map((room) => {
                    let accommodation = {}
                    let accommodationVenue = {}
                    const _accommodation = roomsAccommodations?.find(
                      (a) => a.id === room.detail.accommodation
                    )
                    if (_accommodation) {
                      const { id, town, venue, name } = _accommodation
                      const accommodationVenueType = town ? 'town' : 'allInVenue'
                      const _venue = multipleVenues ? allVenues.find(v => {
                        return v.id === (town ? town : venue) && v.type === accommodationVenueType
                      }) : null
                      accommodation = {
                        id, town, venue, name
                      }

                      if (_venue) {
                        const { id, name } = _venue
                        accommodationVenue = {
                          id, name
                        }
                      }
                    }
                    return (
                      <Grid
                        item
                        {...(sidebarOpen ? { xs: 12, sm: 6, md: 6 } : { xs: 12, sm: 4, md: 4 })}
                        key={room.id}>
                        <RoomCard
                          {...room}
                          accommodation={accommodation}
                          venue={accommodationVenue}
                          isLoading={roomsLoading.includes(room.id)}
                          participants={participants}
                          remainingParticipants={availableParticipants}
                          canRemove={isSmartwayStaffOrManagedBy}
                          onDone={onDoneChange}
                          onChange={onParticipantChange}
                          onRandomFill={handleRandomFillRoom}
                          onRemove={handleRemoveRoom}
                          onClean={handleRemoveParticipantsFromRoom}
                        />
                      </Grid>
                    )
                  })
                ) : rooms && !!rooms.length ? (
                  <Grid item xs={12}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center">
                      <MDBox
                        component="img"
                        src={noResults}
                        alt="no results"
                        width="100%"
                        maxHeight={200}
                        mb={3}
                      />
                      <MDTypography variant="body">No results found!</MDTypography>
                    </MDBox>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}

      {isSmartwayStaffOrManagedBy ? (
        <OffsiteRoomsDialog
          offsite={offsite}
          rooms={rooms}
          venues={[{ ...offsiteVenue, type: offsiteVenueType }, ...linkedVenues]}
          venueType={offsiteVenueType}
          open={roomsDialogOpen}
          setOpen={handleOpenRoomsDialog}
          onClose={handleCloseRoomsDialog}
          onSave={handleSaveRooms}
        />
      ) : null}

      <MDBox
        sx={{
          position: 'sticky',
          bottom: 0,
          width: 'calc(100% + 48px)',
          background: 'white',
          zIndex: 3,
          mx: '-24px',
          mt: 8,
          display: 'flex',
          justifyContent: 'flex-end',
          boxShadow: '0px -8px 15px -3px rgba(0,0,0,0.1)',
          animationName: 'shadowAnimation',
          animationDuration: '0.3s',
          animationTimeline: 'scroll()',
          animationRange: '0vh 100vh',
          '@keyframes shadowAnimation': {
            '0%': {
              boxShadow: '0px -8px 15px -3px rgba(0,0,0,0.1)'
            },
            '10%': {
              boxShadow: '0px -8px 15px -3px rgba(0,0,0,0.1)'
            },
            '100%': {
              boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)'
            }
          }
        }}>
        <MDBox p={2} width="100%" display="flex" justifyContent="space-between">
          {offsite && offsite.id ? (
            <Link to={`/dashboard/offsites/${offsite.id}/agenda`}>
              <MDButton variant="outlined" color="dark">
                Go back
              </MDButton>
            </Link>
          ) : null}
          <Link to={`/dashboard/offsites/${offsite.id}/review`}>
            <LoadingButton
              sx={{
                color: 'white !important',
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.light' }
              }}
              variant="contained">
              Next
            </LoadingButton>
          </Link>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default AccommodationStep
