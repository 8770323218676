import React from 'react'

import CircularProgress from "@mui/material/CircularProgress";

import MDBox from "components/MDBox";


function Spinner() {
    return (
        <MDBox 
            width="100%"
            height="200px"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <CircularProgress />
        </MDBox>
    )
}

export default Spinner