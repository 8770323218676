import { Link } from 'react-router-dom'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: '10',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const Notification = ({ id, title, message, icon, color, link, createdAt, read, onRead }) => {
  const handleOnRead = () => {
    onRead(id)
  }

  return (
    <Card 
      position="relative"
      sx={read ? { 
        boxShadow: 'none', backgroundColor: 'grey.100',
        '&:hover': {
          '.notification__icon': {
            opacity: '1 !important',
            transform: 'scale(1)'
          }
        }
      } : {
        '&:hover': {
          '.notification__icon': {
            opacity: '1 !important',
            transform: 'scale(1)'
          }
        }
      }}>
      <MDBox display="flex" px={2} py={1}>
        { !read ? (
          <MDBox
            className="notification__icon"
            sx={iconStyles}
            onClick={handleOnRead}
          >
            <Icon fontSize="small">check</Icon>
          </MDBox>
        ) : null }
        <Link to={link}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            zIndex={2}
            mt={0.5}
            sx={{ fontSize: ({ typography: { size } }) => size.sm }}>
            <Icon color="white" fontSize="inherit">
              {icon}
            </Icon>
          </MDBox>
        </Link>
        
        <MDBox ml={2} pt={message ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
          <Link to={link}>
            <MDTypography variant="button" fontWeight="medium" color="dark">
              {title}
            </MDTypography>

            <MDBox mt={0.5}>
              <MDTypography variant="caption" color="text">
                {createdAt}
              </MDTypography>
            </MDBox>
          </Link>
          <MDBox mt={1} mb={1.5}>
            {message ? (
              <MDTypography variant="button" color="dark">
                {message}
              </MDTypography>
            ) : null}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default Notification
