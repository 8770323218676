import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import {
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDButton from 'components/MDButton'

import Form from 'components/Form'
import getSectionSchema from './schema'

import SectionExtraFields from './SectionExtraFields'
import { formatSectionValuesFromServer } from 'utils/pages'

const RepeaterItemDialog = ({
  open,
  page,
  type,
  title,
  repeaterFields = {},
  onSave,
  onSaveEntity,
  onDeleteEntity,
  onMediaDelete,
  onClose,
  item,
  parentSection
}) => {
  const dispatch = useDispatch()
  const [currentItem, setCurrentItem] = useState(formatSectionValuesFromServer(item))
  const itemSchema = getSectionSchema({ type, title, isRepeaterItem: true })
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      setCurrentItem({})
      onClose()
    }
  }
  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      const newItem = { ...currentItem, ...values }
      onSave(newItem)
    }
  }

  const handleSaveEntity = (section, entity) => {
    if (onSaveEntity && typeof onSaveEntity === 'function') {
      onSaveEntity(section, entity, true)
    }
  }

  const handleMediaSave = async (createdSection) => {
    const newItem = await dispatch(
      smartwayApi.endpoints.fetchPageEntity.initiate({
        entity: 'sections',
        id: createdSection ? createdSection.id : item.id,
        subentity: 'page',
        pageId: page.id
      })
    )
    if (newItem && newItem.data) {
      setCurrentItem(newItem.data)
    }
  }

  useEffect(() => {
    setCurrentItem(formatSectionValuesFromServer(item))
  }, [item])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Grid container>
            <Form
              {...itemSchema}
              id={`repeater-item-form-${item && item.id ? item.id : 'new'}`}
              onSubmit={handleOnSave}
              values={currentItem}
            />

            <SectionExtraFields
              type={type}
              section={currentItem}
              page={page}
              sectionFields={repeaterFields}
              onSaveEntity={handleSaveEntity}
              onDeleteEntity={onDeleteEntity}
              onMediaSave={handleMediaSave}
              onMediaDelete={onMediaDelete}
              inRepeaterSection
              parentSection={parentSection}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Close</MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            type="submit"
            form={`repeater-item-form-${item && item.id ? item.id : 'new'}`}>
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default RepeaterItemDialog
