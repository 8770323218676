import { useState, useEffect } from 'react'

import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import GuestsCounter from './GuestsCounter'
import RoomsFilters from './RoomsFilters'
import RoomBeds from 'components/RoomBeds'

const getPercentage = (participants, remainingParticipants) => {
  const participantsLength = participants.length
  const remainingParticipantsLength = remainingParticipants.length
  const booked = participantsLength - remainingParticipantsLength
  return (booked / participantsLength) * 100
}

const AccommodationsSidebar = ({
  participants,
  remainingParticipants,
  open,
  onFilterChange,
  onOpen,
  onClose
}) => {
  const [_participants, setParticipants] = useState(participants)
  const [_remainingParticipants, setRemainingParticipants] = useState(participants)
  const [percentage, setPercentage] = useState(
    getPercentage(participants, remainingParticipants)
  )
  const [guestsExpanded, setGuestsExpanded] = useState(false)

  const toggleGuestsExpanded = () => {
    setGuestsExpanded(!guestsExpanded)
  }

  const handleFilterChange = (event) => {
    onFilterChange(event)
  }

  useEffect(() => {
    // console.log('AccommodationsSidebar', participants)
    setPercentage(getPercentage(participants, remainingParticipants))
  }, [participants, remainingParticipants])

  useEffect(() => {
    setParticipants(participants)
    setRemainingParticipants(remainingParticipants)
  }, [participants, remainingParticipants])

  return open ? (
    <Card sx={{ position: 'relative', minWidth: '200px', width: '100%', border: '1px solid #e2e2e2' }}>
      <MDButton
        sx={{ position: 'absolute', top: '-5px', right: '-2px' }}
        color="primary"
        iconOnly
        onClick={onClose}>
        <Icon>close</Icon>
      </MDButton>
      <MDBox p={2}>
        <MDBox mt={2}>
          <RoomsFilters onChange={handleFilterChange} />
        </MDBox>
        <MDBox align="center" mt={2}>
          <GuestsCounter
            value={_participants.length - _remainingParticipants.length}
            total={_participants.length}
            percentage={percentage}
          />
          <MDTypography variant="body2" color="text">
            Guests accommodated
          </MDTypography>
        </MDBox>
        {!!_remainingParticipants.length ? (
          <MDBox mt={2}>
            <Accordion
              expanded={guestsExpanded}
              sx={{ boxShadow: 'none' }}
              onChange={toggleGuestsExpanded}>
              <AccordionSummary
                sx={{
                  backgroundColor: 'transparent !important',
                  minHeight: '48px !important',
                  '> .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '12px 0 !important'
                  }
                }}
                expandIcon={<ExpandMoreIcon />}>
                <MDTypography variant="button" fontWeight="bold">
                  Remaining guests
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: '300px',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '4px'
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#eee',
                    borderRadius: '2px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#ccc',
                    borderRadius: '2px'
                  }
                }}>
                {_remainingParticipants
                  .reduce(
                    (acc, curr) => {
                      const { replied_at } = curr
                      if (replied_at) {
                        acc
                          .find(({ type }) => type === 'confirmed')
                          .participants.push(curr)
                      } else {
                        acc
                          .find(({ type }) => type === 'pending')
                          .participants.push(curr)
                      }
                      return acc
                    },
                    [
                      {
                        type: 'confirmed',
                        label: 'Confirmed',
                        participants: []
                      },
                      {
                        type: 'pending',
                        label: 'Confirmation pending',
                        participants: []
                      }
                    ]
                  )
                  .map(({ type, label, participants }) => {
                    return !!participants.length ? (
                      <MDTypography
                        key={label}
                        variant="button"
                        sx={{
                          display: 'block',
                          fontWeight: 'medium',
                          mb: 1,
                          color:
                            type === 'confirmed'
                              ? 'success.main'
                              : 'warning.main'
                        }}>
                        {label}
                        {participants.map(({ first_name, last_name, room_preference,...p }) => (
                          <MDTypography
                            key={p.id}
                            variant="button"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>{`${first_name} ${last_name}`} {room_preference ? <RoomBeds category={room_preference} /> : null}</MDTypography>
                        ))}
                      </MDTypography>
                    ) : null
                  })}
              </AccordionDetails>
            </Accordion>
          </MDBox>
        ) : null}
      </MDBox>
    </Card>
  ) : (
    <MDButton sx={{ ml: -5 }} color="primary" iconOnly onClick={onOpen}>
      <Icon>settings</Icon>
    </MDButton>
  )
}

export default AccommodationsSidebar
