import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React examples
import SidenavCollapse from 'components/Sidenav/SidenavCollapse'
import SidenavList from 'components/Sidenav/SidenavList'
import SidenavItem from 'components/Sidenav/SidenavItem'

// Custom styles for the Sidenav
import SidenavRoot from 'components/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'components/Sidenav/styles/sidenav'

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
} from 'context'
import { triggerVercelBuild } from 'services/api'

const Sidenav = ({ color, brand, brandName, routes, miniSidenav, setMiniSidenav, ...rest }) => {
  const reduxDispatch = useDispatch()
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false)
  const [controller] = useMaterialUIController()
  const { transparentSidenav, whiteSidenav, darkMode } = controller
  const location = useLocation()
  const { pathname } = location
  const collapseName = pathname.split('/').slice(1)[0]
  const items = pathname.split('/').slice(1)
  const itemParentName = items[1]
  const itemName = items[items.length - 1]
  const isStaff = useSelector((state) => state.auth.profile.is_staff)

  let textColor = 'white'


  const handleBuildTrigger = async () => {
    try {
      const response = await triggerVercelBuild()
      if (response) {
        reduxDispatch(notify({ type: 'success', title: 'Build triggered successfully!', message: 'It will take a few minutes to complete' }))
      }
    } catch (error) {
      reduxDispatch(notify({ type: 'error', title: 'Build not triggered', message: 'Something went wrong' }))
    }
  }

  const closeSidenav = () => setMiniSidenav(true)

  useEffect(() => {
    setOpenCollapse(collapseName)
    setOpenNestedCollapse(itemParentName)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    const handleMiniSidenav = () => {
      setMiniSidenav(window.innerWidth < 1200)
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav)
  }, [location])

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    )

    return template
  }
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            miniSidenav={miniSidenav}
            active={key === itemParentName ? 'isParent' : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains('MuiListItem-root')
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }>
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        )
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}>
            <SidenavItem color={color} name={name} active={key === itemName} miniSidenav={miniSidenav}/>
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
            <SidenavItem color={color} name={name} active={key === itemName} miniSidenav={miniSidenav}/>
          </NavLink>
        )
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>
    })

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      divider
    }) => {
      let returnValue

      if (type === 'collapse') {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={`${key}-${name}-collapse-href`}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                miniSidenav={miniSidenav}
                noCollapse={noCollapse}
              />
            </Link>
          )
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={`${key}-${name}-collapse-no`}>
              <SidenavCollapse
                name={name}
                icon={icon}
                miniSidenav={miniSidenav}
                noCollapse={noCollapse}
                active={key === collapseName}>
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          )
        } else {
          returnValue = (
            <span key={`${key}-${name}-collapse-default`}>
              <SidenavCollapse
                miniSidenav={miniSidenav}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key)
                }>
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
              {divider ? (
                <Divider
                  key={`${key}-${name}-divider`}
                  light
                />
              ) : null}
            </span>
          )
        }
      } else if (type === 'title') {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}>
            {title}
          </MDTypography>
        )
      } else if (type === 'route') {
        returnValue = (
          <MDBox mx={2} key={`${key}-${name}-default`}>
            {href ? (
              <Link
                key={key}
                href={href}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none' }}>
                <SidenavItem icon={icon} name={name} nested miniSidenav={miniSidenav}/>
              </Link>
            ) : (
              <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
                <SidenavItem name={name} icon={icon} active={route === pathname} nested miniSidenav={miniSidenav}/>
              </NavLink>
            )}
          </MDBox>
        )
      } else if (type === 'divider') {
        returnValue = (
          <Divider
            key={`${key}-${name}-divider`}
            light
          />
        )
      }

      return returnValue
    }
  )

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ miniSidenav }}>
      <MDBox display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <MDBox>
          <MDBox pt={3} pb={1} px={4} textAlign="center">
            <MDBox
              display={{ xs: 'block', xl: 'none' }}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              onClick={closeSidenav}
              sx={{ cursor: 'pointer' }}>
              <MDTypography variant="h6" color="secondary">
                <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
              </MDTypography>
            </MDBox>
            <MDBox component={NavLink} to="/" display="flex" alignItems="center">
              {brand && (
                <MDBox component="img" src={brand} alt="Brand" width="8rem" maxHeight="2rem" />
              )}
              <MDBox
                width={!brandName && '100%'}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
                <MDTypography
                  component="h6"
                  variant="button"
                  fontWeight="medium"
                  color={textColor}>
                  {brandName}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider light/>
          <List>{renderRoutes}</List>
        </MDBox>
        { isStaff ? (
          <MDBox py={3} px={miniSidenav ? 2 : 4}>
            <MDButton variant="outlined" color="white" onClick={handleBuildTrigger}>
              <Icon sx={{ mr: miniSidenav ? 0 : 1 }}>settings</Icon>
              {miniSidenav ? '' : 'Build public site'}
            </MDButton>
          </MDBox>
        ) : null }
      </MDBox>

    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: ''
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark'
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Sidenav
