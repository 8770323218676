import Form from 'components/Form'
import getCompanySchema from './schema'

import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

const CompanyForm = ({ company, inDialog = false, onSubmit, onCancel }) => {
  const handleSubmit = (values) => {
    onSubmit({ ...company, ...values })
  }

  const schema = getCompanySchema({ isCompany: !!company, inDialog })

  const CompanyFormWrapper = ({ inDialog, children }) => {
    return inDialog ? <MDBox>{children}</MDBox> : <Card>{children}</Card>
  }

  return (
    <CompanyFormWrapper inDialog>
      <Form
        {...schema}
        values={{
          ...company,
          logo: company?.logo_formats,
          logo_negative: company?.logo_negative_formats,
        }}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </CompanyFormWrapper>
  )
}

export default CompanyForm
