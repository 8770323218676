import { useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

const AgendaDaysStep = ({ onChange }) => {
  const [daysNumber, setDaysNumber] = useState(2)

  const handleAdd = () => {
    setDaysNumber(daysNumber + 1)
  }
  const handleRemove = () => {
    setDaysNumber(daysNumber - 1)
  }

  const handleNext = () => {
    onChange(daysNumber)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8}>
        <Card>
          <MDBox p={8}>
            <MDTypography variant="h5">
              Choose for which type of venue you want to create this agenda
            </MDTypography>
            <MDBox display="flex" alignItems="center" flexDirection="column" gap={2}>
              <MDBox display="flex" justifyContent="center" gap={3} mt={4}>
                <MDBox onClick={handleRemove} px={4} py={2} sx={{ userSelect: 'none', cursor: 'pointer', pointerEvents: daysNumber === 1 ? 'none' : 'auto'}} >
                  <MDTypography variant="h1" sx={{color: daysNumber === 1 ? 'rgba(0, 0, 0, 0.26)' : 'inherit' }}>
                    -
                  </MDTypography>
                </MDBox>
                <MDBox>
                <MDTypography variant="h1" py={2} sx={{ userSelect: 'none' }}>
                  {daysNumber}
                  </MDTypography>
                </MDBox>
                <MDBox onClick={handleAdd} px={4} py={2} sx={{ userSelect: 'none', cursor: 'pointer' }}>
                  <MDTypography variant="h1">
                    +
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <MDTypography variant="body" sx={{ userSelect: 'none' }}>{daysNumber} day{daysNumber > 1 ? 's' : ''} {(daysNumber - 1) > 0 ? `/ ${daysNumber - 1} night${daysNumber > 2 ? 's' : ''}` : ''}</MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox px={8} pb={8} display="flex" justifyContent="flex-end">
            <MDButton variant="gradient" color="primary" onClick={handleNext}>Next</MDButton>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AgendaDaysStep
