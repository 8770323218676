import { grey } from '@mui/material/colors'

const getCommonPinningStyles = (column, row) => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right')

  const getBackground = () => {
    if (isPinned) {
      if (row && row.getIsSelected()) {
        return '#ffecf3'
      }
      return 'white'
    }
    return undefined
  }

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '#f3f3f3 -10px 0px 10px -10px inset'
      : isFirstRightPinnedColumn
        ? '#f3f3f3 10px 0px 10px -10px inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    background: getBackground()
  }
}

const getRowStyles = (row) => {
  return row.getIsSelected() ? {
    backgroundColor: '#ffecf3'
  } : {}
}

const getCheckboxStyles = () => {
  return {
    sx: {
      color: grey[700],
      '&.Mui-checked': {
        color: grey[700],
        '.MuiSvgIcon-root': {
          borderColor: `${grey[700]}`,
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, ${grey[700]} 0%, ${grey[700]} 100%)`
        }
      },
    }
  }
}

export { getCommonPinningStyles, getRowStyles, getCheckboxStyles }