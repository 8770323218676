export const getMainImage = (images) => {
  if (!images) return null
  const featured = images.find(image => image.featured)
  if (featured) return featured
  return images[0]
}

// This should be a temporary check
// normally there will not be a situation where an image exists but has no formats
export const getBestFormat = (formats, format) => {
  const isFormats = !!formats && !!Object.keys(formats).length
  const imagePriority = ['thumbnail', 'uploaded', 'desktop', 'mobile']

  const getFirstPriorityImage = () => {
    let image
    if (formats[format]) {
      image = formats[format]
    } else {
      for (let i = 0; i < imagePriority.length; i++) {
        if (formats[imagePriority[i]]) {
          image = formats[imagePriority[i]]
          break
        }
      }
    }
    return image
  }

  return isFormats ? getFirstPriorityImage() : ''
}