import Form from 'components/Form'
import schema from './schema'

const BasicInfoForm = ({ profile, onSubmit, onCancel }) => {
  const handleSubmit = (values) => {
    const { birthdate } = values
    if (!!birthdate && new Date(birthdate) !== 'Invalid Date') {
      // TODO: this is not working
      // values.birthdate = format(new Date(value), 'yyyy-MM-dd')
    }
    const toExclude = ['last_login', 'user_id', 'username', 'preferences']
    const cleanProfile = {}
    Object.keys(profile).forEach((key) => {
      if (!toExclude.includes(key)) {
        cleanProfile[key] = profile[key]
      }
    })
    onSubmit({ ...cleanProfile, ...values })
  }
  
  return (
    <Form
      {...schema}
      values={{...profile, birthdate: profile.birthdate ? new Date(profile.birthdate) : null}}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  )
}

export default BasicInfoForm
