import { Suspense } from 'react'
import { Await, useLoaderData, useRouteLoaderData } from 'react-router-dom'

import { format } from 'date-fns'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'

import Image from 'components/Image'
import { getMainImage } from 'utils/image'
import { getDaysDiffBetweenDates, getMinutesDiffBetweenDates } from 'utils/dates'
import { checkActivityWarnings } from 'utils/events'

import { ReviewSkeleton, AgendaSkeleton } from '../components/review/Skeletons'
import underConstruction from 'assets/illustrations/under-construction.svg'

const ReviewStep = ({ parent = 'offsite' }) => {
  const { offsite, offsiteVenue } = useRouteLoaderData(`${parent}-detail`)
  const { participants, agendas } = useLoaderData()

  const { indicative_number_days, start_at, end_at } = offsite
  const daysNumber = indicative_number_days
    ? indicative_number_days
    : Math.ceil(getDaysDiffBetweenDates(new Date(start_at).getTime(), new Date(end_at).getTime()) + 1)
  const nightNumber = daysNumber - 1

  return offsite ? (
    <MDBox mb={4}>
      <MDBox mt={2} mx={{ xs: 0, md: 3 }}>
        <MDTypography variant="h3" fontWeight="medium">
          Review
        </MDTypography>
        <MDTypography variant="subtitle2">
          Here are all the details of your upcoming offsite.
        </MDTypography>
      </MDBox>

      <MDBox mx={{ xs: 0, md: 3 }} mt={4} mb={{ xs: 8, md: 2 }}>
        <MDBox display="flex" flexWrap={{ xs: 'wrap', md: 'nowrap' }} gap={4}>
          <MDBox width={{ xs: '100%', md: '40%' }} height="auto">
            <Image
              {...(offsiteVenue.featured_image || getMainImage(offsiteVenue.images))}
              wrapperStyles={{ height: '100%' }}
            />
          </MDBox>
          <MDBox width={{ xs: '100%', md: '60%' }}>
            <MDTypography variant="h3" fontWeight="light">
              {offsiteVenue.name}
            </MDTypography>

            <MDBox mt={2}>
              <MDTypography variant="button" fontWeight="bold">
                When
              </MDTypography>
              <MDTypography variant="body2">
                From {format(new Date(offsite.start_at), 'dd/MM')} to{' '}
                {format(new Date(offsite.end_at), 'dd/MM')} ({daysNumber} day
                {daysNumber === 1 ? '' : 's'}, {nightNumber} night
                {nightNumber === 1 ? '' : 's'})
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              <MDTypography variant="button" fontWeight="bold">
                How many
              </MDTypography>
              <MDBox>
                <Suspense fallback={<ReviewSkeleton />}>
                  <Await errorElement={<div>Error</div>} resolve={participants}>
                    {(participants) => {
                      const declined = participants.filter((p) => p.confirmation === false)
                      const notDeclined = participants.filter((p) => p.confirmation !== false)
                      const notInvited = notDeclined.filter((p) => !p.last_invited_at)
                      const notRegistered = notDeclined.filter((p) => p.last_invited_at && !p.user)
                      const needConfirmations =
                        notDeclined && !!notDeclined.length
                          ? notDeclined.filter((p) => p.user && p.confirmation === null)
                          : []
                      const participantsWarning = declined.length || notInvited.length || notRegistered.length || needConfirmations.length
                      const needRoom =
                        notDeclined && !!notDeclined.length
                          ? notDeclined.filter((p) => !p.room)
                          : []
                      return (
                        <MDBox>
                          {participants.length ? (
                            <MDTypography variant="body2">
                              {participants.length} guest
                              {participants.length === 1 ? '' : 's'}
                            </MDTypography>
                          ) : null}
                          {participantsWarning ? (
                            <MDBox>
                              { declined.length ? (
                                <MDTypography variant="body2" display="flex" alignItems="center">
                                  <Icon sx={{ mr: 1 }} color="error">
                                    close
                                  </Icon>
                                  {declined.length} guest
                                  {declined.length === 1 ? '' : 's'} declined
                                </MDTypography>
                              ) : null }
                              { notInvited.length ? (
                                <MDTypography variant="body2" display="flex" alignItems="center">
                                  <Icon sx={{ mr: 1 }} color="warning">
                                    warning
                                  </Icon>
                                  {notInvited.length} guest
                                  {notInvited.length === 1 ? '' : 's'} still need to be invited
                                </MDTypography>
                              ) : null }
                              { notRegistered.length ? (
                                <MDTypography variant="body2" display="flex" alignItems="center">
                                <Icon sx={{ mr: 1 }} color="warning">
                                  warning
                                </Icon>
                                {notRegistered.length} guest
                                {notRegistered.length === 1 ? '' : 's'} still need to register and confirm
                              </MDTypography>
                              ) : null }
                              { needConfirmations.length ? (
                                <MDTypography variant="body2" display="flex" alignItems="center">
                                  <Icon sx={{ mr: 1 }} color="warning">
                                    warning
                                  </Icon>
                                  {needConfirmations.length} guest
                                  {needConfirmations.length === 1 ? '' : 's'} still need to confirm
                                  {needConfirmations.length === 1 ? ' its' : ' their'} participation
                                </MDTypography>
                                
                              ) : null }

                            </MDBox>
                          ) : (
                            <MDTypography variant="body2" display="flex" alignItems="center">
                              <Icon sx={{ mr: 1 }} color="warning">
                                check
                              </Icon>
                              Everyone has confirmed their participation
                            </MDTypography>
                          )}
                          <MDBox mt={3}>
                            <MDTypography variant="button" fontWeight="bold">
                              Rooming list
                            </MDTypography>
                            {needRoom.length ? (
                              <MDTypography variant="body2" display="flex" alignItems="center">
                                <Icon sx={{ mr: 1 }} color="warning">
                                  warning
                                </Icon>
                                {needRoom.length} guest
                                {needRoom.length === 1 ? '' : 's'} still need a room
                              </MDTypography>
                            ) : (
                              <MDTypography variant="body2" display="flex" alignItems="center">
                                <Icon sx={{ mr: 1 }} color="success">
                                  check
                                </Icon>
                                Everyone has a place to stay!
                              </MDTypography>
                            )}
                          </MDBox>
                          <MDBox mt={2}>
                            <MDTypography variant="button" fontWeight="bold">
                              Agenda
                            </MDTypography>
                            <MDBox>
                              <Suspense fallback={<AgendaSkeleton />}>
                                <Await errorElement={<div></div>} resolve={agendas}>
                                  {(a) => {
                                    const agenda = a.results[0]
                                    const isActivities =
                                      agenda.activities && agenda.activities.length
                                    const activities = isActivities
                                      ? agenda.activities.filter((act) =>
                                          ['EXPERIENTIAL_ACTIVITY', 'CUSTOM'].includes(act.type)
                                        )
                                      : []
                                    const warnings = activities.reduce((acc, curr) => {
                                      const { start_at, end_at } = curr
                                      const slotMinutesDuration = getMinutesDiffBetweenDates(
                                        new Date(start_at).getTime(),
                                        new Date(end_at).getTime()
                                      )
                                      const numParticipants = participants.length
                                      const currWarnings = checkActivityWarnings({
                                        activity: curr.location,
                                        slotMinutesDuration,
                                        numParticipants
                                      })
                                      if (currWarnings.length) {
                                        currWarnings.forEach((w) => acc.push(w))
                                      }
                                      return acc
                                    }, [])
                                    return (
                                      <MDBox>
                                        <MDTypography variant="body2">
                                          {activities.length} activit
                                          {activities.length === 1 ? 'y' : 'ies'}
                                        </MDTypography>
                                        {warnings.length ? (
                                          <MDTypography
                                            variant="body2"
                                            display="flex"
                                            alignItems="center">
                                            <Icon sx={{ mr: 1 }} color="warning">
                                              warning
                                            </Icon>
                                            {warnings.length} issue
                                            {warnings.length === 1 ? '' : 's'} to check about the
                                            agenda.{' '}
                                          </MDTypography>
                                        ) : (
                                          <MDTypography
                                            variant="body2"
                                            display="flex"
                                            alignItems="center">
                                            <Icon sx={{ mr: 1 }} color="success">
                                              check
                                            </Icon>
                                            Ready to go! Everything looks good.
                                          </MDTypography>
                                        )}
                                      </MDBox>
                                    )
                                  }}
                                </Await>
                              </Suspense>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      )
                    }}
                  </Await>
                </Suspense>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  ) : (
    <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <MDBox
        component="img"
        src={underConstruction}
        alt="no results"
        width="100%"
        maxHeight={200}
        mb={3}
      />
      <MDTypography mb={2} variant="button" textAlign="center">
        You still need to build the perfect offsite!
      </MDTypography>
    </MDBox>
  )
}

export default ReviewStep
