import MDBox from "components/MDBox"

import single from "assets/icons/single.svg"
import double from "assets/icons/double.svg"
import french from "assets/icons/french.svg"
import suite from "assets/icons/suite.svg"
import apartment from "assets/icons/apartment.svg"

const RoomBeds = ({ category }) => {
  const SingleIcon = () => <MDBox component="img" src={single} ml={1}></MDBox>
  const DoubleIcon = () => <MDBox component="img" src={double} ml={1}></MDBox>
  const FrenchBedIcon = () => <MDBox component="img" src={french} ml={1}></MDBox>
  const SuiteIcon = () => <MDBox component="img" src={suite} ml={1}></MDBox>
  const ApartmentIcon = () => <MDBox component="img" src={apartment} ml={1}></MDBox>
  switch (category) {
    case 'SINGLE':
      return <SingleIcon />
    case 'DOUBLE':
      return <DoubleIcon />
    case 'TWIN':
      return (
        <>
          <SingleIcon />
          <SingleIcon />
        </>
      )
    case 'TRIPLE':
      return (
        <>
          <SingleIcon />
          <SingleIcon />
          <SingleIcon />
        </>
      )
    case 'TRIPLE_1':
      return (
        <>
          <DoubleIcon />
          <SingleIcon />
        </>
      )
    case 'FRENCH_BED':
      return <FrenchBedIcon />
    case 'SUITE': 
      return <SuiteIcon />
    case 'APARTMENT': 
      return <ApartmentIcon />
    default:
      break
  }
}

export const getRoomCategory = ({ category }) => {
  switch (category) {
    case 'SINGLE':
      return '1 single room'
    case 'DOUBLE':
      return '1 double room'
    case 'TWIN':
      return '1 twin room'
    case 'TRIPLE':
      return '1 triple room (3 single beds)'
    case 'TRIPLE_1':
      return '1 triple room (1 double bed, 1 single bed)'
    case 'FRENCH_BED':
      return '1 french bed room'
    case 'SUITE': 
      return '1 suite'
    case 'APARTMENT': 
      return '1 apartment'
    default:
      break
  }
}

export default RoomBeds