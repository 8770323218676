import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'
import MDButton from 'components/MDButton'
import Filters from 'components/Filters'
const FiltersDialog = ({ open, initialFilters, appliedFilters, searchParams, onApply, onClose }) => {

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const handleOnApply = (values) => {
    onApply(values)
  }

  const handleOnClear = () => {
    onApply({})
  }

  return (
    <Dialog 
      key={open}
      open={open}
      maxWidth="md"
      sx={{ marginLeft: { xl: '250px' }}}
      fullWidth>
      <DialogTitle sx={{paddingInline:`24px`}}>Filters</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Icon>close</Icon>
      </IconButton>
      <DialogContent dividers sx={{ px: 3, py: 2 }}>
        <Filters
          initialFilters={initialFilters}
          appliedFilters={appliedFilters}
          searchParams={searchParams}
          onApply={handleOnApply}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <MDButton variant="outlined" color="secondary" onClick={handleOnClear}>Clear all</MDButton>
        <MDButton
          form="filters-form"
          type="submit"
          variant="contained"
          color="primary">
          Show results
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}

export default FiltersDialog